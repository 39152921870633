"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationDecoder = exports.CurrentStepDecoder = exports.InviteTeamMembersStepDataDecoder = exports.SetupProfileStepDataDecoder = exports.SetupOrganizationStepDataDecoder = exports.SetupInvitationTokenStepDataDecoder = exports.JoinableOrganizationMetadataDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
/**
 * Decoder for joinable organization metadata.
 */
exports.JoinableOrganizationMetadataDecoder = ts_data_json_1.JsonDecoder.nullable(ts_data_json_1.JsonDecoder.object({
    id: ts_data_json_1.JsonDecoder.string,
    name: ts_data_json_1.JsonDecoder.string,
}, 'JoinableOrganizationMetadata'));
/**
 * Decoders for data specific for each registration step.
 */
exports.SetupInvitationTokenStepDataDecoder = ts_data_json_1.JsonDecoder.object({
    invitationToken: ts_data_json_1.JsonDecoder.nullable(ts_data_json_1.JsonDecoder.string),
}, 'SetupInvitationTokenStepData');
exports.SetupOrganizationStepDataDecoder = ts_data_json_1.JsonDecoder.object({
    newOrganizationName: ts_data_json_1.JsonDecoder.nullable(ts_data_json_1.JsonDecoder.string),
}, 'SetupOrganizationStepData');
exports.SetupProfileStepDataDecoder = ts_data_json_1.JsonDecoder.object({
    name: ts_data_json_1.JsonDecoder.string,
    role: ts_data_json_1.JsonDecoder.string,
    roleDescription: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.nullable(ts_data_json_1.JsonDecoder.string)),
}, 'SetupProfileStepData');
exports.InviteTeamMembersStepDataDecoder = ts_data_json_1.JsonDecoder.object({
    emails: ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.string, 'string[]'),
}, 'InviteTeamMembersStepData');
/**
 * Decoder for currentStep attribute
 */
exports.CurrentStepDecoder = ts_data_json_1.JsonDecoder.oneOf([
    ts_data_json_1.JsonDecoder.isExactly('SetupOrganizationStep'),
    ts_data_json_1.JsonDecoder.isExactly('SetupProfileStep'),
    ts_data_json_1.JsonDecoder.isExactly('InviteTeamMembersStep'),
    ts_data_json_1.JsonDecoder.isExactly('Finishing'),
    ts_data_json_1.JsonDecoder.isExactly('Finished'),
], 'CurrentStep');
/**
 * Main decoder for Registration type.
 * It resolves to one of subtypes depending on the currentStep attribute.
 */
exports.RegistrationDecoder = ts_data_json_1.JsonDecoder.object({ currentStep: exports.CurrentStepDecoder }, 'WithCurrentStep').then(function (_a) {
    var currentStep = _a.currentStep;
    switch (currentStep) {
        case 'SetupOrganizationStep':
            return ts_data_json_1.JsonDecoder.object({
                currentStep: ts_data_json_1.JsonDecoder.isExactly('SetupOrganizationStep'),
                joinableOrganizationMetadata: exports.JoinableOrganizationMetadataDecoder,
                setupInvitationTokenStepData: exports.SetupInvitationTokenStepDataDecoder,
            }, 'SetupOrganizationStep');
        case 'SetupProfileStep':
            return ts_data_json_1.JsonDecoder.object({
                currentStep: ts_data_json_1.JsonDecoder.isExactly('SetupProfileStep'),
                joinableOrganizationMetadata: exports.JoinableOrganizationMetadataDecoder,
                setupInvitationTokenStepData: exports.SetupInvitationTokenStepDataDecoder,
                setupOrganizationStepData: exports.SetupOrganizationStepDataDecoder,
            }, 'SetupProfileStep');
        case 'InviteTeamMembersStep':
            return ts_data_json_1.JsonDecoder.object({
                currentStep: ts_data_json_1.JsonDecoder.isExactly('InviteTeamMembersStep'),
                joinableOrganizationMetadata: exports.JoinableOrganizationMetadataDecoder,
                setupInvitationTokenStepData: exports.SetupInvitationTokenStepDataDecoder,
                setupOrganizationStepData: exports.SetupOrganizationStepDataDecoder,
                setupProfileStepData: exports.SetupProfileStepDataDecoder,
            }, 'InviteTeamMembersStep');
        case 'Finishing':
            return ts_data_json_1.JsonDecoder.object({
                currentStep: ts_data_json_1.JsonDecoder.isExactly('Finishing'),
                joinableOrganizationMetadata: exports.JoinableOrganizationMetadataDecoder,
                setupInvitationTokenStepData: exports.SetupInvitationTokenStepDataDecoder,
                setupOrganizationStepData: exports.SetupOrganizationStepDataDecoder,
                setupProfileStepData: exports.SetupProfileStepDataDecoder,
                inviteTeamMembersStepData: exports.InviteTeamMembersStepDataDecoder,
            }, 'Finishing');
        case 'Finished':
            return ts_data_json_1.JsonDecoder.object({
                currentStep: ts_data_json_1.JsonDecoder.isExactly('Finished'),
                joinableOrganizationMetadata: exports.JoinableOrganizationMetadataDecoder,
                setupInvitationTokenStepData: exports.SetupInvitationTokenStepDataDecoder,
                setupOrganizationStepData: exports.SetupOrganizationStepDataDecoder,
                setupProfileStepData: exports.SetupProfileStepDataDecoder,
                inviteTeamMembersStepData: exports.InviteTeamMembersStepDataDecoder,
            }, 'Finished');
        // Never will go there because currentStep is already decoded.
        // But ESlint wants me to implement this case,
        // so I have to do that and disable code coverage check.
        /* istanbul ignore next */
        default:
            return ts_data_json_1.JsonDecoder.fail("Unknown currentStep value " + currentStep);
    }
});
