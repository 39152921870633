import { linkTo, usePathParams } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { selectActiveTeam } from 'reduxStore';
import { useQueryValues } from 'tools';
import { getPendingIntegrationOrg } from 'tools/utils/pendingIntegrationOrg';

import { InstallableServiceWithRedirects, isInstallableServiceWithRedirects } from './interfaces';
import { SetupIntegration } from './SetupIntegration';
import { WorkspaceSelect } from './WorkspaceSelect';

const isOrgIdRequired: Record<InstallableServiceWithRedirects, (urlPayload: Record<string, string>) => boolean> = {
  github: (urlPayload) => urlPayload.setup_action !== 'update',
  slack: () => true,
};

/**
 * Gets orgId from the cookie and renders SetupIntegration
 * If orgId isn't in cookies the user is presented with WorkspaceSelect
 */
export function SetupIntegrationController(): JSX.Element {
  const { service } = usePathParams('setupIntegration');

  const teamId = useSelector(selectActiveTeam);

  const urlPayload = useQueryValues();

  if (!isInstallableServiceWithRedirects(service)) {
    return <Redirect to={linkTo('notFound')} />;
  }

  const orgId = getPendingIntegrationOrg();

  if (!orgId && isOrgIdRequired[service](urlPayload)) {
    return <WorkspaceSelect service={service} urlPayload={urlPayload} />;
  }

  return <SetupIntegration orgId={orgId} service={service} teamId={teamId} urlPayload={urlPayload} />;
}
