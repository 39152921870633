import { getCustomSize, HStack, styled, Text, tokens } from '@taraai/design-system';
import { Data } from '@taraai/types';
import SprintColumnController from 'components/app/controllers/SprintColumnController/SprintColumnController';
import { ScrollPane } from 'components/app/layouts/ScrollPane/ScrollPane';
import { SearchBar } from 'components/app/SearchBar';
import React, { useCallback } from 'react';
import { strings } from 'resources/i18n';
import { SprintPathId } from 'tools/utils/hooks/usePagination';

import { RemoveSprintArea } from './RemoveSprintArea';

export default function SprintColumnsLayout({
  currentSprintId,
  onSelectNextSprint,
  onSelectPrevSprint,
  onSelectCurrentSprint,
  onSelectSprint,
  onTaskSelect,
  selectedSprintId,
  selectedTaskId,
  sprints,
}: {
  currentSprintId: string;
  onSelectNextSprint: () => void;
  onSelectPrevSprint: () => void;
  onSelectCurrentSprint: () => void;
  onSelectSprint: (sprintId: Data.Id.SprintId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedSprintId: Data.Id.SprintId;
  selectedTaskId: Data.Id.TaskId | undefined;
  sprints: SprintPathId[] | undefined;
}): JSX.Element {
  const selectedSprintIndex = sprints?.findIndex(([, id]) => id === selectedSprintId);
  const isFirstSelected = selectedSprintIndex === 0;
  const isLastSelected = sprints ? selectedSprintIndex === sprints.length - 1 : true;

  const renderSprintColumn = useCallback(
    (
      [, id, isPhantom]: SprintPathId,
      ref: React.RefObject<HTMLDivElement>,
      offsetFromSelected: number,
    ): JSX.Element => {
      const selected = offsetFromSelected === 0;

      return (
        <SprintColumnWrapper key={isPhantom ? 'phantom' : id} ref={ref}>
          <SprintColumnController
            // If the sprint is behind the accepted one or is complete, we don't want to enable to drop tasks in it
            acceptDrops={offsetFromSelected >= 0}
            currentSprintId={currentSprintId}
            isPhantom={isPhantom === true}
            onSelectSprint={onSelectSprint}
            onTaskSelect={onTaskSelect}
            selected={selected}
            selectedTaskId={selectedTaskId}
            sprintId={id}
          />
        </SprintColumnWrapper>
      );
    },
    [currentSprintId, onTaskSelect, onSelectSprint, selectedTaskId],
  );

  return (
    <Wrapper>
      <RemoveSprintArea />
      <ControlsRow>
        <SprintNavigation>
          <HStack align='center' alignY='center' full space='$4px'>
            <ControlButton disabled={isFirstSelected} onClick={onSelectPrevSprint} square type='button'>
              {leftArrowIcon}
            </ControlButton>
            <ControlButton onClick={onSelectCurrentSprint} type='button'>
              <Text color='current' size='$10px'>
                {strings.sprints.sprintColumn.current}
              </Text>
            </ControlButton>
            <ControlButton disabled={isLastSelected} onClick={onSelectNextSprint} square type='button'>
              {rightArrowIcon}
            </ControlButton>
          </HStack>
        </SprintNavigation>
        <SearchWrapper>
          <SearchBar />
        </SearchWrapper>
      </ControlsRow>
      <ScrollPane
        anchorLeft={columnWidthInPx / 4}
        items={sprints ?? []}
        renderItem={renderSprintColumn}
        selectedId={selectedSprintId}
      />
    </Wrapper>
  );
}

const columnHorzPaddingInPx = 12;
const columnWidthInPx = 280 + 2 * columnHorzPaddingInPx;
const controlRowHeight = getCustomSize(40);
const controlButtonSize = getCustomSize(16);
const columnHorzPadding = getCustomSize(columnHorzPaddingInPx);
const columnWidth = getCustomSize(columnWidthInPx);
const searchWidth = getCustomSize(340);

const Wrapper = styled('div', {
  background: tokens.colors.$grey4,
  height: '100%',
});

const ControlsRow = styled('div', {
  height: controlRowHeight,
  left: 0,
  position: 'absolute',
  right: 0,
  // TODO: replace `zIndex: 2` with an upcoming LevelUp component
  zIndex: '2',
});

const SprintNavigation = styled('div', {
  height: '$full',
  left: `calc(${columnWidth} / 4)`,
  position: 'absolute',
  top: 0,
  width: columnWidth,
});

const ControlButton = styled(
  'button',
  {
    'alignItems': 'center',
    'backgroundColor': '$white',
    'border': 'none',
    'borderRadius': '$2px',
    'color': '$grey7',
    'cursor': 'pointer',
    'display': 'inline-flex',
    'height': controlButtonSize,
    'justifyContent': 'center',
    'outline': 'none',
    'padding': '$none $4px',

    ':hover': { color: '$grey6' },
  },
  {
    square: { true: { width: controlButtonSize } },
  },
);

const SearchWrapper = styled('div', {
  '--right': 'space.$8px',
  'maxWidth': `calc(100% - 1.25 * ${columnWidth} - var(--right))`,
  'overflow': 'hidden',
  'position': 'absolute',
  'right': 'var(--right)',
  'top': '50%',
  'transform': 'translateY(-50%)',
  'width': searchWidth,
});

const SprintColumnWrapper = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  height: '100%',
  padding: `${controlRowHeight} ${columnHorzPadding} 0`,
  width: columnWidth,
});

const leftArrowIcon = (
  <svg fill='currentColor' height='8' viewBox='0 0 5 8' width='5' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 4L4.5 0.535898L4.5 7.4641L0 4Z' />
  </svg>
);

const rightArrowIcon = (
  <svg fill='currentColor' height='8' viewBox='0 0 5 8' width='5' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5 4L0.5 0.535898L0.5 7.4641L5 4Z' />
  </svg>
);
