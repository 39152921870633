import styled from '@emotion/styled';
import { Data } from '@taraai/types';
import { UIOrganizationStanza } from '@taraai/types/dist/ui';
import WorkspaceColorPickerController from 'components/app/controllers/WorkspaceColorPicker';
import WorkspaceEffortTogglerController from 'components/app/controllers/WorkspaceEffortToggler';
import WorkspaceLogoController from 'components/app/controllers/WorkspaceLogoController';
import Input from 'components/core/controllers/views/Input';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';
import { workspaceTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

import Label from './Label';
import Separator from './Separator';

export interface WorkspaceLayoutProps {
  isAdmin: boolean;
  membersCount: number;
  nameProps: Record<string, unknown>;
  org: UIOrganizationStanza;
  orgURL: string;
  canLeave: boolean;
  teamId: Data.Id.TeamId;
}

export default function WorkspaceLayout({
  membersCount,
  isAdmin,
  nameProps,
  org,
  teamId,
  orgURL,
  canLeave,
}: WorkspaceLayoutProps): JSX.Element {
  const membersLabel = strings.formatString(
    membersCount === 1 ? strings.workspace.members.one : strings.workspace.members.many,
    {
      membersCount,
    },
  ) as string;

  return (
    <Layout>
      <Aside>
        <LogoWrapper>
          <WorkspaceLogoController />
        </LogoWrapper>
        <AsideHeader>{org.name}</AsideHeader>
        {membersCount > 0 && <AsideText>{membersLabel}</AsideText>}
      </Aside>
      <ContentWrapper>
        <DetailsWrapper>
          {isAdmin ? (
            <Input
              {...nameProps}
              data-cy={workspaceTestIds.NAME_INPUT}
              label={strings.profile.label.name}
              style={nameInputStyles}
            />
          ) : (
            <>
              <Label>{strings.workspace.label.name}</Label>
              <ContentText>{org.name}</ContentText>
            </>
          )}
          <Label>{strings.workspace.label.workspaceURL}</Label>
          <ContentText data-chromatic='ignore'>{orgURL}</ContentText>
          <WorkspaceColorPickerController />
        </DetailsWrapper>
        <Separator />
        <WorkspaceEffortTogglerController />
        <Separator />
        {canLeave && (
          <LeaveWorkspaceWrapper>
            <LeaveWorkspaceLink
              data-cy={workspaceTestIds.LEAVE_MODAL_OPEN}
              to={linkTo('leaveWorkspace', { orgId: org.id, teamId })}
            >
              {strings.workspace.leaveWorkspace.title}
            </LeaveWorkspaceLink>
          </LeaveWorkspaceWrapper>
        )}
        {isAdmin && (
          <DeleteWorkspaceLink
            data-cy={workspaceTestIds.DELETE_MODAL_OPEN}
            to={linkTo('deleteWorkspace', { orgId: org.id, teamId })}
          >
            {strings.workspace.deleteWorkspace.title}
          </DeleteWorkspaceLink>
        )}
      </ContentWrapper>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: row;
`;

const Aside = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const AsideHeader = styled((props) => <Text {...props} />)`
  font-size: 1.2rem;
  font-weight: ${atomic.get(atomic.fonts.weight.regular)};
  margin-bottom: 1rem;
`;

const AsideText = styled((props) => <Text {...props} />)`
  color: #67728b;
  font-size: 0.875rem;
`;

const LogoWrapper = styled.div`
  margin-bottom: 1.3rem;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 2.5rem;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 26rem;
`;

const nameInputStyles = {
  main: css`
    color: #303f4b;
    font-size: 1rem;
    background-color: #ffffff;
    border-radius: 6px;
  `,
  container: css`
    height: 2.875rem;
    margin-bottom: 2rem;
  `,
  labelStyle: css`
    font-size: 1rem;
    color: ${atomic.get(atomic.colors.grey7)};
  `,
};

const ContentText = styled((props) => <Text {...props} />)`
  font-weight: ${atomic.get(atomic.fonts.weight.regular)};
  margin-bottom: 2rem;
`;

const LeaveWorkspaceWrapper = styled.div`
  margin-bottom: 1rem;
`;

const LeaveWorkspaceLink = styled((props) => <Link {...props} />)`
  color: ${atomic.get(atomic.colors.grey7)};
  font-size: 0.875rem;
  text-decoration: none;
  :hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const DeleteWorkspaceLink = styled((props) => <Link {...props} />)`
  align-self: flex-start;
  color: ${atomic.get(atomic.colors.error)};
  font-size: 0.875rem;
  text-decoration: none;
  :hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;
