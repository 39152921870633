import { unwrapResult } from '@reduxjs/toolkit';
import { Box, styled, Text } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { TeamPartial } from '@taraai/types/src/data';
import Icon from 'components/core/controllers/views/Icon';
import { InputWithButtons } from 'components/core/controllers/views/InputWithButtons';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createTeam, reduxStore, selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources';
import { inputTestIds, teamSwitcherTestIds } from 'resources/cypress/testAttributesValues';
import { useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';

import { PopupList } from './PopupList';
import { TeamsSection } from './types';

type TeamSwitcherPopupProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  disableOptions: boolean;
  sections: TeamsSection[];
  selectedTeamId: Data.Id.TeamId;
  preferredTeamId: Data.Id.TeamId;
  profileId: string;
};

export function TeamSwitcherPopup({
  containerRef,
  disableOptions,
  preferredTeamId,
  sections,
  selectedTeamId,
  profileId,
}: TeamSwitcherPopupProps): JSX.Element {
  const [teamName, setTeamName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const openCreateModal = (): void => setIsCreating(true);
  const { addToast } = useToast();
  const bottomRef = useRef<HTMLDivElement>(null);
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const scrollToBottom = (): void => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const createNewTeam = useCallback(async (): Promise<void> => {
    try {
      decode<TeamPartial>({ name: teamName }, 'TeamPartial');
    } catch (error) {
      addToast({
        message: strings.createTeamModal.createTooShort,
        type: 'error',
      });
      return Promise.resolve();
    }
    return reduxStore
      .dispatch(createTeam({ name: teamName, userIds: [profileId] }))
      .then(unwrapResult)
      .then(() => {
        addToast({
          message: formatI18n(strings.createTeamModal.createSuccess)({
            team: teamName,
          }),
          type: 'success',
          timeoutMs: 3000,
        });
        setTeamName('');
        scrollToBottom();
      })
      .catch((err) => {
        addToast({
          message: `${strings.createTeamModal.createFailure}: ${err.message}`,
          type: 'error',
        });
      });
  }, [addToast, profileId, teamName]);

  const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTeamName(event.target.value);
  };
  const handleOnCancel = useCallback(() => {
    setTeamName('');
    setIsCreating(false);
  }, []);

  const handleOnSave = useCallback(() => {
    createNewTeam();
    setIsCreating(false);
  }, [createNewTeam]);

  return (
    <Container ref={containerRef}>
      <PopupList
        bottomRef={bottomRef}
        disableOptions={disableOptions}
        preferredTeamId={preferredTeamId}
        sections={sections}
        selectedTeamId={selectedTeamId}
      />
      {isCreating && (
        <InputWithButtons
          dataCyCancel={inputTestIds.CANCEL_INPUT}
          dataCyConfirm={inputTestIds.CONFIRM_INPUT}
          handleOnCancel={handleOnCancel}
          handleOnChange={handleTeamNameChange}
          handleOnSave={handleOnSave}
          id='input'
          isCreating={isCreating}
          placeholder={strings.teamSwitcher.typeTeamName}
          value={teamName}
        />
      )}
      <OptionsContainer>
        <LinkButton
          data-cy={teamSwitcherTestIds.MANAGE_TEAM_BUTTON}
          to={() => linkTo('workspace', { orgId, teamId }, '?tab=Teams')}
        >
          <Box center>
            <StyledIcon name='users' userIcon />
            <Text color='$dark' size='$12px'>
              {strings.teamSwitcher.manageTeams}
            </Text>
          </Box>
        </LinkButton>
        <OptionButton data-cy={teamSwitcherTestIds.CREATE_TEAM_BUTTON} onClick={openCreateModal} primary={!isCreating}>
          <StyledIcon name='plus' primary={!isCreating} />
          {strings.teamSwitcher.createTeam}
        </OptionButton>
      </OptionsContainer>
    </Container>
  );
}

export const Container = styled('div', {
  'position': 'absolute',
  'right': '0.75rem',
  'top': '2.5rem',
  'boxShadow': '$popup',
  'border': 'borderWidths.$1px solid colors.$grey5',
  'borderRadius': '0.1875rem',
  'width': '17.875rem',
  'backgroundColor': '$white',
  'cursor': 'default',
  'pointerEvents': 'auto',
  'outline': 'none',
  '&:hover #input': {
    backgroundColor: '$grey1',
  },
});

export const TeamList = styled('div', {
  maxHeight: '15.25rem',
  overflowY: 'auto',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

const StyledIcon = styled(
  Icon,
  {
    paddingRight: '0.5rem',
  },
  {
    primary: {
      true: {
        color: '$focus',
      },
    },
    userIcon: {
      true: {
        color: '$dark',
      },
    },
  },
);

const OptionsContainer = styled('div', {
  border: 'borderWidths.$1px solid colors.$grey5',
  borderWidth: '0.0625rem 0 0 0',
  marginTop: '0.5rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

const OptionButton = styled(
  'button',
  {
    'background': 'none',
    'border': 'none',
    'outline': 'inherit',
    'width': '$full',
    'height': '1.75rem',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    'paddingLeft': '1rem',
    'fontSize': '0.8125rem',
    'fontWeight': '400',
    'color': '$grey5',
    'cursor': 'not-allowed',
    ':hover': {
      backgroundColor: '$grey1',
    },
  },
  {
    primary: {
      true: {
        color: '$focus',
        cursor: 'pointer',
      },
    },
  },
);

const LinkButton = styled(Link, {
  'width': '$full',
  'height': '1.75rem',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'paddingLeft': '1rem',
  'fontSize': '0.8125rem',
  'fontWeight': '400',
  'color': '$dark',
  ':hover': {
    backgroundColor: '$grey1',
  },
});
