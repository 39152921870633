/* eslint-disable rulesdir/imports */
import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { UIUser } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore';
import { getUsersWithAccessLevels, UsersWithAccessLevelsMap } from 'reduxStore/usersWithAccessLevels';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

const applyAccessLevel = (orgId: Data.Id.OrganizationId, usersWithAccessLevels: UsersWithAccessLevelsMap) => (
  user: UIUser,
): UIUser => ({
  ...user,
  accessLevels: {
    ...(user.accessLevels || {}),
    [orgId]: usersWithAccessLevels[user.id]?.accessLevel,
  },
});

const ascending = (first: UI.UIUser, second: UI.UIUser): number => first.name.localeCompare(second.name);

/**
 * Creates selector that applies access levels for given org id on users selector.
 *
 * E.g. [{ id: 'user-1' }, ...]
 *   -> [{ id: 'user-1', accessLevels: { org-1: 'admin' }}, ...]
 */
const createAllWithAccessLevelsSelector = (
  allUsersSelector: Selector<RootState, UIUser[] | undefined>,
  orgId: Data.Id.OrganizationId,
): Selector<RootState, UIUser[] | undefined> =>
  createSelector(
    [allUsersSelector, (state: RootState): UsersWithAccessLevelsMap => getUsersWithAccessLevels(state, orgId)],
    (users, usersWithAccessLevels) => {
      if (!usersWithAccessLevels) {
        return users;
      }

      return users?.map(applyAccessLevel(orgId, usersWithAccessLevels)).sort(ascending);
    },
  );

/**
 * Private Query Builder
 */
function queryBuilder(orgIds: string[]): Query<UIUser> {
  // firestore array-contains-any has a limit of 10
  const arrayLimit = 10;
  if (!orgIds.some(isNonEmptyString) || orgIds.length > arrayLimit) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: 'users',
    where: ['organizationIds', 'array-contains-any', orgIds],
    storeAs: `users/${orgIds.join('-')}`,
  };

  const selector = createStandardSelector<UIUser>(query);
  const withAccessLevelsSelector = orgIds.reduce(
    (allUsersSelector, orgId) => createAllWithAccessLevelsSelector(allUsersSelector, orgId),
    selector,
  );

  return {
    query: [query],
    selector: withAccessLevelsSelector,
  };
}

/**
 * Public functions
 */

/** Gets all users from organization */
export function getUsers(orgId: Data.Id.OrganizationId): Query<UIUser> {
  return queryBuilder([orgId]);
}
