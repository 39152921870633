import { createSelector, Selector, unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { EmptySectionHeader, SectionFooter, SectionHeader } from 'components/app/controllers/Selectors/common/Section';
import {
  OptionRenderProps,
  PopupHandle,
  SectionRenderProps,
  Selector as SelectorComponent,
} from 'components/core/controllers/Selector';
import { css } from 'emotion';
import React, { forwardRef, RefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import {
  getOrgTeams,
  getSprint,
  getUpcomingSprints,
  reduxStore,
  RootState,
  selectActiveWorkspace,
  selectPreferredTeamId,
  selectProfile,
  selectTeam,
} from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

import { SprintHeader } from './Header';
import SelectButton from './SelectButton';
import SprintOptionView from './SprintOption';
import {
  BacklogOption,
  BacklogSection,
  SprintOption,
  SprintSelectorOption,
  SprintSelectorSection,
  SprintsSection,
} from './types';

export interface SprintSelectorProps {
  task: Pick<UI.UITask, 'id' | 'sprint'>;
  popupRef?: RefObject<HTMLDivElement>;
  openDropdown?: boolean;
  position?: 'left' | 'right';
  dataCy?: string;
}

/**
 * SprintSelector allows to select single sprint
 *
 * user can choose from all organization sprints grouped by teams
 */
export const SprintSelector = forwardRef<PopupHandle, SprintSelectorProps>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function SprintSelector({ task, position = 'left', dataCy }, ref) {
    const orgId = useSelector(selectActiveWorkspace);

    const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
    const preferredTeam = useSelector(selectTeam(orgId, preferredTeamId));

    if (!isLoaded(preferredTeam) || !preferredTeam) {
      throw new Error(`Team ${orgId}/${preferredTeamId} document was not loaded at the time of selector access`);
    }

    const orgTeamsSlice = getOrgTeams(orgId);

    const userTeams = useSelector(
      createSelector(orgTeamsSlice.selector, selectProfile, (allTeams, profile) =>
        allTeams?.filter((team) => profile.teamIds[orgId].includes(team.id) && team.id !== preferredTeamId),
      ),
    );

    const otherOrgTeams = useSelector(
      createSelector(orgTeamsSlice.selector, selectProfile, (allTeams, profile) =>
        allTeams?.filter((team) => !profile.teamIds[orgId].includes(team.id)),
      ),
    );

    const upcomingSprintsSlice = getUpcomingSprints(orgId);
    useFirestoreConnect(upcomingSprintsSlice.query);

    const taskSprintSlice = getSprint(orgId, task.sprint ?? '');
    useFirestoreConnect(taskSprintSlice.query);

    const getUpcomingSprintsFromTeam = ({
      id,
      currentSprintId,
    }: Pick<UI.UITeam, 'id' | 'currentSprintId'>): Selector<RootState, SprintOption[] | undefined> =>
      createSelector(
        upcomingSprintsSlice.selector,
        taskSprintSlice.selector,
        (upcomingSprints, taskSprint) =>
          upcomingSprints && getTeamSectionSprintOptions({ currentSprintId, id }, upcomingSprints, taskSprint),
      );

    const getUpcomingSprintsFromTeams = (
      teams: UI.UITeam[] | undefined,
    ): Selector<RootState, SprintsSection[] | undefined> =>
      createSelector(
        upcomingSprintsSlice.selector,
        taskSprintSlice.selector,
        (upcomingSprints, taskSprint) =>
          upcomingSprints &&
          teams?.map((team) => ({
            ...team,
            options: getTeamSectionSprintOptions(team, upcomingSprints, taskSprint),
          })),
      );

    const upcomingSprintsFromPreferredTeam = useSelector(getUpcomingSprintsFromTeam(preferredTeam));
    const upcomingSprintsFromUserTeams = useSelector(getUpcomingSprintsFromTeams(userTeams));
    const upcomingSprintsFromOtherOrgTeams = useSelector(getUpcomingSprintsFromTeams(otherOrgTeams));

    const sections: SprintSelectorSection[] = [
      backlogSection,
      {
        ...preferredTeam,
        name: `${preferredTeam.name} ${strings.sprintDropdown.preferred}`,
        options: upcomingSprintsFromPreferredTeam ?? [],
      },
      ...(upcomingSprintsFromUserTeams ?? []),
      ...(upcomingSprintsFromOtherOrgTeams ?? []),
    ];

    const selectedOption: SprintSelectorOption =
      sections
        .flatMap((section: SprintSelectorSection): SprintSelectorOption[] => section.options)
        .find((option) => option?.id === task.sprint) ?? backlog;

    const { addToast } = useToast();

    const handleSelectedSprint = useCallback(
      (option: SprintSelectorOption): void => {
        reduxStore
          .dispatch(
            updateTask({
              id: task.id,
              sprint: option.id === 'backlog' ? null : option.id,
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToUpdateTask,
            }),
          );
      },
      [addToast, task],
    );

    return (
      <SelectorComponent
        ref={ref}
        closePopupOnSelection
        dataCy={dataCy}
        onSelectOption={handleSelectedSprint}
        renderHeader={SprintHeader}
        renderOption={({
          option: sprint,
          isActive: isActiveOption,
          isSelected: isSelectedOption,
        }: OptionRenderProps<SprintSelectorOption>): JSX.Element => (
          <SprintOptionView
            dataCy={dataCy}
            isActive={sprint.isActive}
            isActiveOption={isActiveOption}
            isSelectedOption={isSelectedOption}
            sprint={sprint}
          />
        )}
        renderSectionFooter={({ section }: SectionRenderProps<SprintSelectorSection>): JSX.Element | null =>
          section.options?.length > 0 ? <SectionFooter /> : null
        }
        renderSectionHeader={({ section }: SectionRenderProps<SprintSelectorSection>): JSX.Element | null => {
          if (section.options?.length === 0) return null;
          if (section.id === 'backlog') return <EmptySectionHeader />;
          return <SectionHeader>{section.name}</SectionHeader>;
        }}
        renderSelectButton={({ openPopup }): JSX.Element => (
          <SelectButton dataCy={dataCy} onClick={openPopup} selectedOption={selectedOption} />
        )}
        sections={sections}
        selection={selectedOption}
        style={{ popup: popupStyleMap[position] }}
      />
    );
  },
);

const backlog: BacklogOption = {
  id: 'backlog',
  isActive: false,
  sprintName: strings.sprintDropdown.backlog,
};

const backlogSection: BacklogSection = {
  id: 'backlog',
  name: strings.sprintDropdown.backlog,
  options: [backlog],
};

const popupStyleMap = {
  left: css`
    left: 0;
    width: 18.75rem;
  `,
  right: css`
    right: 0;
    width: 18.75rem;
  `,
};

const getTeamSectionSprintOptions = (
  team: Pick<UI.UITeam, 'id' | 'currentSprintId'>,
  orgUpcomingSprints: UI.UISprint[],
  currentTaskSprint?: UI.UISprint,
): SprintOption[] => {
  // Team's upcoming sprints selected from org's upcoming sprints
  const teamUpcomingSprints = orgUpcomingSprints.filter(({ teamId }) => teamId === team.id);

  // If the current task's sprint is completed it won't be present in upcoming sprints and should be added to the list
  const includeCurrentTaskSprint =
    currentTaskSprint?.teamId === team.id && !teamUpcomingSprints.find(({ id }) => id === currentTaskSprint.id);

  // The final list of sprints in this section
  const teamSprints = [
    ...(currentTaskSprint && includeCurrentTaskSprint ? [currentTaskSprint] : []),
    ...teamUpcomingSprints,
  ];

  // Map sprints to sprint options
  return teamSprints.map((sprint) => ({ ...sprint, isActive: team.currentSprintId === sprint.id }));
};
