import { createSelector, OutputParametricSelector } from '@reduxjs/toolkit';
import { Box, styled, VStack } from '@taraai/design-system';
import { Data, TaskStatus, UI } from '@taraai/types';
import { SprintColumnHeaderView } from 'components/app/controllers/views/SprintColumnHeaderView';
import { SprintColumnTasksView } from 'components/app/controllers/views/SprintColumnTasksView';
import { SprintFilterCard } from 'components/app/controllers/views/SprintFilterCard';
import React, { useCallback, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, shallowEqual, useSelector } from 'react-redux';
import {
  QueryAlias,
  RootState,
  selectSprintDocument,
  selectStatusFilter,
  selectTaskBy,
  useFilteredTaskCount,
} from 'reduxStore';

export type SprintColumnType = 'active' | 'complete' | 'upcoming';
const noop = (): void => undefined;

type SprintColumnViewProps = {
  acceptDrops: boolean;
  assigneeFilter?: string;
  currentSprintId: string;
  onFilterReset: () => void;
  onSelectSprint: (sprintId: Data.Id.SprintId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selected: boolean;
  selectedTaskId: Data.Id.TaskId | undefined;
  sprintId: Data.Id.SprintId;
  sprintName: string;
  sprintEndDateSeconds: number;
  sprintStartDateSeconds: number;
  sprintIsComplete: boolean;
  sprintIsPhantom?: boolean;
  taskAlias: QueryAlias;
};

type Effort = {
  overload: number;
  total: number;
  completed: number;
};

const overloadComputeSelector = (): OutputParametricSelector<
  DefaultRootState,
  string,
  Effort,
  (res1: RootState, res2: string, res3: boolean) => Effort
> =>
  createSelector(
    (state: DefaultRootState) => state as RootState,
    (_: unknown, sprintId: string) => sprintId,
    (_: unknown, __: unknown, isPhantom = false) => isPhantom,
    (state, sprintId, isPhantom = false): Effort => {
      if (isPhantom || !sprintId) return { total: 0, completed: 0, overload: 0 };

      const { isComplete = false, computedOnCompletion } = selectSprintDocument(state, sprintId) || {};
      const { totalEffortEstimated = null, totalEffortCompleted = null } = computedOnCompletion || {};

      if (isComplete && typeof totalEffortEstimated === 'number' && typeof totalEffortCompleted === 'number') {
        return {
          total: totalEffortEstimated,
          completed: totalEffortCompleted,
          overload: Math.max(0, totalEffortEstimated - totalEffortCompleted),
        };
      }

      const tasks = selectTaskBy(state, (task) => !task.deleted && task.sprint === sprintId) || [];

      const { completed, total } = tasks.reduce(
        (acc, task: UI.UITask) => {
          acc.total += task.effortLevel;
          acc.completed += task.status === TaskStatus.Doing ? task.effortLevel : 0;
          return acc;
        },
        { total: 0, completed: 0 },
      );

      return { total, completed, overload: Math.max(0, total - completed) };
    },
  );

export const SprintColumnView: React.FC<SprintColumnViewProps> = React.memo(function SprintColumnView({
  acceptDrops,
  assigneeFilter,
  currentSprintId,
  onFilterReset,
  onSelectSprint,
  onTaskSelect,
  selected,
  selectedTaskId,
  sprintId,
  sprintName,
  sprintEndDateSeconds,
  sprintStartDateSeconds,
  sprintIsComplete,
  sprintIsPhantom,
  taskAlias,
}: SprintColumnViewProps): JSX.Element {
  const memoOverload = useMemo(overloadComputeSelector, [sprintId, sprintIsPhantom]);
  const { total, completed, overload } = useSelector(
    (state) => memoOverload(state, sprintId, sprintIsPhantom || false),
    shallowEqual,
  );

  const tasks = useSelector((state) => selectTaskBy(state, (task) => task?.sprint === sprintId) ?? []);

  const statusFilter = useSelector(selectStatusFilter, deepEquals);
  const filtersActive = statusFilter !== undefined || assigneeFilter !== undefined;
  const taskCount = useFilteredTaskCount(selected, tasks);
  const areTasksFiltered = filtersActive && taskCount > 0;
  const showFilterCard = areTasksFiltered && selected;

  let type: SprintColumnType = 'upcoming';
  if (sprintIsComplete === true) {
    type = 'complete';
  } else if (sprintId === currentSprintId) {
    type = 'active';
  }

  const onSelectThisSprint = useCallback(() => {
    onSelectSprint(sprintId);
  }, [onSelectSprint, sprintId]);

  return (
    <VStack full space='$8px'>
      <SprintContainer>
        <Box border='$grey4' borderRadius='$4px' full>
          <VStack full>
            <SprintColumnHeaderView
              effortCompleted={completed}
              effortTotal={total}
              endDateSeconds={sprintEndDateSeconds}
              onSelectSprint={sprintIsPhantom === true ? noop : onSelectThisSprint}
              sprintName={sprintName}
              startDateSeconds={sprintStartDateSeconds}
              type={type}
            />
            <Box background={type === 'complete' ? '$successLight' : '$grey2'} height='$4px' />
            <SprintColumnTasksView
              acceptDrops={sprintIsComplete !== true && acceptDrops}
              areFilteredByAssignee={assigneeFilter !== undefined}
              areFilteredByStatus={statusFilter !== undefined}
              effortOverload={overload}
              effortTotal={total}
              onTaskSelect={onTaskSelect}
              selected={selected}
              selectedTaskId={selectedTaskId}
              sprintId={sprintId}
              taskAlias={taskAlias}
            />
          </VStack>
        </Box>
      </SprintContainer>
      {showFilterCard && (
        <SprintFilterCard assignee={assigneeFilter} onFilterReset={onFilterReset} statusFilter={statusFilter} />
      )}
    </VStack>
  );
});

const SprintContainer = styled(Box, {
  overflow: 'hidden',
  flexShrink: 1,
});
