import { Box, Text } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { getBranchNameFromCommitRef } from '@taraai/utility';
import { SectionWithLoader } from 'components/app/TaskDetails/common/Section';
import React, { useState } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { TaskGitData } from 'reduxStore';
import { strings } from 'resources';
import { taskDetailsTestIds } from 'resources/cypress/testAttributesValues';

import { InfoPopup } from './InfoPopup';

type Props = {
  gitData: TaskGitData | undefined;
  taskId: Data.Id.TaskId;
};

export const Git = ({ gitData, taskId }: Props): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false);
  const hasConnectedData =
    isLoaded(gitData) && (!!gitData.branches[0] || !!gitData.commits[0] || !!gitData.pullRequests[0]);

  return (
    <Box onMouseLeave={() => setShowPopup(false)}>
      <SectionWithLoader
        content={(loadedGitData) => (
          <Box spaceTop='$12px'>
            <Text data-cy={taskDetailsTestIds.GIT_DATA} size='$12px'>
              {getContent({ gitData: loadedGitData, taskId })}
            </Text>
          </Box>
        )}
        data={gitData}
        right={
          <InfoPopup
            data-cy={taskDetailsTestIds.INFO_POPUP}
            hasConnectedData={hasConnectedData}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
            taskId={taskId}
          />
        }
        title={strings.taskSidebar.modules.git.title}
      />
    </Box>
  );
};

/**
 * Prepare git note content based on task git data with the following priority:
 * pull request, branch, commit, no data
 */
const getContent = ({
  taskId,
  gitData,
}: Pick<Props, 'gitData' | 'taskId'>): ReturnType<typeof strings.formatString> => {
  if (gitData?.pullRequests[0])
    return strings.formatString(strings.taskSidebar.modules.git.pullRequestConnected, {
      pullRequestId: gitData.pullRequests[0].data.title,
    });

  if (gitData?.branches[0])
    return strings.formatString(strings.taskSidebar.modules.git.branchConnected, {
      branchName: gitData.branches[0].name,
    });

  if (gitData?.commits[0])
    return strings.formatString(strings.taskSidebar.modules.git.branchConnected, {
      branchName: getBranchNameFromCommitRef(gitData.commits[0].ref) ?? '',
    });

  return strings.formatString(strings.taskSidebar.modules.git.addTaskIdNote, {
    taskId: strings.formatString(strings.task.taskId, { taskId }) as string,
  });
};
