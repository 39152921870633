import { Data } from '@taraai/types';
import {
  ErrorImportNotificationBarView,
  ImportNotificationBarView,
} from 'components/app/controllers/views/ImportNotificationBarView';
import { hasImportFailed, hasImportFinished } from 'components/app/controllers/WorkDrawerImportToastController/guards';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router-dom';
import { selectAuth, selectPreferredTeamId } from 'reduxStore';
import { getImports } from 'reduxStore/github-import/queries';
import { strings } from 'resources/i18n';

/**
 * Tara 2.0 will show import notifications inside Work Drawer
 * @deprecated
 */
export function GithubImportNotificationBarController(): JSX.Element | null {
  const { orgId, teamId } = useParams<{
    orgId: Data.Id.OrganizationId;
    teamId: Data.Id.TeamId;
  }>();
  const history = useHistory();
  const { uid: userID } = useSelector(selectAuth);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));

  const githubImportsSlice = getImports(orgId, 'github-api', userID);

  useFirestoreConnect(githubImportsSlice.query);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userImports = useSelector(githubImportsSlice.selector) || [];

  const [displayNotificationFor, setDisplayNotificationFor] = useState<Data.Id.ImportId | null>(null);

  useEffect(() => {
    // Save a pending import in state to have a reference to it even when it's finished
    const [latestPendingImport] = userImports.filter((userImport) => !hasImportFinished(userImport));

    if (latestPendingImport && latestPendingImport.id !== displayNotificationFor) {
      setDisplayNotificationFor(latestPendingImport.id);
    }
  }, [userImports, displayNotificationFor]);

  const importToShow = userImports.find((userImport) => userImport.id === displayNotificationFor);

  const removeNotification = useCallback(() => {
    setDisplayNotificationFor(null);
  }, [setDisplayNotificationFor]);

  const onTryAgainRequest = useCallback(() => {
    removeNotification();
    history.push(linkTo('workspace', { orgId, teamId }, '?tab=Integrations'));
  }, [removeNotification, history, orgId, teamId]);

  const onViewImportedTasksRequest = useCallback(() => {
    removeNotification();
    history.push(linkTo('sprints', { orgId, teamId: teamId ?? preferredTeamId }));
  }, [removeNotification, history, orgId, teamId, preferredTeamId]);

  if (!importToShow) {
    return null;
  }

  if (hasImportFailed(importToShow)) {
    return (
      <ErrorImportNotificationBarView
        errorCopy={strings.importNotificationBar.github.errorCopy}
        iconName='githubWhite'
        onCloseRequest={removeNotification}
        onTryAgainRequest={onTryAgainRequest}
      />
    );
  }

  return (
    <ImportNotificationBarView
      bgColor='#181818'
      hasImportFinished={hasImportFinished(importToShow)}
      iconName='githubWhite'
      importFinishedCopy={strings.formatString(
        strings.importNotificationBar.github.issuesImportedCopy,
        importToShow.totalItemsCount.toString(),
      )}
      importInProgressCopy={strings.importNotificationBar.github.importInProgressCopy}
      onCloseRequest={removeNotification}
      onViewImportedTasksRequest={onViewImportedTasksRequest}
    />
  );
}
