import { Box, styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { Id } from '@taraai/types/dist/data';
import { OrganizationId, TaskId, TeamId } from '@taraai/types/src/data/id';
import { notUndefined } from '@taraai/utility';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import {
  getLastTaskComments,
  getTask,
  getTaskGitData,
  getTaskRevision,
  selectActiveUsers,
  selectOrganization,
  taskSubTasks,
} from 'reduxStore';
import { useShallowMemo } from 'tools/utils/hooks/general';

import { TaskDetailsView } from './TaskDetailsView';

type Props = {
  orgId: OrganizationId;
  showSummary?: boolean;
  taskId: TaskId;
  teamId: TeamId;
};

export const TaskDetails = React.memo(function TaskDetails({ orgId, showSummary, taskId, teamId }: Props): JSX.Element {
  const taskSlice = getTask(orgId, taskId);
  const taskGitDataSlice = getTaskGitData(orgId, taskId);
  const subTasksSlice = taskSubTasks(orgId, taskId, { orderBy: 'createdAt', reverseOrder: true });
  const taskCommentsSlice = getLastTaskComments(orgId, taskId);
  const taskRevisionsSlice = getTaskRevision(orgId, taskId);
  useFirestoreConnect([
    ...taskSlice.query,
    ...taskGitDataSlice.query,
    ...subTasksSlice.query,
    ...taskCommentsSlice.query,
    ...taskRevisionsSlice.query,
  ]);

  const org = useSelector(selectOrganization(orgId));
  const task = useSelector(taskSlice.selector, deepEquals);
  const gitData = useSelector(taskGitDataSlice.selector);
  const subtasksData = useSelector(compose(getSubtaskFragment, subTasksSlice.selector), deepEquals);
  const comments = useSelector(taskCommentsSlice.selector);
  const revisions = useSelector(taskRevisionsSlice.selector);
  const users: UserFragment[] = useSelector(compose(getUserFragment, selectActiveUsers(orgId)), deepEquals);
  const subtasksIds = useMemo(() => (subtasksData ?? []).map((subtask) => ({ id: subtask.id })), [subtasksData]);
  const collaborators = useShallowMemo(
    () =>
      task?.collaborators.map((userId: Id.UserId) => users.find((user) => user.id === userId)).filter(notUndefined) ||
      [],
    [task?.collaborators, users],
  );

  const { openModal } = useContext(TaskModalContext);
  const handleViewModal = useCallback((): void => openModal(taskId), [openModal, taskId]);

  return isLoaded(task) ? (
    <Container>
      <TaskDetailsView
        collaborators={collaborators}
        comments={comments}
        gitData={gitData}
        handleViewModal={handleViewModal}
        org={org}
        orgId={orgId}
        revisions={revisions}
        showSummary={showSummary}
        subtasksData={subtasksData}
        subtasksIds={subtasksIds}
        task={task}
        taskId={taskId}
        teamId={teamId}
        users={users}
      />
    </Container>
  ) : (
    <Container />
  );
});

const Container = ({ children }: { children?: ReactNode }): JSX.Element => (
  <ContainerBox background='$grey1' borderLeft='$grey4' height='$full'>
    {children}
  </ContainerBox>
);

export type SubtaskFragment = Pick<UI.UITask, 'id' | 'status' | 'title'> & {
  assigneeDocument?: Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;
};

const getSubtaskFragment = (data: UI.UITask[] | undefined): SubtaskFragment[] | undefined =>
  data?.map(({ id, status, title, assigneeDocument }) => ({
    id,
    status,
    title,
    assigneeDocument: assigneeDocument && {
      id: assigneeDocument.id,
      name: assigneeDocument.name,
      avatarURL: assigneeDocument.avatarURL,
    },
  }));

export type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

const getUserFragment = (data: UI.UIUser[]): UserFragment[] =>
  data.map(({ id, name, avatarURL }) => ({ id, name, avatarURL }));

const ContainerBox = styled(Box, { overflowY: 'scroll' });
