import { Avatar, Box, fade, Fluid, Hidden, HStack, styled, Text, Tooltip, VStack } from '@taraai/design-system';
import { Data, TaskStatus, UI } from '@taraai/types';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { TaskCreation } from 'components/app/TaskCreation';
import { EffortInput } from 'components/app/TaskDetails/common/EffortInput';
import { SectionType } from 'components/core/controllers/Selector';
import { StatusSelector } from 'components/core/controllers/StatusSelector';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import React, { ComponentProps, SyntheticEvent, useMemo, useRef, useState } from 'react';
import { strings } from 'resources/i18n';
import { StopPropagation } from 'tools/helpers/StopPropagation';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

import { TaskCardController } from './TaskCardController';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

type Props = ComponentProps<typeof TaskCardController> & {
  showAssignedToSprint?: boolean;
  removeTaskAssignee: (user: UserFragment) => void;
  setTaskAssignee: (user: UserFragment) => void;
  updateTaskWithTitle: (title: string) => void;
  users: UserFragment[];
  status: TaskStatus;
  effortLevel: number;
  title: string;
  assigneeId?: Data.Id.UserId | null;
  assigneeName?: string;
  assigneeAvatarURL?: string | null;
};

export function TaskCard({
  effortLevel,
  filteredByStatus,
  showAssignedToSprint,
  overloaded,
  removeTaskAssignee,
  selected,
  setTaskAssignee,
  taskId,
  title,
  assigneeId,
  assigneeName,
  assigneeAvatarURL,
  status,
  updateTaskWithTitle,
  users,
}: Props): JSX.Element {
  const taskTitleRef = useRef<RichEditorHandle>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleTextClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    if (taskTitleRef.current) taskTitleRef.current.focus();
    setIsEditing(true);
    setShowOptions(true);
  };
  const onAnimationEnd = (): void => {
    if (!showOptions) setIsEditing(false);
  };

  const sections: SectionType<UserFragment>[] = [{ id: 'users', options: users }];
  const selection =
    assigneeId && assigneeName && assigneeAvatarURL
      ? [{ id: assigneeId, name: assigneeName, avatarURL: assigneeAvatarURL }]
      : [];
  const handleTaskTitleChange = (newTitle: string): void => {
    setIsEditing(false);
    updateTaskWithTitle(newTitle);
    setShowOptions(false);
  };

  const background: ComponentProps<typeof Box>['background'] = useMemo(() => {
    if (showAssignedToSprint) {
      return '$grey1';
    }
    if (filteredByStatus) {
      return fade(mapTaskStatusToColor[status], '$5%');
    }
    if (overloaded) {
      return fade('$failure', '$5%');
    }
    return '$white';
  }, [filteredByStatus, overloaded, showAssignedToSprint, status]);

  return (
    <Tooltip
      disabled={!showAssignedToSprint}
      placement='right'
      title={String(strings.formatString(strings.workDrawer.taskMovedToSprint))}
    >
      <Box background='$white' borderRadius='$2px'>
        <Wrapper background={background} borderRadius='$2px' selected={selected} space='$8px' spaceBottom='$12px'>
          <OpacityWrapper inSprint={showAssignedToSprint}>
            <VStack space='$4px'>
              <HStack>
                <Fluid>
                  <Text color='$grey6' size='$10px' weight='medium'>
                    {strings.formatString(strings.task.taskId, { taskId })}
                  </Text>
                </Fluid>
                <HStack space='$4px'>
                  <Box>
                    <StatusSelector status={status ?? 0} taskId={taskId} />
                  </Box>
                  <EffortInput backgroundColor='$grey4' color='$dark' effortLevel={effortLevel} taskId={taskId} />
                  <UserSelector
                    closePopupOnSelection
                    headerTitle={strings.taskSidebar.modules.assignee.dropdown}
                    onDeselectOption={removeTaskAssignee}
                    onSelectOption={setTaskAssignee}
                    optionSize='small'
                    renderSelectButton={({ openPopup }) => (
                      <Box.Button
                        onClick={(event) => {
                          event.stopPropagation();
                          openPopup();
                        }}
                      >
                        <Avatar title={assigneeName} url={assigneeAvatarURL} />
                      </Box.Button>
                    )}
                    searchPlaceholder={strings.taskSidebar.modules.header.searchUsers}
                    sections={sections}
                    selection={selection}
                    selectorPosition='left'
                  />
                </HStack>
              </HStack>
              <Fluid>
                <Hidden hidden={!isEditing} strategy='remove'>
                  <StopPropagation>
                    <TaskCreation
                      initialValue={title}
                      onEnter={handleTaskTitleChange}
                      optionsAnimation={{ onAnimationEnd, show: showOptions }}
                    >
                      <CreateTaskEditor ref={taskTitleRef} placeholder={strings.workDrawer.createTask} saveOnBlur />
                    </TaskCreation>
                  </StopPropagation>
                </Hidden>
                <Hidden hidden={isEditing}>
                  <StyledText italic={showAssignedToSprint} onDoubleClick={handleTextClick} size='$12px'>
                    <SmartText filterLabelsOnClick text={title} />
                  </StyledText>
                </Hidden>
              </Fluid>
            </VStack>
          </OpacityWrapper>
        </Wrapper>
      </Box>
    </Tooltip>
  );
}

const Wrapper = styled(
  Box,
  {
    ':hover': {
      backgroundColor: '$grey1',
    },
  },
  { selected: { true: { boxShadow: 'inset 0 0 0 1px colors.$focus' } } },
);

const OpacityWrapper = styled(Box, {}, { inSprint: { true: { opacity: '0.6' } } });

const StyledText = styled(Text, { wordBreak: 'break-word' });
const CreateTaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$12px',
  caretColor: 'colors.$focus',
});
