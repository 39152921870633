"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.notNull = exports.notUndefined = exports.assertNever = exports.noop = exports.splitArrayOn = void 0;
/**
 * Splits the array in two based on predicate.
 *
 * The element for which predicate returns true is included in the second part.
 *
 * For example:
 *
 * `[2, 4, 5, 0, 8]` with `isOdd` predicate will be split into two arrays like so:
 * `[[2, 4], [5, 0, 8]]`
 *
 * (5 being first odd number signifies the start of second array).
 */
function splitArrayOn(arr, predicate) {
    return arr.reduce(function (_a, element) {
        var before = _a[0], after = _a[1];
        if (after.length > 0 || predicate(element)) {
            // already after the split
            return [before, __spreadArrays(after, [element])];
        }
        return [__spreadArrays(before, [element]), after];
    }, [[], []]);
}
exports.splitArrayOn = splitArrayOn;
/**
 * A simple no-op function.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
var noop = function () { };
exports.noop = noop;
/**
 * Asserts that passed parameter is of type never
 */
/* istanbul ignore next */
function assertNever(value, throwError) {
    if (throwError === void 0) { throwError = true; }
    if (throwError) {
        throw new Error("Assertion failed: assumed 'never' got '" + value + "'");
    }
    return value;
}
exports.assertNever = assertNever;
/**
 * Checks if not undefined
 */
function notUndefined(data) {
    return typeof data !== 'undefined';
}
exports.notUndefined = notUndefined;
/**
 * Checks if not null
 */
function notNull(data) {
    return data !== null;
}
exports.notNull = notNull;
