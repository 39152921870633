import { Box, Fluid, getCustomSize, HStack } from '@taraai/design-system';
import React, { ReactNode } from 'react';

interface SidePanelsLayoutProps {
  children: ReactNode;
  left: ReactNode;
  right: ReactNode;
}

const sidePanelWidth = getCustomSize(297);

export const SidePanelsLayout = ({ children, left, right }: SidePanelsLayoutProps): JSX.Element => (
  <HStack full>
    <Box background='$grey2' width={sidePanelWidth}>
      {left}
    </Box>
    <Fluid>{children}</Fluid>
    <Box background='$grey1' width={sidePanelWidth}>
      {right}
    </Box>
  </HStack>
);
