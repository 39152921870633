import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Data, Functions } from '@taraai/types';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { getOrgId } from 'route-utils';
import { funcLog } from 'tools/libraries/loggers';
import { v1 as uuidV1 } from 'uuid';

export const openImportModal = createAction<Functions.ImportCSVFile.CSVImportableService>('openImportModal');

export const closeImportModal = createAction('closeImportModal');

export const showBacklogImportIndicator = createAction<{
  importId: Data.Id.ImportId;
  service: Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'];
}>('showBacklogImportIndicator');

export const hideBacklogImportIndicator = createAction<Data.ExternalIssue.Any['service']>('hideBacklogImportIndicator');

export const showImportNotification = createAction<{
  importId: Data.Id.ImportId;
  service: Functions.ImportCSVFile.CSVImportableService;
}>('showImportNotification');

export const closeImportNotification = createAction<Functions.ImportCSVFile.CSVImportableService>(
  'closeImportNotification',
);

const slice = createSlice({
  name: 'imports',
  initialState: {
    backlogImportIndicators: {
      trello: null as Data.Id.ImportId | null,
      asana: null as Data.Id.ImportId | null,
      github: null as Data.Id.ImportId | null,
      slack: null as Data.Id.ImportId | null,
    },
    notifications: {
      trello: null as Data.Id.ImportId | null,
      asana: null as Data.Id.ImportId | null,
      github: null as Data.Id.ImportId | null,
    },
    showModalForService: null as Functions.ImportCSVFile.CSVImportableService | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hideBacklogImportIndicator, (state, action) => ({
      ...state,
      backlogImportIndicators: {
        ...state.backlogImportIndicators,
        [action.payload]: null,
      },
    }));
    builder.addCase(showBacklogImportIndicator, (state, action) => ({
      ...state,
      backlogImportIndicators: {
        ...state.backlogImportIndicators,
        [action.payload.service]: action.payload.importId,
      },
    }));
    builder.addCase(closeImportNotification, (state, action) => ({
      ...state,
      notifications: {
        ...state.notifications,
        [action.payload]: null,
      },
    }));
    builder.addCase(showImportNotification, (state, action) => ({
      ...state,
      notifications: {
        ...state.notifications,
        [action.payload.service]: action.payload.importId,
      },
    }));
    builder.addCase(closeImportModal, (state) => ({
      ...state,
      showModalForService: null,
    }));
    builder.addCase(openImportModal, (state, action) => ({
      ...state,
      showModalForService: action.payload,
    }));
  },
});

export interface UploadImportFileActionPayload {
  service: Functions.ImportCSVFile.CSVImportableService;
  file: File;
}
export const importsReducer = slice.reducer;

export const uploadImportFile = createAsyncThunk(
  'UploadImportFile',
  async ({ service, file }: UploadImportFileActionPayload, { extra }): Promise<Data.Id.ImportId | null> => {
    const { getFirebase } = extra as ExtraAPI;

    const storage = getFirebase().storage();
    const functions = getFirebase().functions();

    const orgId = getOrgId();

    if (!orgId) {
      funcLog('Organization id is missing');
      return null;
    }

    const filePath = `orgs/${orgId}/imports/${service}`;
    const storageRef = storage.ref(filePath);

    const uuid = uuidV1();
    const filename = `${uuid}.csv`;
    const fileRef = storageRef.child(filename);

    await fileRef.put(file);

    const payloadDraft: Functions.ImportCSVFile.Payload = {
      service,
      organizationId: orgId,
      filename: uuid, // Backend does not need the extension
    };

    const payload = decode<Functions.ImportCSVFile.Payload>(payloadDraft, 'ImportCSVFilePayload', CREATION_DEFAULTS);

    const { data } = await functions.httpsCallable('importCsvFile')(payload);

    return data;
  },
);
