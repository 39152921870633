import { Fluid, HStack, styled, tokens } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { keys, markdownLabelIdRegExp, markdownMentionIdRegExp, unique } from '@taraai/utility';
import Icon from 'components/core/controllers/views/Icon';
import { getAllEntitiesData } from 'components/editor/entities';
import {
  composePlugins,
  createLabelsPlugin,
  createMentionPlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { labelIdToText } from 'components/editor/plugins/labels/common';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { createSingleLinePlugin } from 'components/editor/plugins/singleLine';
import { RichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { ContentState } from 'draft-js';
import React, { MouseEventHandler, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createLabel,
  defaultLabels,
  getCustomLabels,
  reduxStore,
  SearchQuery,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import { strings } from 'resources';
import { useRerender } from 'tools';

interface Props {
  count: number | null;
  onSearch: (query?: SearchQuery) => void;
  searchQuery?: SearchQuery;
}

/**
 * A single-line editor with mentions and label support for searching
 */
export function SearchBar({ count, onSearch, searchQuery }: Props): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const searchRef = useRef<RichEditorHandle>(null);
  const [hasText, setHasText] = useState(false);
  const getUserTagForId = useUserTagForId(orgId);
  const customLabels = useSelector(getCustomLabels(orgId).selector);
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels || [])];
  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
          readOnly: true,
        }),
        createMentionPlugin(getUserTagForId),
        createSingleLinePlugin(),
      ),
    [getUserTagForId],
  );

  const handleHasTextChange = useCallback(
    (nextHasText) => {
      setHasText(nextHasText);
      if (!nextHasText) onSearch();
    },
    [onSearch],
  );

  const handleSearch = useCallback(
    (text: string, content: ContentState) => {
      if (!text) return;
      onSearch({
        text: text.replaceAll(markdownLabelIdRegExp, '').replaceAll(markdownMentionIdRegExp, ''),
        labels: unique(getAllEntitiesData('label', content).map(({ id }) => id)),
        mentions: unique(getAllEntitiesData('mention', content).map(({ id }) => id)),
      });
    },
    [onSearch],
  );

  const handleWrapperMouseDown = useCallback<MouseEventHandler>((event) => {
    // Prevent focus & blur on mouse down because editor focus will handle it
    event.preventDefault();
    searchRef.current?.focus();
  }, []);

  const handleEditorWrapperMouseDown = useCallback<MouseEventHandler>((event) => {
    // Stop propagation because the editor inside already has focus
    event.stopPropagation();
  }, []);

  const handleClearMouseDown = useCallback<MouseEventHandler>((event) => {
    // Prevent focus & blur on mouse down because clear with hasFocus will handle it
    event.preventDefault();
    event.stopPropagation();
    searchRef.current?.clear(true);
  }, []);

  const conditionalLabels = useRef<string | undefined>(undefined);
  const [editorKey, rerenderEditor] = useRerender();
  useEffect(() => {
    if (searchQuery?.source === 'label') {
      conditionalLabels.current = searchQuery?.labels?.map(labelIdToText).join(' ');
      rerenderEditor();
    } else {
      conditionalLabels.current = undefined;
    }
  }, [rerenderEditor, searchQuery?.labels, searchQuery?.source]);

  return (
    <RichEditorProvider
      key={editorKey}
      initialValue={conditionalLabels.current}
      onSave={handleSearch}
      onTextChange={(text) => handleHasTextChange(text.length > 0)}
      plugin={plugin}
      singleLine
    >
      <Wrapper active={hasText} alignY='center' onMouseDown={handleWrapperMouseDown} space='$4px'>
        <SearchIcon color={tokens.colors.$grey6} name='sprintsearch' />
        <Fluid enabled='horizontal' onMouseDown={handleEditorWrapperMouseDown}>
          <RichEditor ref={searchRef} placeholder={strings.searchBar.placeholder} />
        </Fluid>
        {hasText && (
          <>
            {count !== null && <ResultText>{count} found</ResultText>}
            <ClearIcon name='close' onMouseDown={handleClearMouseDown} />
          </>
        )}
      </Wrapper>
    </RichEditorProvider>
  );
}

const focusStyle = {
  '--focusColor': 'colors.$focus',
  'border': 'solid 0.0625rem colors.$focus',
  'boxShadow': '0 0 0.375rem 0 rgba(29, 152, 255, 0.35)',
  'outline': 'none',
};

const Wrapper = styled(
  HStack,
  {
    '&:focus-within': focusStyle,
    'backgroundColor': '$grey1',
    'border': 'solid 0.0625rem colors.$grey4',
    'borderRadius': '0.25rem',
    'height': '2.1875rem',
    'width': '31.25rem',
  },
  {
    active: {
      true: focusStyle,
    },
  },
);

const ResultText = styled('span', {
  color: '$grey5',
  fontSize: '0.8125rem',
});

const SearchIcon = styled(Icon, {
  color: 'var(--focusColor, $grey6)',
  height: '0.8125rem',
  width: '0.8125rem',
});

const ClearIcon = styled(Icon, {
  height: '0.625rem',
  width: '0.625rem',
});
