import { composePlugins, createMentionPlugin, markdownPlugin } from 'components/editor/plugins';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { getStyledRichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

export default function CommentDraftContent({ content }: { content: string }): JSX.Element {
  const { orgId } = useParams<{ orgId: string }>();
  const getUserTagForId = useUserTagForId(orgId);
  const plugin = useMemo(() => composePlugins(markdownPlugin, createMentionPlugin(getUserTagForId)), [getUserTagForId]);
  return (
    <RichEditorProvider initialValue={content} plugin={plugin} readOnly>
      <StyledCommentEditor />
    </RichEditorProvider>
  );
}

const StyledCommentEditor = getStyledRichEditor({
  color: '$grey7',
  fontSize: '$12px',
  lineHeight: '135%',
});
