import { createSelector } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { LabelChip } from 'components/core/controllers/views/LabelChip';
import React, { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { defaultLabels, reduxStore, searchActions, selectSearchQuery } from 'reduxStore';

interface Props {
  children?: ReactNode;
  id: string;
  filterOnClick?: boolean;
}

const labels = defaultLabels as Record<string, Data.DefaultLabel>;

/**
 * ReduxLabelChip
 * A connected redux component that takes in a label id and renders a `LabelChip`
 */
export function ReduxLabelChip({ children, filterOnClick = false, id }: Props): JSX.Element {
  const description = labels[id]?.description;
  const isFilteringId = useSelector(createSelector(selectSearchQuery, (query) => (query?.labels ?? []).includes(id)));
  const onLabelClick = useCallback(
    (event) => {
      event.stopPropagation();
      reduxStore.dispatch(searchActions.search({ source: 'label', labels: isFilteringId ? [] : [id] }));
    },
    [id, isFilteringId],
  );
  return (
    <Wrapper isClickable={filterOnClick} onClick={filterOnClick ? onLabelClick : undefined}>
      <LabelChip description={description}>{children ?? labels[id]?.title}</LabelChip>
    </Wrapper>
  );
}

const Wrapper = styled(
  'span',
  {},
  {
    isClickable: {
      true: {
        ':hover': {
          cursor: 'pointer',
        },
      },
    },
  },
);
