import { Data, Functions } from '@taraai/types';
import { ImportNotificationBarViewProps } from 'components/app/controllers/views/ImportNotificationBarView';
import { PendingActionToast } from 'components/app/PendingActionToast';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { selectActiveWorkspace } from 'reduxStore';
import { getImport } from 'reduxStore/imports/queries/imports';
import { selectNotifcation } from 'reduxStore/imports/selectors';
import strings from 'resources/i18n';

import { hasImportFinished } from './guards';

type ImportNotificationBarProps = {
  importId: Data.Id.ImportId;
  importInProgressCopy: ImportNotificationBarViewProps['importInProgressCopy'];
};

function ImportToast({ importId, importInProgressCopy }: ImportNotificationBarProps): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const importSlice = getImport(orgId, importId);
  const importDoc = useSelector(importSlice.selector);
  useFirestoreConnect(importSlice.query);

  if (!importDoc) return null;
  if (!hasImportFinished(importDoc)) {
    return <PendingActionToast state='loading'>{importInProgressCopy}</PendingActionToast>;
  }
  return (
    <PendingActionToast state='success'>{strings.importNotificationBar.defaultImportFinishedCopy}</PendingActionToast>
  );
}

type ImportNotificationBarControllerProps = {
  service: Functions.ImportCSVFile.CSVImportableService;
  importInProgressCopy: ImportNotificationBarViewProps['importInProgressCopy'];
};

export function WorkDrawerImportToastController({
  service,
  importInProgressCopy,
}: ImportNotificationBarControllerProps): JSX.Element | null {
  const importId = useSelector(selectNotifcation(service));

  if (!importId) return null;
  return <ImportToast importId={importId} importInProgressCopy={importInProgressCopy} />;
}
