import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { Preview } from 'components/app/Onboarding/Preview';
import { StepName } from 'components/app/Onboarding/types';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { atomic, strings, TaraLogoIcon } from 'resources';
import { workspaceSetupTestIds } from 'resources/cypress/testAttributesValues';
import { useToast } from 'tools/utils/hooks/useToast';

import { flowErrorName } from './FlowError';
import { State, useFlowWrapperReducer } from './flowWrapperReducer';
import { StepIndicator } from './StepIndicator';
import * as TEST_IDS from './test-ids';
import { Step, WithLoaded } from './types';

export type FlowWrapperProps<Steps extends [StepName, Step][]> = {
  /**
   * the id of the saved initial requirement
   */
  firstRequirementId?: Data.Id.RequirementId;
  /**
   * the id of the preferred team that is initially created for a workspace
   */
  preferredTeamId?: Data.Id.TeamId;
  /**
   * indicates if there are workspaces that the logged in user is a part of
   */
  hasActiveOrgs: boolean;
  /**
   * the initial state of the FlowWrapper
   */
  initialState?: State;
  /**
   * sets the step from which to start
   */
  initialStep?: Steps[number][0];
  /**
   * indicates if all data has finished saving in the background
   */
  isOnboardingFlowFinished: boolean;
  /**
   * undefined if the user doesn't yet have an access to the organization, defined otherwise
   */
  loadedOrgId?: Data.Id.OrganizationId;
  /**
   * array of steps
   */
  steps: Steps;
  /**
   * a map from saved task titles to their ids
   */
  taskTitleToId?: Record<UI.UITask['title'], Data.Id.TaskId>;
  /**
   * an array of the saved initial sprints
   */
  upcomingSprintIds?: Data.Id.SprintId[];
  /**
   * called after all steps are finished
   */
  onFinish?: () => void | Promise<void>;
};

/**
 * FlowWrapper
 *
 * responsible for:
 * - displaying correct step,
 * - layout step according to the designs,
 * - handles errors in onNext and onFinish callbacks
 */
export function FlowWrapper<Steps extends [StepName, Step][]>({
  firstRequirementId,
  hasActiveOrgs,
  initialState,
  initialStep,
  isOnboardingFlowFinished,
  loadedOrgId,
  steps,
  taskTitleToId,
  upcomingSprintIds,
  onFinish,
  preferredTeamId,
}: FlowWrapperProps<Steps>): JSX.Element {
  const showRightContainer = useRightContainerVisibility();
  const [state, dispatch] = useFlowWrapperReducer(initialState);
  const { addToast } = useToast();
  const [step, setStep] = useState(() =>
    Math.max(
      0,
      steps.findIndex(([stepName]) => stepName === initialStep),
    ),
  );
  const withPreferredTeam = useWithLoaded(loadedOrgId, preferredTeamId);
  const withFirstRequirement = useWithLoaded(loadedOrgId, firstRequirementId);
  const withTasksAndSprints = useWithLoaded(
    loadedOrgId,
    getTaskIdsFromTitles(taskTitleToId, state.taskTitles),
    upcomingSprintIds,
  );

  const stepsCount = steps.length;

  const handleError = useCallback(
    (error: Error, goBackStep: number): void => {
      const message = error.name === flowErrorName ? error.message : strings.onboarding.errors.default;
      addToast({
        message,
        timeoutMs: 3500,
        type: 'error',
      });
      setStep((currentStep) => (currentStep > goBackStep ? goBackStep : currentStep));
    },
    [addToast],
  );

  const advanceToNextStep = useCallback(
    async (currentStep: number, onNext?: () => Promise<void>): Promise<void> => {
      const maxStep = stepsCount - 1;
      const isLastStep = currentStep === maxStep;

      if (!isLastStep) {
        setStep(currentStep + 1);
      }

      try {
        await onNext?.();

        if (isLastStep) {
          await onFinish?.();
        }
      } catch (error) {
        handleError(error, currentStep);
      }
    },
    [stepsCount, handleError, onFinish],
  );

  if (stepsCount <= step) {
    return <div data-testid={TEST_IDS.EMPTY_DIV} />;
  }

  const [currentStepName, CurrentStep] = steps[step];

  return (
    <Wrapper data-testid={TEST_IDS.WRAPPER}>
      <LeftContainer>
        {hasActiveOrgs ? (
          <Link to={linkTo('home', { orgId: '', teamId: '' })}>
            <Logo data-testid={TEST_IDS.TARA_LOGO} />
          </Link>
        ) : (
          <Logo data-testid={TEST_IDS.TARA_LOGO} />
        )}
        <LeftContentContainer>
          <CurrentStep
            dispatch={dispatch}
            isOnboardingFlowFinished={isOnboardingFlowFinished}
            withFirstRequirement={withFirstRequirement}
            withPreferredTeam={withPreferredTeam}
            withTasksAndSprints={withTasksAndSprints}
          >
            {({ Form, childProps, onNext: stepOnNext }): JSX.Element => (
              <Form
                hasActiveOrgs={hasActiveOrgs}
                isOnboardingFlowFinished={isOnboardingFlowFinished}
                {...childProps}
                onNext={(): Promise<void> => advanceToNextStep(step, stepOnNext)}
              />
            )}
          </CurrentStep>
        </LeftContentContainer>
        <StepIndicatorContainer data-cy={workspaceSetupTestIds.STEP_INDICATOR_CONTAINER}>
          <StepIndicator step={step} stepsCount={stepsCount} />
        </StepIndicatorContainer>
      </LeftContainer>
      {showRightContainer && (
        <RightContainer data-testid={TEST_IDS.RIGHT_CONTAINER}>
          <Preview isOnboardingFlowFinished={isOnboardingFlowFinished} stepName={currentStepName} {...state} />
        </RightContainer>
      )}
    </Wrapper>
  );
}

type NonNullableArray<T extends unknown[]> = {
  [I in keyof T]: NonNullable<T[I]>;
};

/**
 * Hide right container on small mobile devices
 */
function useRightContainerVisibility(): boolean {
  const { small } = atomic.responsive.breakpoints[0];
  const isDesktop = useMediaQuery(`(min-width: ${small}px)`);
  const hasEnoughVerticalSpace = useMediaQuery(`(min-height: ${small}px)`);

  return isDesktop || hasEnoughVerticalSpace;
}

/**
 * Maps taskTitles from FlowWrapper state to ids of the saved tasks.
 * Since task creation is not simultaneous and may happen out of order,
 * this function only returns something when all tasks have an id.
 */
function getTaskIdsFromTitles(
  taskTitleToId: Record<string, string> | undefined,
  taskTitles: string[],
): string[] | undefined {
  if (taskTitleToId && taskTitles.filter(isNonEmptyString).every((taskTitle) => taskTitleToId[taskTitle])) {
    return taskTitles.map((taskTitle) => taskTitleToId[taskTitle]);
  }
  return undefined;
}

/**
 * Returns a callback (A) that takes another callback (B).
 * When A is called:
 * - if all deps are not undefined, B is called (with deps passed)
 * - if some deps are undefined, calling B is deferred until all are defined
 *
 * Example:
 *
 * const withTasks = useWithLoaded(tasks); // tasks are UITask[] | undefined
 * const handleClick = useCallback(
 *   withTasks(async (tasks) => { // tasks are ensured to be UITask[] here
 *     ...
 *   }),
 *   []
 * );
 */
export function useWithLoaded<Deps extends unknown[]>(...deps: Deps): WithLoaded<NonNullableArray<Deps>> {
  const deferredCallbacks = useRef<((...args: NonNullableArray<Deps>) => Promise<void>)[]>([]);
  const isEverythingLoaded = isLoaded(...deps);
  useEffect(() => {
    if (isEverythingLoaded) {
      deferredCallbacks.current.forEach((call) => {
        // eslint-disable-next-line prefer-spread
        call.apply(undefined, deps as NonNullableArray<Deps>);
      });
      deferredCallbacks.current = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEverythingLoaded]);
  return useCallback<WithLoaded<NonNullableArray<Deps>>>(
    (callback) => async () => {
      if (!isEverythingLoaded) {
        return new Promise((resolve, reject) => {
          deferredCallbacks.current.push(async (...args) => {
            try {
              // eslint-disable-next-line prefer-spread
              await callback.apply(undefined, args);
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        });
      }
      // eslint-disable-next-line prefer-spread
      await callback.apply(undefined, deps as NonNullableArray<Deps>);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEverythingLoaded],
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    flex-direction: row;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 1rem;
  background-color: #ffffff;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    width: 31.25rem;
    padding: 1rem 2.5rem;
  }
`;

const RightContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #f4f4f6;
`;

const Logo = styled((props) => <img alt={strings.logo.tara} src={TaraLogoIcon} {...props} />)`
  width: 2.5rem;
  height: 2.5rem;
  align-self: center;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    margin: 1.5rem 0;
    align-self: flex-start;
  }
`;

const LeftContentContainer = styled.div`
  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    padding: 5.124rem 0 1rem 0;
  }
`;

const StepIndicatorContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
