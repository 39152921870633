import { Box, fade, Fluid, Hidden, HStack, styled, Text, tokens } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { getFilterByNameFn } from 'components/app/controllers/Selectors/common/filterFn';
import { IconElementData, IconGroup as IconGroupCore } from 'components/core/controllers/IconGroup';
import { SectionType, Selector } from 'components/core/controllers/Selector';
import Icon from 'components/core/controllers/views/Icon';
import { TLogoSize } from 'components/core/controllers/views/Logo';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getOrgTeams, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources';
import { defineRequirementDetailsIds } from 'resources/cypress/testAttributesValues';

type Section = SectionType<UI.UITeam>;
type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;

type SelectorPosition = 'left' | 'right';

type Props = {
  assignedTeams: TeamFragment[];
  linkToCreateTeam?: string;
  onSelectOption?: (teamId: TeamFragment) => void;
  onDeselectOption?: (teamId: TeamFragment) => void;
  selectorPosition?: SelectorPosition;
  disablePopup?: boolean;
};

const filterFn = getFilterByNameFn<Section, UI.UITeam>();

// This is a modified version of the TeamSelector component.
export function SidePanelTeamDropdown({
  assignedTeams,
  linkToCreateTeam,
  onSelectOption,
  onDeselectOption,
  selectorPosition = 'left',
  disablePopup = false,
}: Props): JSX.Element {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);

  const teams = useSelector(getOrgTeams(orgId).selector) ?? [];

  const goToCreateTeam = useCallback(
    (onClose: (event: React.SyntheticEvent) => void) => (event: React.SyntheticEvent): void => {
      onClose(event);
      linkToCreateTeam && history.push(linkToCreateTeam);
    },
    [history, linkToCreateTeam],
  );

  const assignedTeamElements = assignedTeams.map((team) => ({
    id: team.id,
    name: team.name,
    tooltip: team.name,
  }));

  const section: SectionType<TeamFragment> = {
    id: 'teams',
    options: teams,
  };

  const popupStyles: Record<SelectorPosition, string> = {
    right: '',
    left: css`
      right: 0;
      width: 18.75rem;
    `,
  };

  return (
    <Selector
      disablePopup={disablePopup}
      filterFn={filterFn}
      onDeselectOption={onDeselectOption}
      onSelectOption={onSelectOption}
      renderFooter={({ onClose }): JSX.Element => (
        <Box.Button
          borderTop='$grey4'
          height='$fitContent'
          onClick={goToCreateTeam(onClose)}
          role='button'
          spaceHorz='$16px'
          spaceVert='$12px'
        >
          <HStack space='$8px'>
            <PlusIcon name='plus' noPadding />
            <Text color='$focus' size='$14px' weight='regular'>
              {strings.teamSwitcher.createTeam}
            </Text>
          </HStack>
        </Box.Button>
      )}
      renderHeader={() => (
        <Box spaceHorz='$16px' spaceVert='$12px'>
          <Text size='$14px' weight='regular'>
            {strings.teams.selector.defineDropdownPlaceholder}
          </Text>
        </Box>
      )}
      renderOption={({ option, ...props }) => (
        <Box clickable spaceHorz='$16px' spaceVert='$8px'>
          <HStack>
            <Fluid>
              <Text color='$dark' size='$14px' weight='regular'>
                {option.name}
              </Text>
            </Fluid>
            {props.isSelected && <IndigoButton name='tick' noPadding />}
          </HStack>
        </Box>
      )}
      renderSelectButton={({ openPopup }) => (
        <IconGroup elements={assignedTeamElements} elementSize='small' openPopup={disablePopup ? null : openPopup} />
      )}
      sections={[section] as Section[]}
      selection={assignedTeams}
      style={{
        popup: popupStyles[selectorPosition],
      }}
    />
  );
}

const PlusIcon = styled(Icon, { alignSelf: 'center', height: '0.75rem', width: '0.75rem', color: '$focus' });
const IndigoButton = styled(Icon, { color: '$indigo' });
export interface TeamIconGroupProps {
  elements: IconElementData[];
  elementSize?: TLogoSize;
  openPopup: (() => void) | null;
}
function IconGroup({ elements, elementSize, openPopup }: TeamIconGroupProps): JSX.Element {
  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      openPopup?.();
    },
    [openPopup],
  );

  return (
    <>
      <Hidden hidden={elements.length > 0}>
        <Box.Button
          background={fade('$todo', '$20%')}
          borderRadius='$circle'
          center='vert'
          height='$16px'
          onClick={onClick}
          spaceHorz='$4px'
        >
          <HStack alignY='center' space='$4px'>
            <Text size='$10px' weight='medium'>
              {strings.requirements.draftButton.emoji}
            </Text>
            <Text color='$todo' size='$10px' uppercase weight='medium'>
              {strings.requirements.draftButton.label}
            </Text>
            <DropdownIcon color={tokens.colors.$todo} name='dropdownindicator' noPadding />
          </HStack>
        </Box.Button>
      </Hidden>
      <Hidden hidden={!elements.length}>
        <IconGroupCore
          dataCyElement={defineRequirementDetailsIds.TEAM_SELECTOR}
          elements={elements}
          elementSize={elementSize}
          maxShown={5}
          onCountButtonClick={onClick}
          onElementClick={(_elementId, event): void => onClick(event)}
          showPlusButton={false}
        />
      </Hidden>
    </>
  );
}

const DropdownIcon = styled(Icon, {
  width: '0.375rem',
  height: '0.375rem',
  marginTop: '$1px',
});
