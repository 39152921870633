import { Data } from '@taraai/types';
import GitSnippet from 'components/app/controllers/views/GitSnippet';
import { css } from 'emotion';
import React from 'react';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface NoGitDataProps {
  taskId: Data.Id.TaskId;
  isGithubInstalled: boolean;
}

/**
 * NoGitData
 * empty states for no git data
 *
 */
export default function NoGitData({ taskId, isGithubInstalled }: NoGitDataProps): JSX.Element {
  return (
    <div>
      <div
        className={css`
          display: flex;
          padding-top: 1.875rem;
        `}
      >
        <div
          className={css`
            text-transform: uppercase;
            color: #949caf;
            font-size: 0.75rem;
            font-weight: 600;
            width: 6.25rem;
          `}
        >
          {strings.gitTaskLifecycle.branches}
        </div>
        <div
          className={css`
            padding-left: 1.125rem;
          `}
        >
          <div
            className={css`
              color: #949caf;
              font-size: 0.875rem;
              font-weight: normal;
            `}
          >
            {strings.gitTaskLifecycle.include}
            <span
              className={css`
                color: #1d98ff;
              `}
            >
              {strings.formatString(strings.gitTaskLifecycle.taskIdQuote, {
                taskId,
              })}
            </span>
            {strings.gitTaskLifecycle.inLinkYourCode}
          </div>
          <GitSnippet branch dataCy={taskModalTestIds.TASK_MODAL_ROW.BRANCH_URL} taskId={taskId} />
        </div>
      </div>
      {!isGithubInstalled && (
        <>
          <div
            className={css`
              display: flex;
              padding-top: 1.875rem;
              align-items: center;
            `}
          >
            <div
              className={css`
                text-transform: uppercase;
                color: #949caf;
                font-size: 0.75rem;
                font-weight: 600;
                width: 6.25rem;
              `}
            >
              {strings.gitTaskLifecycle.pullRequests}
            </div>
            <div
              className={css`
                color: #949caf;
                font-size: 0.875rem;
                font-weight: normal;
                padding-left: 1.125rem;
              `}
            >
              {strings.gitTaskLifecycle.include}
              <span
                className={css`
                  color: #1d98ff;
                `}
              >
                {strings.formatString(strings.gitTaskLifecycle.taskIdQuote, {
                  taskId,
                })}
              </span>
              {strings.gitTaskLifecycle.inYourPR}
            </div>
          </div>
          <div
            className={css`
              display: flex;
              padding-top: 1.875rem;
            `}
          >
            <div
              className={css`
                text-transform: uppercase;
                color: #949caf;
                font-size: 0.75rem;
                font-weight: 600;
                width: 6.25rem;
              `}
            >
              {strings.gitTaskLifecycle.lastCommit}
            </div>
            <div
              className={css`
                padding-left: 1.125rem;
              `}
            >
              <div
                className={css`
                  color: #949caf;
                  font-size: 0.875rem;
                  font-weight: normal;
                `}
              >
                {strings.gitTaskLifecycle.include}
                <span
                  className={css`
                    color: #1d98ff;
                  `}
                >
                  {strings.formatString(strings.gitTaskLifecycle.taskIdQuote, {
                    taskId,
                  })}
                </span>
                {strings.gitTaskLifecycle.inYourCommit}
              </div>
              <GitSnippet
                className={css`
                  min-width: 28.0625rem;
                `}
                dataCy={taskModalTestIds.TASK_MODAL_ROW.COMMIT_URL}
                taskId={taskId}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
