"use strict";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable id-length */
/* eslint-disable no-loops/no-loops */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelativePositioning = exports.placements = void 0;
var core_1 = require("@popperjs/core");
var react_1 = __importStar(require("react"));
var react_dom_1 = require("react-dom");
var core_2 = require("@popperjs/core");
Object.defineProperty(exports, "placements", { enumerable: true, get: function () { return core_2.placements; } });
/**
 * A common component for positioning content relatively to the child.
 * Uses Popper.js and accepts all placements that Popper.js allows.
 *
 * **Important note:**
 *
 * To avoid wrapping children in another HTML element, the child component has to accept a ref. If the child is
 * a simple function component, then React will warn about it in the console and relative positioning won't work.
 * For an example of how to forward refs check out the Box component in the design-system package.
 *
 * Avoid the temptation to pass an arrow function to `forwardRef` as that will make the component anonymous in React
 * dev tools.
 */
exports.RelativePositioning = react_1.forwardRef(function RelativePositioning(_a, ref) {
    var children = _a.children, content = _a.content, _b = _a.offset, offset = _b === void 0 ? 0 : _b, _c = _a.placement, placement = _c === void 0 ? 'bottom' : _c, _d = _a.show, show = _d === void 0 ? true : _d;
    var childRef = react_1.useRef(null);
    var _e = react_1.useState(null), portal = _e[0], setPortal = _e[1];
    react_1.useImperativeHandle(ref, function () { return portal; }, [portal]);
    react_1.useImperativeHandle(children.ref, function () { return childRef.current; }, []);
    react_1.useLayoutEffect(function () {
        if (!show)
            return;
        var portalElement = window.document.createElement('div');
        portalElement.style.position = 'absolute';
        window.document.body.appendChild(portalElement);
        setPortal(portalElement);
        return function () {
            /**
             * A possible perf improvement would be to create the element on first show and then keep it until the component
             * is unmounted; but for now this should suffice.
             */
            setPortal(null);
            window.document.body.removeChild(portalElement);
        };
    }, [show]);
    react_1.useLayoutEffect(function () {
        if (!portal)
            return;
        var popperInstance = core_1.createPopper(childRef.current, portal, getOptions({ offset: offset, placement: placement }));
        return function () { return popperInstance.destroy(); };
    }, [offset, placement, portal]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.cloneElement(children, { ref: childRef }),
        portal && react_dom_1.createPortal(content, portal)));
});
function getOptions(_a) {
    var offset = _a.offset, placement = _a.placement;
    return {
        placement: placement,
        modifiers: [
            { name: 'offset', options: { offset: [0, offset] } },
            {
                name: 'updateState',
                enabled: true,
                phase: 'write',
                fn: function (_a) {
                    var state = _a.state;
                    // TODO: replace `zIndex: 999` with an upcoming LevelUp component
                    Object.assign(state.elements.popper.style, __assign(__assign({}, state.styles.popper), { zIndex: 999 }));
                    for (var _i = 0, _b = Object.entries(state.attributes.popper); _i < _b.length; _i++) {
                        var _c = _b[_i], key = _c[0], value = _c[1];
                        state.elements.popper.setAttribute(key, String(value));
                    }
                },
                requires: ['computeStyles'],
            },
            { name: 'applyStyles', enabled: false },
        ],
    };
}
