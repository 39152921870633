import { Box, styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { composePlugins, createLabelsPlugin, getWhitespacePlugin, markdownPlugin } from 'components/editor/plugins';
import { RichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { MutableRefObject, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  createLabel,
  defaultLabels,
  getCustomLabels,
  reduxStore,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';

type TaskNameInputProps = {
  value: string;
  trySave: (title: string) => Promise<void>;
  placeholder?: string;
};

export const TaskNameInput: React.FC<TaskNameInputProps> = ({
  value,
  trySave,
  placeholder = '',
}: TaskNameInputProps) => {
  const orgId = useSelector(selectActiveWorkspace);
  const customLabels = useSelector(getCustomLabels(orgId).selector);

  // Don't recreate the plugin every time labels change
  const labels = useRef() as MutableRefObject<UI.UILabel[]>;
  labels.current = [
    ...(Object.keys(defaultLabels) as Data.DefaultLabelId[]).map(selectDefaultLabel),
    ...(customLabels || []),
  ];

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        markdownPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => labels.current,
        }),
      ),
    [],
  );

  return (
    <RichEditorProvider initialValue={value} onSave={trySave} plugin={plugin}>
      <TaskNameInputWrapper spaceVert='$16px'>
        <RichEditor data-cy={taskModalTestIds.TASK_NAME} isTitle placeholder={placeholder} />
      </TaskNameInputWrapper>
    </RichEditorProvider>
  );
};

const TaskNameInputWrapper = styled(Box, {
  paddingLeft: '1.4375rem',
  paddingRight: '1.4375rem',
});
