import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { ExtraAPI, Firestore, Write } from 'reduxStore/utils/types';

type TaskBacklog = null;
type MoveSprint = {
  taskId: Data.Id.TaskId;
  destinationSprintId: Data.Id.SprintId | TaskBacklog;
  orderedTaskIds: Data.Id.TaskId[] | null;
};
type MoveRequirement = {
  taskId: Data.Id.TaskId;
  destinationRequirementId: Data.Id.RequirementId | TaskBacklog;
  orderedTaskIds: Data.Id.TaskId[] | null;
};
type MoveTaskPayload = MoveSprint | MoveRequirement;

export const moveSprintTask = createAsyncThunk(
  'MoveSprintTask',
  async (payload: MoveTaskPayload, { getState, extra }) => {
    const state = getState() as RootState;
    const { taskId, orderedTaskIds } = payload;
    const { getOrgId, getFirestore, getUserId } = extra as ExtraAPI;
    const firestore = getFirestore();
    const orgId = getOrgId();
    const userId = getUserId(state);
    const uniques = (orderedTaskIds && Array.from(new Set(orderedTaskIds))) || null;

    const getDocument = ([path, id]: [string, string]): UI.UITask | undefined => {
      const firestoreData = state.firestore.cache?.database?.[path]?.[id];
      const override = state.firestore.cache?.databaseOverrides?.[path]?.[id];
      return { ...firestoreData, ...override };
    };

    if ('destinationRequirementId' in payload) {
      const { destinationRequirementId } = payload;
      return moveRequirement(
        { taskId, destinationRequirementId, orderedTaskIds: uniques },
        userId,
        orgId,
        firestore,
        getDocument,
      );
    }
    if ('destinationSprintId' in payload) {
      const { destinationSprintId } = payload;
      return moveSprint(
        { taskId, destinationSprintId, orderedTaskIds: uniques },
        userId,
        orgId,
        firestore,
        getDocument,
      );
    }

    return Promise.reject();
  },
);

function moveSprint(
  { taskId, destinationSprintId, orderedTaskIds }: MoveSprint,
  userId: Data.Id.UserId,
  orgId: Data.Id.OrganizationId,
  firestore: Firestore,
  getDoc: (tuple: [string, string]) => UI.UITask | undefined,
): Promise<unknown> {
  const writes: Write[] = [];
  const taskPath = `orgs/${orgId}/tasks`;
  const sprintPath = `orgs/${orgId}/sprints`;

  const { sprint: sourceSprintId } = getDoc([taskPath, taskId]) ?? {};

  if (destinationSprintId !== sourceSprintId) {
    writes.push({
      doc: taskId,
      collection: taskPath,
      data: {
        sprint: destinationSprintId,
        updatedBy: userId,
        ...(destinationSprintId === null ? { '_relationships.requirement': null } : {}),
      },
    });
    // update order if not moving from backlog
    if (sourceSprintId) {
      writes.push({
        doc: sourceSprintId,
        collection: sprintPath,
        data: { orderedTaskIds: ['::arrayRemove', taskId] },
      });
    }
  }

  if (destinationSprintId && orderedTaskIds) {
    writes.push({
      doc: destinationSprintId,
      collection: sprintPath,
      data: { orderedTaskIds },
    });
  }

  return firestore.mutate(writes);
}

function moveRequirement(
  { taskId, destinationRequirementId, orderedTaskIds }: MoveRequirement,
  userId: Data.Id.UserId,
  orgId: Data.Id.OrganizationId,
  firestore: Firestore,
  getDoc: (tuple: [string, string]) => UI.UITask | undefined,
): Promise<unknown> {
  const writes = [];
  const taskPath = `orgs/${orgId}/tasks`;
  const requirementPath = `orgs/${orgId}/requirements`;

  const { _relationships: { requirement: sourceRequirementId = undefined } = {} } = getDoc([taskPath, taskId]) ?? {};

  // handle moving from another requirement
  const requirement =
    destinationRequirementId === sourceRequirementId ? {} : { '_relationships.requirement': destinationRequirementId };
  writes.push({
    doc: taskId,
    collection: taskPath,
    data: {
      ...requirement,
      sprint: null,
      updatedBy: userId,
    },
  });

  if (sourceRequirementId && sourceRequirementId !== destinationRequirementId) {
    writes.push({
      doc: sourceRequirementId,
      collection: requirementPath,
      data: { orderedTaskIds: ['::arrayRemove', taskId] },
    });
  }

  if (orderedTaskIds && destinationRequirementId) {
    writes.push({
      doc: destinationRequirementId,
      collection: requirementPath,
      data: { orderedTaskIds },
    });
  }

  return firestore.mutate(writes);
}
