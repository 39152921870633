/* eslint-disable @typescript-eslint/no-shadow */
import {
  Box,
  Dropdown,
  Fluid,
  HStack,
  Popup,
  Spacer,
  Switch,
  Text,
  tokens,
  Tooltip,
  Tree,
  VStack,
} from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import { AddSeparator } from 'components/app/AddSeparator';
import {
  DraggableElement,
  DraggableType,
  DroppableArea,
  DroppableDescription,
  DroppableType,
} from 'components/app/DragAndDrop';
import {
  EmptyDrawerImports,
  EmptyDrawerProps,
  EmptyDrawerRepos,
  EmptyDrawerRequirements,
  EmptyDrawerTasks,
} from 'components/app/EmptyWorkDrawer/EmptyWorkDrawer';
import { TaskCardController } from 'components/app/TaskCard';
import { TaskCreation } from 'components/app/TaskCreation';
import { PrimaryHeader, SecondaryHeader } from 'components/app/WorkDrawerHeaders';
import { WorkDrawerInlineInput } from 'components/app/WorkDrawerInlineInput';
import Icon from 'components/core/controllers/views/Icon';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { linkTo } from 'components/Router/paths';
import React, { forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { orderedDocuments, reduxStore, requirementTasks } from 'reduxStore';
import { openImportModal } from 'reduxStore/imports/actions';
import {
  SettingType,
  useWorkDrawerSelectedValue,
  useWorkDrawerSettingsSetState,
  useWorkDrawerSettingsState,
  useWorkDrawerSettingsToggleState,
  useWorkDrawerUnfoldedState,
} from 'reduxStore/workDrawer';
import { strings } from 'resources';
import { IconName } from 'resources/assets/icons';
import { ASANA_EXPORT_INSTRUCTION_URL, TRELLO_EXPORT_INSTRUCTION_URL } from 'tools/libraries';

import { removeExtraTaskFields, SelectTaskFragment, WorkDrawerImportTasksId } from './WorkDrawerController';

export function WorkDrawerHeader(): JSX.Element {
  return (
    <Box background='$white' spaceHorz='$8px' spaceVert='$12px'>
      <HStack alignY='center'>
        <Text size='$12px' weight='medium'>
          {strings.workDrawer.title}
        </Text>
        <Fluid />
        <Text color='$grey6' size='$10px' weight='bold'>
          {strings.workDrawer.filters}
        </Text>
        <Spacer space='$12px' />
        <FilterButton iconName='tasksFilterAssigned' setting='tasks:show-assigned-to-sprint' />
        <Spacer space='$8px' />
        <FilterButton iconName='tasksFilterDone' setting='tasks:show-done' />
        <Spacer space='$8px' />
        <WorkDrawerSettings />
      </HStack>
    </Box>
  );
}

type FilterButtonSettingType = SettingType & ('tasks:show-assigned-to-sprint' | 'tasks:show-done');

function FilterButton({ iconName, setting }: { iconName: IconName; setting: FilterButtonSettingType }): JSX.Element {
  const settingState = useWorkDrawerSettingsState(setting);
  const iconColor = settingState ? tokens.colors.$indigo : tokens.colors.$grey6;
  const tooltipTitle = strings.workDrawer.settingsTooltips[setting][settingState ? 'on' : 'off'];
  return (
    <Tooltip title={tooltipTitle}>
      <Box.Button onClick={useWorkDrawerSettingsToggleState(setting)}>
        <Icon autoSize color={iconColor} name={iconName} noPadding />
      </Box.Button>
    </Tooltip>
  );
}

export function WorkDrawerSettings(): JSX.Element {
  const [show, setShow] = useState(false);
  return (
    <Popup
      content={
        <Popup.Content>
          <WorkDrawerSettingsPopup />
        </Popup.Content>
      }
      onHide={() => setShow(false)}
      placement='bottom-end'
      show={show}
    >
      <Box.Button onClick={() => setShow((prev) => !prev)}>
        <Icon autoSize color={show ? tokens.colors.$dark : tokens.colors.$grey5} name='kebab2' noPadding />
      </Box.Button>
    </Popup>
  );
}

function WorkDrawerSettingsPopup(): JSX.Element {
  return (
    <VStack space='$16px'>
      <WorkDrawerSettingsPopupHeader>{strings.workDrawer.settingsHeaders.requirements}</WorkDrawerSettingsPopupHeader>
      <WorkDrawerSettingsPopupItem settingType='requirements:show-drafts' />
      <WorkDrawerSettingsPopupItem settingType='requirements:show-archived' />
    </VStack>
  );
}

function WorkDrawerSettingsPopupHeader({ children }: { children: string }): JSX.Element {
  return (
    <Text color='$grey7' size='$12px' weight='medium'>
      {children}
    </Text>
  );
}

type PopupItemSettingType = SettingType & ('requirements:show-archived' | 'requirements:show-drafts');

function WorkDrawerSettingsPopupItem({ settingType }: { settingType: PopupItemSettingType }): JSX.Element {
  return (
    <HStack alignY='center' space='$12px'>
      <Fluid>
        <Text color='$grey7' size='$12px'>
          {strings.workDrawer.settingsDescriptions[settingType]}
        </Text>
      </Fluid>
      <Switch checked={useWorkDrawerSettingsState(settingType)} onChange={useWorkDrawerSettingsSetState(settingType)} />
    </HStack>
  );
}

export type WorkDrawerTaskCardProps = Pick<UI.UITask, 'id'>;

export function WorkDrawerTasks({
  droppableAreaDescription,
  onTaskCreate,
  onTaskSelect,
  tasks,
}: {
  droppableAreaDescription: DroppableDescription;
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  tasks: WorkDrawerTaskCardProps[];
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('tasks');
  return (
    <DroppableArea description={droppableAreaDescription} styles={{ minHeight: '0' }}>
      {() => {
        return (
          <Tree onChange={setUnfolded} unfolded={unfolded ?? false}>
            <PrimaryHeader icon={tasksIcon} title={strings.tasks.tasks} />
            <Tree.Content>
              {tasks.length ? (
                <TaskCardsWithCreation
                  onTaskCreate={onTaskCreate}
                  onTaskSelect={onTaskSelect}
                  showSeparatorInTasksSection
                  tasks={tasks}
                />
              ) : (
                <EmptyDrawerTasks />
              )}
            </Tree.Content>
          </Tree>
        );
      }}
    </DroppableArea>
  );
}

export function WorkDrawerRequirements({
  onRequirementCreate,
  onRequirementSelect,
  onTaskCreate,
  onTaskSelect,
  orgId,
  requirements,
  filterTask,
}: {
  onRequirementCreate: (title: string) => void;
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  orgId: string;
  requirements: UI.UIRequirement[];
  filterTask: (task: SelectTaskFragment) => boolean;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('requirements');
  const [showInput, setShowInput] = useState(false);
  return (
    <Tree onChange={setUnfolded} unfolded={unfolded ?? true}>
      <PrimaryHeader
        icon={requirementsIcon}
        title={strings.requirements.title}
        widget={
          <Tree.Widget>
            {({ toggle, unfolded }) => (
              <TopLevelCreationButton
                onClick={() => {
                  if (!unfolded) toggle();
                  setShowInput(true);
                }}
              />
            )}
          </Tree.Widget>
        }
      />
      <Tree.Content>
        <VStack space='$2px'>
          {showInput && (
            <WorkDrawerInlineInput
              initialFocus
              onDelete={() => setShowInput(false)}
              onEnter={(title) => {
                onRequirementCreate(title);
                setShowInput(false);
              }}
              placeholder={strings.workDrawer.createRequirement}
            />
          )}
          {requirements.length ? (
            requirements.map((requirement) => (
              <WorkDrawerRequirementsItem
                key={requirement.id}
                filterTask={filterTask}
                onRequirementSelect={onRequirementSelect}
                onTaskCreate={onTaskCreate}
                onTaskSelect={onTaskSelect}
                orgId={orgId}
                {...requirement}
              />
            ))
          ) : (
            <EmptyDrawerRequirements />
          )}
        </VStack>
      </Tree.Content>
    </Tree>
  );
}

function WorkDrawerRequirementsItem({
  id,
  onRequirementSelect,
  onTaskCreate,
  onTaskSelect,
  orderedTaskIds,
  orgId,
  title,
  filterTask,
}: UI.UIRequirement & {
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  orgId: string;
  filterTask: (task: SelectTaskFragment) => boolean;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('requirements', id);
  const editorRef = useRef<RichEditorHandle>(null);
  const [selected, block] = useWorkDrawerSelectedValue('requirements', id);
  const ref = useScrollIntoViewRef<HTMLDivElement>(selected, block);

  useEffect(() => {
    if (selected) editorRef.current?.focus();
  }, [selected]);

  return (
    <VStack space='$1px'>
      <Tree onChange={setUnfolded} unfolded={unfolded ?? selected}>
        <SecondaryHeader
          ref={ref}
          isUnfolded={unfolded}
          onClick={() => onRequirementSelect(id)}
          selected={selected}
          title={title}
        />
        <Tree.Content strategy='lazy'>
          <RequirementTaskCards
            editorRef={editorRef}
            filterTask={filterTask}
            onTaskCreate={onTaskCreate}
            onTaskSelect={onTaskSelect}
            orderedTaskIds={orderedTaskIds}
            orgId={orgId}
            requirementId={id}
          />
        </Tree.Content>
      </Tree>
    </VStack>
  );
}

export function WorkDrawerRepos({
  onTaskSelect,
  orgId,
  repos,
  teamId,
}: EmptyDrawerProps & {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  repos: WorkDrawerImportTasksId[];
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('repos');
  return (
    <Tree onChange={setUnfolded} unfolded={unfolded ?? false}>
      <PrimaryHeader
        icon={reposIcon}
        title='Repositories'
        widget={<TopLevelCreationLink to={linkTo('integrations', { orgId, teamId })} />}
      />
      <Tree.Content>
        <VStack space='$2px'>
          {repos.some((repo) => repo.tasks?.length) ? (
            repos.map(
              (repo) =>
                repo.tasks &&
                repo.tasks.length > 0 && <WorkDrawerReposItem key={repo.title} onTaskSelect={onTaskSelect} {...repo} />,
            )
          ) : (
            <EmptyDrawerRepos orgId={orgId} teamId={teamId} />
          )}
        </VStack>
      </Tree.Content>
    </Tree>
  );
}

function WorkDrawerReposItem({
  onTaskSelect,
  tasks,
  title,
}: WorkDrawerImportTasksId & {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('repos', title);
  return (
    <Tree onChange={setUnfolded} unfolded={unfolded ?? false}>
      <SecondaryHeader onClick={noop} title={title} />
      <Tree.Content>
        <TaskCards isDraggable={false} onTaskSelect={onTaskSelect} tasks={tasks} />
      </Tree.Content>
    </Tree>
  );
}

export function WorkDrawerImports({
  imports,
  onTaskSelect,
  orgId,
  teamId,
}: EmptyDrawerProps & {
  imports: WorkDrawerImportTasksId[];
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('imports');
  return (
    <Tree onChange={setUnfolded} unfolded={unfolded ?? false}>
      <PrimaryHeader icon={importsIcon} title='Imports' widget={<ImportsDropdown />} />
      <Tree.Content>
        {imports.some((importItem) => importItem.tasks?.length) ? (
          imports.map(
            (service) =>
              service.tasks &&
              service.tasks.length > 0 && (
                <WorkDrawerImportsItem key={service.title} onTaskSelect={onTaskSelect} {...service} />
              ),
          )
        ) : (
          <EmptyDrawerImports orgId={orgId} teamId={teamId} />
        )}
      </Tree.Content>
    </Tree>
  );
}

function WorkDrawerImportsItem({
  onTaskSelect,
  tasks,
  title,
}: WorkDrawerImportTasksId & {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('imports', title);
  return (
    <Tree onChange={setUnfolded} unfolded={unfolded}>
      <SecondaryHeader
        onClick={noop}
        title={title}
        widget={
          <Tree.FoldedContent>
            <Text size='$10px'>{tasks?.length}</Text>
          </Tree.FoldedContent>
        }
      />
      <Tree.Content>
        <TaskCards isDraggable={false} onTaskSelect={onTaskSelect} tasks={tasks} />
      </Tree.Content>
    </Tree>
  );
}

function ImportsDropdown(): JSX.Element {
  const [show, setShow] = useState(false);
  return (
    <Dropdown
      items={[
        <Dropdown.DefaultItem key='trello-upload' onClick={() => reduxStore.dispatch(openImportModal('trello'))}>
          <HStack alignY='center' space='$8px'>
            <Fluid>
              <Text size='$12px'>{strings.integrations.trello.upload}</Text>
            </Fluid>
            {uploadIcon}
          </HStack>
        </Dropdown.DefaultItem>,
        <Dropdown.DefaultItem key='asana-upload' onClick={() => reduxStore.dispatch(openImportModal('asana'))}>
          <HStack alignY='center' space='$8px'>
            <Fluid>
              <Text size='$12px'>{strings.integrations.asana.upload}</Text>
            </Fluid>
            {uploadIcon}
          </HStack>
        </Dropdown.DefaultItem>,
        <a key='trello-info' href={TRELLO_EXPORT_INSTRUCTION_URL} rel='noopener noreferrer' target='_blank'>
          <Dropdown.DefaultItem>
            <Text size='$12px'>{strings.integrations.trello.importInfo}</Text>
          </Dropdown.DefaultItem>
        </a>,
        <a key='asana-info' href={ASANA_EXPORT_INSTRUCTION_URL} rel='noopener noreferrer' target='_blank'>
          <Dropdown.DefaultItem>
            <Text size='$12px'>{strings.integrations.asana.importInfo}</Text>
          </Dropdown.DefaultItem>
        </a>,
      ]}
      onHide={() => setShow(false)}
      placement='bottom-end'
      show={show}
    >
      <TopLevelCreationButton onClick={() => setShow((prev) => !prev)} />
    </Dropdown>
  );
}

function RequirementTaskCards({
  onTaskCreate,
  onTaskSelect,
  orderedTaskIds,
  orgId,
  requirementId,
  filterTask,
  editorRef,
}: {
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  editorRef?: RefObject<RichEditorHandle>;
  orderedTaskIds: Data.Id.TaskId[];
  orgId: Data.Id.OrganizationId;
  requirementId: Data.Id.RequirementId;
  filterTask: (task: SelectTaskFragment) => boolean;
}): JSX.Element | null {
  const tasksSlice = requirementTasks(orgId, requirementId);
  const tasks: SelectTaskFragment[] = useSelector(
    compose(
      (tasks: UI.UITask[]) =>
        tasks &&
        tasks.map(({ id, status, sprint, externalIssue }) => ({
          id,
          status,
          sprint,
          externalIssue,
        })),
      orderedDocuments(tasksSlice.selector, () => orderedTaskIds),
    ),
    deepEquals,
  );
  useFirestoreConnect(tasksSlice.query);
  const filteredTasks: WorkDrawerTaskCardProps[] = tasks?.filter(filterTask)?.filter(removeExtraTaskFields) || [];
  const droppableAreaDescription: DroppableDescription = {
    id: requirementId,
    type: DroppableType.requirement,
    visibleList: filteredTasks.map((task) => task.id),
    list: tasks?.map((task) => task.id) || [],
  };

  return (
    <DroppableArea description={droppableAreaDescription}>
      {() => {
        if (filteredTasks.length) {
          return (
            <>
              <TaskCardsWithCreation
                onTaskCreate={onTaskCreate}
                onTaskSelect={onTaskSelect}
                requirementId={requirementId}
                tasks={filteredTasks}
              />
            </>
          );
        }
        if (isLoaded(tasks) && filteredTasks.length < 1) {
          return (
            <Box background='$white' borderRadius='$2px' space='$8px' spaceRight='$4px'>
              <TaskCreation onEnter={(newTaskTitle) => onTaskCreate(newTaskTitle, requirementId)}>
                <CreateTaskEditor ref={editorRef} placeholder={strings.workDrawer.createFirstRequirementTask} />
              </TaskCreation>
            </Box>
          );
        }
        return <></>;
      }}
    </DroppableArea>
  );
}

function TaskCards({
  onTaskSelect,
  tasks,
  sourceType = DroppableType.workdrawerTasks,
  isDraggable = true,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  tasks: WorkDrawerTaskCardProps[];
  sourceType?: DroppableType;
  isDraggable?: boolean;
}): JSX.Element {
  return (
    <Box spaceLeft='$12px'>
      <VStack space='$1px'>
        {tasks.map((task, index) =>
          isDraggable ? (
            <DraggableElement
              key={`WD_${task.id}_${sourceType}`}
              index={index}
              sortable={false}
              sourceType={sourceType}
              type={DraggableType.requirement}
            >
              <TaskCardsItem key={task.id} onTaskSelect={onTaskSelect} task={task} />
            </DraggableElement>
          ) : (
            <TaskCardsItem key={task.id} onTaskSelect={onTaskSelect} task={task} />
          ),
        )}
      </VStack>
    </Box>
  );
}

const TaskCardsWithCreation = React.memo(function TaskCardsWithCreation({
  onTaskCreate,
  onTaskSelect,
  tasks,
  requirementId,
  showSeparatorInTasksSection = false,
}: {
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  tasks: WorkDrawerTaskCardProps[];
  requirementId?: Data.Id.RequirementId;
  showSeparatorInTasksSection?: boolean;
}): JSX.Element {
  const [showCreation, setShowCreation] = useState(false);
  const [creationIndex, setCreationIndex] = useState(0);
  const createInlineTaskRef = useRef<RichEditorHandle>(null);
  const submitting = useRef(false);
  submitting.current = false;

  useEffect(() => {
    if (showCreation) createInlineTaskRef.current?.focus();
  }, [showCreation]);

  const handleSeparatorClick = (index: number): void => {
    setShowCreation(true);
    setCreationIndex(index);
  };

  const handleTaskCreation = (title: string, index: number): void => {
    if (submitting.current) return;
    submitting.current = true;
    const currentIds = tasks.map((task) => task.id);
    /**
     * we are incrementing by 1 here because:
     * (example) clicking below the first card will
     * give an index of 0, when we actually want to
     * 'insert' the task at the next index of 1
     */
    onTaskCreate(title, requirementId, index + 1, currentIds);
    createInlineTaskRef.current?.clear();
    setShowCreation(false);
    submitting.current = false;
  };
  return (
    <Box spaceLeft='$12px' spaceTop='$2px'>
      <VStack space='$1px'>
        {showSeparatorInTasksSection && !showCreation && <AddSeparator onClick={() => handleSeparatorClick(0)} />}
        {creationIndex === 0 && showCreation && showSeparatorInTasksSection && (
          <TaskCreationBox
            createInlineTaskRef={createInlineTaskRef}
            handleTaskCreation={handleTaskCreation}
            index={creationIndex}
          />
        )}
        {tasks.map((task, index) => (
          <DraggableElement
            key={`WD_${task.id}_workdrawerTasks`}
            index={index}
            sortable={false}
            sourceType={DroppableType.workdrawerTasks}
            type={DraggableType.workDrawerTask}
          >
            <Box key={task.id}>
              <span>
                <TaskCardsItem onTaskSelect={onTaskSelect} task={task} />
                {!showCreation && !showSeparatorInTasksSection && (
                  <AddSeparator onClick={() => handleSeparatorClick(index)} />
                )}
              </span>
              {creationIndex === index && showCreation && !showSeparatorInTasksSection && (
                <TaskCreationBox
                  createInlineTaskRef={createInlineTaskRef}
                  handleTaskCreation={(title: string) => handleTaskCreation(title, index)}
                  index={index}
                />
              )}
            </Box>
          </DraggableElement>
        ))}
      </VStack>
    </Box>
  );
});

function TaskCardsItem({
  onTaskSelect,
  task,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  task: WorkDrawerTaskCardProps;
}): JSX.Element {
  const [selected, block] = useWorkDrawerSelectedValue('tasks', task.id);
  const ref = useScrollIntoViewRef<HTMLDivElement>(selected, block);
  return (
    <Box background='$grey2'>
      <Box.Button key={task.id} ref={ref} onClick={() => onTaskSelect(task.id)}>
        <TaskCardController key={task.id} inWorkDrawer selected={selected} taskId={task.id} />
      </Box.Button>
    </Box>
  );
}

function TopLevelCreationLink({ to }: { to: string }): JSX.Element {
  return (
    <Link onClick={(event) => event.stopPropagation()} to={to}>
      {addIcon}
    </Link>
  );
}

const TopLevelCreationButton = forwardRef<HTMLDivElement, { onClick: () => void }>(function TopLevelCreationButton(
  { onClick },
  ref,
): JSX.Element {
  return (
    <Box.Button
      ref={ref}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {addIcon}
    </Box.Button>
  );
});

function useScrollIntoViewRef<RefElement extends Element>(
  active: boolean,
  block: 'nearest' | 'center',
): RefObject<RefElement> {
  const ref = useRef<RefElement>(null);
  useEffect(() => {
    if (active) {
      ref.current?.scrollIntoView({ block, inline: 'nearest' });
    }
  }, [active, block]);
  return ref;
}

const CreateTaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$12px',
  caretColor: 'colors.$focus',
});

type TaskCreationBoxProps = {
  handleTaskCreation: (title: string, index: number) => void;
  createInlineTaskRef: React.RefObject<RichEditorHandle>;
  index: number;
};
const TaskCreationBox = ({ handleTaskCreation, createInlineTaskRef, index }: TaskCreationBoxProps): JSX.Element => (
  <Box spaceTop='$1px'>
    <Box background='$white' borderRadius='$2px' space='$8px' spaceRight='$4px'>
      <TaskCreation onEnter={(title: string) => handleTaskCreation(title, index)}>
        <CreateTaskEditor ref={createInlineTaskRef} placeholder={strings.workDrawer.createTask} saveOnBlur />
      </TaskCreation>
    </Box>
  </Box>
);
const tasksIcon = (
  <svg fill={tokens.colors.$grey7} height='16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.58497 10.5001L6.23147 9.5H5.17071H4C3.72386 9.5 3.5 9.27614 3.5 9V4C3.5 3.72386 3.72386 3.5 4 3.5H12C12.2761 3.5 12.5 3.72386 12.5 4V9C12.5 9.27614 12.2761 9.5 12 9.5H10.8293H9.76853L9.41503 10.5001C9.2084 11.0848 8.65077 11.5 8 11.5C7.34923 11.5 6.7916 11.0848 6.58497 10.5001ZM4 2C2.89543 2 2 2.89543 2 4V9V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V9V4C14 2.89543 13.1046 2 12 2H4Z' />
  </svg>
);

const requirementsIcon = (
  <svg fill={tokens.colors.$grey7} height='16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12.393 2.38672L13.6158 1.16386L15.3836 2.93163L14.1608 4.15449L12.393 2.38672ZM11.869 2.91074L9.28094 5.49875L11.0487 7.26652L13.6367 4.6785L11.869 2.91074ZM8.1054 7.72825L8.84035 5.93937L10.6081 7.70713L8.73039 8.35325L8.1054 7.72825ZM9 3.5H4C3.72386 3.5 3.5 3.72386 3.5 4V12C3.5 12.2761 3.72386 12.5 4 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12V8H14V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4C2 2.89543 2.89543 2 4 2H9V3.5ZM5 4.75H7V6.25H5V4.75ZM7 7.25H5V8.75H7V7.25ZM5 9.75H11V11.25H5V9.75Z' />
  </svg>
);

const reposIcon = (
  <svg fill={tokens.colors.$grey7} fillRule='evenodd' height='16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 3.5H9V8L7.5 6.75L6 8V3.5H4.5V12.5H12C12.2761 12.5 12.5 12.2761 12.5 12V4C12.5 3.72386 12.2761 3.5 12 3.5ZM4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2H4Z' />
  </svg>
);

const importsIcon = (
  <svg fill={tokens.colors.$grey7} fillRule='evenodd' height='16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 3.5H8.75V7.25H11.0311L8 11L4.96891 7.25H7.25V3.5H4C3.72386 3.5 3.5 3.72386 3.5 4V12C3.5 12.2761 3.72386 12.5 4 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12V4C12.5 3.72386 12.2761 3.5 12 3.5ZM4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2H4Z' />
  </svg>
);

const addIcon = (
  <svg fill='none' height='14' stroke={tokens.colors.$grey6} width='14' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 4V10M4 7H10' strokeLinecap='round' />
    <circle cx='7' cy='7' r='6.5' />
  </svg>
);

const uploadIcon = (
  <svg fill='none' height='12' stroke={tokens.colors.$grey6} width='12' xmlns='http://www.w3.org/2000/svg'>
    <rect height='11' rx='1.5' width='11' x='0.5' y='0.5' />
    <path d='M6 3.5V8.5M6 3.5L4 5.5M6 3.5L8 5.5' strokeLinecap='round' />
  </svg>
);
