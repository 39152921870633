/**
 * Initializes Stackdriver error reporting so we have all our reports
 * in Google Cloud.
 *
 * Usage:
 * ```
 * import { errorHandler } from 'tools/libraries/stackdriver';
 *
 * reportError(new Error('something broke!'));
 * ```
 *
 * See https://github.com/GoogleCloudPlatform/stackdriver-errors-js
 */

const projectId = process.env.REACT_APP_GCP_PROJECT_ID;
const apiKey = process.env.REACT_APP_STACKDRIVER_KEY;
const reportUrl = `https://clouderrorreporting.googleapis.com/v1beta1/projects/${projectId}/events:report?key=${apiKey}`;

export const reportError = process.env.NODE_ENV === 'production' ? stackdriverReport : rethrow;

if (process.env.NODE_ENV === 'production') {
  window.onerror = (message, source, lineno, colno, error) => {
    if (error) reportError(error);
    return true;
  };

  window.onunhandledrejection = (promiseRejectionEvent: PromiseRejectionEvent) => {
    if (promiseRejectionEvent) reportError(promiseRejectionEvent.reason);
    return true;
  };
}

function stackdriverReport(error: Error): void {
  const payload = {
    serviceContext: {
      service: 'default',
      version: process.env.REACT_APP_VERSION,
    },
    context: {
      httpRequest: {
        userAgent: window.navigator.userAgent,
        url: window.location.href,
      },
    },
    message: error.stack,
  };

  const xhr = new XMLHttpRequest();
  xhr.open('POST', reportUrl);
  xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  xhr.send(JSON.stringify(payload));
}

function rethrow(error: Error): never {
  throw error;
}
