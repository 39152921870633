import { UISprint } from '@taraai/types/dist/ui';
import SprintsRow from 'components/app/controllers/SprintsRow';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { getCompletedSprints, selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { sort } from 'tools/libraries/helpers/sort';

/**
 * controls running query for completed sprint and passing completed sprints from the store to the SprintsRow
 *
 */
export default function CompletedSprints(): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const completedSlice = getCompletedSprints(orgId, teamId, {
    orderBy: 'sprintNumber',
  });
  useFirestoreConnect(completedSlice.query);

  const completedSprints = useSelector(
    compose(
      (arr: UISprint[]) =>
        arr.map((data) => ({
          id: data.id,
          sprintName: data.sprintName,
          description: data.description,
          startDate: data.startDate,
          endDate: data.endDate,
          completedAt: data.completedAt,
          computedOnCompletion: data.computedOnCompletion,
          isComplete: data.isComplete,
          orderedTaskIds: data.orderedTaskIds,
        })),
      (arr) => sort(arr || [], 'createdAt', true),
      completedSlice.selector,
    ),
    deepEquals,
  );

  return <SprintsRow isComplete sprints={completedSprints} />;
}
