import { UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import InvitedUsersLayout from 'components/app/controllers/views/InvitedUsersLayout';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { reduxStore, selectActiveWorkspace, selectPreferredTeamId } from 'reduxStore';
import { inviteUsers } from 'reduxStore/invitation';
import { getOrgInvitations } from 'reduxStore/invitation/queries';
import { strings } from 'resources/i18n';
import { useToast } from 'tools/utils/hooks/useToast';

type ResendingInProgressMap = {
  [inviteeEmail: string]: boolean;
};

export default function InvitedUsersController(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));

  const orgInvitationsSlice = getOrgInvitations(orgId);

  useFirestoreConnect(orgInvitationsSlice.query);

  // select only invitations with invitee email address.
  const invitationsWithInviteeEmail =
    useSelector(
      compose(
        (arr) =>
          arr
            ?.map((data) => {
              if ('email' in data) {
                return { email: data.email, message: data.message };
              }
              return undefined;
            })
            .filter(notUndefined),
        orgInvitationsSlice.selector,
      ),
    ) ?? [];

  const { addToast } = useToast();

  const [resendingInProgressMap, setResendingInProgressMap] = useState({} as ResendingInProgressMap);

  const onResendButtonClick = useCallback(
    async (email, message) => {
      // We don't want multiple resend clicks to result in multiple emails
      if (resendingInProgressMap[email]) {
        return;
      }

      setResendingInProgressMap({
        ...resendingInProgressMap,
        [email]: true,
      });

      const payload: UI.Invitation = {
        emails: [email],
        message,
        // teamId for resend should be ignored on backend, but is required in payload
        teamId: preferredTeamId,
      };
      await reduxStore.dispatch(inviteUsers(payload));

      setResendingInProgressMap({
        ...resendingInProgressMap,
        [email]: false,
      });

      addToast({
        message: strings.users.invitedUsers.resendSuccessNotification,
        timeoutMs: 3500,
        type: 'success',
      });
    },
    [resendingInProgressMap, addToast, preferredTeamId],
  );

  return (
    <InvitedUsersLayout
      invitations={invitationsWithInviteeEmail}
      onResendButtonClick={onResendButtonClick}
      resendingInProgressMap={resendingInProgressMap}
    />
  );
}
