import { unwrapResult } from '@reduxjs/toolkit';
import { TaskPartial } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { actions, getFlowError, Step } from 'components/app/Onboarding/FlowWrapper';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Redirect } from 'react-router';
import { createTask, reduxStore, selectPreferredTeamId } from 'reduxStore';
import { skipOnboarding } from 'reduxStore/onboarding/actions/skip';
import { decode } from 'reduxStore/utils/decoders';
import strings from 'resources/i18n';
import { segment } from 'tools/libraries/analytics';
import { useLoadedOrgId } from 'tools/utils/hooks/useLoadedOrgId';

import { Form } from './Form';

export const CreateTasksStep: Step = ({
  children,
  dispatch,
  withFirstRequirement,
  withPreferredTeam,
  isOnboardingFlowFinished,
}) => {
  const loadedOrgId = useLoadedOrgId();
  const preferredTeamId = useSelector(selectPreferredTeamId(loadedOrgId));
  const [taskTitles, setTaskTitles] = useState<string[]>([]);
  const [isSkipping, setIsSkipping] = useState(false);

  const handleOnChange = useCallback(
    (taskTitle: string, index: number) =>
      setTaskTitles((prevTaskTitles) => Object.assign([], prevTaskTitles, { [index]: taskTitle })),
    [],
  );

  useEffect(() => {
    dispatch(actions.setTaskTitles(taskTitles));
  }, [dispatch, taskTitles]);

  const onSkip = useCallback(async () => {
    setIsSkipping(true);
    await withPreferredTeam(async (orgId) => {
      try {
        await reduxStore.dispatch(skipOnboarding({ orgId })).then(unwrapResult);
      } catch {
        setIsSkipping(false);
        throw getFlowError(strings.onboarding.tasksForm.errorOnSkip);
      }
    })();
  }, [withPreferredTeam]);

  const onNext = useCallback(async () => {
    let decodedTaskTitles: string[];
    try {
      decodedTaskTitles = taskTitles
        .filter((taskName) => isNonEmptyString(taskName))
        .map((title) => {
          decode<TaskPartial>({ title }, 'TaskPartial');
          return title;
        });
    } catch {
      throw getFlowError(strings.task.titleTooShort);
    }

    await withFirstRequirement(async (orgId, firstRequirementId) => {
      try {
        await Promise.all(
          decodedTaskTitles.map((title) =>
            reduxStore
              .dispatch(
                createTask({
                  title,
                  _relationships: { requirement: firstRequirementId },
                  sprint: null,
                  orgId,
                }),
              )
              .then(unwrapResult)
              .then((taskId) => {
                segment.track('TaskCreated', { orgID: orgId, taskID: taskId, location: 'Onboarding' });
              }),
          ),
        );
      } catch {
        throw getFlowError(strings.onboarding.tasksForm.createTaskFailed);
      }
    })();
  }, [taskTitles, withFirstRequirement]);

  if (isSkipping && isOnboardingFlowFinished && isLoaded(loadedOrgId) && isLoaded(preferredTeamId)) {
    return (
      <Redirect
        to={linkTo('sprints', {
          orgId: loadedOrgId,
          teamId: preferredTeamId,
        })}
      />
    );
  }

  return children({
    childProps: {
      taskTitles,
      handleOnChange,
      onSkip,
      isSkipping,
    },
    Form,
    onNext,
  });
};
