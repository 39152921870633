import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Checkbox, Fluid, Hidden, HStack, Text } from '@taraai/design-system';
import { TaskStatus, UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { SubtaskFragment } from 'components/app/TaskDetails/TaskDetails';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import { RichEditorHandle } from 'components/editor/RichEditor';
import React, { useEffect, useRef, useState } from 'react';
import { deleteTask, reduxStore, updateTask } from 'reduxStore';
import { decodeChain } from 'reduxStore/utils/decoders';
import { strings } from 'resources';
import { taskDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { useToast } from 'tools';

import { Editor } from './Editor';
import { SubtaskAssigneeController } from './SubtaskAssigneeController';

type Props = {
  subtask: SubtaskFragment;
};

export const SubtaskItem = ({ subtask }: Props): JSX.Element => {
  const { addToast } = useToast();
  const editorRef = useRef<RichEditorHandle>(null);
  const { whenError } = useToast();
  const [editing, setEditing] = useState(false);
  const textAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editing) editorRef.current?.focus();
  }, [editing]);

  const handleDelete = (): void => {
    reduxStore
      .dispatch(deleteTask({ id: subtask.id }))
      .then(unwrapResult)
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToRemoveTask });
      });
  };

  const handleSave = (taskTitle: string): void => {
    const { effortLevel, collaborators } = extractEffortLevel(extractMentions({ title: taskTitle }));

    const changesetData = {
      id: subtask.id,
      title: taskTitle,
      ...(effortLevel && { effortLevel }),
      ...(collaborators?.length && { collaborators }),
    };

    if (taskTitle.length)
      decodeChain<UI.UITaskChangeset>(changesetData, 'UITaskChangeset')
        .then((changeset) => reduxStore.dispatch(updateTask(changeset)))
        .then(unwrapResult)
        .catch(noop);
  };

  const isTaskComplete = subtask.status === TaskStatus.Done;
  const setTaskComplete = (isChecked: boolean): void => {
    reduxStore
      .dispatch(updateTask({ id: subtask.id, status: isChecked ? TaskStatus.Done : TaskStatus.Doing }))
      .then(unwrapResult)
      .catch(whenError(strings.task.failedToUpdateTask));
  };

  return (
    <Box>
      <HStack alignY='center' space='$8px'>
        <Checkbox checked={isTaskComplete} data-cy={taskDetailsTestIds.SUBTASK_CHECKBOX} onChange={setTaskComplete} />
        <Fluid ref={textAreaRef}>
          <Hidden hidden={!editing} strategy='remove'>
            <Editor
              ref={editorRef}
              data-cy={taskDetailsTestIds.SUBTASK_TITLE}
              initialValue={subtask.title}
              onBlur={() => setEditing(false)}
              onDelete={handleDelete}
              onSave={handleSave}
            />
          </Hidden>
          <Hidden hidden={editing}>
            <Box.Button onClick={noop} onDoubleClick={() => setEditing(true)} spaceHorz='$1px'>
              <Text color='$grey7' size='$12px'>
                <SmartText text={subtask.title ?? ''} />
              </Text>
            </Box.Button>
          </Hidden>
        </Fluid>
        <SubtaskAssigneeController assignee={subtask.assigneeDocument} subtaskId={subtask.id} />
      </HStack>
    </Box>
  );
};
