import { HStack, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { EffortInput } from 'components/app/TaskDetails/common/EffortInput';
import { Section } from 'components/app/TaskDetails/common/Section';
import React from 'react';
import { strings } from 'resources';

type Props = {
  effortLevel: UI.UITask['effortLevel'];
  effortUnit: UI.UIOrganization['effortUnit'];
  taskId: UI.UITask['id'];
};

export function Effort({ effortLevel, effortUnit, taskId }: Props): JSX.Element {
  return (
    <Section
      right={
        <HStack space='$4px'>
          <EffortInput effortLevel={effortLevel} taskId={taskId} />
          <Text color='$grey6' size='$12px'>
            {strings.taskSidebar.modules.effort.units[effortUnit]}
          </Text>
        </HStack>
      }
      title={strings.taskSidebar.modules.effort.title}
    />
  );
}
