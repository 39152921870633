import { Data, TaskStatus } from '@taraai/types';
import { SprintFiltersModule } from 'components/core/controllers/views/SprintFiltersModule';
import { TaskSummaries } from 'components/core/controllers/views/SprintFiltersModule/types';
import React, { useCallback } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import {
  estimatedEffortSelector,
  getCompletedSprints,
  getSprint,
  getSprintTasks,
  reduxStore,
  searchActions,
  selectStatusFilter,
} from 'reduxStore';

interface Props {
  sprintId: Data.Id.SprintId;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
}

export function SprintFiltersModuleController({ sprintId, orgId, teamId }: Props): JSX.Element {
  const sprintSlice = getSprint(orgId, sprintId);
  const filterValue = useSelector(selectStatusFilter);
  const sprintTasksSlice = getSprintTasks(orgId, sprintId);
  const completedSlice = getCompletedSprints(orgId, teamId);

  useFirestoreConnect([...sprintSlice.query, ...completedSlice.query, ...sprintTasksSlice.query]);

  const estimatedEffort = useSelector(estimatedEffortSelector(completedSlice.selector, sprintSlice.selector));
  const sprintTasksFragment =
    useSelector(
      compose(
        (tasks) =>
          tasks &&
          tasks.map((task) => ({
            status: task.status,
            effortLevel: task.effortLevel,
          })),
        sprintTasksSlice.selector,
      ),
      deepEquals,
    ) || [];

  // Since we are willing to always show all the filters, the default is
  // to have 0 for everything. Then we go through the tasks and set the actual values
  // @TODO: As soon we convert the taskStatus to an enum, this will be done in a more elegant way
  const taskSummaries: TaskSummaries = Array(3).fill({ taskCount: 0, plannedEffort: 0 });
  sprintTasksFragment?.forEach((taskFragment) => {
    taskSummaries[taskFragment.status] = {
      taskCount: taskSummaries[taskFragment.status].taskCount + 1,
      plannedEffort: taskSummaries[taskFragment.status].plannedEffort + taskFragment.effortLevel,
    };
    return taskSummaries;
  });

  const handleFilterReset = useCallback(() => reduxStore.dispatch(searchActions.search(undefined)), []);

  const handleFilterSelect = useCallback(
    (selectedFilter: TaskStatus | undefined) =>
      reduxStore.dispatch(searchActions.search(selectedFilter !== undefined ? { status: selectedFilter } : undefined)),
    [],
  );

  return (
    <SprintFiltersModule
      filterValue={filterValue}
      onFilterReset={handleFilterReset}
      onFilterSelect={handleFilterSelect}
      recommendedEffort={estimatedEffort}
      taskSummaries={taskSummaries}
    />
  );
}
