import SprintsRow from 'components/app/controllers/SprintsRow';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { getUpcomingSprints, selectActiveTeam, selectActiveWorkspace, selectTeam } from 'reduxStore';

/**
 * gets active sprints from store and passes them to the SprintsRow
 */
export default function UpcomingSprints(): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const sprintSlice = getUpcomingSprints(orgId, teamId, {
    orderBy: 'createdAt',
  });
  useFirestoreConnect(sprintSlice.query);

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgId, teamId)));

  const upcomingSprints = useSelector(
    compose(
      (arr) =>
        arr?.map((data) => ({
          id: data.id,
          sprintName: data.sprintName,
          startDate: data.startDate,
          endDate: data.endDate,
          completedAt: data.completedAt,
          computedOnCompletion: data.computedOnCompletion,
          isComplete: data.isComplete,
          orderedTaskIds: data.orderedTaskIds,
        })),
      sprintSlice.selector,
    ),
    deepEquals,
  );

  return <SprintsRow currentSprintId={currentSprintId ?? undefined} sprints={upcomingSprints} />;
}
