/* eslint-disable sonarjs/no-duplicate-string */
import { Data } from '@taraai/types';
import { assertNever, keys, notUndefined } from '@taraai/utility';
import { match, matchPath, useLocation, useParams } from 'react-router';

/**
 * Represents constant path segment (eg. `/foo`).
 */
type PathSegment<S extends string> = { type: 'segment'; segment: S };

/**
 * Represents path parameter (eg. `/:orgId`).
 */
type PathParam<P extends string> = { type: 'param'; param: P };

/**
 * Represents optional path parameter (eg. `/:token?`).
 */
type PathParamOpt<P extends string> = { type: 'paramOpt'; param: P };

/**
 * Represents all possible path elements.
 */
type PathElement<P extends string> = PathParam<P> | PathParamOpt<P> | PathSegment<P>;

/** PathParam constructor */
const param = <P extends string>(parameter: P): PathParam<P> => ({
  type: 'param',
  param: parameter,
});

/** PathParamOpt constructor */
const paramOpt = <P extends string>(parameter: P): PathParamOpt<P> => ({
  type: 'paramOpt',
  param: parameter,
});

/** PathSegment constructor */
const seg = <S extends string>(segment: S): PathSegment<S> => ({
  type: 'segment',
  segment,
});

const route = <T extends PathElement<string>[]>(...path: T): T => path;

/**
 * This object represents all possible paths used in the app.
 */
export const routes = {
  /**
   * We need support for legacy links without team id in the URL
   * for tasks and requirements.
   *
   * All LEGACY_ routes should be on the top of routes object.
   * In the other case router will match wrong paths.
   */
  LEGACY_requirements: route(param('orgId'), seg('requirements')),
  LEGACY_requirement: route(param('orgId'), seg('requirement'), param('requirementId')),
  LEGACY_task: route(param('orgId'), seg('tasks'), param('taskId')),
  LEGACY_requirementTask: route(
    param('orgId'),
    seg('requirement'),
    param('requirementId'),
    seg('task'),
    param('taskId'),
  ),
  LEGACY_homeTask: route(param('orgId'), seg('task'), param('taskId')),
  LEGACY_sprintsTask: route(param('orgId'), seg('sprints'), seg('task'), param('taskId')),
  LEGACY_sprintDetailsTask: route(
    param('orgId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('task'),
    param('taskId'),
  ),

  upgrade: route(param('orgId'), param('teamId'), seg('upgrade')),
  checkoutSuccess: route(param('orgId'), param('teamId'), seg('checkout-success')),
  deactivated: route(seg('deactivated')),
  emailActionHandler: route(seg('email-action-handler')),
  login: route(seg('login')),
  logout: route(seg('logout')),
  invite: route(seg('invite'), paramOpt('token')),
  notFound: route(seg('404')),
  home: route(param('orgId'), param('teamId')),
  homeTask: route(param('orgId'), param('teamId'), seg('task'), param('taskId')),
  githubLearnMore: route(param('orgId'), param('teamId'), seg('learn-more')),
  requirements: route(param('orgId'), param('teamId'), seg('define'), seg('requirements')),
  newRequirement: route(param('orgId'), param('teamId'), seg('define'), seg('requirements'), seg('new')),
  createRequirement: route(param('orgId'), param('teamId'), seg('sprints'), seg('new-requirement')),
  // FIXME: change route to /requirements/ for consistency
  requirement: route(param('orgId'), param('teamId'), seg('define'), seg('requirement'), param('requirementId')),
  requirementTask: route(
    param('orgId'),
    param('teamId'),
    seg('define'),
    seg('requirement'),
    param('requirementId'),
    seg('task'),
    param('taskId'),
  ),
  requirementCreateTeam: route(
    param('orgId'),
    param('teamId'),
    seg('define'),
    seg('requirement'),
    param('requirementId'),
    seg('team-create'),
  ),
  sprints: route(param('orgId'), param('teamId'), seg('sprints')),
  sprints_LEGACY: route(param('orgId'), param('teamId'), seg('sprints'), seg('legacy')),
  sprintsSettings: route(param('orgId'), param('teamId'), seg('sprints'), seg('sprints-settings')),
  sprintsTask: route(param('orgId'), param('teamId'), seg('sprints'), seg('task'), param('taskId')),
  sprintsRequirement: route(
    param('orgId'),
    param('teamId'),
    seg('sprints'),
    seg('requirement'),
    param('requirementId'),
  ),
  sprintSelected: route(param('orgId'), param('teamId'), seg('sprints'), param('sprintId')),
  sprintStart: route(param('orgId'), param('teamId'), seg('sprints'), param('sprintId'), seg('start')),
  sprintEdit: route(param('orgId'), param('teamId'), seg('sprints'), param('sprintId'), seg('edit')),
  sprintComplete: route(param('orgId'), param('teamId'), seg('sprints'), param('sprintId'), seg('complete')),
  sprintInsights: route(param('orgId'), param('teamId'), seg('sprints'), param('sprintId'), seg('insights')),
  sprintDetails: route(param('orgId'), param('teamId'), seg('sprint'), param('sprintId'), seg('details')),
  sprintDetailsInsights: route(
    param('orgId'),
    param('teamId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('insights'),
  ),
  sprintDetailsTask: route(
    param('orgId'),
    param('teamId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('task'),
    param('taskId'),
  ),
  sprintDetailsComplete: route(
    param('orgId'),
    param('teamId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('complete'),
  ),
  sprintDetailsEdit: route(
    param('orgId'),
    param('teamId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('edit'),
  ),
  task: route(param('orgId'), param('teamId'), seg('define'), seg('tasks'), param('taskId')),
  taskDetails: route(param('orgId'), param('teamId'), seg('tasks'), param('taskId')),
  profile: route(param('orgId'), param('teamId'), seg('profile')),
  workspace: route(param('orgId'), param('teamId'), seg('workspace')),
  leaveWorkspace: route(param('orgId'), param('teamId'), seg('workspace'), seg('leave')),
  deleteWorkspace: route(param('orgId'), param('teamId'), seg('workspace'), seg('delete')),
  integrations: route(param('orgId'), param('teamId'), seg('integrations')),
  teamCreate: route(param('orgId'), param('teamId'), seg('workspace'), seg('team-create')),
  teamCreateSprintPlanning: route(param('orgId'), param('teamId'), seg('sprints'), seg('team-create')),
  teamCreateSprintDetails: route(
    param('orgId'),
    param('teamId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('team-create'),
  ),
  teamDetails: route(param('orgId'), param('teamId'), seg('workspace'), seg('team-details')),
  leaveTeam: route(param('orgId'), param('teamId'), seg('teams'), seg('details'), seg('leave')),
  deleteTeam: route(param('orgId'), param('teamId'), seg('teams'), seg('details'), seg('delete')),
  deleteTeamWithConfirmation: route(param('orgId'), param('teamId'), seg('delete')),
  teamDetailsSprintPlanning: route(param('orgId'), param('teamId'), seg('sprints'), seg('team-details')),
  teamDetailsSprintDetails: route(
    param('orgId'),
    param('teamId'),
    seg('sprint'),
    param('sprintId'),
    seg('details'),
    seg('team-details'),
  ),
  errorGithubRepoNotSelected: route(param('orgId'), param('teamId'), seg('error'), seg('github-repo-not-selected')),
  onboarding: route(seg('onboarding'), paramOpt('orgId')),
  onboardingMobileNote: route(seg('onboarding'), param('orgId'), seg('mobile-note')),
  setupIntegration: route(seg('setup-integration'), param('service')),
} as const;

export type Routes = typeof routes;
export type RouteName = keyof Routes;

type FilterRouteParam<T extends PathElement<string>> = T extends PathParam<string> ? T : never;

type FilterRouteParamOpt<T extends PathElement<string>> = T extends PathParamOpt<string> ? T : never;

/** Converts a route to a union of `PathParameters` required to construct that route. */
type TakeParams<R extends Routes[RouteName]> = FilterRouteParam<R[number]>;

/** Converts a route to a union of `PathParametersOpt` required to construct that route. */
type TakeParamsOpt<R extends Routes[RouteName]> = FilterRouteParamOpt<R[number]>;

/** For `PathParam<T>` gives back `T`. */
type ExtractParamName<P extends PathParam<string>> = P extends PathParam<infer N> ? N : never;

/** For `PathParamOpt<T>` gives back `T`. */
type ExtractParamOptName<P extends PathParamOpt<string>> = P extends PathParamOpt<infer N> ? N : never;

/** Filter out all keys `K` that `T[K]` doesn't match `U` */
type FilteredKeys<T extends { [k: string]: unknown }, U> = {
  [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];

/** Routes without any params */
type SimpleRoutes = {
  [K in FilteredKeys<{ [RN in RouteName]: Routes[RN][number] }, PathSegment<string>>]: Routes[K];
};

type SimpleRouteName = keyof SimpleRoutes;

type ParamPayload<R extends Routes[RouteName]> = {
  [K in ExtractParamName<TakeParams<R>>]: string;
} &
  {
    [K in ExtractParamOptName<TakeParamsOpt<R>>]: string | undefined;
  };

const insertParamValue = (params: { [name: string]: string | undefined }, name: string): string | undefined => {
  return params[name];
};

/**
 * Return interpolated URL for a given route and a supplied param object.
 */
export function linkTo<RN extends SimpleRouteName>(routeName: RN): string;
export function linkTo<RN extends RouteName>(
  routeName: RN,
  params: ParamPayload<Routes[RN]>,
  queryParams?: string,
): string;
export function linkTo<RN extends RouteName | SimpleRouteName>(
  routeName: RN,
  params?: ParamPayload<Routes[RN]>,
  queryParams = '',
): string {
  const selectedRoute = routes[routeName] as PathElement<string>[];

  const pathString = selectedRoute
    .map((value): string | undefined => {
      switch (value.type) {
        case 'segment':
          return value.segment;
        case 'param':
        case 'paramOpt':
          if (!params) {
            throw Error('linkTo() can be used without params only for SimpleRoute');
          }
          return insertParamValue(params, value.param);
        default:
          return assertNever(value);
      }
    })
    .filter(notUndefined)
    .join('/');

  return `/${pathString}${queryParams}`;
}

// TODO: it might be better if this took route instead of route name
/**
 * Return path definition string for a given routeName.
 * It replaces all PathParam objects with `:` notation
 * (eg. for `PathParam<'orgId'>` gives back `:orgId`).
 */
export const getPath = (routeName: RouteName): string => {
  const selectedRoute = routes[routeName] as PathElement<string>[];

  const pathString = selectedRoute
    .map((value): string => {
      switch (value.type) {
        case 'segment':
          return value.segment;
        case 'param':
          return `:${value.param}`;
        case 'paramOpt':
          return `:${value.param}?`;
        default:
          return assertNever(value);
      }
    })
    .join('/');

  return `/${pathString}`;
};

/**
 * Returns path parameters for given `routeName`.
 *
 * Besides doing typecheck on given `routeName`, this function
 * also does a runtime check to see if passed `routeName` is in fact
 * current route.
 */
export const usePathParams = <RN extends RouteName>(routeName: RN): ParamPayload<Routes[RN]> => {
  const location = useLocation();
  const routePath = getPath(routeName);
  const matched = matchPath(location.pathname, routePath);
  if (!matched) {
    throw Error(`Expected usePathParams to be used on ${routePath}, but was used on ${location.pathname}`);
  }

  return useParams<ParamPayload<Routes[RN]>>();
};

/**
 * find and return current route name or default return home
 */
export function getRouteName(): RouteName {
  const location = window.location.pathname;
  const routeKeys = keys(routes);
  const routeMatches = routeKeys.map(getPath).map(
    (routePath) =>
      matchPath(location, {
        path: routePath,
        exact: true,
        strict: true,
      }) as match<{
        orgId?: Data.Id.OrganizationId;
        teamId?: Data.Id.TeamId;
        requirementId?: Data.Id.RequirementId;
        sprintId?: Data.Id.SprintId;
        taskId?: Data.Id.TaskId;
      }> | null,
  );
  return routeKeys[routeMatches.findIndex((routeToMatch) => !!routeToMatch)] ?? 'home';
}

/**
 * matchRoute takes an array of route strings and
 * returns true if the current path is in the array
 */
export function matchRoute(routesToMatch: RouteName[]): boolean {
  const location = window.location.pathname;
  return routesToMatch
    .map((routeKey) => getPath(routeKey))
    .map(
      (routePath) =>
        matchPath(location, {
          path: routePath,
          exact: true,
          strict: true,
        }) as match<{
          orgId?: Data.Id.OrganizationId;
          teamId?: Data.Id.TeamId;
          requirementId?: Data.Id.RequirementId;
          sprintId?: Data.Id.SprintId;
          taskId?: Data.Id.TaskId;
        }>,
    )
    .some((routeObject) => !!routeObject);
}
