import { Box, getCustomSize, HStack, Spacer, styled, Switch, Text, VStack } from '@taraai/design-system';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import React from 'react';
import { strings } from 'resources';

import comparison from './images/comparison.gif';
import coPilotYearlyPlanCard from './images/coPilotAnnualPlanCard.svg';
import coPilotMonthlyPlanCard from './images/coPilotMonthlyPlanCard.svg';
import freePlanCard from './images/freePlanCard.svg';
import premiumYearlyPlanCard from './images/premiumAnnualPlanCard.svg';
import premiumMonthlyPlanCard from './images/premiumMonthlyPlanCard.svg';

const PREMIUM_MONTHLY_PRICE_ID = process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID as string;
const PREMIUM_YEARLY_PRICE_ID = process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID as string;
const COPILOT_MONTHLY_PRICE_ID = process.env.REACT_APP_STRIPE_COPILOT_MONTHLY_PRICE_ID as string;
const COPILOT_YEARLY_PRICE_ID = process.env.REACT_APP_STRIPE_COPILOT_YEARLY_PRICE_ID as string;

type Props = {
  isLoading: boolean;
  isYearly: boolean;
  setIsYearly: (isYearly: boolean) => void;
  sendToCheckout: (priceId: string) => void;
};

export function UpgradeView({ isLoading, isYearly, setIsYearly, sendToCheckout }: Props): JSX.Element {
  return (
    <>
      {isLoading && <StandardSpinner fillSpace size='medium' />}
      <Box background='$upgradeBGGradient' height={upgradeBackground} space='$24px' width='$full'>
        <Box center spaceBottom='$24px'>
          <VStack space='$24px'>
            <Box center spaceTop='$24px'>
              <VStack space='$24px'>
                <Box spaceVert='$24px'>
                  <Text color='$white' size='$38px' weight='medium'>
                    {strings.upgrade.title}
                  </Text>
                </Box>
                <Box center>
                  <Box spaceRight='$4px'>
                    <Text color='$white' size='$14px' weight='regular'>
                      {strings.upgrade.yearly}
                    </Text>
                  </Box>
                  <Text color='$white' size='$14px'>
                    {strings.upgrade.save20}
                  </Text>
                  <Box spaceHorz='$8px'>
                    <Switch
                      activeColor='$grey5'
                      checked={!isYearly}
                      inactiveColor='$grey5'
                      onChange={() => setIsYearly(!isYearly)}
                    />
                  </Box>
                  <Text color='$white' size='$14px' weight='regular'>
                    {strings.upgrade.monthly}
                  </Text>
                </Box>
              </VStack>
            </Box>
            <Box center>
              {isYearly ? (
                <HStack>
                  <Box>
                    <img alt='Free plan card' src={freePlanCard} />
                  </Box>
                  <Box.Button id='premium-yearly' onClick={() => sendToCheckout(PREMIUM_YEARLY_PRICE_ID)}>
                    <img alt='Premium Yearly plan card' src={premiumYearlyPlanCard} />
                  </Box.Button>
                  <Box.Button onClick={() => sendToCheckout(COPILOT_YEARLY_PRICE_ID)}>
                    <img alt='co-Pilot Yearly plan card' src={coPilotYearlyPlanCard} />
                  </Box.Button>
                </HStack>
              ) : (
                <HStack>
                  <Box>
                    <img alt='Free plan card' src={freePlanCard} />
                  </Box>
                  <Box.Button onClick={() => sendToCheckout(PREMIUM_MONTHLY_PRICE_ID)}>
                    <img alt='Premium Monthly plan card' src={premiumMonthlyPlanCard} />
                  </Box.Button>
                  <Box.Button onClick={() => sendToCheckout(COPILOT_MONTHLY_PRICE_ID)}>
                    <img alt='co-Pilot Monthly plan card' src={coPilotMonthlyPlanCard} />
                  </Box.Button>
                </HStack>
              )}
            </Box>
            <Box center>
              <SectionHeaderBackground borderRadius='$4px' center spaceVert='$16px' width={headerWidth}>
                <SectionHeaderText size='$18px'>Full Feature Breakdown</SectionHeaderText>
              </SectionHeaderBackground>
            </Box>
            <Box>
              <img alt='plan comparisons' src={comparison} style={{ overflow: 'hidden' }} />
            </Box>
            <Box center spaceBottom='$24px'>
              <VStack space='$8px'>
                <Box center spaceBottom='$24px'>
                  <Text color='$dark' size='$38px' weight='medium'>
                    {strings.upgrade.FAQ}
                  </Text>
                </Box>
                {strings.upgrade.QA.map(({ question, answer }) => (
                  <Box key={question} borderBottom='$grey4' spaceBottom='$24px' spaceTop='$12px' width={headerWidth}>
                    <VStack space='$4px'>
                      <Text color='$dark' size='$18px'>
                        {question}
                      </Text>
                      <Text color='$dark' size='$14px'>
                        {answer}
                      </Text>
                    </VStack>
                  </Box>
                ))}
              </VStack>
            </Box>
          </VStack>
          <Spacer space='$24px' />
        </Box>
      </Box>
    </>
  );
}

const SectionHeaderBackground = styled(Box, {
  background: 'linear-gradient(135.19deg,rgba(88,166,231,.05) .34%,rgba(119,93,250,.05))',
});

const SectionHeaderText = styled(Text, {
  fontWeight: '400',
  fontSize: '18px',
  // color: 'white',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  backgroundImage: 'linear-gradient(135.19deg, #58a6e7 0.34%, #775dfa 100%)',
  backgroundSize: '100%',
  WebkitTextFillColor: 'transparent',
});

export const upgradeBackground = getCustomSize(583);
export const headerWidth = getCustomSize(1140);
