import { unwrapResult } from '@reduxjs/toolkit';
import React, { createContext, useCallback, useState } from 'react';
import { BeforeCapture, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { moveSprintTask, reduxStore } from 'reduxStore';
import { createAndPopulateSprint } from 'reduxStore/sprints/actions/createAndPopulate';
import { strings } from 'resources';
import { insertNear, useToast } from 'tools';

import { DroppableType } from './types';
import { getDraggableDescription, getDroppableDescription } from './utils';

export type DragStatus =
  | {
      isDragging: true;
      draggedElement: string | null;
    }
  | { isDragging: false };

export const DNDContext = createContext<DragStatus>({ isDragging: false });

export const DNDProvider = ({ children = [] }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const { whenError } = useToast();

  const [globalDragStatus, setGlobalDragStatus] = useState<DragStatus>({
    isDragging: false,
  });

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }: DropResult): void => {
      setGlobalDragStatus({
        isDragging: false,
      });
      // Droped nowhere it can be droped, so nothing to do.
      if (!destination) return;
      // If same source && same location within the list, the element didn't move at all, so nothing to do
      if (!(source.droppableId !== destination.droppableId || source.index !== destination.index)) return;

      // we extract the actual element ID, so we remove the sourceType from the ID.
      // This is composed as ${droppableType}-${draggableId} to enable repeated elements in the screen
      const draggableElementId = getDraggableDescription(draggableId).id;

      const deserializedDestination = getDroppableDescription(destination.droppableId);

      // We get the result list from moving the element to the new list
      const updatedList =
        insertNear(
          draggableElementId,
          destination.index,
          deserializedDestination.visibleList,
          deserializedDestination.list,
        ) || [];

      // @TODO: Write all this in a much more elegant way
      if (deserializedDestination.type === DroppableType.requirement) {
        reduxStore
          .dispatch(
            moveSprintTask({
              destinationRequirementId: deserializedDestination.id,
              taskId: draggableElementId,
              orderedTaskIds: updatedList,
            }),
          )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      } else if (deserializedDestination.type === DroppableType.sprint) {
        if (deserializedDestination.id === undefined) {
          reduxStore
            .dispatch(createAndPopulateSprint({ taskId: draggableElementId }))
            .then(unwrapResult)
            .catch(whenError(strings.task.failedToUpdateTask));
        } else {
          reduxStore
            .dispatch(
              moveSprintTask({
                destinationSprintId: deserializedDestination.id,
                taskId: draggableElementId,
                orderedTaskIds: updatedList,
              }),
            )
            .then(unwrapResult)
            .catch(whenError(strings.task.failedToUpdateTask));
        }
      } else if (deserializedDestination.type === DroppableType.removeFromSprint) {
        reduxStore
          .dispatch(
            moveSprintTask({ taskId: draggableElementId, orderedTaskIds: updatedList, destinationSprintId: null }),
          )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      } else if (deserializedDestination.type === DroppableType.workdrawerTasks) {
        reduxStore
          .dispatch(
            moveSprintTask({ destinationRequirementId: null, taskId: draggableElementId, orderedTaskIds: updatedList }),
          )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      }
    },
    [whenError],
  );

  const onBeforeCapture = useCallback((before: BeforeCapture): void => {
    setGlobalDragStatus({
      isDragging: true,
      draggedElement: before.draggableId,
    });
  }, []);

  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      <DNDContext.Provider value={globalDragStatus}>{children}</DNDContext.Provider>
    </DragDropContext>
  );
};
