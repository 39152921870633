import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, Identifiable, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { populate } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { getUsers } from 'reduxStore/users';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';
import { isBefore } from 'tools/libraries/helpers';
import { sort } from 'tools/libraries/helpers/sort';

export interface AllQuery<T> {
  query: ReduxFirestoreQuery[];
  selector: Selector<RootState, T[] | undefined>;
}
function inertQuery<T>(): AllQuery<T> {
  const stateFnc: Selector<RootState, unknown> = (state: RootState) => state;
  const query: ReduxFirestoreQuery[] = [];
  return {
    query,
    selector: createSelector(stateFnc, (): T[] | undefined => undefined),
  };
}

interface RevisionTask extends UI.UITask {
  sprintDocument?: UI.UISprint;
  updatedByDocument: UI.UIUser;
}
export function getTaskRevision(orgId: Data.Id.OrganizationId, taskId: Data.Id.TaskId): AllQuery<UI.UITaskRevision> {
  return queryBuilder(orgId, taskId);
}

function queryBuilder(orgId: Data.Id.OrganizationId, taskId: Data.Id.TaskId): AllQuery<UI.UITaskRevision> {
  if (![orgId, taskId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const queryTask: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks/${taskId}/revisions`,
    storeAs: `revisions/${taskId}/${orgId}`,
  };
  const querySprint: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/sprints`,
    storeAs: `revisions/sprints/${orgId}`,
  };

  const query = [queryTask, querySprint];

  const allTasksRevisions = createSelector(
    [
      (state: RootState): Record<string, UI.UITask> | undefined => state.firestore.data[queryTask.storeAs as string],
      (state: RootState): Record<string, UI.UIUser> | undefined =>
        state.firestore.data[getUsers(orgId).query[0].storeAs as string],
      (state: RootState): Record<string, UI.UISprint> | undefined =>
        state.firestore.data[querySprint.storeAs as string],
    ],
    (tasks, users, sprints) => {
      if (!tasks || !users) return undefined;

      const revisionsTask = Object.values(
        populate({ data: { tasks, users, sprints } }, 'tasks', [
          {
            child: 'updatedBy',
            root: 'users',
            childAlias: 'updatedByDocument',
          },
          {
            child: 'sprint',
            root: 'sprints',
            childAlias: 'sprintDocument',
          },
        ]) as RevisionTask[],
      );

      const sortedRevisionsTask = sort(revisionsTask, 'updatedAt');

      return (
        sortedRevisionsTask &&
        sortedRevisionsTask.reduce((result: UI.UITaskRevision[], ___: Identifiable, index: number) => {
          if (index === 0) {
            return result;
          }
          const obj1 = sortedRevisionsTask[index - 1];
          const obj2 = sortedRevisionsTask[index];
          result.push(...getObjectDiff(obj1, obj2));
          return result;
        }, [])
      );
    },
  );

  return {
    query,
    selector: allTasksRevisions,
  } as AllQuery<UI.UITaskRevision>;
}

function getObjectDiff(obj1: RevisionTask, obj2: RevisionTask): UI.UITaskRevision[] {
  const { updatedAt } = obj2;
  const avatarURL = obj2.updatedByDocument?.avatarURL;
  const name = obj2.updatedByDocument?.name;
  const user = obj2.updatedByDocument;
  const sprint = obj2.sprintDocument;
  const { id } = obj2;
  const requiredFields: (keyof RevisionTask)[] = ['effortLevel', 'status', 'sprint'];
  return requiredFields.reduce((result: UI.UITaskRevision[], key: keyof RevisionTask): UI.UITaskRevision[] => {
    const value1 = getValueFromRevisionTask(obj1, key);
    const value2 = getValueFromRevisionTask(obj2, key);
    if (value1 === value2) {
      return result;
    }
    const wasSprintActive = isSprintActive(obj2, obj1);
    result.push({
      id,
      property: key as keyof UI.UITask,
      current: value2,
      previous: value1,
      updatedAt,
      user,
      avatarURL,
      name,
      sprint,
      wasSprintActive,
    });
    return result;
  }, []);
}

function getValueFromRevisionTask(obj: RevisionTask, key: keyof RevisionTask): string | undefined {
  if (key === 'sprint') {
    return obj.sprintDocument?.sprintName;
  }
  return obj[key];
}

function isSprintActive(currentRevision: RevisionTask, previousRevision: RevisionTask): boolean {
  const { updatedAt } = currentRevision;
  const sprint = currentRevision.sprintDocument ?? previousRevision.sprintDocument;
  if (!sprint) {
    return false;
  }
  return isBefore(updatedAt, sprint.endDate) && !isBefore(updatedAt, sprint.startDate);
}
