"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hidden = void 0;
var react_1 = __importStar(require("react"));
function Hidden(_a) {
    var children = _a.children, hidden = _a.hidden, _b = _a.strategy, strategy = _b === void 0 ? 'hide' : _b;
    var shownAtLeastOnce = react_1.useRef(!hidden);
    shownAtLeastOnce.current || (shownAtLeastOnce.current = !hidden);
    var rendered = strategy === 'hide' || (strategy === 'remove' && !hidden) || (strategy === 'lazy' && shownAtLeastOnce.current);
    return rendered ? react_1.default.createElement("div", { hidden: hidden }, children) : null;
}
exports.Hidden = Hidden;
