import TaskModalContext from 'components/app/controllers/TaskModalContext';
import TaskModalController from 'components/app/controllers/TaskModalController';
import { browserHistory } from 'components/Router/history';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';

export interface TaskModalProviderProps extends React.HTMLProps<HTMLElement> {
  children?: JSX.Element | JSX.Element[] | string;
}

export default function TaskModalProvider({ children }: TaskModalProviderProps): JSX.Element {
  const [initialModalTaskId, setInitialModalTaskId] = useState<string | null>(null);
  const [initialPath, setInitialPath] = useState('');

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const location = useLocation();
  const history = useHistory();

  const openModal = useCallback(
    (taskId) => {
      if (orgId && teamId) {
        setInitialPath(location.pathname + location.search);
        setInitialModalTaskId(taskId);
        browserHistory.pushRoute(linkTo('taskDetails', { orgId, teamId, taskId }));
      }
    },
    [location, orgId, teamId],
  );

  const closeModal = useCallback(() => {
    setInitialModalTaskId(null);
    history.replace(initialPath);
  }, [history, initialPath]);

  return (
    <TaskModalContext.Provider value={{ openModal }}>
      {initialModalTaskId &&
        createPortal(
          <TaskModalController key={initialModalTaskId} closeModal={closeModal} initialTaskId={initialModalTaskId} />,
          document.body,
        )}
      {children}
    </TaskModalContext.Provider>
  );
}
