import { UI } from '@taraai/types';

import { WorkDrawerImportTasksFragment } from './WorkDrawerController';

type TasksByRepositories = Record<string, UI.UITask[]>;

export const groupTasksByRepository = (tasks: UI.UITask[]): WorkDrawerImportTasksFragment[] => {
  const data = tasks.reduce<TasksByRepositories>((group, task) => {
    if (task.externalIssue && 'repository' in task.externalIssue) {
      const { repository } = task.externalIssue;
      const accumulatedTasksInRepository = group[repository] || [];

      return { ...group, [repository]: [...accumulatedTasksInRepository, task] };
    }

    // eslint-disable-next-line no-console
    console.error(`Imported task with id ${task.id} is missing repository information`);
    return group;
  }, {});

  return mapGroupedTasksToWorkDrawerFormat(data);
};

function mapGroupedTasksToWorkDrawerFormat(data: TasksByRepositories): WorkDrawerImportTasksFragment[] {
  return Object.entries(data).map(([title, tasks]) => ({ title, tasks }));
}
