import { Data } from '@taraai/types';
import { noop } from '@taraai/utility';
import React from 'react';

export interface TaskModalContextType {
  openModal: (taskId: Data.Id.TaskId) => void;
}

const TaskModalContext = React.createContext<TaskModalContextType>({
  openModal: noop,
});

export default TaskModalContext;
