import { Data } from '@taraai/types';

export enum DroppableType {
  requirement = 'requirement',
  sprint = 'sprint',
  workdrawerTasks = 'workdrawerTasks',
  removeFromSprint = 'removeFromSprint',
}

export enum DraggableType {
  workDrawerTask = 'workDrawerTask',
  selectedSprintTask = 'selectedSprintTask',
  sprintTask = 'sprintTask',
  requirement = 'requirement',
}

export interface DraggableDescription {
  id: Data.Id.RequirementId | Data.Id.TaskId;
  type: DraggableType;
  droppableType: DroppableType;
  droppableId?: Data.Id.SprintId;
}

export interface DroppableDescription {
  id: string;
  type: DroppableType;
  // If the D&D is happening in a filtered list,
  // the visible list and the full list are different. But we need both.
  visibleList: string[];
  list: string[] | null;
}
