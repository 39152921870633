import { UI } from '@taraai/types';
import { getFilterByTitleFn } from 'components/app/controllers/Selectors/common/filterFn';
import { Option, OptionSize } from 'components/app/controllers/Selectors/common/Option';
import SearchHeader from 'components/app/controllers/Selectors/common/SearchHeader';
import { SectionType, Selector } from 'components/core/controllers/Selector';
import React from 'react';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type Section = SectionType<RequirementFragment>;

type SelectorProps = Omit<
  React.ComponentProps<typeof Selector>,
  'renderOption' | 'onSelectOption' | 'onDeselectOption'
>;

type Props = SelectorProps & {
  selection: RequirementFragment[];
  headerTitle?: string;
  searchPlaceholder: string;
  optionSize?: OptionSize;
  onSelectOption: (requirement: RequirementFragment) => void;
  onDeselectOption: (requirement: RequirementFragment) => void;
};

const filterFn = getFilterByTitleFn<Section, RequirementFragment>();

/**
 * RequirementSelector is an UI component build on top of the Selector to handle selecting requirements.
 *
 * It supports:
 * - multiple and single selection of requirement by mouse clicking and keyboard navigation
 * - searching requirements by name
 * - displaying requirement names in dropdown
 */
export function RequirementSelector({ headerTitle, searchPlaceholder, optionSize, ...props }: Props): JSX.Element {
  return (
    <Selector
      filterFn={filterFn}
      renderHeader={(headerProps): JSX.Element => (
        <SearchHeader searchPlaceholder={searchPlaceholder} showSearch title={headerTitle} {...headerProps} />
      )}
      renderOption={({ option, ...optionProps }) => <Option size={optionSize} title={option.title} {...optionProps} />}
      {...props}
    />
  );
}
