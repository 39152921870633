"use strict";
/* istanbul ignore file - all functions are tested because components using them are tested */
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomSize = exports.tokens = exports.styled = exports.mapTokens = exports.mapCustom = exports.mapBreakpoints = exports.GlobalStyles = exports.fade = void 0;
var breakpoints_1 = require("./breakpoints");
var createStyled_1 = require("./createStyled");
var tokens_1 = require("./tokens");
exports.fade = (_a = createStyled_1.createStyled({
    tokens: tokens_1.tokens,
    breakpoints: breakpoints_1.breakpoints,
}), _a.fade), exports.GlobalStyles = _a.GlobalStyles, exports.mapBreakpoints = _a.mapBreakpoints, exports.mapCustom = _a.mapCustom, exports.mapTokens = _a.mapTokens, exports.styled = _a.styled, exports.tokens = _a.tokens;
/** The value of 1rem */
var baseFontSizeInPx = 16;
exports.getCustomSize = createStyled_1.getCustomConstructor('customSize', function (sizeInPx) { return sizeInPx / baseFontSizeInPx + "rem"; });
