import { Fluid, HStack, styled, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import Badge from 'components/app/controllers/views/Badge';
import { RichEditorToolbar } from 'components/editor/Toolbar';
import React from 'react';
import { strings } from 'resources/i18n';

import { formatUpdateStatus } from './formatUpdateStatus';

export interface RequirementBuilderSubHeaderProps extends React.HTMLProps<HTMLDivElement> {
  requirement: UI.UIRequirement;
  forceSave: () => Promise<void>;
  isEditorConflict: boolean;
  onAttachmentUpload: (file: File) => void;
}

/**
 * Sub Header for the requirement builder
 */

export default function RequirementBuilderSubHeader({
  requirement,
  forceSave,
  isEditorConflict,
  onAttachmentUpload,
}: RequirementBuilderSubHeaderProps): JSX.Element {
  return (
    <Wrapper>
      <HStack alignY='center' full space='$8px'>
        {requirement.archived && <Badge>{strings.requirements.archived}</Badge>}
        <Text color='$grey6' size='$12px'>
          {formatUpdateStatus(requirement)}
        </Text>
        <Fluid />
        <Toolbar onAttachmentUpload={onAttachmentUpload} />
      </HStack>
      {isEditorConflict && (
        <ConflictMessage>
          {strings.formatString(strings.builder.conflict.firstLine)}
          {strings.formatString(strings.builder.conflict.secondLine, {
            cta: <ConflictCta onClick={forceSave}>{strings.formatString(strings.builder.conflict.cta)}</ConflictCta>,
          })}
        </ConflictMessage>
      )}
    </Wrapper>
  );
}

type ToolbarProps = Pick<RequirementBuilderSubHeaderProps, 'onAttachmentUpload'>;

function Toolbar({ onAttachmentUpload }: ToolbarProps): JSX.Element {
  return <RichEditorToolbar onAttachmentUpload={onAttachmentUpload} />;
}

const Wrapper = styled('div', {
  width: '100%',
  padding: '0 1.5rem',
  maxWidth: '65.5rem',
});

const ConflictMessage = styled('div', {
  color: '$failure',
  marginTop: '1rem',
});

const ConflictCta = styled('span', {
  cursor: 'pointer',
  textDecoration: 'underline',
});
