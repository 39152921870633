import { Radio } from '@taraai/design-system';
import { TaskStatus } from '@taraai/types';
import { taskStatusMap } from 'components/core/controllers/StatusSelector';
import React from 'react';
import { IconName, strings } from 'resources';

import { FilterElementLayout } from './FilterElementLayout';
import { ProgressBackground, ProgressBackgroundMode } from './ProgressBackground';

const getBackgroundMode = (
  isItemSelected: boolean,
  isNothingSelected: boolean,
  taskCount: number,
): ProgressBackgroundMode => {
  if (!taskCount) {
    return 'disabled';
  }
  if (isItemSelected) {
    return 'primary';
  }
  if (isNothingSelected) {
    return 'secondary';
  }
  return 'transparent';
};

export function RadioFilterEntry({
  taskCount,
  plannedEffort,
  taskStatus,
  totalTaskCount,
  selectedFilterType,
}: {
  taskCount: number;
  plannedEffort: number;
  taskStatus: number;
  totalTaskCount: number;
  selectedFilterType: TaskStatus | undefined;
}): JSX.Element {
  const { key, color, label, name } = taskStatusMap[taskStatus];
  const summary = strings.formatString(strings.sprintFilters.summaries[taskStatus], {
    taskCount,
    totalTaskCount,
  }) as string;
  const taskProgress = taskCount / totalTaskCount;
  const itemSelected = selectedFilterType === key;

  const greyIconStatusMap: Record<number, IconName> = {
    0: 'todoGrey',
    1: 'doingGrey',
    2: 'doneGrey',
  };

  const greyIconName = greyIconStatusMap[taskStatus];
  return (
    <ProgressBackground
      key={key}
      color={color}
      mode={getBackgroundMode(itemSelected, selectedFilterType === undefined, taskCount)}
      progress={taskProgress}
    >
      <Radio activeColor={taskCount ? color : '$grey7'} inactiveColor={taskCount ? color : '$grey7'} value={key}>
        <FilterElementLayout
          active={itemSelected}
          color={taskCount ? color : '$grey7'}
          effort={plannedEffort}
          icon={taskCount ? name : greyIconName}
          label={label}
          summary={summary}
        />
      </Radio>
    </ProgressBackground>
  );
}
