import { Avatar, Box, Fluid, HStack } from '@taraai/design-system';
import { getLastWord, truncate } from '@taraai/utility';
import CommentDraftContent from 'components/app/controllers/views/CommentBoxView/CommentDraftContent';
import React from 'react';

type Props = {
  avatarUrl?: string;
  description: string;
  limit?: number;
};

function omitBrokenMention(value: string, lastWord: string): string {
  return `${value.slice(0, -lastWord.length).trimEnd()}...`;
}

export function truncateRawMarkdown(value: string, limit: number): string {
  const truncated = truncate(value, limit);
  const lastWord = getLastWord(truncated);
  const hasBrokenMention = lastWord.includes('@');

  return hasBrokenMention ? omitBrokenMention(truncated, lastWord) : truncated;
}

export const Comment = ({ avatarUrl, description, limit = 250 }: Props): JSX.Element => (
  <Box background='$grey1' border='$grey2' borderRadius='$4px' space='$8px'>
    <HStack space='$12px'>
      <Avatar url={avatarUrl} />
      <Fluid>
        <CommentDraftContent content={truncateRawMarkdown(description, limit)} />
      </Fluid>
    </HStack>
  </Box>
);
