import { Box, TokenProp, VStack } from '@taraai/design-system';
import { UI } from '@taraai/types';
import DraggableFeatureCard from 'components/app/controllers/views/DraggableFeatureCard';
import React from 'react';
import { DroppableProvided } from 'react-beautiful-dnd';

/**
 * sprintColumn prop is used to resize width of dropdown
 */

type Props = {
  disableOrdering?: boolean;
  provided?: DroppableProvided;
  space?: TokenProp<'space'>;
  tasks: UI.UITask[];
  toggleModal: (task: UI.UITask) => void;
};

export function DraggableFeatureCardList({
  disableOrdering,
  provided,
  space = '$8px',
  tasks,
  toggleModal,
}: Props): JSX.Element {
  return (
    <Box space={space} {...provided?.droppableProps} ref={provided?.innerRef}>
      <VStack space='$8px'>
        {tasks.map((task: UI.UITask, index: number) => (
          <DraggableFeatureCard
            key={task.id}
            disableOrdering={disableOrdering}
            index={index}
            onClick={(): void => toggleModal(task)}
            task={task}
          />
        ))}
        {disableOrdering ? <span style={{ display: 'none' }}>{provided?.placeholder}</span> : provided?.placeholder}
      </VStack>
    </Box>
  );
}
