import { UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import React from 'react';

export interface LegacyTaskModalContextType {
  openModal: (task: UI.UITask, category: string) => void;
}

const LegacyTaskModalContext = React.createContext<LegacyTaskModalContextType>({
  openModal: noop,
});

export default LegacyTaskModalContext;
