import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import ProfileLayout from 'components/app/layouts/ProfileLayout';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import {
  reduxStore,
  selectActiveWorkspace,
  selectAuth,
  selectPreferredTeam,
  selectProfile,
  selectUserTeams,
  setPreferredTeamId,
  updateUser,
} from 'reduxStore';
import { strings } from 'resources/i18n';
import { useFirebaseInput, useToast } from 'tools';
import { auth } from 'tools/libraries/firebaseValues';

export default function ProfileController(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const history = useHistory();
  const authData = useSelector(selectAuth);
  const { name } = useSelector(selectProfile);

  const isBasicAuth = (authData.providerData || []).some(({ providerId }) => providerId === 'password');

  const preferredTeamId = useSelector(compose((data) => data?.id, selectPreferredTeam(orgId)));
  const userTeams = useSelector(selectUserTeams(orgId));

  const [emailResetSent, setEmailResetSent] = useState(false);

  const { addToast, whenSuccess, whenError } = useToast();

  // @FIXME: This typecast is wrong on so many levels.
  // But I don't want to refactor every input in the project ATM,
  // so I just did the same thing that is done in other similar components.
  const [nameProps] = useFirebaseInput(name, (newName: string) => {
    if (newName.length < 2) {
      addToast({
        message: strings.error.user.nameTooShort,
        type: 'error',
      });
      return;
    }
    if (!authData.uid) {
      addToast({
        message: strings.error.user.missingCredentials,
        type: 'error',
      });
      return;
    }
    reduxStore.dispatch(updateUser({ id: authData.uid, name: newName }));
  }) as { value: string }[];

  const onSetPreferredTeamId = useCallback(
    async (teamId: Data.Id.TeamId): Promise<void> =>
      reduxStore
        .dispatch(setPreferredTeamId({ teamId }))
        .then(unwrapResult)
        .then(whenSuccess(strings.profile.teams.setPreferredSuccess))
        .catch(whenError(strings.profile.teams.setPreferredError)),
    [whenSuccess, whenError],
  );

  const passwordReset = useCallback(async () => {
    if (!authData.email) return;
    auth()
      .sendPasswordResetEmail(authData.email)
      .then(() => {
        setEmailResetSent(true);
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }, [authData.email]);

  if (!isLoaded(preferredTeamId)) return null;

  return (
    <ProfileLayout
      authData={authData}
      emailResetSent={emailResetSent}
      isBasicAuth={isBasicAuth}
      nameProps={nameProps}
      onLogoutClick={(): void => history.push('/logout')}
      onPasswordReset={passwordReset}
      onSetPreferredTeamId={onSetPreferredTeamId}
      preferredTeamId={preferredTeamId}
      teams={userTeams}
    />
  );
}
