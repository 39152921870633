import { UI } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { css } from 'emotion';
import React from 'react';
import { requirementDetailsTestIds, requirementsListTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { FirebaseInput } from 'tools';

export type HeaderMenuProps = {
  archiveRequirement: () => void;
  restoreArchivedRequirement: () => void;
  titleProps: FirebaseInput<string>;
  requirement: UI.UIRequirement;
};

export default function HeaderMenu({
  requirement,
  archiveRequirement,
  restoreArchivedRequirement,
  titleProps,
}: HeaderMenuProps): JSX.Element {
  const editName = (): void => titleProps.Ref.current?.focus();
  const builderMenuOptions = [
    {
      title: strings.builder.profileMenu.editName,
      onSelect: editName,
    },
    {
      title: strings.builder.profileMenu.restoreRequirement,
      onSelect: restoreArchivedRequirement,
      hidden: !requirement.archived,
      dataCy: requirementsListTestIds.RESTORE_REQUIREMENT,
    },
    {
      title: strings.builder.profileMenu.archiveRequirement,
      onSelect: archiveRequirement,
      hidden: requirement.archived,
      dataCy: requirementsListTestIds.ARCHIVE_REQUIREMENT,
    },
  ];

  return (
    <div
      className={css`
        position: relative;
      `}
    >
      <Menu options={builderMenuOptions} position='bottomLeft'>
        <Icon
          className={css`
            padding: 0 0.5rem;
          `}
          data-cy={requirementDetailsTestIds.HEADER_THREE_DOT_MENU}
          name='meatballs'
        />
      </Menu>
    </div>
  );
}
