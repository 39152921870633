import { styled } from '@taraai/design-system';
import { FilterSearchBar } from 'components/app/controllers/FilterSearchBar/FilterSearchBar';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace, selectTeam } from 'reduxStore';
import { atomic } from 'resources';
import { strings } from 'resources/i18n';

export default function SprintHeader(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const team = useSelector(selectTeam(orgId, teamId));

  if (!isLoaded(team)) {
    return null;
  }

  return (
    <Wrapper>
      <Title>{strings.sprints.title}</Title>
      <EndWrapper>
        <FilterSearchBar />
        <SprintSettingsLink to={linkTo('sprintsSettings', { orgId, teamId })}>
          <DurationText h4 textDecoration='none'>
            {strings.sprints.repeatsEvery[team.sprintSettings.duration]}
          </DurationText>
          <CogIcon name='cog' />
        </SprintSettingsLink>
      </EndWrapper>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  flexShrink: '0',
  justifyContent: 'space-between',
  padding: '1.125rem 0.75rem 0.75rem 1rem',
});

const Title = styled(Text, {
  fontSize: '1rem',
  fontWeight: 500,
  color: atomic.get(atomic.colors.grey7),
});

const EndWrapper = styled('div', {
  'display': 'flex',
  'padding': 0,
  '> * + *': { marginLeft: '$8px' },
});

const SprintSettingsLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#949caf',
});

const CogIcon = styled(Icon, {
  width: '0.8rem',
  padding: '0rem',
  paddingLeft: '0.5rem',
});

const DurationText = styled(Text, { marginBottom: '0.25rem' });
