import { keyframes } from '@emotion/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import LegacyTaskModal from 'components/app/controllers/views/LegacyTaskModal';
import RemovedTaskView from 'components/app/controllers/views/RemovedTaskView';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getTask, reduxStore, selectActiveWorkspace, taskSubTasks } from 'reduxStore';
import { getTaskComments } from 'reduxStore/comments/queries';
import { getTaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { deleteTask } from 'reduxStore/tasks/actions';
import { getTaskRevision } from 'reduxStore/tasks/queries/taskRevisionQueries';
import { strings } from 'resources';
import { useToast } from 'tools';

export interface LegacyTaskControllerProps {
  taskId: Data.Id.TaskId;
}

/**
 * TaskController
 * top level controller for high level task data
 */
export default function LegacyTaskController({ taskId }: LegacyTaskControllerProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSlice = getTask(orgId, taskId);
  const subTasksSlice = taskSubTasks(orgId, taskId);
  const gitDataSlice = getTaskGitData(orgId, taskId);
  const revisionsSlice = getTaskRevision(orgId, taskId);
  const commentsSlice = getTaskComments(orgId, taskId);

  const task = useSelector(taskSlice.selector);

  useFirestoreConnect([
    ...taskSlice.query,
    ...revisionsSlice.query,
    ...subTasksSlice.query,
    ...gitDataSlice.query,
    ...commentsSlice.query,
  ]);

  const gitData = useSelector(gitDataSlice.selector);
  const subtasks = useSelector(subTasksSlice.selector) || [];
  const revisions = useSelector(revisionsSlice.selector) || [];
  const taskComments = useSelector(commentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);

  const { addToast } = useToast();

  const deleteCurrentTask = (): void => {
    const subtasksIds = subtasks.map((subtask) => ({ id: subtask.id }));
    task &&
      reduxStore
        .dispatch(deleteTask([...subtasksIds, { id: task?.id }]))
        .then(unwrapResult)
        .then(() =>
          addToast({
            message: `${strings.formatString(strings.task.deleted, {
              taskId,
            })}`,
            timeoutMs: 3500,
            type: 'success',
          }),
        )
        .catch(() => {
          addToast({ type: 'error', message: strings.task.failedToDeleteTask });
        });
  };

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/*/tasks/${taskId}`);
    setTaskCopiedText(true);
  };

  if (
    !isLoaded(task) ||
    !isLoaded(taskId) ||
    !isLoaded(subtasks) ||
    !isLoaded(revisions) ||
    !isLoaded(gitData) ||
    !isLoaded(taskComments)
  ) {
    return (
      <StandardSpinner
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
        fillSpace
        size='medium'
      />
    );
  }

  if (task.deleted) {
    return <RemovedTaskView currentOrg={orgId} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <LegacyTaskModal
        author={task.authorDocument}
        copyTaskId={copyTaskId}
        currentOrg={orgId}
        deleteCurrentTask={deleteCurrentTask}
        gitData={gitData}
        noModal
        revisions={revisions}
        setTaskCopiedText={setTaskCopiedText}
        task={task}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
      />
    </DndProvider>
  );
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
