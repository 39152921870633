import { getSelectedTeamId } from 'components/app/GlobalNav/helpers';
import { GetPremiumButton } from 'components/app/monetization/GetPremiumButton';
import { Tab } from 'components/core/controllers/views/Tabs';
import { getRouteName, linkTo } from 'components/Router/paths';
import { stringify } from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getOrgTeams, getSubscriptionType, hasFeature, selectActiveWorkspace, selectPreferredTeamId } from 'reduxStore';
import { strings } from 'resources';
import { globalNavTestIds } from 'resources/cypress/testAttributesValues';
import { useQueryValue } from 'tools';

import { NavigationLayout } from './NavigationLayout';

export const Navigation: React.FC = () => {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const orgTeams = useSelector(getOrgTeams(orgId).selector) || [];
  const isEnabled = useSelector(hasFeature('alphaMonetization', orgId));
  const subscriptionType = useSelector(getSubscriptionType(orgId));
  const isPremium = subscriptionType !== null;

  // Team should be either the selected team (if present) or the preferred one
  const teamId = getSelectedTeamId(orgTeams) || preferredTeamId;

  const DEFINEPATH = linkTo('requirements', { orgId, teamId });
  const SPRINTPATH = linkTo('sprints', { orgId, teamId });
  const HOMEPATH = linkTo('home', { orgId, teamId });
  const PROGRESSPATH = linkTo('sprintDetails', {
    orgId,
    teamId,
    sprintId: 'current',
  });

  const typeFromQuery = useQueryValue('type') as PreviousParams['type'];
  const idFromQuery = useQueryValue('active');

  type PreviousParams = { id?: string; type?: 'sprint' | 'requirement' | 'task' };
  const [previousParams, setPreviousParams] = useState<PreviousParams>({});

  useEffect(() => {
    idFromQuery &&
      typeFromQuery &&
      typeFromQuery !== previousParams?.type &&
      idFromQuery !== previousParams?.id &&
      setPreviousParams({ id: idFromQuery, type: typeFromQuery });
  }, [idFromQuery, previousParams?.id, previousParams?.type, typeFromQuery]);

  const handleGetRoute = useCallback(
    (tab: Tab): string => {
      if (tab.route === SPRINTPATH && previousParams?.id && previousParams?.type) {
        const { type, id } = previousParams;
        const typeMap = {
          sprint: `${SPRINTPATH}?${stringify({ type: 'sprint', active: id })}`,
          requirement: `${SPRINTPATH}?${stringify({ type: 'requirement', active: id })}`,
          task: `${SPRINTPATH}?${stringify({ type: 'requirement', active: id })}`,
        };
        return typeMap[type] || tab.route;
      }
      if (tab.route === DEFINEPATH && previousParams?.id && previousParams?.type) {
        const { type, id } = previousParams;
        const typeMap = {
          sprint: tab.route,
          requirement: linkTo('requirement', { orgId, teamId, requirementId: id }),
          task: linkTo('task', { orgId, teamId, taskId: id }),
        };
        return typeMap[type] as string;
      }
      return tab.route;
    },
    [SPRINTPATH, previousParams, DEFINEPATH, orgId, teamId],
  );

  const tabs = useMemo(
    (): Tab[] => [
      {
        label: strings.navigation.home,
        route: HOMEPATH,
        icon: 'homeFull',
        dataCy: globalNavTestIds.HOME,
      },
      {
        label: strings.navigation.define,
        route: DEFINEPATH,
        dataCy: globalNavTestIds.DEFINE,
      },
      {
        label: strings.navigation.sprint,
        route: SPRINTPATH,
        dataCy: globalNavTestIds.SPRINT,
      },
      {
        label: strings.navigation.progress,
        route: PROGRESSPATH,
        dataCy: globalNavTestIds.PROGRESS,
      },
    ],
    [DEFINEPATH, HOMEPATH, PROGRESSPATH, SPRINTPATH],
  );

  const [selectedTab, setSelectedTab] = useState<Tab>();
  useEffect(
    () =>
      setSelectedTab(
        tabs.find(({ route, label }) => {
          const routeName = getRouteName();
          const defineRoute = routeName === 'task' || routeName === 'requirement';
          // select define option when on requirement builder page
          const onRequirements = defineRoute && label === strings.navigation.define;
          // select sprint option when on sprints page with task sidebar enabled
          const onSprint = routeName === 'sprintsTask' && label === strings.navigation.sprint;

          const onPrevious = route === history.location.pathname;

          return onRequirements || onSprint || onPrevious;
        }),
      ),
    [history.location.pathname, tabs],
  );

  const handleTabChange = useCallback(
    (tab: Tab) => {
      history.push(handleGetRoute(tab));
    },
    [handleGetRoute, history],
  );

  return (
    <>
      <NavigationLayout handleTabChange={handleTabChange} selectedTab={selectedTab} tabs={tabs} />
      {isEnabled && !isPremium && (
        <GetPremiumButton onClick={() => history.push(linkTo('upgrade', { orgId, teamId }))} />
      )}
    </>
  );
};
