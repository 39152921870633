import { Hidden, styled } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { AsanaNotificationBarController } from 'components/app/controllers/AsanaNotificationBarController';
import { GithubImportNotificationBarController } from 'components/app/controllers/GithubImportNotificationBarController';
import ImportModalController from 'components/app/controllers/ImportModalController';
import LegacyTaskModalProvider from 'components/app/controllers/LegacyTaskModalProvider';
import { SlackConnectAccountNotificationBar } from 'components/app/controllers/SlackConnectAccountNotificationBar/SlackConnectAccountNotificationBar';
import { SprintCompletedNotificationBarController } from 'components/app/controllers/SprintCompletedNotificationBarController';
import TaskModalProvider from 'components/app/controllers/TaskModalProvider';
import { TrelloNotificationBarController } from 'components/app/controllers/TrelloNotificationBarController';
import { GlobalNav } from 'components/app/GlobalNav';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { hasFeature } from 'reduxStore';

const Scaffold: React.FC = ({ children }) => {
  const { orgId } = useParams<{ orgId: Data.Id.OrganizationId }>();
  const hasNewSprintsPage = useSelector(hasFeature('newSprintsPage', orgId));
  return (
    <LegacyTaskModalProvider>
      <TaskModalProvider>
        <SlackConnectAccountNotificationBar />
        <Hidden hidden={hasNewSprintsPage} strategy='lazy'>
          <TrelloNotificationBarController />
          <AsanaNotificationBarController />
          <GithubImportNotificationBarController />
        </Hidden>
        <SprintCompletedNotificationBarController />
        <Container>
          <GlobalNav />
          <ImportModalController />
          <Main>{children}</Main>
        </Container>
      </TaskModalProvider>
    </LegacyTaskModalProvider>
  );
};

export default Scaffold;

const Main = styled('main', {
  backgroundColor: '#ffffff',
  height: 'calc(100% - 3rem)',
  width: '100%',
});

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
});
