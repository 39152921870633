import { Data, Functions, UI } from '@taraai/types';
import { AsanaIcon } from 'components/app/controllers/AsanaImportBacklogIndicatorController';
import { GithubIcon } from 'components/app/controllers/GithubImportBacklogIndicatorController';
import LegacyTaskModalContext from 'components/app/controllers/LegacyTaskModalContext';
import { TrelloIcon } from 'components/app/controllers/TrelloImportBacklogIndicatorController';
import ImportedTasksView from 'components/app/controllers/views/ImportedTasksView';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { selectActiveWorkspace } from 'reduxStore';
import { getImportedTasks } from 'reduxStore/imports/queries/imported-tasks';
import { strings } from 'resources/i18n';

type Props = {
  service: Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'];
  onBackClick: () => void;
};

const filterTasksMovedToSprints = (importedTasks: UI.UITask[] = []): UI.UITask[] =>
  importedTasks.filter((task) => task.sprint === null);

export const ImportedTasksController = ({ service, onBackClick }: Props): JSX.Element | null => {
  const { openModal } = useContext(LegacyTaskModalContext);

  const orgId = useSelector(selectActiveWorkspace);

  const importedTasksSlice = getImportedTasks(orgId, service);

  useFirestoreConnect(importedTasksSlice.query);
  const importedTasks = useSelector(compose(filterTasksMovedToSprints, importedTasksSlice.selector));

  const taskCount = importedTasks.length;

  const toggleModal = useCallback(
    (task) => {
      openModal(task, service);
    },
    [openModal, service],
  );
  switch (service) {
    case 'github':
      return (
        <ImportedTasksView
          description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
          icon={<GithubIcon />}
          label={strings.importIndicatorInBacklog.importedFromGithub}
          onBackClick={onBackClick}
          tasks={importedTasks}
          toggleModal={toggleModal}
        />
      );
    case 'trello':
      return (
        <ImportedTasksView
          description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
          icon={<TrelloIcon />}
          label={strings.importIndicatorInBacklog.importedFromTrello}
          onBackClick={onBackClick}
          tasks={importedTasks}
          toggleModal={toggleModal}
        />
      );
    case 'asana':
      return (
        <ImportedTasksView
          description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
          icon={<AsanaIcon />}
          label={strings.importIndicatorInBacklog.importedFromAsana}
          onBackClick={onBackClick}
          tasks={importedTasks}
          toggleModal={toggleModal}
        />
      );
    default:
      return null;
  }
};
