import { styled } from '@taraai/design-system';
import { createEntity } from 'components/editor/entities';
import { isWithinSelection } from 'components/editor/plugins/utils';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUsers, selectActiveWorkspace } from 'reduxStore';

import { UserDropdown } from './UserDropdown';
import { useUserTagForId } from './useUserTagForId';

/**
 * DraftMentionInserter
 * Draft Mention Inserter for parsing mentions from markdown and letting the
 * user insert mentions.
 *
 */
export default function DraftMentionInserter({
  blockKey,
  children,
  decoratedText,
  end,
  start: startWithWhitespace,
}: DraftDecoratorComponentProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const { editorState, setEditorState } = useContext(RichEditorContext);
  const users = useSelector(selectActiveUsers(orgId));
  const getUserTagForId = useUserTagForId(orgId);
  const start = startWithWhitespace + decoratedText.indexOf('@'); // Take the leading whitespace into account
  const entityHasSelection = isWithinSelection(editorState.getSelection(), getSelectionState(blockKey, start, end));
  const insertMention = useCallback(
    (text: string | undefined, id: string) => setEditorState(replaceWithMention({ id, text }, blockKey, start, end)),
    [blockKey, end, setEditorState, start],
  );
  return (
    <UserDropdown
      onSelect={(user) => insertMention(getUserTagForId(user.id), user.id)}
      searchQuery={decoratedText.slice(decoratedText.indexOf('@') + 1)}
      show={entityHasSelection}
      users={users}
    >
      <Wrapper>{children}</Wrapper>
    </UserDropdown>
  );
}

const Wrapper = styled('span', { position: 'relative' });

const replaceWithMention = (
  { id, text }: { id: string; text: string | undefined },
  blockKey: string,
  start: number,
  end: number,
) => (state: EditorState): EditorState => {
  if (!text) return state;
  const content = state.getCurrentContent();
  const { contentWithEntity, key } = createEntity('mention', { id }, content);
  const selection = getSelectionState(blockKey, start, end);
  return EditorState.push(
    state,
    Modifier.replaceText(contentWithEntity, selection, text, undefined, key).set(
      'selectionAfter',
      getSelectionState(blockKey, start + text.length),
    ) as ContentState,
    'apply-entity',
  );
};

function getSelectionState(blockKey: string, anchorOffset: number, focusOffset = anchorOffset): SelectionState {
  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset,
    hasFocus: true,
  }) as SelectionState;
}
