import { styled } from '@taraai/design-system';
import React, { useEffect } from 'react';
import { LoginSignupBanner, TaraLogo } from 'resources/assets';
import { strings } from 'resources/i18n';
import { useQueryValue } from 'tools';
import { setInvitationTokenCookie } from 'tools/utils/invitationToken';

type AuthLayoutProps = {
  children?: React.ReactNode;
  title: string;
  subHeader?: string;
};

export default function AuthLayout({
  children,
  title = strings.authLayout.signIn,
  subHeader = '',
}: AuthLayoutProps): JSX.Element {
  const tokenFromQuery = useQueryValue('token');
  useEffect(() => {
    if (tokenFromQuery) setInvitationTokenCookie(tokenFromQuery);
  }, [tokenFromQuery]);
  return (
    <Container>
      <SignupContainer>
        <AnchorTag href='https://tara.ai'>
          <TaraLogoImg alt={strings.logo.tara} src={TaraLogo} />
        </AnchorTag>
        <HeaderTitle>{title}</HeaderTitle>
        <SubHeader>{subHeader}</SubHeader>
        <ChildrenContainer>{children}</ChildrenContainer>
        <PolicyTermsContainer>
          {strings.formatString(
            strings.authLayout.policyTerms,
            <PrivacyPolicy href='https://tara.ai/privacy/' rel='noopener noreferrer' target='_blank'>
              {strings.authLayout.privacyPolicy}
            </PrivacyPolicy>,
            <TermsOfService href='https://tara.ai/terms-of-service/' rel='noopener noreferrer' target='_blank'>
              {strings.authLayout.termsOfService}
            </TermsOfService>,
          )}
        </PolicyTermsContainer>
      </SignupContainer>
      <Banner>
        <BannerImage alt='' src={LoginSignupBanner} />
        <BannerText>{strings.authLayout.hero.title}</BannerText>
      </Banner>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  flex: 'auto',
  justifyContent: 'space-between',
});
const AnchorTag = styled('a', { textAlign: 'left' });
const TaraLogoImg = styled('img', { width: '135px', marginBottom: '3.125rem' });
const HeaderTitle = styled('h1', {
  color: '#191919',
  fontWeight: '500',
  textAlign: 'left',
  margin: '0',
  marginBottom: '2rem',
});
const SubHeader = styled('h3', {
  fontWeight: 'normal',
  color: '#575f65',
  textAlign: 'left',
  fontSize: '1rem',
});
const ChildrenContainer = styled('div', { marginBottom: '5rem' });
const PolicyTermsContainer = styled('div', {
  fontWeight: 'normal',
  color: '#575f65',
  textAlign: 'left',
  fontSize: '0.75rem',
});
const PrivacyPolicy = styled('a', { color: '#1d98ff' });
const TermsOfService = styled('a', { color: '#1d98ff' });
const BannerText = styled('h1', {
  fontSize: '1.35rem',
  fontWeight: '600',
  lineHeight: '1.13',
  color: '#ffffff',
  textAlign: 'center',
  margin: 0,
  padding: '0.5rem 7rem 5rem 7rem',
});
const SignupContainer = styled('div', {
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  margin: '6.25rem 5.25rem',
});
const Banner = styled('div', {
  display: {
    initial: 'none',
    desktop: 'flex',
  },
  flexGrow: '0.75',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30%',
  backgroundImage: 'linear-gradient(141deg, #58a6e7 0%, #775dfa)',
});
const BannerImage = styled('img', {
  padding: '2rem 4rem 0 4rem',
  width: '100%',
});
