import { UI } from '@taraai/types';
import TeamSelector from 'components/app/controllers/Selectors/TeamSelector';
import { RequirementsViewProps } from 'components/app/controllers/views/RequirementsView';
import FilterMenuController from 'components/core/controllers/FilterMenuController';
import Table from 'components/core/controllers/Table';
import Avatar from 'components/core/controllers/views/Avatar';
import TableCell from 'components/core/controllers/views/TableCell';
import TableRow from 'components/core/controllers/views/TableRow';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { RequirementsEmptyState } from 'resources/assets';
import { requirementsListTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatFromNow } from 'tools';

type RequirementFragment = Pick<UI.UIRequirement, 'assignedTeamIds'>;
export interface LegacyRequirementListProps
  extends Omit<RequirementsViewProps, 'activeRequirements' | 'archivedRequirements' | 'filteredRequirements'> {
  requirements: UI.UIRequirement[];
  type: string;
}

export default function LegacyRequirementList({
  requirements,
  teams,
  type,
  statusFilterOptions,
  clearResults,
  handleSearch,
  handleFilter,
}: LegacyRequirementListProps): JSX.Element {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  useEffect(() => {
    clearResults();
  }, [clearResults]);

  const data = requirements.map((requirement: UI.UIRequirement) => ({
    title: (
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
        data-cy={requirementsListTestIds.REQUIREMENT_NAME}
      >
        <Avatar
          key={requirement.id}
          className={css`
            margin-right: 1.625rem;
            ${atomic.responsive.query({
              nanoTiny: true,
              tinySmall: true,
              smallMedium: true,
            })} {
              display: flex;
              margin-right: 0.5rem;
            }
          `}
          requirement={requirement}
          size='medium'
        />
        {requirement.title}
      </div>
    ),
    author: (
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
        data-cy={requirementsListTestIds.REQUIREMENT_AUTHOR}
      >
        <Avatar
          key={requirement.id}
          className={css`
            margin-right: 0.5rem;
          `}
          size='medium'
          user={requirement.authorDocument}
        />
        {requirement?.authorDocument?.name ?? ''}
      </div>
    ),
    team: (
      <TeamSelector
        assignedTeams={teams.filter((team) => requirement.assignedTeamIds.includes(team.id))}
        disablePopup
      />
    ),
    updatedAt: formatFromNow(requirement.updatedAt),
    id: requirement.id,
  }));

  const handleSearchWithRequirements = (query: string): void => handleSearch(requirements, query);

  const handleFilterWithRequirements = (
    collectionToFilter: keyof RequirementFragment,
    itemsToFilter: (string | number)[],
  ): void => handleFilter(requirements, collectionToFilter, itemsToFilter);

  const columns = {
    title: { name: strings.products.table.name, width: '50%' },
    author: { name: strings.products.table.author, width: '20%' },
    team: {
      name: strings.products.table.team,
      width: '15%',
      filterRenderer: (
        <FilterMenuController
          collectionName='assignedTeamIds'
          handleFilter={handleFilterWithRequirements}
          options={statusFilterOptions}
        />
      ),
    },
    updatedAt: { name: strings.products.table.lastUpdated, width: '15%' },
  };

  return (
    <Table
      bottomRow={
        data.length < 1 ? (
          <TableRow>
            <TableCell
              className={css`
                cursor: auto;
                padding: 4.6em !important;
                text-align: center !important;
              `}
              columnSpan={Object.keys(columns).length}
            >
              <img
                alt={strings.emptyRequirement}
                className={css`
                  padding: 0.75rem;
                `}
                src={RequirementsEmptyState}
              />
              <div
                className={css`
                  font-size: 0.875rem;
                  font-weight: 500;
                  color: #949caf;
                  padding: 0.75rem;
                  text-transform: capitalize;
                `}
              >
                {type
                  ? strings.formatString(strings.requirements.emptyStateRequirementsWithLabel, { label: type })
                  : strings.requirements.emptyStateRequirements}
              </div>
            </TableCell>
          </TableRow>
        ) : undefined
      }
      data={data}
      handleSearch={handleSearchWithRequirements}
      headers={columns}
      placeholderSearch={strings.table.searchRequirementPlaceholder}
      tableRowOnClick={(row) => (): void => {
        history.push(linkTo('requirement', { orgId, requirementId: row.id, teamId }));
      }}
      withSearch
    />
  );
}
