import { Data } from '@taraai/types';
import { SprintDateModule } from 'components/core/controllers/views/SprintDateModule';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { getSprint } from 'reduxStore';

interface Props {
  orgId: Data.Id.OrganizationId;
  sprintId: Data.Id.SprintId;
}

export default function SprintDateModuleController({ orgId, sprintId }: Props): JSX.Element | null {
  const sprintSlice = getSprint(orgId, sprintId);
  const sprintFragment = useSelector(
    compose((sprint) => sprint && { endDate: sprint.endDate, startDate: sprint.startDate }, sprintSlice.selector),
    deepEquals,
  );

  useFirestoreConnect(sprintSlice.query);

  if (!sprintFragment) {
    return null;
  }

  return <SprintDateModule endDate={sprintFragment.startDate} startDate={sprintFragment.endDate} />;
}
