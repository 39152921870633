/* eslint-disable sonarjs/cognitive-complexity */
import { Box, Fluid, Hidden, HStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import AvatarListController from 'components/app/controllers/AvatarListController';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectActiveWorkspace } from 'reduxStore';
import { CompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';
import { selectActiveTeam, selectTeam } from 'reduxStore/teams';
import { sprintsTabTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { toDate } from 'tools';

import { SprintDetailsNavigation } from './SprintDetailsNavigation';

export interface SprintDetailsHeaderProps {
  selectedSprint?: UI.UISprint;
  organizationId: Data.Id.OrganizationId;
  nextSprintId: Data.Id.SprintId | null;
  previousSprintId: Data.Id.SprintId | null;
  completeSprintData: CompleteSprintData | undefined;
}

/**
 * Render the sprint number, complete status, and date filter which will update
 * the top level state and render the filtered data for SprintDetailsTimeline
 */
export default function SprintDetailsHeader({
  selectedSprint,
  organizationId,
  nextSprintId,
  previousSprintId,
  completeSprintData,
}: SprintDetailsHeaderProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const team = useSelector(selectTeam(orgId, teamId));
  const history = useHistory();

  function completeSprint(event: React.SyntheticEvent): void {
    event.preventDefault();
    if (!selectedSprint?.id) {
      return;
    }
    history.push(
      linkTo('sprintDetailsComplete', {
        orgId: organizationId,
        teamId,
        sprintId: selectedSprint.id,
      }),
    );
  }

  const { sprintTasks } = completeSprintData ?? {};

  const completeSprintButtonShow = team && !team?.sprintSettings.autoSprints;
  const completeSprintButtonFlashy =
    !!selectedSprint?.endDate && toDate(selectedSprint?.endDate).getDate() <= new Date(Date.now()).getDate();

  return (
    <div>
      <Box spaceVert='$8px'>
        <HStack alignY='center'>
          <div>
            <SprintDetailsNavigation
              nextSprintId={nextSprintId}
              organizationId={organizationId}
              previousSprintId={previousSprintId}
              selectedSprint={selectedSprint}
            >
              {selectedSprint ? selectedSprint?.sprintName : strings.sprints.noActiveSprints.header}
            </SprintDetailsNavigation>
          </div>
          <Fluid />
          {selectedSprint && (
            <div>
              {selectedSprint.isComplete ? (
                <Link
                  className={css`
                    font-weight: 500;
                    margin-left: 1rem;
                    align-self: center;
                    font-size: 0.875rem;
                    text-decoration: none;
                    ${atomic.color(atomic.theme.color.success)};
                    :hover {
                      ${atomic.color(atomic.theme.color.success)};
                    }
                  `}
                  to={linkTo('sprintDetailsEdit', {
                    orgId: organizationId,
                    teamId,
                    sprintId: selectedSprint.id,
                  })}
                >
                  <Icon
                    className={css`
                      width: 0.875rem;
                      height: 0.75rem;
                      padding: 0rem;
                      margin-right: 0.3125rem;
                      margin-bottom: -0.1406rem;
                    `}
                    color='white'
                    name='tick'
                  />
                  {strings.sprints.completed}
                </Link>
              ) : (
                <Hidden hidden={!completeSprintButtonShow}>
                  <Button
                    className={css`
                      @keyframes pulse {
                        0% {
                          transform: scale(0.95);
                        }
                        70% {
                          transform: scale(1);
                          box-shadow: 0 0 0 5px rgba(71, 167, 0, 0.4);
                        }
                        100% {
                          transform: scale(0.95);
                          box-shadow: 0 0 0 5px rgba(71, 167, 0, 0.4);
                        }
                      }
                      padding: 0rem;
                      background-color: ${completeSprintButtonFlashy
                        ? atomic.get(atomic.colors.success)
                        : atomic.get(atomic.colors.grey2)};
                      margin: 0rem;
                      margin-left: 1rem;
                      max-height: 1.6875rem;
                      min-height: 1.6875rem;
                      min-width: 0rem;
                      outline: 0;
                      width: 5.8125rem;
                      font-size: 0.875rem;
                      font-weight: 500;
                      color: ${completeSprintButtonFlashy ? '#ffffff' : '#67728b'};
                      -webkit-animation: ${completeSprintButtonFlashy ? 'pulse 1.5s infinite' : 'none'};
                      transition: all 0.3s;
                      box-shadow: 0 0 0 0 rgba(#47a700, 0.5);
                      :hover {
                        background-color: ${completeSprintButtonFlashy
                          ? atomic.get(atomic.colors.success)
                          : atomic.get(atomic.colors.grey2)};
                        -webkit-animation: none;
                      }
                    `}
                    data-cy={sprintsTabTestIds.COMPLETE_SPRINT}
                    onClick={completeSprint}
                  >
                    {strings.sprints.sprintDetails.completeSprint}
                  </Button>
                </Hidden>
              )}
            </div>
          )}
        </HStack>
      </Box>
      {selectedSprint?.isComplete && (
        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          <div
            className={css`
              flex-grow: 1;
              margin: 2.25rem 0;
            `}
          >
            <br />
            <span aria-label={strings.sprints.completedHeader.rocket.label} role='img'>
              <Text h1>{strings.sprints.completedHeader.rocket.emoji}</Text>
            </span>
            <Text
              className={css`
                margin-top: 0.5rem;
                margin-bottom: 1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                ${atomic.color(atomic.colors.greySlate)};
              `}
              h3
            >
              {strings.sprints.completedHeader.header}
            </Text>
            <AvatarListController userIds={sprintTasks?.map((task) => task.assignee)} />
          </div>
        </div>
      )}
    </div>
  );
}
