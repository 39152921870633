import { keyframes } from '@emotion/core';
import { unwrapResult } from '@reduxjs/toolkit';
import LegacyTaskModal from 'components/app/controllers/views/LegacyTaskModal';
import Modal from 'components/core/controllers/views/Modal';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  deleteTask,
  getTask,
  getTaskComments,
  getTaskRevision,
  reduxStore,
  selectActiveWorkspace,
  taskSubTasks,
} from 'reduxStore';
import { getTaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { strings } from 'resources/i18n';
import { useToast } from 'tools/utils/hooks/useToast';

export interface LegacyTaskModalControllerProps extends React.HTMLProps<HTMLElement> {
  taskId: string;
  navigationCategory: string | null;
  closeModal: () => void;
}

/**
 * TaskModalController
 * controller specific logic for task modal
 *
 */
export default function LegacyTaskModalController({
  taskId,
  closeModal,
  navigationCategory,
}: LegacyTaskModalControllerProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const { addToast } = useToast();

  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSlice = getTask(orgId, taskId);
  const taskCommentsSlice = getTaskComments(orgId, taskId);
  const taskSubTasksSlice = taskSubTasks(orgId, taskId);
  const taskGitDataSlice = getTaskGitData(orgId, taskId);
  const taskRevisionsSlice = getTaskRevision(orgId, taskId);

  useFirestoreConnect([
    ...taskSlice.query,
    ...taskCommentsSlice.query,
    ...taskSubTasksSlice.query,
    ...taskGitDataSlice.query,
    ...taskRevisionsSlice.query,
  ]);

  const currentTask = useSelector(taskSlice.selector);
  const taskComments = useSelector(taskCommentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);
  const revisions = useSelector(taskRevisionsSlice.selector) || [];
  const gitData = useSelector(taskGitDataSlice.selector);
  const subtasks = useSelector(taskSubTasksSlice.selector) || [];

  /**
   * This use effect is effectively intercepting any react router history and forcing the modal to close
   * instead of going back on the history stack.
   */
  useEffect((): (() => void) => {
    window.onpopstate = (): void => {
      closeModal();
    };
    return (): null => (window.onpopstate = null);
  }, [closeModal]);

  useHotkeys('esc', () => closeModal(), {}, []);

  const deleteCurrentTask = (): void => {
    if (!currentTask) return;

    const subtasksIds = subtasks.map((subtask) => ({ id: subtask.id }));
    reduxStore
      .dispatch(deleteTask([...subtasksIds, { id: currentTask.id }]))
      .then(unwrapResult)
      .then(() =>
        addToast({
          message: `${strings.formatString(strings.task.deleted, {
            taskId: currentTask.id,
          })}`,
          timeoutMs: 3500,
          type: 'success',
        }),
      )
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToDeleteTask });
      });

    closeModal();
  };

  useEffect(() => {
    if (!currentTask) {
      closeModal();
    }
  }, [closeModal, currentTask]);

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/*/tasks/${taskId}`);
    setTaskCopiedText(true);
  };

  if (!currentTask) {
    return (
      <Modal
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
      >
        <StandardSpinner fillSpace size='medium' />
      </Modal>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <LegacyTaskModal
        key={currentTask.id}
        author={currentTask.authorDocument}
        closeModal={closeModal}
        copyTaskId={copyTaskId}
        currentOrg={orgId}
        deleteCurrentTask={deleteCurrentTask}
        gitData={gitData}
        navigationCategory={navigationCategory}
        revisions={revisions}
        setTaskCopiedText={setTaskCopiedText}
        task={currentTask}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
      />
    </DndProvider>
  );
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
