import { Data, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import React, { useCallback } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  reduxStore,
  selectActiveUsers,
  selectActiveWorkspace,
  selectTaskDocument,
  selectUserDocument,
  updateTask,
} from 'reduxStore';

import { TaskCard } from './TaskCard';

export type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

type Props = {
  assignee?: UserFragment;
  filteredByStatus?: boolean;
  overloaded?: boolean;
  selected?: boolean;
  inWorkDrawer?: boolean;
  taskId: Data.Id.TaskId;
};
const getUserFragment = (data: UI.UIUser[]): UserFragment[] =>
  data.map(({ id, name, avatarURL }) => ({ id, name, avatarURL }));

export const TaskCardController = React.memo(function TaskCardController({
  taskId,
  inWorkDrawer,
  ...props
}: Props): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const taskFragment = useSelector(
    (state) => selectTaskDocument(state, taskId, ['status', 'effortLevel', 'title', 'sprint', 'assignee']),
    deepEquals,
  );
  const users: UserFragment[] = useSelector(compose(getUserFragment, selectActiveUsers(orgId)), deepEquals);

  if (taskFragment?.assignee && !props.assignee) {
    props.assignee = users.find((user) => user.id === taskFragment?.assignee);
  }

  const { name, avatarURL } =
    useSelector((state) => selectUserDocument(state, taskFragment?.assignee, ['name', 'avatarURL']), deepEquals) ?? {};

  const updateTaskWithTitle = useCallback(
    async (title: string): Promise<void> => {
      const { collaborators, ...taskData } = extractMentions(extractEffortLevel({ title }));
      const labels = unique(parseLabelsFromPlainText(taskData.title));
      await reduxStore.dispatch(
        updateTask({
          id: taskId,
          labels,
          collaborators: collaborators.length ? ['::arrayUnion', ...collaborators] : undefined,
          ...taskData,
        }),
      );
    },
    [taskId],
  );

  const setTaskAssignee = useCallback(
    async ({ id }: UserFragment): Promise<void> => {
      await reduxStore.dispatch(updateTask({ id: taskId, assignee: id }));
    },
    [taskId],
  );

  const removeTaskAssignee = useCallback(async (): Promise<void> => {
    await reduxStore.dispatch(updateTask({ id: taskId, assignee: null }));
  }, [taskId]);

  return taskFragment ? (
    <TaskCard
      assigneeAvatarURL={avatarURL}
      assigneeId={taskFragment.assignee}
      assigneeName={name}
      effortLevel={taskFragment.effortLevel}
      removeTaskAssignee={removeTaskAssignee}
      setTaskAssignee={setTaskAssignee}
      showAssignedToSprint={inWorkDrawer && taskFragment.sprint !== null}
      status={taskFragment.status}
      taskId={taskId}
      title={taskFragment.title}
      updateTaskWithTitle={updateTaskWithTitle}
      users={users}
      {...props}
    />
  ) : null;
});
