import { VStack } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { Organization } from '@taraai/types/src/data';
import { OrganizationId, TaskId, TeamId } from '@taraai/types/src/data/id';
import { Summary } from 'components/app/shared/Summary';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { TaskGitData } from 'reduxStore';

import { TaskDetailsActivity } from './Activity/TaskDetailsActivity';
import { Assignee } from './Assignee';
import { Collaborators } from './Collaborators';
import { Comments } from './Comments';
import { Effort } from './Effort';
import { Git } from './Git';
import { Requirement } from './Requirement';
import { Status } from './Status';
import { SubtasksSection } from './Subtasks/SubtasksSection';
import { SubtaskFragment, UserFragment } from './TaskDetails';
import { TitleController } from './Title/TitleController';

type Props = {
  orgId: OrganizationId;
  showSummary?: boolean;
  taskId: TaskId;
  subtasksIds: { id: TaskId }[];
  task: UI.UITask;
  teamId: TeamId;
  handleViewModal: () => void;
  org: Organization;
  users: UserFragment[];
  collaborators: UserFragment[];
  subtasksData?: SubtaskFragment[];
  comments?: UI.UIComment[];
  gitData?: TaskGitData;
  revisions?: UI.UITaskRevision[];
};

export const TaskDetailsView = React.memo(function TaskDetails({
  orgId,
  subtasksIds,
  task,
  taskId,
  teamId,
  showSummary,
  handleViewModal,
  org,
  users,
  collaborators,
  subtasksData,
  comments,
  gitData,
  revisions,
}: Props): JSX.Element {
  return (
    <VStack space='$1px'>
      <TitleController orgId={orgId} subtasksIds={subtasksIds} task={task} taskId={taskId} teamId={teamId} />
      {showSummary && <Summary description={task.description} handleViewModal={handleViewModal} />}
      <Status status={task.status} taskId={taskId} />
      <Effort effortLevel={task.effortLevel} effortUnit={org.effortUnit} taskId={taskId} />
      <Assignee assigneeDocument={task.assigneeDocument} taskId={taskId} users={users} />
      <Requirement task={task} />
      <Collaborators collaborators={collaborators} orgId={orgId} taskId={taskId} users={users} />
      <SubtasksSection subtasks={subtasksData} taskId={taskId} />
      <Comments comments={comments} onClickViewModal={handleViewModal} taskId={taskId} />
      <Git gitData={gitData} taskId={taskId} />
      <TaskDetailsActivity revisions={revisions} />
    </VStack>
  );
},
deepEquals);
