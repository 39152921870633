/* eslint-disable @typescript-eslint/no-shadow */

import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import DropdownController from 'components/core/controllers/DropdownController';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { getRequirement, getRequirements, reduxStore, selectActiveWorkspace } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type TaskFragment = Pick<UI.UITask, 'id' | '_relationships'>;
export interface RequirementSelectorProps {
  task: TaskFragment;
  requirementsRef?: React.RefObject<HTMLDivElement>;
  dataCy?: string;
}

function RequirementSelector({ task, requirementsRef, dataCy }: RequirementSelectorProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);

  const requirementId = task?._relationships?.requirement || '';

  const [show, setShow] = useState(false);

  const toggleShow = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    setShow((show) => !show);
  }, []);

  const individualRequirementSlice = getRequirement(orgId, requirementId);
  useFirestoreConnect(individualRequirementSlice.query);

  const selectedRequirement =
    useSelector(createSelector(individualRequirementSlice.selector, (requirement) => requirement && [requirement])) ??
    [];
  const selectedRequirementTitle = selectedRequirement[0]?.title ?? strings.requirementDropdown.noSelection;

  const requirementsSlice = getRequirements(orgId, null, { orderBy: 'title' });

  useFirestoreConnect(requirementsSlice.query);
  const dropdownOptions = useSelector(
    createSelector(requirementsSlice.selector, (requirements = []) => requirements.map((requirement) => requirement)),
  );

  const { addToast } = useToast();

  const updateRequirement = useCallback(
    (id: string) => {
      if (id !== undefined && typeof id !== 'number') {
        setShow(!show);
        reduxStore
          .dispatch(
            updateTask({
              id: task?.id,
              _relationships: { requirement: id },
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToUpdateTask,
            }),
          );
      } else {
        addToast({
          type: 'error',
          message: strings.task.failedToUpdateTask,
        });
      }
    },
    [show, task, addToast],
  );

  const removeRequirement = useCallback(
    (id: string) => {
      if (id !== undefined && typeof id !== 'number') {
        setShow(!show);
        reduxStore
          .dispatch(
            updateTask({
              id: task?.id,
              _relationships: { requirement: null },
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToRemoveTask,
            }),
          );
      } else {
        addToast({
          type: 'error',
          message: strings.task.failedToRemoveTask,
        });
      }
    },
    [show, task, addToast],
  );
  return (
    <DropdownController
      data-cy={dataCy}
      header
      headerPlaceholder={strings.dropdown.placeholder.searchRequirements}
      headerTitle={strings.dropdown.header.addRequirements}
      onAdd={updateRequirement}
      onClose={useCallback(() => setShow(false), [])}
      onRemove={removeRequirement}
      options={dropdownOptions}
      selectedOptions={selectedRequirement}
      show={show}
    >
      <div
        ref={requirementsRef}
        className={css`
          cursor: pointer;
          align-items: center;
          display: flex;
          outline: 0;
        `}
        data-cy={dataCy}
        onClick={toggleShow}
        onKeyDown={toggleShow}
        role='button'
        tabIndex={-1}
      >
        <div
          className={css`
            color: #303f4b;
            font-size: 14px;
            font-weight: normal;
            padding-right: 8px;
          `}
          data-cy={dataCy}
        >
          {selectedRequirementTitle}
        </div>
        <Icon
          className={css`
            padding: 0px;
            padding-left: 0rem;
            width: 0.4375rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            color: #949caf;
            cursor: pointer;
          `}
          data-cy={dataCy}
          name='dropdownindicator'
        />
      </div>
    </DropdownController>
  );
}

export default RequirementSelector;
