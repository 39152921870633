import { Radio, Text, VStack } from '@taraai/design-system';
import { TaskStatus } from '@taraai/types';
import { SprintDetailsSidebarModule } from 'components/app/controllers/views/SprintDetailsSidebarModule';
import { Header } from 'components/app/controllers/views/SprintDetailsSidebarModule/Header';
import React, { useEffect } from 'react';
import { strings } from 'resources/i18n';

import { PlannedEffortEntry } from './PlannedEffortEntry';
import { RadioFilterEntry } from './RadioFilterEntry';
import { TaskSummaries } from './types';

function SprintFiltersModuleHeader({
  onFilterReset,
  resetDisabled,
}: {
  onFilterReset: () => void;
  resetDisabled: boolean;
}): JSX.Element {
  return (
    <Header
      button={
        <Text
          color={resetDisabled ? '$grey2' : '$focus'}
          onClick={() => {
            if (!resetDisabled) {
              onFilterReset();
            }
          }}
          size='$10px'
          style={{ cursor: resetDisabled ? 'default' : 'pointer', userSelect: 'none' }}
          weight='medium'
        >
          {strings.sprintFilters.reset}
        </Text>
      }
      icon='filters'
      title={strings.sprintFilters.moduleTitle}
    />
  );
}

type Props = {
  recommendedEffort?: number;
  taskSummaries: TaskSummaries;
  filterValue: TaskStatus | undefined;
  onFilterSelect: (status: TaskStatus | undefined) => void;
  onFilterReset: () => void;
};

export function SprintFiltersModule({
  recommendedEffort,
  taskSummaries,
  filterValue,
  onFilterSelect,
  onFilterReset,
}: Props): JSX.Element {
  const totalTaskCount = taskSummaries.reduce((sum, { taskCount }) => sum + taskCount, 0);
  const totalPlannedEffort = taskSummaries.reduce((sum, { plannedEffort }) => sum + plannedEffort, 0);
  const enabledFilterTypes = taskSummaries.reduce(
    (enabled, { taskCount }, filterType) => (taskCount > 0 ? [...enabled, filterType as TaskStatus] : enabled),
    [] as TaskStatus[],
  );

  // if there is only one valid filter we can apply
  // we want to show it as if the filter is applied,
  // without actually applying it (it makes no difference, since sprint only contains one type of tasks)
  const onlyEnabledFilterType = enabledFilterTypes.length === 1 ? enabledFilterTypes[0] : undefined;

  useEffect(() => {
    // reset filters if we have only one option (it makes no difference if the filter is applied or not)
    if (onlyEnabledFilterType) {
      onFilterReset();
    }
  }, [onFilterReset, onlyEnabledFilterType]);

  return (
    <SprintDetailsSidebarModule
      header={
        <SprintFiltersModuleHeader
          onFilterReset={onFilterReset}
          resetDisabled={filterValue === undefined || !!onlyEnabledFilterType}
        />
      }
    >
      <Radio.Group
        deselectable
        onChange={(value) => {
          if (!onlyEnabledFilterType) onFilterSelect(value);
        }}
        value={onlyEnabledFilterType ?? filterValue}
      >
        <VStack space='$4px'>
          {recommendedEffort !== undefined && (
            <PlannedEffortEntry
              filterSelected={filterValue !== undefined}
              recommendedEffort={recommendedEffort}
              totalPlannedEffort={totalPlannedEffort}
            />
          )}
          {taskSummaries.map(({ taskCount, plannedEffort }, taskStatus) => (
            <RadioFilterEntry
              // eslint-disable-next-line react/no-array-index-key
              key={taskStatus}
              plannedEffort={plannedEffort}
              selectedFilterType={onlyEnabledFilterType ?? filterValue}
              taskCount={taskCount}
              taskStatus={taskStatus}
              totalTaskCount={totalTaskCount}
            />
          ))}
        </VStack>
      </Radio.Group>
    </SprintDetailsSidebarModule>
  );
}
