import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import LegacyRequirementsList from 'components/app/controllers/views/LegacyRequirementsList';
import RequirementsHeader from 'components/app/controllers/views/RequirementsHeader';
import TabBar from 'components/core/controllers/views/TabBar';
import React from 'react';
import { strings } from 'resources';
import { Option } from 'tools/libraries/helpers/tableFilter';

type RequirementFragment = Pick<UI.UIRequirement, 'assignedTeamIds'>;
type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;

export interface RequirementsViewProps {
  activeRequirements: UI.UIRequirement[];
  archivedRequirements: UI.UIRequirement[];
  filteredRequirements: UI.UIRequirement[];
  teams: TeamFragment[];
  statusFilterOptions: Option[];
  clearResults: () => void;
  handleSearch: (data: UI.UIRequirement[], query: string) => void;
  handleFilter: (
    requirementsToFilter: UI.UIRequirement[],
    collectionToFilter: keyof RequirementFragment,
    itemsToFilter: (string | number)[],
  ) => void;
}

/**
 * RequirementsView
 * The view for RequirementsController
 *
 */
export const RequirementsView: React.FC<RequirementsViewProps> = ({
  activeRequirements,
  archivedRequirements,
  filteredRequirements,
  clearResults,
  handleFilter,
  handleSearch,
  statusFilterOptions,
  teams,
}) => (
  <Wrapper>
    <Header>
      <RequirementsHeader />
    </Header>
    <TabBar
      tabbedContent={[
        {
          label: strings.requirements.active,
          content: (
            <LegacyRequirementsList
              key={strings.requirements.active}
              clearResults={clearResults}
              handleFilter={handleFilter}
              handleSearch={handleSearch}
              requirements={filteredRequirements.length > 0 ? filteredRequirements : activeRequirements}
              statusFilterOptions={statusFilterOptions}
              teams={teams}
              type={strings.requirements.active}
            />
          ),
          route: strings.requirements.active,
        },
        {
          label: strings.requirements.archived,
          content: (
            <LegacyRequirementsList
              key={strings.requirements.archived}
              clearResults={clearResults}
              handleFilter={handleFilter}
              handleSearch={handleSearch}
              requirements={filteredRequirements.length > 0 ? filteredRequirements : archivedRequirements}
              statusFilterOptions={statusFilterOptions}
              teams={teams}
              type={strings.requirements.archived}
            />
          ),
          route: strings.requirements.archived,
        },
      ]}
    />
  </Wrapper>
);

const Wrapper = styled('div', {
  padding: '2.5rem',
  height: '100%',
  overflowY: 'scroll',
});

const Header = styled('div', {
  paddingBottom: '1.5rem',
});
