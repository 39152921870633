import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { reduxStore } from 'reduxStore';
import { subscriptionPurchase } from 'reduxStore/monetization';
import { useToast } from 'tools';

import { UpgradeView } from './UpgradeView';

export function UpgradeController(): JSX.Element {
  const { addToast } = useToast();
  const [isYearly, setIsYearly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  function sendToCheckout(price: string): void {
    setIsLoading(true);
    reduxStore
      .dispatch(subscriptionPurchase({ price }))
      .then(unwrapResult)
      .catch(() => {
        setIsLoading(false);
        addToast({ type: 'error', message: 'Error processing payment.', timeoutMs: 3000 });
      });
  }

  return (
    <UpgradeView isLoading={isLoading} isYearly={isYearly} sendToCheckout={sendToCheckout} setIsYearly={setIsYearly} />
  );
}
