import { unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { deleteTask, reduxStore, updateTask } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

import { Title } from './Title';

type Props = {
  task: UI.UITask;
  subtasksIds: Pick<UI.UITask, 'id'>[];
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  taskId: Data.Id.TaskId;
};

export const TitleController = ({ task, subtasksIds, orgId, teamId, taskId }: Props): JSX.Element | null => {
  const { whenError } = useToast();
  const history = useHistory();

  const updateTaskWithTitle = useCallback(
    async (title: string): Promise<void> => {
      const { collaborators, ...taskData } = extractMentions(extractEffortLevel({ title }));
      const labels = unique(parseLabelsFromPlainText(taskData.title));
      await reduxStore.dispatch(
        updateTask({
          id: taskId,
          labels,
          collaborators: collaborators.length ? ['::arrayUnion', ...collaborators] : undefined,
          ...taskData,
        }),
      );
    },
    [taskId],
  );

  const deleteCurrentTask = useCallback((): void => {
    reduxStore
      .dispatch(deleteTask([...subtasksIds, { id: taskId }]))
      .then(unwrapResult)
      .catch(whenError(strings.task.failedToDeleteTask));
    history.goBack();
  }, [history, subtasksIds, taskId, whenError]);

  return (
    <Title
      authorName={task.authorDocument?.name ?? ''}
      id={task.id}
      onClickDeleteTask={deleteCurrentTask}
      orgId={orgId}
      status={task.status}
      taskId={taskId}
      teamId={teamId}
      title={task.title}
      updatedAt={task.updatedAt}
      updateTask={updateTaskWithTitle}
    />
  );
};
