import { UI } from '@taraai/types';
import { keys } from '@taraai/utility/dist/objects';
import {
  composePlugins,
  createBackspaceDeletePlugin,
  createLabelsPlugin,
  createMentionPlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { MutableRefObject, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  createLabel,
  defaultLabels,
  getCustomLabels,
  reduxStore,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import { strings } from 'resources';

type Props = {
  initialValue?: string;
  onBlur?: () => void;
  onDelete?: () => void;
  onFocus?: () => void;
  onSave: (content: string) => void;
};

export const Editor = React.forwardRef<RichEditorHandle, Props>(function Editor(
  { initialValue, onBlur, onDelete, onFocus, onSave },
  ref,
): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const customLabels = useSelector(getCustomLabels(orgId).selector);
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels || [])];

  const getUserTagForId = useUserTagForId(orgId);

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
        }),
        createMentionPlugin(getUserTagForId),
        createBackspaceDeletePlugin(onDelete),
      ),
    [getUserTagForId, onDelete],
  );

  return (
    <RichEditorProvider
      initialValue={initialValue}
      onSave={onSave}
      plugin={plugin}
      saveStrategy={initialValue ? 'both' : 'saveOnReturn'}
      selectAllContent={!!initialValue}
      singleLine
    >
      <TaskEditor
        ref={ref}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={!initialValue ? strings.taskSidebar.addNewSubtask : ''}
      />
    </RichEditorProvider>
  );
});

const TaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$12px',
  color: '$grey7',
});
