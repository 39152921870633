import { css, cx } from 'emotion';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

export default function ModalContainer({
  className = '',
  children = null,
  routeBack = false,
  closeModal = undefined,
  noModal = undefined,
  modalContainerHeader = undefined,
  zIndexPlus = 0,
}) {
  // FIXME: this component manages its state by:
  // a) keeping internal state
  // b) delegating its state to router
  // c) delegating its state to parent by using a callback
  // d) all of the above                                        <----
  const history = useHistory();
  const containerRef = useRef(null);
  const [showModal, setShowModal] = useState(true);
  const toggleModal = useCallback(
    (event) => {
      if (event) event.stopPropagation();
      if (routeBack && !closeModal) history.go(-1);
      if (closeModal) closeModal();
      setShowModal((modalShown) => !modalShown);
    },
    [closeModal, routeBack, history],
  );
  const handleOutsideClick = useCallback(
    (event) => {
      if (event.target.contains(containerRef.current)) toggleModal(event);
    },
    [toggleModal],
  );
  return useMemo(
    () => (
      <div
        className={cx(
          css`
            position: fixed;
            ${showModal ? 'display: show' : 'display: none'};
            overflow-y: auto;
            background: ${noModal ? 'none' : 'rgba(0, 0, 0, 0.6)'};
            z-index: ${noModal ? '0' : 999 + zIndexPlus};
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding-top: ${noModal ? '5rem' : '0rem'};
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
          `,
          className,
        )}
        onKeyDown={(event) => (event.keyCode === 13 ? event.stopPropagation() : null)}
        onMouseDown={!noModal ? handleOutsideClick : null}
        role='button'
        tabIndex={0}
      >
        {modalContainerHeader}
        {typeof children === 'function' ? children({ toggleModal, Ref: containerRef }) : children}
      </div>
    ),
    [showModal, noModal, className, handleOutsideClick, modalContainerHeader, children, toggleModal, zIndexPlus],
  );
}
