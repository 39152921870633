import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import TeamsLayout from 'components/app/layouts/TeamsLayout';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getOrgTeams,
  reduxStore,
  selectActiveWorkspace,
  selectAuth,
  selectProfile,
  updateTeamMembership,
} from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

/**
 * TeamsController
 */
export default function TeamsController(): JSX.Element {
  const { whenSuccess, whenError } = useToast();

  const { uid: profileId } = useSelector(selectAuth);
  const orgId = useSelector(selectActiveWorkspace);
  const orgTeamsSlice = getOrgTeams(orgId);
  const teams = useSelector(orgTeamsSlice.selector) || [];

  const profile = useSelector(selectProfile);
  const userTeamsIds = profile.teamIds[orgId];

  const onJoinTeam = useCallback(
    (teamId: Data.Id.TeamId, teamName: string): void => {
      reduxStore
        .dispatch(updateTeamMembership({ action: 'add', teamId, userIds: [profileId] }))
        .then(unwrapResult)
        .then(
          whenSuccess(
            strings.formatString(strings.joinTeam.success, {
              teamName,
            }) as string,
          ),
        )
        .catch(whenError(({ message }) => message));
    },
    [profileId, whenError, whenSuccess],
  );

  return <TeamsLayout onJoinTeamClick={onJoinTeam} orgId={orgId} teams={teams} userTeamsIds={userTeamsIds} />;
}
