import { unwrapResult } from '@reduxjs/toolkit';
import { Avatar, Box, checkboxSize, Fluid, HStack } from '@taraai/design-system';
import { TaskId } from '@taraai/types/src/data/id';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import { RichEditorHandle } from 'components/editor/RichEditor';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createTask, reduxStore, selectAuth, selectProfile } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

import { Editor } from './Editor';

type Props = {
  isCreating: boolean;
  onBlur: () => void;
  onFocus: () => void;
  taskId: TaskId;
};
export function CreateSubtask({ isCreating, onBlur, onFocus, taskId }: Props): JSX.Element {
  const { uid } = useSelector(selectAuth);
  const profile = useSelector(selectProfile);
  const editorRef = useRef<RichEditorHandle>(null);
  const { whenError } = useToast();

  useEffect(() => {
    if (isCreating) editorRef.current?.focus();
  }, [isCreating]);

  const handleCreate = (taskTitle: string): void => {
    if (taskTitle.length < 2) return;

    editorRef.current?.clear();

    const taskPartial = extractEffortLevel(extractMentions({ title: taskTitle }));

    const changeset = { assignee: uid, ...taskPartial, _relationships: { parent: taskId } };
    reduxStore.dispatch(createTask(changeset)).then(unwrapResult).catch(whenError(strings.task.failedToCreateTask));
  };

  return (
    <Box spaceTop='$4px'>
      <HStack alignY='center' space='$8px'>
        <Box border={isCreating ? '$focus' : '$grey5'} borderRadius='$2px' height={checkboxSize} width={checkboxSize} />
        <Fluid>
          <Editor ref={editorRef} onBlur={onBlur} onFocus={onFocus} onSave={handleCreate} />
        </Fluid>
        <Avatar title={profile.name} url={profile.avatarURL} />
      </HStack>
    </Box>
  );
}
