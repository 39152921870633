import { RichEditorPlugin } from 'components/editor/types';
import { ContentState, DraftHandleValue, EditorState } from 'draft-js';

const handleIncomingText = (text: string, editorState: EditorState, maxLength: number): DraftHandleValue =>
  editorState.getCurrentContent().getPlainText().length + text.length > maxLength ? 'handled' : 'not-handled';

export const createMaxLengthPlugin = (options: { maxLength: number }): RichEditorPlugin<() => ContentState> => ({
  handleBeforeInput: (chars, editorState) => handleIncomingText(chars, editorState, options.maxLength),
  handlePastedText: (text, html, editorState) => handleIncomingText(text, editorState, options.maxLength),
});
