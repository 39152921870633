import { unwrapResult } from '@reduxjs/toolkit';
import { Box, styled } from '@taraai/design-system';
import { Attachment, Data, Markdown, Timestamp, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import TaskCommentBox from 'components/app/controllers/TaskCommentBox';
import { TaskNameController } from 'components/app/controllers/TaskNameController';
import TaskModalHeader from 'components/app/controllers/views/TaskModalHeader';
import TaskNavigationCount from 'components/app/controllers/views/TaskNavigationCount';
import TaskRevision from 'components/app/controllers/views/TaskRevision';
import TaskRows from 'components/app/controllers/views/TaskRows';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import Modal from 'components/core/controllers/views/Modal';
import { getAllEntitiesData } from 'components/editor/entities';
import {
  composePlugins,
  createAttachmentPlugin,
  // TODO: enable when it's ready or moved to feature flag
  // createCommentsPlugin,
  createLabelsPlugin,
  createMentionPlugin,
  linkPlugin,
  markdownPlugin,
} from 'components/editor/plugins';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { RichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { RichEditorToolbar } from 'components/editor/Toolbar';
import { EditorAttachments } from 'components/EditorAttachments';
import { ContentState } from 'draft-js';
import { css } from 'emotion';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  addAttachment,
  createLabel,
  defaultLabels,
  getCustomLabels,
  getPathForTask,
  reduxStore,
  removeAttachment,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import { TaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { toDate, useToast } from 'tools';
import moment from 'tools/helpers/moment';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { reportError } from 'tools/libraries/stackdriver';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { GitTaskData } from './GitTaskData';

// TODO replace with comments loaded from firestore
// TODO: enable when it's ready or moved to feature flag
// const MOCK_COMMENTS = [
//   {
//     startBlockIndex: 0,
//     startOffset: 0,
//     endBlockIndex: 0,
//     endOffset: 5,
//     id: 'comment-1',
//   },
// ];

type Props = {
  author?: UI.UIUser;
  closeModal?: () => void;
  copyTaskId: () => void;
  currentOrg: Data.Id.OrganizationId;
  currentTaskNumber?: number;
  deleteCurrentTask: () => void;
  gitData: TaskGitData | undefined;
  navigationCategory?: string | null;
  noModal?: boolean;
  revisions: UI.UITaskRevision[];
  setTaskCopiedText: (value: boolean) => void;
  task: UI.UITask;
  taskComments?: UI.UIComment[];
  taskCopiedText: boolean;
  taskCount?: number;
};

/**
 * TaskModal
 * contains all sub components of the task drawer modal
 *
 */
export default function LegacyTaskModal({
  author,
  closeModal,
  copyTaskId,
  currentOrg,
  currentTaskNumber,
  deleteCurrentTask,
  gitData,
  navigationCategory,
  noModal,
  revisions,
  setTaskCopiedText,
  task,
  taskComments,
  taskCopiedText,
  taskCount,
}: Props): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const taskId = task.id;
  const getAllTaskLabels = useCallback(
    ({ title = task.title, description = task.description }: { title?: string; description?: string }) =>
      unique([...parseLabelsFromPlainText(title), ...parseLabelsFromPlainText(description)]),
    [task.description, task.title],
  );

  return (
    <Modal
      bodyStyle={css`
        padding: 0rem;
      `}
      className={css`
        width: 60rem;
        border: solid 0.0625rem #dee3ec;
      `}
      closeModal={closeModal}
      header={
        <TaskModalHeader
          closeModal={closeModal}
          copyTaskId={copyTaskId}
          currentOrg={currentOrg}
          deleteCurrentTask={deleteCurrentTask}
          requirementId={task.requirementDocument?.id}
          requirementTitle={task.requirementDocument?.title}
          setTaskCopiedText={setTaskCopiedText}
          taskCopiedText={taskCopiedText}
          taskId={task.id}
          taskService={task.externalIssue?.service}
        />
      }
      headerStyle={css`
        background-color: #fbfbfd;
        padding: 0.75rem 0.9375rem 0.75rem 1.5rem;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
      `}
      modalContainerHeader={
        navigationCategory ? (
          <TaskNavigationCount
            closeModal={closeModal}
            currentTaskNumber={currentTaskNumber}
            navigationCategory={navigationCategory}
            taskCount={taskCount}
          />
        ) : undefined
      }
      noExit
      noModal={noModal}
      superHeader={
        <div
          className={css`
            padding-bottom: 1rem;
            font-size: 0.75rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              display: flex;
            `}
          >
            {author && <Avatar size='medium' user={author} />}
            <div
              className={css`
                padding-left: 0.5rem;
                padding-top: 0.25rem;
                align-self: center;
              `}
            >
              <div
                className={css`
                  color: ${noModal ? '#303f4b' : '#ffffff'};
                `}
                data-cy={taskModalTestIds.AUTHOR_NAME}
              >
                {author &&
                  formatI18n(strings.task.createdByAuthor)({
                    author: author.name,
                  })}
              </div>
              <div
                className={css`
                  color: #9c9c9c;
                  opacity: 1;
                `}
                data-cy={taskModalTestIds.CREATION_DATE}
              >
                {moment(toDate(task.createdAt)).format('ll')}
              </div>
            </div>
          </div>
          <div
            className={css`
              display: flex;
              align-items: flex-end;
            `}
          >
            {!noModal && (
              <Icon
                className={css`
                  padding: 0px;
                  padding-right: 4px;
                  padding-bottom: 2px;
                `}
                name='history'
              />
            )}
            <div
              className={css`
                align-self: flex-end;
                color: ${noModal ? '#575f65' : '#ffffff'};
                font-style: italic;
                font-size: 12px;
                font-weight: 500;
                opacity: ${noModal ? '0.5' : '0.8'};
              `}
              data-cy={taskModalTestIds.UPDATE_DATE}
            >
              <span
                className={css`
                  text-decoration: underline;
                `}
              >
                {strings.task.edited}
              </span>
              <TaskUpdatedAt updatedAt={task.updatedAt} />
            </div>
          </div>
        </div>
      }
    >
      <div
        className={css`
          display: flex;
        `}
      >
        <div
          className={css`
            border-right: solid 0.0625rem #dee3ec;
            height: calc(100vh - 12rem);
            overflow: auto;
            width: 40rem;
          `}
        >
          <TaskNameController getAllTaskLabels={getAllTaskLabels} taskId={taskId} value={task.title} />
          <TaskDescription
            attachments={task.attachments ?? []}
            description={task.description}
            getAllTaskLabels={getAllTaskLabels}
            orgId={orgId}
            taskId={taskId}
          />
          <TaskRows task={task} />
          <GitTaskData data={gitData} taskId={taskId} />
          <TaskRevision revisions={revisions} />
        </div>
        <div
          className={css`
            height: calc(100vh - 12rem);
            width: 20rem;
          `}
        >
          <TaskCommentBox taskComments={taskComments} taskId={taskId} />
        </div>
      </div>
    </Modal>
  );
}

function TaskUpdatedAt({ updatedAt }: { updatedAt: Timestamp }): JSX.Element {
  const [now, setNow] = useState(moment());
  const { text, refreshIn } = useMemo(() => {
    const date = moment(toDate(updatedAt));
    // Prevent negative numbers when update happens
    const seconds = Math.max(now.diff(date, 'seconds'), 0);
    const minutes = now.diff(date, 'minutes');
    const hours = now.diff(date, 'hours');
    const days = now.diff(date, 'days');
    switch (true) {
      case minutes < 1:
        return {
          text: formatI18n(strings.task.lastEditedSeconds)({ seconds }),
          refreshIn: Math.max(seconds, 15) * 1000, // twice as many seconds, at least 15
        };
      case hours < 1:
        return {
          text: formatI18n(strings.task.lastEditedMinutes)({ minutes }),
          refreshIn: 60000, // 1m in ms
        };
      case days < 1:
        return {
          text: formatI18n(strings.task.lastEditedHours)({ hours }),
          refreshIn: 3600000, // 1h in ms
        };
      default:
        return {
          text: formatI18n(strings.task.lastEditedDays)({ days }),
          refreshIn: 86400000, // 1 day in ms
        };
    }
  }, [now, updatedAt]);

  useEffect(() => {
    const handle = setInterval(() => {
      setNow(moment());
    }, refreshIn);
    return () => clearInterval(handle);
  }, [refreshIn]);

  return <>{text}</>;
}

function TaskDescription({
  attachments,
  description,
  orgId,
  taskId,
  getAllTaskLabels,
}: {
  attachments: Attachment[];
  description: string;
  getAllTaskLabels: (overrides: { description: string }) => string[];
  orgId: string;
  taskId: string;
}): JSX.Element {
  const customLabels = useSelector(getCustomLabels(orgId).selector);
  const { addToast, removeToast } = useToast();
  const path = getPathForTask(taskId, orgId);

  const handleAttachmentUpload = useCallback(
    async (file: File): Promise<string> => {
      addToast({
        message: strings.attachments.uploading,
        type: 'loading',
        id: file.name,
      });
      return reduxStore
        .dispatch(addAttachment({ type: 'task', path, file }))
        .then(unwrapResult)
        .then((attachmentURL) => {
          removeToast(file.name);
          return attachmentURL;
        })
        .catch((error) => {
          reportError(error);
          removeToast(file.name);
          const message = strings
            .formatString(strings.attachments.uploadError, {
              errorMessage: error.message,
            })
            .toString();
          addToast({ message, type: 'error' });
          throw error;
        });
    },
    [addToast, removeToast, path],
  );

  const handleAttachmentRemove = useCallback(
    (attachment: Attachment): void => {
      reduxStore
        .dispatch(removeAttachment({ type: 'task', attachment, path }))
        .then(unwrapResult)
        .catch((error: Error) => {
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
        });
    },
    [addToast, path],
  );

  // Don't recreate the plugin every time labels change
  const labels = useRef() as MutableRefObject<UI.UILabel[]>;
  labels.current = [
    ...(Object.keys(defaultLabels) as Data.DefaultLabelId[]).map(selectDefaultLabel),
    ...(customLabels || []),
  ];

  const getUserTagForId = useUserTagForId(orgId);

  const plugin = useMemo(
    () =>
      composePlugins(
        markdownPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => labels.current,
        }),
        createMentionPlugin(getUserTagForId),
        linkPlugin,
        createAttachmentPlugin(addToast, handleAttachmentUpload),
        // TODO: enable when it's ready or moved to feature flag
        // createCommentsPlugin({
        //   getComments: () => MOCK_COMMENTS,
        // }),
      ),
    [addToast, getUserTagForId, handleAttachmentUpload],
  );

  const uploadMarkdown = useCallback(
    async (markdown: Markdown, content: ContentState) => {
      await reduxStore
        .dispatch(
          updateTask({
            description: markdown,
            id: taskId,
            mentionedUserIds: getAllEntitiesData('mention', content).map(({ id }) => id),
            labels: getAllTaskLabels({ description: markdown }),
          }),
        )
        .then(unwrapResult)
        .catch((error: Error) => {
          return addToast({ message: error.message, type: 'error' });
        });
    },
    [addToast, getAllTaskLabels, taskId],
  );

  const { trySave } = useForceSave(description, uploadMarkdown, getKeyForReconciliation);

  return (
    <>
      <RichEditorProvider initialValue={description} onSave={trySave} plugin={plugin}>
        <ToolbarWrapper space='$8px' spaceHorz='$16px'>
          <RichEditorToolbar onAttachmentUpload={handleAttachmentUpload} />
        </ToolbarWrapper>
        <Divider />
        <Box spaceHorz='$24px' spaceVert='$20px'>
          <RichEditor placeholder={strings.editor.taskDescriptionPlaceholder} />
        </Box>
      </RichEditorProvider>
      <EditorAttachments attachments={attachments} onRemove={handleAttachmentRemove} />
    </>
  );
}

const getKeyForReconciliation = (newDescription: string): string => newDescription;

const ToolbarWrapper = styled(Box, {
  'div[role="button"]': {
    height: 'fontSizes.$28px',
    width: 'fontSizes.$28px',
    padding: '$4px',
  },
});

const Divider = styled('div', {
  borderBottom: 'borderWidths.$1px solid colors.$grey4',
  height: '0.25rem',
});
