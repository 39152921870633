import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, isTaskStatus, StatusHistory, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { flattenNestedAttribute } from 'reduxStore/utils/firebaseHelpers';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';

interface UpdateTaskPayload extends UI.UITaskChangeset {
  orgId?: Data.Id.OrganizationId;
  meta?: {
    transaction?: Firestore['Transaction'];
  };
}

export const updateTask = createAsyncThunk(
  'UpdateTask',
  async ({ orgId: forceOrgId, meta, ...data }: UpdateTaskPayload, { getState, extra }) => {
    const state = getState() as RootState;
    const { getOrgId, getFirestore, getUserId } = extra as ExtraAPI;
    const orgId = forceOrgId ?? getOrgId();
    const userId = getUserId(state);
    const firestore = getFirestore();
    const tasksCollectionPath = `orgs/${orgId}/tasks`;

    function buildChangeset(change: UI.UITaskChangeset): UI.UITaskStrictChangeset {
      const { collaborators, ...rest } = change;
      const changeset: UI.UITaskStrictChangeset = {
        ...rest,
        updatedAt: firestore.Timestamp.now(),
        updatedBy: userId,
        lastUpdateVia: 'tara',
      };
      if (collaborators) {
        changeset.collaborators = collaborators;
      }
      return maybeAddStatusHistory(changeset);
    }

    function maybeAddStatusHistory(change: UI.UITaskStrictChangeset): UI.UITaskStrictChangeset {
      if (isTaskStatus(change.status)) {
        const statusHistory: StatusHistory = {
          lastUpdatedBy: change.updatedBy,
          updatedAt: firestore.Timestamp.now(),
          status: change.status,
        };
        return {
          ...change,
          statusHistory: firestore.FieldValue.arrayUnion(statusHistory),
        };
      }
      return change;
    }

    const decodedChangesWithId = decode<UI.UITaskStrictChangeset>(buildChangeset(data), 'UITaskStrictChangeset');

    const flattenChangesWithId = flattenNestedAttribute(decodedChangesWithId, '_relationships');

    const { id: taskId, ...flattenChanges } = flattenChangesWithId;

    if (meta?.transaction) {
      meta.transaction.update(firestore.doc(`orgs/${orgId}/tasks/${taskId}`), flattenChanges);
    } else {
      await firestore.mutate({
        doc: taskId as string,
        collection: tasksCollectionPath,
        data: flattenChanges,
      });
    }

    return decodedChangesWithId;
  },
);
