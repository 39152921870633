import { Box } from '@taraai/design-system';
import { Data } from '@taraai/types';
import DropdownOption from 'components/app/controllers/views/DropdownOption';
import { DropdownOption as DropdownOptionTypes } from 'components/core/controllers/DropdownController';
import React, { SyntheticEvent } from 'react';

export interface DropdownBodyProps extends React.HTMLProps<HTMLDivElement> {
  activeResult: number;
  onKeyDown: (event: React.SyntheticEvent) => void;
  onOptionClick: (id: string) => void;
  options: DropdownOptionTypes[];
  selectedIds: Data.Id.UserId[] | Data.Id.RequirementId[];
  typeaheadResults: DropdownOptionTypes[];
}

/**
 * DropdownBody
 * selectable list for dropdown
 *
 */
export default function DropdownBody({
  activeResult,
  onKeyDown,
  onOptionClick,
  options,
  selectedIds,
  typeaheadResults,
}: DropdownBodyProps): JSX.Element {
  return (
    <Box onClick={stopPropagation} spaceVert='$8px'>
      {options.length > 0 &&
        typeaheadResults.map((option) => (
          <DropdownOption
            key={option.id}
            highlighted={typeaheadResults[activeResult]?.id === option.id}
            isSelected={selectedIds.includes(option.id)}
            onClick={onOptionClick}
            onKeyDown={onKeyDown}
            option={option}
          />
        ))}
    </Box>
  );
}

function stopPropagation(event: SyntheticEvent): void {
  event.stopPropagation();
}
