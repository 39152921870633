"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = exports.RadioContext = void 0;
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var styled_1 = require("./core/styled");
var Fluid_1 = require("./Fluid");
var HStack_1 = require("./HStack");
exports.RadioContext = react_1.default.createContext({
    onToggle: function () { return undefined; },
    value: undefined,
});
function Radio(_a) {
    var value = _a.value, children = _a.children, _b = _a.activeColor, activeColor = _b === void 0 ? '$indigo' : _b, _c = _a.inactiveColor, inactiveColor = _c === void 0 ? '$grey6' : _c;
    var radioCtx = react_1.useContext(exports.RadioContext);
    var isSelected = value === radioCtx.value;
    return (react_1.default.createElement(Wrapper, { activeColor: activeColor, inactiveColor: inactiveColor, onClick: function () { return radioCtx.onToggle(value); }, selected: isSelected },
        react_1.default.createElement(HStack_1.HStack, { alignY: 'center', space: '$8px' },
            react_1.default.createElement(Indicator, { selected: isSelected }),
            react_1.default.createElement(Fluid_1.Fluid, null, children))));
}
exports.Radio = Radio;
var Wrapper = styled_1.styled(Box_1.Box.Button, {}, {
    activeColor: styled_1.mapTokens('colors', function (value) { return ({ '--activeColor': value }); }),
    inactiveColor: styled_1.mapTokens('colors', function (value) { return ({ '--inactiveColor': value }); }),
    selected: {
        true: { color: 'var(--activeColor)' },
        false: { color: 'var(--inactiveColor)' },
    },
});
var Indicator = styled_1.styled('div', {
    width: '10px',
    height: '10px',
    borderRadius: '$circle',
    borderWidth: '$1px',
    borderStyle: 'solid',
    borderColor: 'var(--inactiveColor)',
    transition: 'all 0.1s linear',
}, {
    selected: {
        true: {
            borderWidth: '$3px',
            borderColor: 'var(--activeColor)',
        },
    },
});
Radio.Group = function RadioGroup(props) {
    // we can't destructure props in function arguments because then we
    // lose distinction between unselectable and selectable versions of props
    var onToggle = function (newValue) {
        if (props.deselectable && newValue === props.value) {
            return props.onChange(undefined);
        }
        // Warning! This cast is not entirely safe since we can't guarantee that both
        // Radio and Radio.Group have the same `Value` type.
        // FIXME: consider making both of these components be accesible via hook that would bind those
        // two types together.
        return props.onChange(newValue);
    };
    return react_1.default.createElement(exports.RadioContext.Provider, { value: { onToggle: onToggle, value: props.value } }, props.children);
};
