/* eslint-disable @typescript-eslint/no-shadow */

import { getEntityRanges } from 'components/editor/entities';
import { zwsp } from 'components/editor/plugins/utils';
import { RichEditorPlugin } from 'components/editor/types';
import { ContentState, Modifier, SelectionState } from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import flow from 'lodash.flow';

export const markdownPlugin: RichEditorPlugin<(source: string) => ContentState> = {
  pipeline: {
    read: flow(stateFromMarkdown, replaceImageSpaceWithZwsp),
    save: stateToMarkdown,
  },
};

/**
 * Our current markdown plugin is using a space character for images, which generates a lot of problems.
 * Unfortunately it's not possible to pass any option that would change that, so we need some post-processing.
 */
function replaceImageSpaceWithZwsp(content: ContentState): ContentState {
  return content.getBlocksAsArray().reduce(
    (content, block) =>
      getEntityRanges('IMAGE', content, block).reduce(
        (content, [start, end]) =>
          Modifier.replaceText(
            content,
            SelectionState.createEmpty(block.getKey()).merge({
              anchorOffset: start,
              focusOffset: end,
            }) as SelectionState,
            zwsp,
            block.getInlineStyleAt(start),
            block.getEntityAt(start),
          ),
        content,
      ),
    content,
  );
}
