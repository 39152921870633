import { Box, Hidden, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { TaskId } from '@taraai/types/dist/data/id';
import { SectionWithLoader } from 'components/app/TaskDetails/common/Section';
import { ActionIcon } from 'components/app/TaskDetails/common/styles';
import React from 'react';
import { strings } from 'resources';

import { Comment } from './Comment';

type Props = {
  comments: UI.UIComment[] | undefined;
  taskId: TaskId;
  onClickViewModal: (taskId: Data.Id.TaskId) => void;
};

export const Comments = ({ comments, taskId, onClickViewModal }: Props): JSX.Element => {
  return (
    <SectionWithLoader
      content={(loadedComments) => {
        const showOnlyLastComment = loadedComments[0]?.description.length > 500;

        return (
          <Hidden hidden={!loadedComments.length}>
            <Box spaceTop='$12px'>
              {showOnlyLastComment ? (
                <LastComment comment={loadedComments[0]} />
              ) : (
                <AllComments comments={loadedComments} />
              )}
            </Box>
          </Hidden>
        );
      }}
      data={comments}
      right={
        <Hidden hidden={!comments}>
          <ActionIcon
            name={comments?.length ? 'popup' : 'bluePlus'}
            noPadding
            onClick={() => onClickViewModal(taskId)}
          />
        </Hidden>
      }
      title={strings.taskSidebar.modules.comments}
    />
  );
};

const LastComment = ({ comment }: { comment: UI.UIComment }): JSX.Element => (
  <Comment avatarUrl={comment.authorIdDocument?.avatarURL || ''} description={comment.description} limit={500} />
);

const AllComments = ({ comments }: { comments: UI.UIComment[] }): JSX.Element => (
  <VStack space='$4px'>
    {comments.map(({ authorIdDocument, description, id }) => (
      <Comment key={id} avatarUrl={authorIdDocument?.avatarURL || ''} description={description} />
    ))}
  </VStack>
);
