import { Box, Fluid, getCustomSize, HStack, styled, VStack } from '@taraai/design-system';
import { Attachment, Data, TaskStatus } from '@taraai/types';
import { CopyShare } from 'components/app/controllers/CopyShare';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { RichEditorToolbar } from 'components/editor/Toolbar';
import { RichEditorPlugin } from 'components/editor/types';
import { EditorAttachments } from 'components/EditorAttachments';
import { ContentState } from 'draft-js';
import React, { ReactNode, RefObject } from 'react';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

type Props = {
  attachments: Attachment[];
  description: string;
  descriptionPlugin: Required<RichEditorPlugin<(source: string) => ContentState>>;
  descriptionRef?: RefObject<RichEditorHandle>;
  handleAttachmentRemove: (attachment: Attachment) => void;
  handleAttachmentUpload: (file: File) => Promise<string>;
  id: Data.Id.RequirementId | Data.Id.TaskId;
  orgId: Data.Id.OrganizationId;
  status?: TaskStatus;
  SubTitle?: ReactNode;
  title: string;
  titlePlugin: Required<RichEditorPlugin<(source: string) => ContentState>>;
  trySaveDescription: (newDescription: string, content: ContentState) => Promise<void>;
  trySaveTitle: (newTitle: string) => Promise<void>;
  type: 'requirement' | 'tasks';
};

export function DefinePanel({
  attachments,
  description,
  descriptionPlugin,
  descriptionRef,
  handleAttachmentRemove,
  handleAttachmentUpload,
  id,
  orgId,
  status,
  SubTitle,
  title,
  titlePlugin,
  trySaveDescription,
  trySaveTitle,
  type,
}: Props): JSX.Element | null {
  const defineTask = type === 'tasks';
  const mainTitle = defineTask ? `TASK-${id}` : title;

  return (
    <RichEditorProvider
      // Add a key so that the editor will reload when the editee changes
      key={`${type}-${id}`}
      initialValue={description}
      onSave={trySaveDescription}
      plugin={descriptionPlugin}
    >
      <Box full width={editorWidth}>
        <VStack full>
          <ToolbarWrapperBox
            background='$white'
            borderTopRadius='$4px'
            shadow='$navbar'
            spaceHorz='$12px'
            spaceVert='$8px'
          >
            <HStack alignY='center'>
              <RichEditorToolbar onAttachmentUpload={handleAttachmentUpload} />
              <Fluid />
              <CopyShare id={id} orgId={orgId} type={type} />
            </HStack>
          </ToolbarWrapperBox>
          <Fluid>
            <Box background='$white' style={{ overflow: 'auto' }}>
              <VStack full>
                <Fader data-testid='fader' />
                <MainTitleWrapper status={status}>
                  <RichEditorProvider
                    initialValue={mainTitle}
                    onSave={trySaveTitle}
                    plugin={titlePlugin}
                    readOnly={defineTask}
                  >
                    <MainTitle />
                  </RichEditorProvider>
                </MainTitleWrapper>
                {SubTitle}
                <Fluid>
                  <DescriptionEditorWrapper spaceTop='$12px'>
                    <DescriptionEditor ref={descriptionRef} />
                  </DescriptionEditorWrapper>
                </Fluid>
              </VStack>
            </Box>
          </Fluid>
          {attachments.length ? (
            <AttachmentsBox background='$white'>
              <EditorAttachments attachments={attachments} onRemove={handleAttachmentRemove} />
            </AttachmentsBox>
          ) : null}
        </VStack>
      </Box>
    </RichEditorProvider>
  );
}

const editorWidth = getCustomSize(720);

const zIndexes = { description: 0, fader: 1, title: 2, toolbar: 3 };

const ToolbarWrapperBox = styled(Box, {
  zIndex: zIndexes.toolbar,
});

const Fader = styled('div', {
  'height': 0,
  'position': 'sticky',
  'top': 0,
  'zIndex': zIndexes.fader,

  '&:before': {
    background: 'linear-gradient(180deg, #fff 42%, transparent)',
    content: '""',
    height: 64,
    left: 0,
    // Prevent mouse event on the overlapping area
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const MainTitleWrapper = styled(
  'div',
  {
    '--bottomPadding': 'space.$12px',
    '--topPadding': 'space.$16px',

    'position': 'relative',
    'zIndex': zIndexes.title,

    '&:before': {
      borderLeft: 'space.$4px solid var(--editorColor)',
      bottom: 'var(--bottomPadding)',
      content: '""',
      position: 'absolute',
      top: 'var(--topPadding)',
    },
  },
  {
    status: {
      [TaskStatus.Todo]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.Todo]}` },
      [TaskStatus.Doing]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.Doing]}` },
      [TaskStatus.Done]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.Done]}` },
      undefined: { '--editorColor': 'colors.$indigo' },
    },
  },
);

const MainTitle = getStyledRichEditor({
  color: 'var(--editorColor)',
  fontSize: '$18px',
  fontWeight: '$medium',
  lineHeight: '$18px',
  padding: 'var(--topPadding) 24px var(--bottomPadding)',
});

const DescriptionEditorWrapper = styled(Box, {
  'zIndex': zIndexes.description,

  // We need to permeate all of the Draft.js layers so that we reach the editable content
  '&, > div, > div > div, > div > div > div, > div > div > div > div': {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
  },
});

const DescriptionEditor = getStyledRichEditor({
  padding: '0 24px 32px',
});

const AttachmentsBox = styled(Box, {
  padding: '12px 24px',
});
