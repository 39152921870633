import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export const getImports = (
  orgId: Data.Id.OrganizationId,
  service: Data.ImportableService,
  author?: Data.Id.UserId,
): Query<UI.UIImport> => {
  if (!isNonEmptyString(orgId) || !author) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/imports`,
    where: [
      ['author', '==', author],
      ['service', '==', service],
    ],
    storeAs: `imports/${orgId}/${service}/service`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIImport>(query),
  };
};

export const getOrgGithubProducts = (orgId: Data.Id.OrganizationId): Query<UI.UIProduct> => {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/products`,
    where: [
      ['externalService.service', '==', 'github'],
      ['archived', '==', false],
      ['deleted', '==', false],
    ],
    storeAs: `imports/${orgId}/github-products`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIProduct>(query),
  };
};
