import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxStore } from 'reduxStore/store';
import { selectProfile } from 'reduxStore/users';
import { UnwrappedResult } from 'reduxStore/utils';

import { connectToOrganization } from './actions';

const hasNotStarted: UnwrappedResult<{ organizationId: string }> = {
  pending: false,
  error: null,
  data: null,
};

const pending: UnwrappedResult<{ organizationId: string }> = {
  pending: true,
  error: null,
  data: null,
};

export const useConnectToOrganization = (): [
  UnwrappedResult<{ organizationId: string }>,
  (invitationToken: string) => Promise<void>,
] => {
  const token = useSelector(compose((data) => data?.refreshToken, selectProfile));
  const claims = useSelector(compose((data) => data?.token?.claims, selectProfile));
  const [joiningOrganizationResult, setJoiningOrganizationResult] = useState<
    UnwrappedResult<{ organizationId: string }>
  >();
  const [refreshToken] = useState(token);

  const loadingFn = useCallback(
    async (invitationToken: string): Promise<void> => {
      if (joiningOrganizationResult) {
        return Promise.resolve();
      }
      setJoiningOrganizationResult(pending);
      const payload: Functions.ConnectToOrganization.ConnectToOrganizationPayload = {
        type: 'JoinExistingOrganization',
        invitationToken,
      };
      return reduxStore
        .dispatch(connectToOrganization(payload))
        .then(unwrapResult)
        .then((result) => {
          setJoiningOrganizationResult({
            pending: false,
            error: null,
            data: result,
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          setJoiningOrganizationResult({
            pending: false,
            error,
            data: null,
          });
        });
    },
    [joiningOrganizationResult],
  );

  if (!joiningOrganizationResult) {
    // joiningOrganization hasn't started yet
    return [hasNotStarted, loadingFn];
  }

  // refresh token is a way to notify FE that custom-claims had changed
  // http://react-redux-firebase.com/docs/auth.html#custom-claims
  const refreshTokenIsUpdated = token !== refreshToken;

  const userClaimsAreUpdated =
    joiningOrganizationResult.data &&
    refreshTokenIsUpdated &&
    claims?.orgs[joiningOrganizationResult.data.organizationId];

  const needToWaitForUserOrganizationIdsUpdate = !userClaimsAreUpdated;
  if (needToWaitForUserOrganizationIdsUpdate) {
    return [pending, loadingFn];
  }

  return [joiningOrganizationResult, loadingFn];
};
