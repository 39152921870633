import { createAsyncThunk } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { flattenNestedAttribute } from 'reduxStore/utils/firebaseHelpers';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';

export const updateUser = createAsyncThunk(
  'UpdateUser',
  async ({ ...dataToSet }: UI.UIUserChangeset, { extra, getState }) => {
    const { getUserId, getFirestore } = extra as ExtraAPI;
    const userId = getUserId(getState() as RootState);
    if (!userId) throw strings.error.user.noId;

    const firestore = getFirestore();
    const path = `users/${userId}`;

    const decodedChangesWithId = decode<UI.UIUserStrictChangeset>({ ...dataToSet }, 'UIUserPartial');

    const flattenChangesWithId = flattenNestedAttribute(decodedChangesWithId, 'tutorialCards');

    const { id, ...flattenChanges } = flattenChangesWithId;

    await firestore.update(path, flattenChanges);

    return { id: userId, ...flattenChanges };
  },
);
