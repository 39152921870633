import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Box, Fluid, Hidden, HStack, styled, Text, VStack } from '@taraai/design-system';
import { Data, Timestamp, UI } from '@taraai/types';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { SidePanelTeamDropdown } from 'components/app/DefineSidePanel/SidePanelTeamDropdown';
import { TaskCreation } from 'components/app/TaskCreation';
import { getStyledRichEditor } from 'components/editor/RichEditor';
import { linkTo } from 'components/Router/paths';
import React, { useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  archiveRequirement,
  getRequirement,
  reduxStore,
  restoreArchivedRequirement,
  selectRequirementAssignedTeams,
  updateRequirement,
  UpdateRequirementTeamAction,
} from 'reduxStore';
import { strings } from 'resources';
import { formatFromNow, useToast } from 'tools';

type TeamFragment = Pick<UI.UITeam, 'id'>;
type DefineSidePanelRequirementProps = {
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  requirementId: Data.Id.RequirementId;
};

export const RequirementDetailsController = React.memo(function RequirementDetailsController({
  orgId,
  teamId,
  requirementId,
}: DefineSidePanelRequirementProps): JSX.Element | null {
  const { whenError, whenSuccess } = useToast();
  const requirementSlice = getRequirement(orgId, requirementId);
  useFirestoreConnect(requirementSlice.query);
  const requirementFragment = useSelector(
    compose(
      (requirement) =>
        requirement && {
          id: requirement.id,
          authorName: { name: requirement.authorDocument?.name },
          title: requirement.title,
          archived: requirement.archived,
          updatedAt: requirement.updatedAt,
        },
      requirementSlice.selector,
    ),
    deepEquals,
  );

  const assignedTeams = useSelector(
    compose(
      (teams: UI.UITeam[]) => teams.map(({ id, name }) => ({ id, name })),
      selectRequirementAssignedTeams(orgId, requirementId),
    ),
    deepEquals,
  );

  const manageRequirementTeam = (action: UpdateRequirementTeamAction) => ({ id: updateTeamId }: TeamFragment) => {
    reduxStore
      .dispatch(updateRequirement({ id: requirementId, [action]: [updateTeamId] }))
      .then(unwrapResult)
      .catch(
        whenError(
          action === 'appendTeamIds'
            ? strings.requirements.failedToAssignTeam
            : strings.requirements.failedToRemoveTeam,
        ),
      );
  };

  const archive = (): void => {
    reduxStore
      .dispatch(archiveRequirement({ id: requirementFragment?.id || '' }))
      .then(unwrapResult)
      .then(
        whenSuccess(
          strings
            .formatString(strings.requirements.archivedSuccess, {
              name: requirementFragment?.title || '',
            })
            .toString(),
        ),
      )
      .catch(
        whenError((error) =>
          strings
            .formatString(strings.requirements.archivedFailure, {
              errorMessage: error.message,
            })
            .toString(),
        ),
      );
  };

  const restore = (): void => {
    reduxStore
      .dispatch(restoreArchivedRequirement({ id: requirementFragment?.id || '' }))
      .then(unwrapResult)
      .then(
        whenSuccess(
          strings
            .formatString(strings.requirements.restoreArchivedSuccess, {
              name: requirementFragment?.title || '',
            })
            .toString(),
        ),
      )
      .catch(
        whenError((error) =>
          strings
            .formatString(strings.requirements.restoreArchivedFailure, {
              errorMessage: error.message,
            })
            .toString(),
        ),
      );
  };

  const uploadTitle = (newTitle: string): void => {
    reduxStore
      .dispatch(updateRequirement({ title: newTitle, id: requirementId }))
      .then(unwrapResult)
      .catch(
        whenError((error) =>
          strings
            .formatString(strings.requirements.failedToUpdateTitle, {
              errorMessage: error.message,
            })
            .toString(),
        ),
      );
  };

  if (!requirementFragment) return null;

  return (
    <RequirementSidePanel
      archive={archive}
      archived={requirementFragment.archived}
      assignedTeams={assignedTeams}
      authorName={requirementFragment.authorName.name}
      manageRequirementTeam={manageRequirementTeam}
      orgId={orgId}
      requirementId={requirementFragment.id}
      restore={restore}
      teamId={teamId}
      title={requirementFragment.title}
      updatedAt={requirementFragment.updatedAt.seconds}
      uploadTitle={uploadTitle}
    />
  );
});

type Props = {
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  archived: boolean;
  authorName?: string;
  requirementId: string;
  title: string;
  updatedAt: number;
  assignedTeams: Pick<UI.UITeam, 'id' | 'name'>[];
  manageRequirementTeam: (action: UpdateRequirementTeamAction) => (teamId: Pick<UI.UITeam, 'id' | 'name'>) => void;
  archive: () => void;
  restore: () => void;
  uploadTitle: (newTitle: string) => void;
};

export function RequirementSidePanel({
  orgId,
  teamId,
  authorName,
  archived,
  requirementId,
  title,
  updatedAt,
  assignedTeams,
  manageRequirementTeam,
  archive,
  restore,
  uploadTitle,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleTextClick = (): void => {
    setIsEditing(true);
    setShowOptions(true);
  };

  const onAnimationEnd = (): void => {
    if (!showOptions) setIsEditing(false);
  };

  const handleTitleChange = (newTitle: string): void => {
    uploadTitle(newTitle);
    setShowOptions(false);
  };

  return (
    <Box background='$white' borderLeft='$grey4' height='$full'>
      <VStack space='$1px'>
        <Box borderBottom='$grey2' spaceRight='$8px' spaceVert='$12px'>
          <VStack space='$12px'>
            <Header
              assignedTeams={assignedTeams}
              manageRequirementTeam={manageRequirementTeam}
              orgId={orgId}
              requirementId={requirementId}
              teamId={teamId}
            />

            <Box spaceHorz='$12px'>
              <Hidden hidden={!isEditing} strategy='remove'>
                <TaskCreation
                  initialValue={title}
                  onEnter={handleTitleChange}
                  optionsAnimation={{ onAnimationEnd, show: showOptions }}
                >
                  <CreateTaskEditor placeholder={strings.workDrawer.createTask} saveOnBlur />
                </TaskCreation>
              </Hidden>
              <Hidden hidden={isEditing}>
                <Text color='$dark' onDoubleClick={handleTextClick} size='$12px'>
                  <SmartText text={title} />
                </Text>
              </Hidden>
            </Box>

            <Box spaceHorz='$12px' spaceVert='$none'>
              <Text size='$12px' weight='regular'>
                {strings.formatString(strings.builder.header.updateStatus, {
                  author: authorName ?? '',
                  date: formatFromNow({ seconds: updatedAt } as Timestamp),
                })}
              </Text>
            </Box>
          </VStack>
        </Box>
        <Box spaceHorz='$12px' spaceVert='$8px'>
          <Box.Button onClick={archived ? restore : archive}>
            <ActionText color='$focus' size='$12px'>
              {archived ? strings.requirements.restore : strings.requirements.archive}
            </ActionText>
          </Box.Button>
        </Box>
      </VStack>
    </Box>
  );
}

type HeaderProps = {
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  requirementId: string;
  assignedTeams: Pick<UI.UITeam, 'id' | 'name'>[];
  manageRequirementTeam: (action: UpdateRequirementTeamAction) => (teamId: Pick<UI.UITeam, 'id' | 'name'>) => void;
};

function Header({ requirementId, assignedTeams, manageRequirementTeam, orgId, teamId }: HeaderProps): JSX.Element {
  return (
    <HStack>
      <Box borderLeft={['$grey7', '$2px']} height='$16px' width='$12px' />
      <Fluid>
        <Box>
          <HStack>
            <Fluid>
              <Box spaceRight='$8px'>
                <Text color='$grey7' size='$12px' weight='medium'>
                  {strings.requirements.sidebarTitle}
                </Text>
              </Box>
            </Fluid>
            <HStack>
              <SidePanelTeamDropdown
                assignedTeams={assignedTeams}
                linkToCreateTeam={linkTo('requirementCreateTeam', {
                  orgId,
                  teamId,
                  requirementId,
                })}
                onDeselectOption={manageRequirementTeam('removeTeamIds')}
                onSelectOption={manageRequirementTeam('appendTeamIds')}
              />
            </HStack>
          </HStack>
        </Box>
      </Fluid>
    </HStack>
  );
}

const CreateTaskEditor = getStyledRichEditor({
  color: '$dark',
  fontSize: '$12px',
  lineHeight: '$12px',
  caretColor: 'colors.$focus',
});

const ActionText = styled(Text, {
  ':hover': { textDecoration: 'underline' },
});
