import { Data, TaskStatus, UI } from '@taraai/types';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { selectActiveTeam } from 'reduxStore';
import { requirementBacklogTasks } from 'reduxStore/tasks/queries';
import { strings } from 'resources/i18n';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'archived'>;
export interface BacklogProductHeaderProps {
  getRequirements: () => void;
  orgId: Data.Id.OrganizationId;
  requirement: RequirementFragment;
}

/**
 * BacklogProductHeader is a legacy name. The correct one would be BacklogRequirementHeader.
 */
export default function BacklogProductHeader({
  getRequirements,
  orgId,
  requirement,
}: BacklogProductHeaderProps): JSX.Element {
  const teamId = useSelector(selectActiveTeam);
  const tasksSlice = requirementBacklogTasks(orgId, requirement.id);

  useFirestoreConnect(tasksSlice.query);

  const taskCount = useSelector(
    compose(
      (tasks) => tasks?.filter((task: UI.UITask): boolean => task.status !== TaskStatus.Done).length,
      tasksSlice.selector,
    ),
    deepEquals,
  );

  const handleClick = (): void => {
    getRequirements();
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <Icon
          className={css`
            cursor: pointer;
            color: #949caf;
          `}
          name='sprintleft'
          onClick={handleClick}
        />
        <Avatar
          key={requirement.id}
          className={css`
            margin-right: 0.5rem;
          `}
          requirement={requirement}
          size='medium'
        />
        <Text
          className={css`
            padding-top: 0.1rem;
            font-size: 1rem;
            font-weight: normal;
            color: #303f4b;
          `}
        >
          {requirement?.title}
          <div
            className={css`
              font-size: 0.875rem;
              font-weight: normal;
              color: #949caf;
              padding-top: 0.25rem;
            `}
          >
            {taskCount &&
              !isNaN(taskCount) &&
              strings.formatString(strings.sprints.taskCount, {
                number: taskCount,
              })}
          </div>
        </Text>
      </div>
      <Link
        className={css`
          padding-right: 0.5rem;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          width: 20%;
          align-items: flex-end;
        `}
        to={linkTo('requirement', {
          orgId,
          requirementId: requirement.id,
          teamId,
        })}
      >
        <Text
          className={css`
            padding-top: 0.1rem;
            font-size: 0.875rem;
            color: #1d98ff;
            font-weight: normal;
          `}
          secondary
        >
          {strings.sprints.view}
        </Text>
      </Link>
    </div>
  );
}
