import OnboardingInput from 'components/app/controllers/views/OnboardingInput';
import React from 'react';
import { onboardingTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { InputProps } from 'tools';

type WorkspaceNameInputProps = InputProps & {
  showAsterisk?: boolean;
};

export function WorkspaceNameInput({
  showAsterisk = false,
  ...organizationNameInputProps
}: WorkspaceNameInputProps): JSX.Element {
  return (
    <OnboardingInput
      data-cy={onboardingTestIds.ORGANIZATION_NAME_INPUT}
      label={strings.createWorkspace.labels.workspaceName}
      placeholder={strings.createWorkspace.placeholders.workspaceName}
      showAsterisk={showAsterisk}
      {...organizationNameInputProps}
    />
  );
}
