import { Box } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { RequirementDetailsController } from 'components/app/RequirementDetails/RequirementDetails';
import { TaskDetails } from 'components/app/TaskDetails/TaskDetails';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';

export function DefineSidePanelController({
  requirementId,
  taskId,
}: {
  requirementId: Data.Id.RequirementId;
  taskId: Data.Id.TaskId;
}): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  if (!taskId && !requirementId) return <Container />;

  return (
    <Container>
      {requirementId && <RequirementDetailsController orgId={orgId} requirementId={requirementId} teamId={teamId} />}
      {taskId && <TaskDetails orgId={orgId} taskId={taskId} teamId={teamId} />}
    </Container>
  );
}

function Container({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <Box background='$grey2' height='$full'>
      {children}
    </Box>
  );
}
