import { Box, styled, tokens } from '@taraai/design-system';
import React, { useState } from 'react';

type Props = {
  onClick: () => void;
};

export function AddSeparator({ onClick }: Props): JSX.Element {
  const [show, setShow] = useState(false);

  return (
    <WrapperBoxButton
      background='$indigo'
      height='$1px'
      onClick={onClick}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      show={show}
    >
      <FloatingCenter full>
        <Box height='$24px' />
      </FloatingCenter>
      <FloatingCenter>{plus}</FloatingCenter>
    </WrapperBoxButton>
  );
}

const WrapperBoxButton = styled(
  Box.Button,
  {
    zIndex: 1,
    transition: 'opacity 0.2s ease',
    opacity: 0,
  },
  {
    show: { true: { opacity: 1, transitionDelay: '0.2s' } },
  },
);

const FloatingCenter = styled(
  'div',
  {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  {
    full: { true: { width: '100%' } },
  },
);

const plus = (
  <svg fill='none' height='13' viewBox='0 0 18 13' width='18' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 6.5L7.24353 1.26856C8.29207 0.51128 9.70793 0.51128 10.7565 1.26856L18 6.5L10.7565 11.7314C9.70793 12.4887 8.29207 12.4887 7.24353 11.7314L0 6.5Z'
      fill={tokens.colors.$indigo}
    />
    <path d='M9 3.5V9.5' stroke='white' strokeLinecap='round' />
    <path d='M6 6.5H12' stroke='white' strokeLinecap='round' />
  </svg>
);
