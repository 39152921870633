import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export function getOpenPullRequests(orgId: string): Query<UI.UIPullRequest> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/pull-requests`,
    where: [['state', '==', 'open']],
    orderBy: [['createdAt', 'asc']],
    storeAs: `pullrequests/${orgId}/open`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIPullRequest>(query),
  };
}
