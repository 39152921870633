"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.minPopupWidth = exports.Popup = void 0;
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var RelativePositioning_1 = require("./common/RelativePositioning");
var SuperContext_1 = require("./common/SuperContext");
var useClickOutside_1 = require("./common/useClickOutside");
var core_1 = require("./core");
exports.Popup = SuperContext_1.withContentRefList(function Popup(_a) {
    var children = _a.children, content = _a.content, onHide = _a.onHide, placement = _a.placement, show = _a.show;
    var childRef = react_1.useRef(null);
    var contentRef = react_1.useRef(null);
    var _b = react_1.useContext(SuperContext_1.ContentRefListContext), contentRefs = _b.contentRefs, addContentRef = _b.addContentRef, removeContentRef = _b.removeContentRef;
    useClickOutside_1.useClickOutside.apply(void 0, __spreadArrays([show, onHide, childRef], contentRefs));
    react_1.useImperativeHandle(children.ref, function () { return childRef.current; }, []);
    react_1.useLayoutEffect(function () {
        addContentRef(contentRef);
        return function () { return removeContentRef(contentRef); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (react_1.default.createElement(RelativePositioning_1.RelativePositioning, { ref: contentRef, content: content, offset: 8, placement: placement, show: show }, react_1.cloneElement(children, { ref: childRef })));
});
exports.Popup.Content = function PopupContent(_a) {
    var children = _a.children, _b = _a.small, small = _b === void 0 ? false : _b;
    return (react_1.default.createElement(Box_1.Box, { background: '$white', border: '$grey5', borderRadius: '$4px', maxWidth: small ? exports.minPopupWidth : undefined, minWidth: small ? undefined : exports.minPopupWidth, role: 'dialog', shadow: '$popup', space: '$16px', spaceTop: '$12px' }, children));
};
exports.minPopupWidth = core_1.getCustomSize(250);
