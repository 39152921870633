import { Selector } from '@reduxjs/toolkit';
import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { createIndividualSelector, IndividualQuery, inertIndividualQuery } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export type SprintFragment = Pick<UISprint, 'id' | 'completedAt' | 'computedOnCompletion' | 'isComplete'>;
export type SprintSelector = Selector<RootState, UISprint | SprintFragment | undefined>;
export type SprintListSelector = Selector<RootState, UISprint[] | undefined>;

export function getSprint(orgId: string, sprintId: string): IndividualQuery<UISprint> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(sprintId)) {
    return inertIndividualQuery();
  }
  const querySprint: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/sprints`,
    where: [['__name__', '==', sprintId]],
    storeAs: `sprint/${orgId}/sprint-${sprintId}`,
  };

  return {
    query: [querySprint],
    selector: createIndividualSelector<UISprint>(querySprint),
  };
}
