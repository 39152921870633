import styled from '@emotion/styled';
import Button from 'components/core/controllers/views/Button';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import Text from 'components/core/controllers/views/Text';
import React from 'react';
import { atomic } from 'resources';
import { workspaceSetupTestIds } from 'resources/cypress/testAttributesValues';

export const Wrapper = styled.div`
  padding: 1rem 0;
`;

export const Description = styled.div`
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    margin-bottom: 3.5rem;
    text-align: left;
  }
`;

export const Header = styled((props) => <Text h1 {...props} />)`
  color: ${atomic.get(atomic.colors.dark)};
  font-weight: ${atomic.get(atomic.fonts.weight.semibold)};
`;

export const SubHeader = styled((props) => <Text h2 {...props} />)`
  margin-top: 1rem;
  color: ${atomic.get(atomic.colors.grey6)};
  font-weight: ${atomic.get(atomic.fonts.weight.medium)};
  line-height: 1.33;
`;

export const NextButton = styled((props) => (
  <Button color='gradient' data-cy={workspaceSetupTestIds.SECOND_STEP_NEXT_BUTTON} {...props} />
))`
  width: 100%;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    width: 11.1875rem;
  }
`;

export const SkipButton = styled((props) => (
  <SpinnerButton color='ghost' data-cy={workspaceSetupTestIds.SECOND_STEP_SKIP_BUTTON} name='skip' {...props} />
))`
  border: 0;
  min-width: 0;
`;
