import { Data, Functions } from '@taraai/types';
import { hasImportFinished } from 'components/app/controllers/WorkDrawerImportToastController/guards';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { reduxStore, RootState, selectActiveWorkspace } from 'reduxStore';
import { hideBacklogImportIndicator } from 'reduxStore/imports/actions';
import { getImportedTasks } from 'reduxStore/imports/queries/imported-tasks';
import { getImport } from 'reduxStore/imports/queries/imports';

interface ImportBacklogIndicatorProps {
  service: Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'];
  showImportedTasks: () => void;

  importInProgressIndicator: (props: { onClick: () => void; importDoc: Data.Import }) => JSX.Element;

  tasksImportedIndicator: JSX.Element;
  importId: Data.Id.ImportId;
}

function ImportBacklogIndicator({
  importId,
  service,
  showImportedTasks,
  importInProgressIndicator: ImportInProgressIndicator,
  tasksImportedIndicator,
}: ImportBacklogIndicatorProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);

  const importSlice = getImport(orgId, importId);

  useFirestoreConnect(importSlice.query);
  const importDoc = useSelector(importSlice.selector);

  useEffect(() => {
    if (importDoc && hasImportFinished(importDoc)) {
      reduxStore.dispatch(hideBacklogImportIndicator(service));
    }
  }, [importDoc, service]);

  if (!importDoc) {
    return tasksImportedIndicator;
  }

  return <ImportInProgressIndicator importDoc={importDoc} onClick={showImportedTasks} />;
}

type ImportBacklogIndicatorControllerProps = Omit<
  ImportBacklogIndicatorProps,
  'importId' | 'tasksImportedIndicator'
> & {
  tasksImportedIndicator: (props: { onClick: () => void; taskCount: number }) => JSX.Element | null;
};

export function ImportBacklogIndicatorController({
  service,
  showImportedTasks,
  tasksImportedIndicator: TasksImportedIndicator,
  ...indicatorProps
}: ImportBacklogIndicatorControllerProps): JSX.Element | null {
  const importId = useSelector((state: RootState) => state.imports.backlogImportIndicators[service]);

  const orgId = useSelector(selectActiveWorkspace);

  const importedTasksSlice = getImportedTasks(orgId, service);

  useFirestoreConnect(importedTasksSlice.query);
  const importedTasks = useSelector(importedTasksSlice.selector) || [];
  const taskCount = importedTasks.length;

  const taskImportedIndicator = <TasksImportedIndicator onClick={showImportedTasks} taskCount={taskCount} />;

  if (!importId) {
    return taskImportedIndicator;
  }

  return (
    <ImportBacklogIndicator
      importId={importId}
      service={service}
      showImportedTasks={showImportedTasks}
      tasksImportedIndicator={taskImportedIndicator}
      {...indicatorProps}
    />
  );
}
