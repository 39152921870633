import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import DraggableFeatureCardList from 'components/app/controllers/DraggableFeatureCardList';
import LegacyTaskModalContext from 'components/app/controllers/LegacyTaskModalContext';
import SprintColumnHeader from 'components/app/controllers/SprintColumnHeader';
import SprintEffort from 'components/app/controllers/SprintEffort';
import CreateTaskInput from 'components/app/controllers/views/CreateTaskInput';
import EmptySprintColumn from 'components/app/controllers/views/EmptySprintColumn';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { MenuOption } from 'components/core/controllers/views/Menu/menuTypes';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import { History } from 'history';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { Link, useHistory } from 'react-router-dom';
import {
  deleteSprint,
  getSprintTasks,
  moveSprintTask,
  orderedDocuments,
  reduxStore,
  selectActiveTeam,
  selectActiveWorkspace,
  selectTeam,
} from 'reduxStore';
import { atomic } from 'resources';
import { sprintsTabTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { CustomSorts, sort } from 'tools/libraries/helpers/sort';

type SprintFragment = Pick<
  UI.UISprint,
  | 'id'
  | 'sprintName'
  | 'startDate'
  | 'endDate'
  | 'completedAt'
  | 'computedOnCompletion'
  | 'isComplete'
  | 'orderedTaskIds'
>;

export interface SprintProps extends React.HTMLProps<HTMLDivElement> {
  sprint: SprintFragment;
  isActive: boolean;
  isComplete: boolean;
  currentSprintId: Data.Id.SprintId | undefined;
  isFirstSprint?: boolean;
}

const getMenuOptions = (
  history: History,
  orgId: Data.Id.OrganizationId,
  teamId: Data.Id.TeamId,
  isActive: boolean,
  sprint: SprintFragment | undefined,
  isComplete: boolean,
  deleteSelectedSprint: () => void,
): MenuOption[] => {
  if (isActive || isComplete)
    return [
      {
        title: strings.sprints.featureCard.view,
        onSelect: (): void => {
          history.push(
            linkTo('sprintDetails', {
              orgId,
              teamId,
              sprintId: sprint?.id ?? '',
            }),
          );
        },
      },
    ];

  return [
    {
      title: strings.tasks.delete,
      onSelect: deleteSelectedSprint,
      dataCy: sprintsTabTestIds.DELETE_SPRINT,
    },
  ];
};

export default function Sprint({
  sprint,
  isActive,
  isComplete,
  className,
  currentSprintId,
  isFirstSprint,
}: SprintProps): JSX.Element | null {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const sprintTasks = getSprintTasks(orgId, sprint?.id);
  useFirestoreConnect(sprintTasks.query);
  const team = useSelector(selectTeam(orgId, teamId));

  const [tasksLoading, setTasksLoading] = useState(true);
  const [isRemoving, setIsRemoving] = useState(false);
  const { openModal } = useContext(LegacyTaskModalContext);
  const { addToast } = useToast();
  const [sortFilter] = useState<keyof CustomSorts | null>(null);
  const allTasksSelector = createSelector(
    [(): keyof CustomSorts | null => sortFilter, sprintTasks.selector],
    (filter, allTasks) => allTasks && sort(allTasks, filter ?? 'createdAtDesc'),
  );

  const tasksData = useSelector(
    orderedDocuments(allTasksSelector, () => sprint?.orderedTaskIds ?? []),
    deepEquals,
  );

  useEffect(() => {
    if (tasksData !== undefined) setTasksLoading(false);
  }, [tasksData]);

  const createTaskCallback = useCallback(
    (taskId: Data.Id.TaskId) => {
      reduxStore.dispatch(
        moveSprintTask({ destinationSprintId: sprint.id, taskId, orderedTaskIds: [taskId, ...sprint.orderedTaskIds] }),
      );
    },
    [sprint.id, sprint.orderedTaskIds],
  );

  const deleteSelectedSprint = useCallback(() => {
    setIsRemoving(true);
    reduxStore
      .dispatch(
        deleteSprint({
          id: sprint?.id,
        }),
      )
      .then(unwrapResult)
      .then((success) => {
        const message = strings
          .formatString(strings.sprints.deletedSuccess, {
            sprintName: success.sprintName,
            numberOfTasks: success.remainingTasksCount,
          })
          .toString();
        return addToast({
          message,
          timeoutMs: 5500,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        setIsRemoving(false);
        const message = strings
          .formatString(strings.sprints.deletingFailure, {
            errorMessage: error.message,
          })
          .toString();
        addToast({ message, type: 'error' });
      });
  }, [addToast, sprint]);

  const toggleModal = useCallback(
    (task) => {
      openModal(task, sprint.sprintName);
    },
    [openModal, sprint.sprintName],
  );

  const menuOptions = getMenuOptions(history, orgId, teamId, isActive, sprint, isComplete, deleteSelectedSprint);

  const disableDraggableScroll = css`
    overflow-y: auto;
    height: 100%;
  `;

  const droppableId = JSON.stringify({
    id: sprint?.id ?? null,
    type: 'sprint',
    order: tasksData?.map((task) => task.id),
  });

  return useMemo(
    () =>
      tasksData ? (
        <div
          className={cx(
            css`
              display: flex;
              flex-direction: column;
              min-height: 0;
              width: 25.875rem;
              position: relative;
            `,
            className,
          )}
        >
          {isRemoving && (
            <div
              className={css`
                display: flex;
                flex-direction: column;
                position: absolute;
                z-index: 10;
                background-color: black;
                opacity: 50%;
                border-radius: 0.375rem;
                min-width: 100%;
                min-height: 100%;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
              `}
            >
              <FastSmallSpinner
                color={atomic.get(atomic.colors.white)}
                size={1.5}
                spinnerStyles={css`
                  margin-bottom: 0.3rem;
                `}
              />
              <Text color={atomic.get(atomic.colors.white)}>{strings.sprints.deleting}</Text>
            </div>
          )}
          {tasksLoading && (
            <div
              className={css`
                display: flex;
                flex-direction: column;
                position: absolute;
                z-index: 10;
                min-width: 100%;
                min-height: 100%;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
              `}
            >
              <FastSmallSpinner
                color={atomic.get(atomic.colors.grey6)}
                size={1.5}
                spinnerStyles={css`
                  margin-bottom: 0.3rem;
                `}
              />
              <Text color={atomic.get(atomic.colors.grey6)}>{strings.sprints.loadingTasks}</Text>
            </div>
          )}
          <SprintColumnHeader
            currentSprintId={currentSprintId}
            isActive={isActive}
            isComplete={isComplete}
            isFirstSprint={isFirstSprint}
            sprint={sprint}
            tasks={tasksData}
          />
          <div
            className={css`
              display: flex;
              flex-direction: column;
              border: 0.0625rem solid #eaeef5;
              border-radius: 0.375rem;
              width: 25.875rem;
              min-height: 0;
              flex-grow: 1;
              background-color: #ffffff;
            `}
          >
            <div
              className={css`
                width: 25.75rem;
                min-height: 0.5rem;
                background-color: ${isActive ? '#389e0d' : 'white'};
                border-radius: 0.375rem 0.375rem 0rem 0rem;
              `}
            />
            <div
              className={css`
                padding: 0.5rem 1rem;
                display: flex;
                align-items: center;
                min-height: fit-content;
                position: relative;
                justify-content: space-between;
              `}
            >
              <div
                className={css`
                  display: flex;
                `}
              >
                {isActive || isComplete ? (
                  <Link
                    className={css`
                      :hover {
                        text-decoration: underline;
                      }
                    `}
                    data-cy={sprintsTabTestIds.VIEW_SPRINT_DETAILS}
                    to={linkTo('sprintDetails', {
                      orgId,
                      teamId,
                      sprintId: sprint.id,
                    })}
                  >
                    {sprint.sprintName}
                  </Link>
                ) : (
                  sprint.sprintName
                )}
              </div>
              <div
                className={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <SprintEffort currentSprint={sprint} orgId={orgId} tasks={tasksData} />
                {!team?.sprintSettings.autoSprints ? (
                  <Menu options={menuOptions} position='bottomLeft'>
                    <Icon
                      className={css`
                        fill: #949caf;
                        width: 0.875rem;
                        height: 1.375rem;
                        padding: 0.3125rem 0.125rem 0rem 0.5rem;
                        cursor: pointer;
                      `}
                      data-cy={sprintsTabTestIds.THREE_DOT_MENU_SPRINT}
                      name='meatballs'
                    />
                  </Menu>
                ) : null}
              </div>
            </div>
            <hr
              className={css`
                border: 0;
                min-height: 0.0625rem;
                background: #eaeef5;
              `}
            />
            <div
              className={css`
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                min-height: 0;
              `}
            >
              <div
                className={css`
                  display: flex;
                  min-height: 0;
                  flex-direction: column;
                  flex-grow: 1;
                  background-color: #f4f4fa;
                  margin: 1rem;
                  border: 0.0625rem solid #eaeef5;
                  border-radius: 0.1875rem;
                `}
              >
                {!isComplete && (
                  <CreateTaskInput
                    callback={createTaskCallback}
                    className={css`
                      border-top: none;
                    `}
                    dataCy={sprintsTabTestIds.NEW_TASK_INPUT_SPRINT_COLUMN}
                    sprint={sprint}
                  />
                )}
                <Droppable key={sprint.id} droppableId={droppableId}>
                  {(provided) => (
                    <>
                      {!isComplete && !tasksLoading && isEmpty(tasksData) ? (
                        <EmptySprintColumn provided={provided} />
                      ) : (
                        <div ref={provided.innerRef} {...provided.droppableProps} className={disableDraggableScroll}>
                          <DraggableFeatureCardList provided={provided} tasks={tasksData} toggleModal={toggleModal} />
                        </div>
                      )}
                    </>
                  )}
                </Droppable>
              </div>
            </div>
          </div>
        </div>
      ) : null,
    [
      tasksData,
      className,
      isRemoving,
      tasksLoading,
      currentSprintId,
      isActive,
      isComplete,
      isFirstSprint,
      sprint,
      orgId,
      teamId,
      team?.sprintSettings.autoSprints,
      menuOptions,
      createTaskCallback,
      droppableId,
      disableDraggableScroll,
      toggleModal,
    ],
  );
}
