import 'draft-js/dist/Draft.css';

import { styled } from '@taraai/design-system';
import { Editor, EditorState } from 'draft-js';
import React, { forwardRef, MouseEventHandler, useContext, useImperativeHandle, useMemo, useRef } from 'react';
import { findEventTargetData } from 'tools/helpers/event';

import { generateThreadsStyleMap } from './plugins/comments/generateThreadsStyleMap';
import { RichEditorContext, useRichEditorContextGuard } from './RichEditorProvider';
import { blockStyleFn, containerStyle, styleMap } from './styles';
import { withSelection } from './utils';

export type RichEditorHandle = {
  blur: () => void;
  clear: (hasFocus?: boolean) => void;
  focus: () => void;
  save: () => void;
};

type Props = {
  // Should only be used with getStyledRichEditor
  className?: string;
  isTitle?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  saveOnBlur?: boolean;
};

export const RichEditor = forwardRef<RichEditorHandle, Props>(function RichEditor(
  { className, isTitle, onBlur, onFocus, placeholder, saveOnBlur },
  ref,
): JSX.Element {
  useRichEditorContextGuard();
  const editor = useRef<Editor>(null);
  const {
    editorState,
    handlers,
    onChange,
    readOnly,
    setEditorState,
    showThread,
    singleLine,
    threadIds,
    save,
  } = useContext(RichEditorContext);

  useImperativeHandle(
    ref,
    () => ({
      blur: () => editor.current?.blur(),
      // TODO: move this to RichEditorProvider after the pipeline refactor
      clear: (hasFocus = false) =>
        setEditorState((currentEditorState) => {
          const emptyEditorState = EditorState.createEmpty(currentEditorState.getDecorator());
          return withSelection(emptyEditorState, {
            anchorKey: emptyEditorState.getCurrentContent().getFirstBlock().getKey(),
            anchorOffset: 0,
            hasFocus,
          });
        }),
      focus: () => editor.current?.focus(),
      save: () => save(editorState.getCurrentContent()),
    }),
    [editorState, save, setEditorState],
  );

  // Generate styles map for threads highlighting
  const threadsStyleMap = useMemo(() => generateThreadsStyleMap(threadIds), [threadIds]);

  const handleClick: MouseEventHandler = (event) => {
    const getThreadId = (target: HTMLElement): string => target.style.getPropertyValue('--thread-id');
    const threadElement = findEventTargetData(event.target as HTMLElement, (target) => !!getThreadId(target));

    if (threadElement) showThread(getThreadId(threadElement));
  };

  return (
    <DefaultStylesWrapper className={className} isTitle={isTitle} onClick={handleClick} singleLine={singleLine}>
      <Editor
        ref={editor}
        blockStyleFn={blockStyleFn}
        customStyleMap={{ ...styleMap, ...threadsStyleMap }}
        editorState={editorState}
        onBlur={() => {
          if (saveOnBlur) save(editorState.getCurrentContent());
          onBlur?.();
        }}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        {...handlers}
      />
    </DefaultStylesWrapper>
  );
});

const DefaultStylesWrapper = styled('div', containerStyle, {
  isTitle: {
    true: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
  },
  singleLine: {
    true: {
      '.public-DraftStyleDefault-block': {
        overflowX: 'hidden',
        whiteSpace: 'pre',
      },
      '.public-DraftEditorPlaceholder-inner': {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        // Override the inline style from Draft.js 🤔
        whiteSpace: 'pre !important',
      },
    },
  },
});

type Styles = Parameters<typeof styled>[1];

// It's not possible to get a clean type out of this...
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getStyledRichEditor(contentStyles: Styles) {
  return styled(RichEditor, {
    '.public-DraftEditor-content, .public-DraftEditorPlaceholder-root': contentStyles,
  });
}
