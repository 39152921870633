import { Box, HStack, styled } from '@taraai/design-system';
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { SprintPathId } from 'tools/utils/hooks/usePagination';

type Props = {
  anchorLeft: number;
  items: SprintPathId[];
  renderItem: (item: SprintPathId, ref: React.RefObject<HTMLDivElement>, offsetFromSelected: number) => JSX.Element;
  selectedId: string;
};

export function ScrollPane({ anchorLeft, items, renderItem, selectedId }: Props): JSX.Element {
  const paneRef = useRef<HTMLDivElement>(null);
  const oldSelectedId = useRef(selectedId);

  const itemsWithRefs = useMemo(() => {
    return items.map((item) => ({ item, ref: React.createRef<HTMLDivElement>() }));
  }, [items]);
  const selectedItemRef = itemsWithRefs.find(({ item }) => item[1] === selectedId);
  const selectedIndex = items.findIndex(([, id]) => id === selectedId);

  useLayoutEffect(() => {
    const selectedItemDidNotChange = oldSelectedId.current === selectedId;
    if (selectedItemDidNotChange && selectedItemRef && paneRef.current) {
      // when selectedId did not change but selectedIndex did
      // it means that we need to immediately set new scroll position, since selected element
      // is now at a different offset
      const itemOffset = selectedItemRef.ref.current?.offsetLeft ?? 0;
      const scrollPosition = itemOffset - anchorLeft;
      paneRef.current.scrollLeft = scrollPosition;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  useEffect(() => {
    if (selectedItemRef && paneRef.current) {
      // when selectedId changed, scroll using smooth animation
      const itemOffset = selectedItemRef.ref.current?.offsetLeft ?? 0;
      const scrollPosition = itemOffset - anchorLeft;
      paneRef.current.scrollTo({ top: 0, left: scrollPosition, behavior: 'smooth' });
    }
    oldSelectedId.current = selectedId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, anchorLeft]);

  return (
    <Wrapper ref={paneRef} full>
      <HStack full style={{ paddingLeft: anchorLeft }}>
        {itemsWithRefs.map(({ item, ref }, idx) => {
          const selectedItemIdx = itemsWithRefs.findIndex((itemWithRef) => itemWithRef.item[1] === selectedId);
          const offsetFromSelected = idx - selectedItemIdx;
          return renderItem(item, ref, offsetFromSelected);
        })}
        <Box full />
      </HStack>
    </Wrapper>
  );
}

const Wrapper = styled(Box, {
  overflow: 'hidden',
});
