import { Data } from '@taraai/types';
import { SprintDetailsSidebarHeaderView } from 'components/app/controllers/views/SprintDetailsSidebarHeaderView';
import {
  composePlugins,
  createMaxLengthPlugin,
  createSingleLinePlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { getSprint, reduxStore, selectActiveWorkspace, updateSprint } from 'reduxStore';
import { strings } from 'resources';
import { useRerender, useToast } from 'tools';

const DESCRIPTION_LENGTH_MIN = 2;
const DESCRIPTION_LENGTH_MAX = 140;

interface SprintDetailsSidebarHeaderProps {
  sprintId: Data.Id.SprintId;
}

export function SprintDetailsSidebarHeader({ sprintId }: SprintDetailsSidebarHeaderProps): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const sprintSlice = getSprint(orgId, sprintId);
  const sprintFragment = useSelector(
    compose(
      (sprint) =>
        sprint && {
          id: sprint.id,
          description: sprint.description,
          isComplete: sprint.isComplete,
          sprintName: sprint.sprintName,
        },
      sprintSlice.selector,
    ),
    deepEquals,
  );
  useFirestoreConnect(sprintSlice.query);
  const { whenError } = useToast();
  const descriptionRef = useRef<RichEditorHandle>(null);
  const sprintState = useMemo(() => {
    if (sprintFragment?.isComplete) return 'completed';
    // TODO active
    return undefined;
  }, [sprintFragment?.isComplete]);
  const [editorKey, rerenderEditor] = useRerender();
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState<string>();

  const onCancel = useCallback((): void => {
    setIsEditing(false);
    rerenderEditor();
  }, [rerenderEditor]);

  const onSubmit = useCallback(
    (nextDescription: string) => {
      setIsEditing(false);
      if (!sprintFragment?.id) return;
      reduxStore
        .dispatch(updateSprint({ id: sprintFragment.id, description: nextDescription }))
        .catch(whenError((err) => `${strings.sprints.error.updateFailure} ${err.message}`));
    },
    [setIsEditing, sprintFragment?.id, whenError],
  );

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createMaxLengthPlugin({ maxLength: DESCRIPTION_LENGTH_MAX }),
        createSingleLinePlugin({ returnHandled: true }),
      ),
    [],
  );

  const text = newText ?? (sprintFragment?.description || '');

  if (!isLoaded(sprintFragment)) return null;

  return (
    <RichEditorProvider
      key={editorKey}
      initialValue={sprintFragment.description}
      onSave={onSubmit}
      onTextChange={setNewText}
      plugin={plugin}
      readOnly={!isEditing}
      saveStrategy='saveOnReturn'
    >
      <SprintDetailsSidebarHeaderView
        counter={isEditing ? { currentLength: text.length, maxLength: DESCRIPTION_LENGTH_MAX } : undefined}
        descriptionRef={descriptionRef}
        isEditing={isEditing}
        onCancel={onCancel}
        onEdit={() => setIsEditing(true)}
        onSubmit={() => onSubmit(text)}
        sprintName={sprintFragment.sprintName}
        sprintState={sprintState}
        submitDisabled={text.length < DESCRIPTION_LENGTH_MIN}
      />
    </RichEditorProvider>
  );
}
