import { Box, fade, getCustomSize, HStack, styled, Tooltip } from '@taraai/design-system';
import { noop } from '@taraai/utility';
import { EditorState } from 'draft-js';
import React, { createElement, SyntheticEvent, useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { hasFeature, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources';

import {
  AttachIcon,
  BlockCodeIcon,
  BlockquoteIcon,
  BoldIcon,
  BulletListIcon,
  HeadingOneIcon,
  HeadingTwoIcon,
  IconProps,
  InlineCodeIcon,
  ItalicIcon,
  LinkIcon,
  NumListIcon,
  StrikeIcon,
  UnderlineIcon,
  UnknownIcon,
} from './icons';
import { RichEditorContext, useRichEditorContextGuard } from './RichEditorProvider';
import { EditorStateTransform, getLinkDisabledMessage, isLinkSelected, transforms } from './transforms';
import { isBlockTypeSelected, isInlineStyleSelected } from './utils';

type EditorToolName =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'code'
  | 'link'
  | 'attachment'
  | 'headerOne'
  | 'headerTwo'
  | 'unorderedList'
  | 'orderedList'
  | 'blockquote'
  | 'codeBlock'
  | 'thread';

type Props = {
  enabled?: EditorToolName[];
  onAttachmentUpload?: (file: File) => void;
};

export function RichEditorToolbar({
  enabled: initiallyEnabled = defaultEnabled,
  onAttachmentUpload,
}: Props): JSX.Element {
  useRichEditorContextGuard();
  const orgId = useSelector(selectActiveWorkspace);
  const toolbarButtons = useMemo(() => createToolbarButtons(onAttachmentUpload ?? noop), [onAttachmentUpload]);

  /**
   * Show thread button only for orgs with inlineComments feature flag
   *
   * @TODO - please, remember to remove redundant Route configuration from related stories
   * for reference, see https://github.com/TARAAI/tara-js/pull/4410
   */
  const hasInlineComments = useSelector(hasFeature('inlineComments', orgId));
  const enabled = hasInlineComments ? initiallyEnabled : initiallyEnabled.filter((name) => name !== 'thread');

  if (onAttachmentUpload && !initiallyEnabled.includes('attachment')) enabled.push('attachment');

  return (
    <Box onMouseDown={preventDefault} spaceRight='$12px'>
      <HStack space='$8px'>
        {enabled.map((toolName) => createElement(toolbarButtons[toolName], { key: toolName }))}
      </HStack>
    </Box>
  );
}

const defaultEnabled: EditorToolName[] = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'code',
  'link',
  'headerOne',
  'headerTwo',
  'unorderedList',
  'orderedList',
  'blockquote',
  'codeBlock',
  'thread',
];

const createToolbarButtons = (onAttachmentUpload: (file: File) => void): Record<EditorToolName, () => JSX.Element> => ({
  bold: getToolbarButton('Bold', isInlineStyleSelected('BOLD'), BoldIcon, transforms.toggleBold),
  italic: getToolbarButton('Italic', isInlineStyleSelected('ITALIC'), ItalicIcon, transforms.toggleItalics),
  underline: getToolbarButton(
    'Underline',
    isInlineStyleSelected('UNDERLINE'),
    UnderlineIcon,
    transforms.toggleUnderline,
  ),
  strikethrough: getToolbarButton(
    'Strikethrough',
    isInlineStyleSelected('STRIKETHROUGH'),
    StrikeIcon,
    transforms.toggleStrikethrough,
  ),
  code: getToolbarButton('Code', isInlineStyleSelected('CODE'), InlineCodeIcon, transforms.toggleCode),
  link: getToolbarButton('Link', isLinkSelected, LinkIcon, transforms.toggleLink, getLinkDisabledMessage),
  attachment: getAttachmentButton(onAttachmentUpload),
  headerOne: getToolbarButton(
    'Large Title',
    isBlockTypeSelected('header-one'),
    HeadingOneIcon,
    transforms.toggleHeaderOne,
  ),
  headerTwo: getToolbarButton(
    'Small Title',
    isBlockTypeSelected('header-two'),
    HeadingTwoIcon,
    transforms.toggleHeaderTwo,
  ),
  unorderedList: getToolbarButton(
    'Bulleted List',
    isBlockTypeSelected('unordered-list-item'),
    BulletListIcon,
    transforms.toggleUnorderedList,
  ),
  orderedList: getToolbarButton(
    'Ordered List',
    isBlockTypeSelected('ordered-list-item'),
    NumListIcon,
    transforms.toggleOrderedList,
  ),
  blockquote: getToolbarButton(
    'Blockquote',
    isBlockTypeSelected('blockquote'),
    BlockquoteIcon,
    transforms.toggleBlockquote,
  ),
  codeBlock: getToolbarButton(
    'Code Block',
    isBlockTypeSelected('code-block'),
    BlockCodeIcon,
    transforms.toggleCodeBlock,
  ),
  thread: getToolbarButton('Thread', () => false, UnknownIcon, transforms.createThread),
});

function getToolbarButton(
  title: string,
  isSelected: (state: EditorState) => boolean,
  icon: (props: IconProps) => JSX.Element,
  onTransformState: EditorStateTransform,
  getDisabledMessage: (state: EditorState) => string | undefined = noDisabledMessage,
) {
  return function ToolbarButton(): JSX.Element {
    const { editorState, save, setEditorState, setForceSelectedEntity, setThreadIds } = useContext(RichEditorContext);
    const selected = isSelected(editorState);
    const disabledMessage = getDisabledMessage(editorState);
    return useMemo(
      () => (
        <BaseToolbarButton
          disabled={Boolean(disabledMessage)}
          icon={icon}
          onClick={() =>
            setEditorState((currentEditorState) =>
              onTransformState(currentEditorState, { setEditorState, setForceSelectedEntity, setThreadIds, save }),
            )
          }
          selected={selected}
          title={disabledMessage ?? title}
        />
      ),
      [disabledMessage, save, selected, setEditorState, setForceSelectedEntity, setThreadIds],
    );
  };
}

function getAttachmentButton(onAttachmentUpload: (file: File) => void) {
  return function AttachmentButton(): JSX.Element {
    const fileInput = useRef<HTMLInputElement>(null);
    return useMemo(
      () => (
        <>
          <UploadFilesInput
            ref={fileInput}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              Array.from(event.target.files ?? []).forEach(onAttachmentUpload)
            }
            type='file'
          />
          <BaseToolbarButton
            icon={AttachIcon}
            onClick={() => fileInput.current?.click()}
            title={strings.editor.insertFile}
          />
        </>
      ),
      [],
    );
  };
}

const UploadFilesInput = styled('input', {
  opacity: 0,
  position: 'absolute',
  transform: 'scale(0, 0)',
});

const buttonSize = getCustomSize(20);

function BaseToolbarButton({
  disabled = false,
  icon,
  onClick,
  selected = false,
  title,
}: {
  disabled?: boolean;
  icon: (props: IconProps) => JSX.Element;
  onClick: () => void;
  selected?: boolean;
  title: string;
}): JSX.Element {
  return (
    <Tooltip title={title}>
      <StyledButton
        borderRadius='$2px'
        disabled={disabled}
        height={buttonSize}
        onClick={onClick}
        onMouseDown={preventDefault}
        selected={selected}
        space='$2px'
        width={buttonSize}
      >
        {icon({ color: selected ? '$indigo' : '$grey6' })}
      </StyledButton>
    </Tooltip>
  );
}

const StyledButton = styled(
  Box.Button,
  {},
  {
    selected: { true: { backgroundColor: fade('$indigo', '$10%') } },
  },
);

function preventDefault(event: SyntheticEvent): void {
  event.preventDefault();
}

function noDisabledMessage(): undefined {
  return undefined;
}
