/* eslint-disable @typescript-eslint/no-shadow */

import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Box, Fluid, getCustomSize, HStack, styled, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import DropdownController from 'components/core/controllers/DropdownController';
import Icon from 'components/core/controllers/views/Icon';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { getRequirement, getRequirements, reduxStore, selectActiveWorkspace } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

export interface RequirementSelectorProps {
  task: UI.UITask;
  requirementsRef?: React.RefObject<HTMLDivElement>;
  dataCy?: string;
}

export function RequirementSelector({ task, requirementsRef, dataCy }: RequirementSelectorProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);

  const requirementId = task?._relationships?.requirement || '';

  const [show, setShow] = useState(false);

  const toggleShow = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    setShow((show) => !show);
  }, []);

  const individualRequirementSlice = getRequirement(orgId, requirementId);
  useFirestoreConnect(individualRequirementSlice.query);

  const selectedRequirement =
    useSelector(createSelector(individualRequirementSlice.selector, (requirement) => requirement && [requirement])) ??
    [];
  const selectedRequirementTitle = selectedRequirement[0]?.title;

  const requirementsSlice = getRequirements(orgId, null, { orderBy: 'title' });

  useFirestoreConnect(requirementsSlice.query);
  const dropdownOptions = useSelector(
    createSelector(requirementsSlice.selector, (requirements = []) => requirements.map((requirement) => requirement)),
  );

  const { addToast } = useToast();

  const updateRequirement = useCallback(
    (id: string) => {
      if (id !== undefined && typeof id !== 'number') {
        setShow(!show);
        reduxStore
          .dispatch(
            updateTask({
              id: task?.id,
              _relationships: { requirement: id },
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToUpdateTask,
            }),
          );
      } else {
        addToast({
          type: 'error',
          message: strings.task.failedToUpdateTask,
        });
      }
    },
    [show, task, addToast],
  );

  const removeRequirement = useCallback(
    (id: string) => {
      if (id !== undefined && typeof id !== 'number') {
        setShow(!show);
        reduxStore
          .dispatch(
            updateTask({
              id: task?.id,
              _relationships: { requirement: null },
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToRemoveTask,
            }),
          );
      } else {
        addToast({
          type: 'error',
          message: strings.task.failedToRemoveTask,
        });
      }
    },
    [show, task, addToast],
  );
  return (
    <Box full>
      <DropdownController
        data-cy={dataCy}
        header
        headerPlaceholder={strings.dropdown.placeholder.searchRequirements}
        headerTitle={strings.dropdown.header.addRequirements}
        onAdd={updateRequirement}
        onClose={useCallback(() => setShow(false), [])}
        onRemove={removeRequirement}
        options={dropdownOptions}
        selectedOptions={selectedRequirement}
        show={show}
      >
        <RequirementWrapper
          ref={requirementsRef}
          data-cy={dataCy}
          onClick={toggleShow}
          onKeyDown={toggleShow}
          role='button'
          tabIndex={-1}
        >
          <Fluid>
            <HStack align='right'>
              {requirementId === '' ? (
                <Text color='$focus' size='$12px'>
                  {strings.taskSidebar.modules.requirement.select}
                </Text>
              ) : (
                <Fluid>
                  <Text singleLine size='$12px' textAlign='right'>
                    {selectedRequirementTitle}
                  </Text>
                </Fluid>
              )}
              <ArrowDownIcon name='arrowdown' noPadding />
            </HStack>
          </Fluid>
        </RequirementWrapper>
      </DropdownController>
    </Box>
  );
}

const RequirementWrapper = styled('div', { cursor: 'pointer', alignItems: 'center', display: 'flex', outline: '0' });

const ArrowDownIcon = styled(Icon, { height: getCustomSize(12), width: getCustomSize(16), color: '$focus' });
