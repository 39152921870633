import { createSelector, OutputParametricSelector, Selector } from '@reduxjs/toolkit';
import Tara, { Identifiable, PathId, UI } from '@taraai/types';
import { isNonEmptyString, notUndefined } from '@taraai/utility';
import pick from 'lodash.pick';
import { DefaultRootState } from 'react-redux';
import { selectActiveSprintPath, selectActiveTaskPath, selectActiveTeamPath } from 'reduxStore/organization/slice';
import { RootState } from 'reduxStore/store';
import { compareByCreatedAtAsc } from 'tools/libraries/helpers/tasks';

import { QueryAlias, ReduxFirestoreQuery } from './types';

type PathSeletor = (state: { workspace: string }) => string;
const select = <TaraType>(
  state: DefaultRootState,
  getPath: PathSeletor,
  id: string | undefined,
): TaraType | undefined => {
  if (id === undefined) return undefined;
  const rootState = state as RootState;
  const path = getPath(rootState);
  const override = rootState.firestore.cache.databaseOverrides?.[path]?.[id];
  const document = rootState.firestore.cache.database[path]?.[id];
  if (override) return { ...document, ...override };
  return document;
};

const collection = <TaraType extends Identifiable>(
  state: DefaultRootState,
  getPath: PathSeletor,
  filter: (doc: TaraType) => boolean,
): TaraType[] | undefined => {
  const rootState = state as RootState;
  const coll = rootState.firestore.cache.database[getPath(rootState)] || {};
  const overrides = rootState.firestore.cache.databaseOverrides?.[getPath(rootState)];
  const documents = Object.values(coll) as TaraType[];

  return documents.reduce((results, data) => {
    const override = overrides && overrides[data.id];
    const doc = override ? { ...data, ...override } : data;
    if (filter(doc)) {
      results.push(doc);
    }
    return results;
  }, [] as TaraType[]);
};

const queryOrder = (state: DefaultRootState, alias: QueryAlias | undefined): PathId[] | undefined => {
  if (!alias) return;
  return (state as RootState).firestore.cache[alias]?.ordered;
};

function selectValue<TaraType, Field extends keyof TaraType>(
  state: DefaultRootState,
  id: string | undefined,
  selector: PathSeletor,
  field: Field,
): TaraType[Field] | undefined {
  const doc = select(state, selector, id) as TaraType;
  return doc?.[field] as TaraType[Field];
}

function selectDocument<TaraType, Field extends keyof TaraType>(
  state: DefaultRootState,
  id: string | undefined,
  selector: PathSeletor,
  fields: Field[] | undefined,
): TaraType | Pick<TaraType, Field> | undefined {
  const doc = select<TaraType>(state, selector, id);
  if (doc && fields) {
    return pick(doc, fields) as Pick<TaraType, Field>;
  }
  return doc;
}

function selectList<TaraType, Field extends keyof TaraType>(
  state: DefaultRootState,
  alias: QueryAlias | undefined,
  selector: PathSeletor,
  fields: Field[] | undefined,
): TaraType[] | Pick<TaraType, Field>[] | undefined {
  const pathIds = queryOrder(state, alias);
  if (!pathIds) return undefined;
  const empty = fields ? ([] as Pick<TaraType, Field>[]) : ([] as TaraType[]);

  return pathIds.reduce((arr, [, id]) => {
    const doc = selectDocument<TaraType, Field>(state, id, selector, fields);
    if (doc) {
      arr.push(doc);
    }
    return arr;
  }, empty);
}

export function selectFirestoreQuery(
  state: DefaultRootState,
  alias: string | undefined,
): (ReduxFirestoreQuery & { ordered: [string, string][]; docs: unknown }) | undefined {
  return alias !== undefined && (state as RootState).firestore.cache[alias];
}

export const selectTaskValue = <Field extends keyof UI.UITask>(
  state: DefaultRootState,
  id: string | undefined,
  field: Field,
): UI.UITask[Field] | undefined => selectValue<UI.UITask, Field>(state, id, selectActiveTaskPath, field);

export const selectTaskDocument = <Field extends keyof UI.UITask>(
  state: DefaultRootState,
  id: string | undefined,
  fields?: Field[],
): Tara.UI.UITask | Pick<Tara.UI.UITask, Field> | undefined =>
  selectDocument<UI.UITask, Field>(state, id, selectActiveTaskPath, fields);

export const selectTaskList = (
  state: DefaultRootState,
  alias: QueryAlias | undefined,
  fields?: (keyof UI.UITask)[],
): Tara.UI.UITask[] | Pick<Tara.UI.UITask, keyof Tara.UI.UITask>[] | undefined =>
  selectList<UI.UITask, keyof UI.UITask>(state, alias, selectActiveTaskPath, fields);

export const selectTaskBy = <Fields extends keyof UI.UITask>(
  state: DefaultRootState,
  filter: (task: UI.UITask) => boolean,
  fields?: Fields,
): Pick<UI.UITask, Fields>[] | undefined => {
  const tasks = collection<UI.UITask>(state, selectActiveTaskPath, filter);
  if (tasks && fields) {
    return tasks.map((doc) => pick(doc, fields)) as Pick<UI.UITask, Fields>[];
  }
  return tasks;
};

export const selectSprintValue = <Field extends keyof UI.UISprint>(
  state: DefaultRootState,
  id: string | undefined,
  field: Field,
): Tara.UI.UISprint[Field] | undefined => selectValue<UI.UISprint, Field>(state, id, selectActiveSprintPath, field);

export const selectSprintDocument = (
  state: DefaultRootState,
  id: string | undefined,
  fields?: (keyof UI.UISprint)[],
): Tara.UI.UISprint | Pick<Tara.UI.UISprint, keyof Tara.UI.UISprint> | undefined =>
  selectDocument<UI.UISprint, keyof UI.UISprint>(state, id, selectActiveSprintPath, fields);

export const selectSprintList = (
  state: DefaultRootState,
  id: QueryAlias | undefined,
  fields?: (keyof UI.UISprint)[],
): Tara.UI.UISprint[] | Pick<Tara.UI.UISprint, keyof Tara.UI.UISprint>[] | undefined =>
  selectList<UI.UISprint, keyof UI.UISprint>(state, id, selectActiveSprintPath, fields);

export const selectTeamDocument = (
  state: DefaultRootState,
  id: string | undefined,
  fields?: (keyof UI.UITeam)[],
): Tara.UI.UITeam | Pick<Tara.UI.UITeam, keyof Tara.UI.UITeam> | undefined =>
  selectDocument<UI.UITeam, keyof UI.UITeam>(state, id, selectActiveTeamPath, fields);

export const selectUserDocument = (
  state: DefaultRootState,
  id?: string | null,
  fields?: (keyof UI.UIUser)[],
): Tara.UI.UIUser | Pick<Tara.UI.UIUser, keyof Tara.UI.UIUser> | undefined =>
  !id ? undefined : selectDocument<UI.UIUser, keyof UI.UIUser>(state, id, () => 'users', fields);

export interface CreatedIdentifiable extends Tara.Identifiable {
  createdAt: Tara.Timestamp;
}
export type QuerySelector<T extends Tara.Identifiable> = Selector<RootState, T[] | undefined>;

export type IndividualSelector<T extends Tara.Identifiable> = Selector<RootState, T | undefined>;

export interface Query<T extends Tara.Identifiable> {
  query: ReduxFirestoreQuery[];
  selector: QuerySelector<T>;
}

export interface IndividualQuery<T extends Tara.Identifiable> {
  query: ReduxFirestoreQuery[];
  selector: IndividualSelector<T>;
}

export function createStandardSelector<T extends Tara.Identifiable>(parent: ReduxFirestoreQuery): QuerySelector<T> {
  const alias = parent.storeAs;
  if (!alias) return () => undefined;

  return (state) => state?.firestore?.cache?.[alias]?.docs;
}

export function createIndividualSelector<T extends Tara.Identifiable>(
  parent: ReduxFirestoreQuery,
): IndividualSelector<T> {
  const { storeAs: alias, collection: path, doc, where = [] } = parent;
  const clauses = Array.isArray(where[0]) ? where : [where];
  const document = (clauses || []).find(([field]) => field === '__name__');
  const id = doc || (document && document[2]);
  if (!alias) return () => undefined;

  return (state) => {
    const data = state?.firestore?.cache?.[alias]?.docs;
    if (data && data[0]) {
      return data[0];
    }

    if (path && id) {
      const firestore = state?.firestore?.cache?.database?.[path]?.[id];
      const optimstic = state?.firestore?.cache?.databaseOverrides?.[path]?.[id];
      if (firestore) {
        return { ...firestore, ...(optimstic || {}) };
      }
    }

    return undefined;
  };
}

export function inertQuery<T extends Tara.Identifiable>(): Query<T> {
  return {
    query: [],
    selector: () => undefined,
  };
}

// eslint-disable-next-line sonarjs/no-identical-functions
export function inertIndividualQuery<T extends Tara.Identifiable>(): IndividualQuery<T> {
  return {
    query: [],
    selector: () => undefined,
  };
}

export const orderedDocuments = <T extends CreatedIdentifiable>(
  documents: Selector<RootState, T[] | undefined>,
  orderedIds: Selector<RootState, string[] | undefined>,
): Selector<RootState, T[] | undefined> =>
  createSelector([documents, orderedIds], (docs, ordered = []): T[] | undefined => {
    if (docs === undefined) return undefined;

    const orderedList = ordered.map((docId) => docs.find((doc) => doc?.id === docId)).filter(notUndefined);

    const additionalList = docs.filter((doc) => !orderedList.includes(doc)).sort(compareByCreatedAtAsc);

    return [...orderedList, ...additionalList] as T[];
  });

export const selectTasksOrderedByParent = (): OutputParametricSelector<
  DefaultRootState,
  string,
  Tara.UI.UITask[] | undefined,
  (res1: RootState, res2: string, res3: string, res4?: (keyof UI.UITask)[]) => Tara.UI.UITask[] | undefined
> => {
  return createSelector(
    (state: DefaultRootState) => state as RootState,
    (___: unknown, sprintId: string) => sprintId,
    (___: unknown, ____: unknown, tasksAlias: QueryAlias) => tasksAlias,
    (___: unknown, ____: unknown, _____: unknown, fields?: (keyof UI.UITask)[]) => fields,
    (state, sprintId, alias, fields): UI.UITask[] | undefined => {
      if (fields && !fields.some((field) => field === 'sprint')) {
        fields.push('sprint');
      }

      let tasks: Pick<UI.UITask, keyof UI.UITask>[] | undefined;
      const wasBulkLoad = !isNonEmptyString(alias);
      if (wasBulkLoad) {
        tasks = selectTaskBy(
          state,
          (task) => !task.deleted && task.sprint === sprintId,
          fields as keyof UI.UITask | undefined,
        );
      } else {
        tasks = selectTaskList(state, alias, fields as (keyof UI.UITask)[] | undefined);
      }
      if (!tasks) return;

      const orderedTaskIds = selectSprintValue<'orderedTaskIds'>(state, sprintId, 'orderedTaskIds');

      const orderedList = (orderedTaskIds ?? [])
        .map((taskId) => tasks?.find((task) => task?.id === taskId))
        .filter(notUndefined);
      const additionalList = tasks.filter((task) => !orderedList.includes(task)).sort(compareByCreatedAtAsc);

      return [...orderedList, ...additionalList];
    },
  );
};
