import { Data, UI } from '@taraai/types';
import AssignedPRs from 'components/app/controllers/AssignedPRs';
import CreatedPRs from 'components/app/controllers/CreatedPRs';
import DashboardSprint from 'components/app/controllers/views/DashboardSprint';
import HomeBanner from 'components/app/controllers/views/HomeBanner';
import PullRequestHeader from 'components/app/controllers/views/PullRequestHeader';
import TabBar from 'components/core/controllers/views/TabBar';
import { css } from 'emotion';
import React from 'react';
import { ConnectGitHubAccountData } from 'types/connect-account';
import { InstallGitHubData } from 'types/install-integration';

export interface HomeLayoutProps {
  org: UI.UIOrganizationStanza;
  allTeamsWithActiveSprintsOrdered: UI.UITeam[];
  activeSprintIds: Data.Id.SprintId[];
  name: string;
  installGitHubData?: InstallGitHubData;
  connectGitHubAccountData?: ConnectGitHubAccountData;
}

/**
 * HomeLayout layout for all the main view components on the home page
 *
 */
export default function HomeLayout({
  org,
  allTeamsWithActiveSprintsOrdered,
  activeSprintIds,
  installGitHubData,
  connectGitHubAccountData,
  name,
}: HomeLayoutProps): JSX.Element {
  return (
    <div
      className={css`
        padding: 2.5rem;
        height: 100%;
        width: 100%;
        overflow-y: auto;
      `}
    >
      <HomeBanner backgroundColor={org.color} name={name} />
      <DashboardSprint
        activeSprintIds={activeSprintIds}
        allTeamsWithActiveSprintsOrdered={allTeamsWithActiveSprintsOrdered}
        orgId={org.id}
      />
      {(installGitHubData?.id || connectGitHubAccountData?.accounts.length !== 0) && (
        <>
          <PullRequestHeader />
          <div
            className={css`
              padding: 0rem;
              border: solid 1px #dee3ec;
              border-radius: 0.1875rem;
            `}
          >
            <TabBar
              tabbedContent={[
                {
                  label: 'Assigned',
                  content: <AssignedPRs />,
                  route: 'assigned',
                },
                {
                  label: 'Created By You',
                  content: <CreatedPRs />,
                  route: 'created-by-you',
                },
              ]}
              tabButtonMargin='none'
              tabsSeparatorBottomMargin='none'
            />
          </div>
        </>
      )}
    </div>
  );
}
