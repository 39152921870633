/* eslint-disable no-loops/no-loops */

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, TaskStatus, UI } from '@taraai/types';
import { isNonEmptyString, markdownLabelIdRegExp, markdownMentionIdRegExp } from '@taraai/utility';
import Fuse from 'fuse.js';
import uniq from 'lodash.uniq';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateWithProfile } from 'reduxStore/store';

const fuseOptions = {
  ignoreLocation: true,
};

export type SearchTaskFragment = Pick<UI.UITask, 'id' | 'title' | 'assignee' | 'labels' | 'status'>;

export type SearchQuerySource = 'label';

export interface SearchQuery {
  text?: string;
  labels?: string[];
  status?: TaskStatus;
  mentions?: Data.Id.UserId[];
  source?: SearchQuerySource;
}

export interface SearchState {
  query: SearchQuery | undefined;
  matchedIds: string[];
}

const initialState: SearchState = {
  query: undefined,
  matchedIds: [],
};

const selectSearchState = (state: RootStateWithProfile): SearchState => state.search;

export const selectSearchQuery = createSelector(selectSearchState, ({ query }) => query);

type TaskMatchPredicate = (task: SearchTaskFragment, query: SearchQuery) => boolean;

/** Matches tasks with the same status as defined in the query */
const statusMatch: TaskMatchPredicate = (task, query) => {
  return task.status === query.status;
};

/** Matches tasks when title contains text defined in the query */
const textMatch: TaskMatchPredicate = (task, query) => {
  // if query contains text try to match by title
  if (isNonEmptyString(query.text)) {
    const fuse = new Fuse(
      [task.title.replaceAll(markdownLabelIdRegExp, '').replaceAll(markdownMentionIdRegExp, '')],
      fuseOptions,
    );
    return fuse.search(query.text).length !== 0;
  }
  // otherwise any task matches
  return true;
};

/** Matches task when task assignee is defined in the `mentions` array in the query */
const assigneeMatch: TaskMatchPredicate = (task, query) => {
  if (query.mentions && query.mentions.length > 0) {
    return task.assignee ? query.mentions.includes(task.assignee) : false;
  }
  return true;
};

/** Matches task if it includes ___all___ labels defined in the query */
const labelsMatch: TaskMatchPredicate = (task, query) => {
  if (query.labels) {
    return query.labels.every((label) => task.labels.includes(label));
  }
  return true;
};

/**
 * DON'T USE THIS DIRECTLY
 *
 * We need to inform the store that a task is matching; use one of the hooks below
 * or add a new one that preserves this logic.
 */
const dangerouslyGetTaskHasMatch = (task: SearchTaskFragment, query?: SearchQuery): boolean => {
  // if no query, then all tasks match
  if (!query) return true;

  // status search comes from sprint sidebar and takes precedence over every other search
  if (query.status !== undefined) return statusMatch(task, query);

  const matchesText = textMatch(task, query);
  const matchesAssignee = assigneeMatch(task, query);
  const matchesLabels = labelsMatch(task, query);

  // those match predicates treat no corresponding query as a match,
  // so we can just && all of the results together
  return matchesText && matchesAssignee && matchesLabels;
};

export const selectSearchCount = createSelector(selectSearchState, ({ matchedIds, query }) =>
  query ? uniq(matchedIds).length : null,
);

export const selectStatusFilter = createSelector(selectSearchState, ({ query }) => query?.status);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search: (state, action: PayloadAction<SearchQuery | undefined>) => {
      state.query = action.payload;
    },
    addIdMatch: (state, action: PayloadAction<string>) => {
      state.matchedIds.push(action.payload);
    },
    removeIdMatch: (state, action: PayloadAction<string>) => {
      const index = state.matchedIds.indexOf(action.payload);
      if (index === -1) return;
      // Put the last element in place of the found one to limit computations
      state.matchedIds[index] = state.matchedIds[state.matchedIds.length - 1];
      state.matchedIds.pop();
    },
  },
});

export const searchActions = searchSlice.actions;
export const searchReducer = searchSlice.reducer;

export function useTaskHasMatch(task: SearchTaskFragment): boolean {
  const dispatch = useDispatch();
  const query = useSelector(selectSearchQuery);
  const hasMatch = dangerouslyGetTaskHasMatch(task, query);

  useEffect(() => {
    if (!hasMatch) return;

    dispatch(searchActions.addIdMatch(task.id));
    return () => {
      dispatch(searchActions.removeIdMatch(task.id));
    };
  }, [dispatch, hasMatch, task.id]);

  return hasMatch;
}

export function useFilteredTasks<Task extends SearchTaskFragment>(enabled: boolean, tasks: Task[]): Task[] {
  const dispatch = useDispatch();
  const idsRef = useRef(new Set<string>());
  const query = useSelector(selectSearchQuery);
  const filteredTasks = useMemo(
    () => (enabled ? tasks.filter((task) => dangerouslyGetTaskHasMatch(task, query)) : tasks),
    [enabled, query, tasks],
  );

  useEffect(() => {
    const nextIds = enabled ? new Set(filteredTasks.map((task) => task.id)) : new Set<string>();
    for (const id of idsRef.current) {
      if (!nextIds.has(id)) dispatch(searchActions.removeIdMatch(id));
    }
    for (const id of nextIds) {
      if (!idsRef.current.has(id)) dispatch(searchActions.addIdMatch(id));
    }
    idsRef.current = nextIds;
  }, [dispatch, enabled, filteredTasks]);

  return filteredTasks;
}

export function useFilteredTaskCount<Task extends SearchTaskFragment>(enabled: boolean, tasks: Task[]): number {
  return useFilteredTasks(enabled, tasks).length;
}
