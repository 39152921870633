import { FIREBASE_UI_INTERFACE } from 'components/core/controllers/views/FirebaseAuthUI';
import firebase from 'firebase/app';

import { onLogin } from './firebase';
import { authLog } from './loggers';

export const FIREBASE_AUTH_PROVIDERS = {
  GOOGLE: {
    name: 'Google',
    normalized: 'google.com',
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    customParameters: {
      prompt: 'select_account',
    },
  },
  MICROSOFT: {
    name: 'Microsoft',
    provider: 'microsoft.com',
    customParameters: {
      prompt: 'consent',
    },
  },
  EMAIL: {
    name: 'Tara.AI',
    normalized: 'email',
    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  },
};
export const defaultFirebaseAuthConfig = {
  uid: 'tara-login',
  defaultCallbacks: {
    [FIREBASE_UI_INTERFACE.CALLBACK.SHOWN]: authLog.bind(authLog, FIREBASE_UI_INTERFACE.CALLBACK.SHOWN),
    [FIREBASE_UI_INTERFACE.CALLBACK.MOUNTED]: authLog.bind(authLog, FIREBASE_UI_INTERFACE.CALLBACK.MOUNTED),
    [FIREBASE_UI_INTERFACE.CALLBACK.UNMOUNTED]: authLog.bind(authLog, FIREBASE_UI_INTERFACE.CALLBACK.UNMOUNTED),
    [FIREBASE_UI_INTERFACE.CALLBACK.LOGGED_IN](data: unknown, redirect: boolean): void {
      authLog(FIREBASE_UI_INTERFACE.CALLBACK.LOGGED_IN, data, redirect);
    },
    [FIREBASE_UI_INTERFACE.CALLBACK.LOGGED_OUT](data: unknown, redirect: boolean): void {
      authLog(FIREBASE_UI_INTERFACE.CALLBACK.LOGGED_OUT, data, redirect);
    },
    [FIREBASE_UI_INTERFACE.CALLBACK.SIGN_IN_SUCCESS](data: {
      authResult: {
        additionalUserInfo?: firebase.auth.AdditionalUserInfo | null;
        user: firebase.User | null;
      };
    }): void {
      authLog(FIREBASE_UI_INTERFACE.CALLBACK.SIGN_IN_SUCCESS, data);
      onLogin(data.authResult);
    },
    [FIREBASE_UI_INTERFACE.CALLBACK.SIGN_IN_FAILURE]: authLog.bind(
      authLog,
      FIREBASE_UI_INTERFACE.CALLBACK.SIGN_IN_FAILURE,
    ),
  },
  defaultUIConfig: {
    [FIREBASE_UI_INTERFACE.KEY.SIGN_IN_SUCCESS_URL]: '/',
    [FIREBASE_UI_INTERFACE.KEY.TOS_URL]: 'https://tara.ai/terms-of-service/',
    [FIREBASE_UI_INTERFACE.KEY.PRIVACY_POLICY_URL]: 'https://tara.ai/privacy/',
    [FIREBASE_UI_INTERFACE.KEY.SIGN_IN_FLOW]: FIREBASE_UI_INTERFACE.FLOW.REDIRECT,
    [FIREBASE_UI_INTERFACE.KEY.CREDENTIAL_HELPER]: FIREBASE_UI_INTERFACE.CREDENTIAL_HELPER.NONE,
    [FIREBASE_UI_INTERFACE.KEY.SIGN_IN_OPTIONS]: Object.values(FIREBASE_AUTH_PROVIDERS).map((config) => ({
      ...config, // TODO: Implement sorting order
      provider: config.provider,
    })),
  },
};
