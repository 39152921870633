import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { StatusHistory, UI } from '@taraai/types';
import { EffortUnit } from '@taraai/types/dist/data';
import { CreateTaskPayload } from '@taraai/types/src/ui';
import { isNonEmptyString, notNull } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Write, WriteFn } from 'reduxStore/utils/types';
import { insertNear } from 'tools';

type UserOrg = {
  id: string;
  effortUnit: EffortUnit;
  nextTaskSlug: number;
  path: string;
};

export const createTask = createAsyncThunk('CreateTask', async (payload: CreateTaskPayload, { extra, getState }) => {
  try {
    const { getOrgId, getUserId, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userId = getUserId(state);
    const orgId = 'orgId' in payload ? payload?.orgId : getOrgId();
    const {
      title,
      assignee,
      collaborators,
      labels = [],
      _relationships,
      sprint = null,
      insertAtIndex,
      taskIds,
    } = payload;
    const firestore = getFirestore();
    const cachedTaskId = state.firestore.cache.database.orgs[orgId].nextTaskSlug.toString();
    const requirementDoc = _relationships?.requirement;
    const requirementCreation = requirementDoc && insertAtIndex;

    if (!isNonEmptyString(orgId)) throw new Error('Missing orgId');

    const updateOrg = ({ userOrg }: { userOrg: UserOrg }): Write => ({
      doc: userOrg.id,
      collection: userOrg.path,
      data: {
        nextTaskSlug: userOrg.nextTaskSlug + 1,
      },
    });

    const updateTask = ({ userOrg }: { userOrg: UserOrg }): Write => {
      const { nextTaskSlug, effortUnit } = userOrg;
      const id = String(nextTaskSlug);
      const decodedRelationships = decode<Tara.Data.TaskRelationships>(
        { parent: _relationships?.parent || null, requirement: _relationships?.requirement || null },
        'Relationships',
        CREATION_DEFAULTS,
      );
      const statusHistory = [
        decode<StatusHistory>(
          {
            lastUpdatedBy: userId,
            updatedAt: firestore.Timestamp.now(),
          },
          'StatusHistory',
          CREATION_DEFAULTS,
        ),
      ];
      const taskShell = decode<UI.UITaskCreateChangeset>(
        {
          id,
          author: userId,
          assignee,
          collaborators,
          updatedAt: ['::serverTimestamp'],
          createdAt: ['::serverTimestamp'],
          lastUpdateVia: 'tara',
          title,
          sprint,
          updatedBy: userId,
          _relationships: decodedRelationships,
          statusHistory,
          labels,
        },
        'UITaskCreateChangeset',
        CREATION_DEFAULTS,
      );
      return {
        doc: id,
        collection: `orgs/${orgId}/tasks`,
        data: { ...taskShell, effortUnit },
      };
    };

    const updateRequirement =
      requirementCreation && requirementDoc
        ? ({ userOrg }: { userOrg: UserOrg }): Write => {
            const { nextTaskSlug } = userOrg;
            const result = insertNear(String(nextTaskSlug), insertAtIndex ?? 0, taskIds);
            return {
              doc: requirementDoc,
              collection: `orgs/${orgId}/requirements`,
              data: { orderedTaskIds: result },
            };
          }
        : null;

    const reads = {
      userOrg: { collection: 'orgs', doc: orgId },
    };

    const writes = [updateOrg, updateTask, updateRequirement].filter(notNull) as WriteFn[];

    firestore.mutate({ reads, writes });
    return cachedTaskId;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
});
