import { ImportNotificationBarController } from 'components/app/controllers/ImportNotificationBarController';
import React from 'react';
import { strings } from 'resources/i18n';

/**
 * Tara 2.0 will show import notifications inside Work Drawer
 * @deprecated
 */
export function AsanaNotificationBarController(): JSX.Element {
  return (
    <ImportNotificationBarController
      bgColor='#e65e71'
      iconName='asanaWhite'
      importInProgressCopy={strings.importNotificationBar.asana.importInProgressCopy}
      service='asana'
    />
  );
}
