/* eslint-disable sonarjs/cognitive-complexity */
import { Box, styled, Text, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { SprintOverloadedCard } from 'components/app/controllers/views/SprintOverloadedCard';
import {
  DraggableElement,
  DraggableType,
  DroppableArea,
  DroppableDescription,
  DroppableType,
} from 'components/app/DragAndDrop';
import { TaskCardController } from 'components/app/TaskCard';
import React, { useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { shallowEqual, useSelector } from 'react-redux';
import { QueryAlias, selectSprintValue, selectTasksOrderedByParent, useFilteredTasks } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useShallowMemo } from 'tools/utils/hooks/general';

export type TaskFragment = Pick<
  UI.UITask,
  | '_relationships'
  | 'assignee'
  | 'assigneeDocument'
  | 'description'
  | 'effortLevel'
  | 'id'
  | 'labels'
  | 'status'
  | 'title'
>;

interface Accumulator {
  acc: TaskWithOverloadedStatus[];
  usedEffort: number;
}

type TaskWithOverloadedStatus = TaskFragment & {
  overloaded: boolean;
  sprintId: string;
};

export function SprintColumnTasksView({
  acceptDrops,
  areFilteredByStatus,
  areFilteredByAssignee,
  effortOverload,
  effortTotal,
  onTaskSelect,
  selected,
  selectedTaskId,
  sprintId,
  taskAlias,
}: {
  acceptDrops: boolean;
  areFilteredByStatus: boolean;
  areFilteredByAssignee: boolean;
  effortOverload: number;
  effortTotal: number;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selected: boolean;
  selectedTaskId: Data.Id.TaskId | undefined;
  sprintId: string;
  taskAlias: QueryAlias;
}): JSX.Element {
  const orderedTaskIds = useSelector(
    (state) => selectSprintValue<'orderedTaskIds'>(state, sprintId, 'orderedTaskIds') ?? [],
    shallowEqual,
  );

  const memoTasks = useMemo(selectTasksOrderedByParent, [sprintId, taskAlias]);
  const tasks = useSelector((state) => memoTasks(state, sprintId, taskAlias), deepEquals);
  const taskIds = useMemo(() => tasks?.map((task) => task.id) ?? [], [tasks]);

  const droppableDescription: DroppableDescription = useShallowMemo(
    () => ({
      id: sprintId,
      type: DroppableType.sprint,
      visibleList: taskIds,
      list: orderedTaskIds,
    }),
    [sprintId, orderedTaskIds, taskIds],
  );

  // Annotate each task with overloaded flag
  const tasksWithOverloadStatus = useMemo<TaskWithOverloadedStatus[]>(() => {
    // For no overloadPoints annotate mark each task as not overloaded
    if (!effortOverload) {
      return tasks?.map((task) => (({ ...task, overloaded: false } as unknown) as TaskWithOverloadedStatus)) ?? [];
    }

    const availableEffort = effortTotal - effortOverload;

    // Annotate each task with overloaded flag
    const results = tasks?.reduce<Accumulator>(
      ({ acc, usedEffort: prevUsedEffort }, task) => {
        const usedEffort = prevUsedEffort + task.effortLevel;
        const taskWithOverloadedStatus = ({
          ...task,
          overloaded: usedEffort > availableEffort,
        } as unknown) as TaskWithOverloadedStatus;

        return { usedEffort, acc: [...acc, taskWithOverloadedStatus] };
      },
      { acc: [] as TaskWithOverloadedStatus[], usedEffort: 0 },
    );
    return results?.acc ?? [];
  }, [effortOverload, tasks, effortTotal]);

  const areFiltered = areFilteredByStatus || areFilteredByAssignee;
  const filteredTasks = useFilteredTasks(selected, tasksWithOverloadStatus);

  return (
    <ScrollContainer background='$grey2' borderBottomRadius='$2px' full>
      <VStack space='$1px'>
        {acceptDrops ? (
          <DroppableArea description={droppableDescription} isDropDisabled={!acceptDrops}>
            {() => (
              <>
                {filteredTasks.length === 0 && areFiltered && <NoTasksAfterFiltering />}
                {filteredTasks.length === 0 && !areFiltered && <PlanASprintHelper />}
                {filteredTasks.length > 0 && (
                  <VStack space='$1px'>
                    {filteredTasks.map((task, index) => (
                      <DraggableElement
                        key={`Sprint_${task.id}`}
                        index={index}
                        sourceId={sprintId}
                        sourceType={DroppableType.sprint}
                        type={DraggableType.sprintTask}
                      >
                        <TaskCardItem
                          key={task.id}
                          filteredByStatus={areFilteredByStatus}
                          onTaskSelect={onTaskSelect}
                          overloaded={task.overloaded}
                          selected={task.id === selectedTaskId}
                          taskId={task.id}
                        />
                      </DraggableElement>
                    ))}
                  </VStack>
                )}
              </>
            )}
          </DroppableArea>
        ) : (
          <>
            {filteredTasks.length === 0 && areFiltered && <NoTasksAfterFiltering />}
            {filteredTasks.length === 0 && !areFiltered && <PlanASprintHelper />}
            {filteredTasks.length > 0 && (
              <VStack space='$1px'>
                {filteredTasks.map((task) => (
                  <TaskCardItem
                    key={task.id}
                    filteredByStatus={areFilteredByStatus}
                    onTaskSelect={onTaskSelect}
                    overloaded={task.overloaded}
                    selected={task.id === selectedTaskId}
                    taskId={task.id}
                  />
                ))}
              </VStack>
            )}
          </>
        )}

        {filteredTasks.length && effortOverload ? <SprintOverloadedCard effortOverload={effortOverload} /> : null}
      </VStack>
    </ScrollContainer>
  );
}

const NoTasksAfterFiltering = (): JSX.Element => (
  <Box background='$grey1' center space='$16px'>
    <Text color='$grey6' size='$10px' textAlign='center'>
      {strings.sprints.sprintColumn.noTasksAfterFiltering}
    </Text>
  </Box>
);

const PlanASprintHelper = (): JSX.Element => (
  <Box background='$grey1' center space='$16px'>
    <Text color='$grey6' size='$10px' textAlign='center'>
      {strings.sprints.sprintColumn.planASprintHelper}
    </Text>
  </Box>
);

function TaskCardItem({
  onTaskSelect,
  overloaded,
  selected,
  taskId,
  filteredByStatus,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  overloaded: boolean;
  selected: boolean;
  taskId: Data.Id.TaskId;
  filteredByStatus?: boolean;
}): JSX.Element | null {
  return (
    <Box.Button key={taskId} onClick={() => onTaskSelect(taskId)}>
      <TaskCardController
        filteredByStatus={filteredByStatus}
        overloaded={overloaded}
        selected={selected}
        taskId={taskId}
      />
    </Box.Button>
  );
}

const ScrollContainer = styled(Box, {
  overflowY: 'overlay',
  flexShrink: 1,
});
