import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import React, { ComponentProps, ReactNode } from 'react';
import { isLoaded } from 'react-redux-firebase';

type BoxProps = Pick<ComponentProps<typeof Box>, 'spaceVert'>;

type Props = BoxProps & {
  content?: ReactNode;
  noBorder?: boolean;
  right?: ReactNode;
  title: string;
};

type LoaderProps<Data> = Props & {
  content: (data: Data) => JSX.Element;
  data: Data | undefined;
  fallback?: JSX.Element;
};

export function Section({ content, noBorder, right, spaceVert = '$8px', title }: Props): JSX.Element {
  return (
    <Box
      background='$grey1'
      borderBottom={noBorder ? undefined : '$grey2'}
      spaceHorz='$12px'
      spaceVert={spaceVert}
      width='$full'
    >
      <Header title={title}>{right}</Header>
      {content}
    </Box>
  );
}

export function SectionWithLoader<Data>({
  content,
  noBorder,
  data,
  fallback = <LightSpinner noPadding size={0.6} />,
  right,
  spaceVert = '$8px',
  title,
}: LoaderProps<Data>): JSX.Element {
  return (
    <Box
      background='$grey1'
      borderBottom={noBorder ? undefined : '$grey2'}
      spaceHorz='$12px'
      spaceVert={spaceVert}
      width='$full'
    >
      <Header title={title}>{isLoaded(data) ? right : fallback}</Header>
      {isLoaded(data) && content(data)}
    </Box>
  );
}

function Header({ children, title }: { children: ReactNode; title: string }): JSX.Element {
  return (
    <HStack alignY='center'>
      <Fluid>
        <Text size='$10px' weight='medium'>
          {title}
        </Text>
      </Fluid>
      <Fluid>
        <HStack align='right'>{children}</HStack>
      </Fluid>
    </HStack>
  );
}

const LightSpinner = styled(FastSmallSpinner, {
  opacity: 0.5,
});
