import { Box, styled, Text, VStack } from '@taraai/design-system';
import { SmartText } from 'components/app/controllers/views/SmartText';
import React from 'react';
import { strings } from 'resources';

type Props = {
  description: string;
  handleViewModal: () => void;
};

export const Summary = ({ description, handleViewModal }: Props): JSX.Element => {
  let summary = description.substring(0, 300);

  if (summary.length < description.length) {
    summary += '...';
  }

  return (
    <Box background='$grey1' borderBottom='$grey2' space='$12px'>
      <VStack space='$12px'>
        {summary.length ? (
          <Description size='$12px'>
            <SmartText text={summary} />
          </Description>
        ) : null}
        <Box.Button onClick={handleViewModal}>
          <ActionText color='$focus' size='$12px'>
            {summary.length ? strings.taskSidebar.editTaskDetails : strings.taskSidebar.noDescription}
          </ActionText>
        </Box.Button>
      </VStack>
    </Box>
  );
};

const Description = styled(Text, {
  overflow: 'hidden',
});

const ActionText = styled(Text, {
  ':hover': { textDecoration: 'underline' },
});
