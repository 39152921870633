import { createSelector, OutputParametricSelector } from '@reduxjs/toolkit';
import { Data, PathId, UI } from '@taraai/types';
import { notNull, notUndefined } from '@taraai/utility';
import uniqBy from 'lodash.uniqby';
import { useCallback, useMemo, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  Query,
  QueryAlias,
  selectActiveTeam,
  selectFirestoreQuery,
  selectSprintList,
  selectTeamDocument,
} from 'reduxStore';
import { formatDMMM, toTimestamp } from 'tools/libraries/helpers/dates';
import { sort } from 'tools/libraries/helpers/sort';

export type PhantomSprintPathId = [string, string, true];
export type PhantomSprint = null;
export type SprintPathId = PathId | PhantomSprintPathId;

type SprintFragment = Pick<UI.UISprint, 'id' | 'path'> & { endSeconds: number };
type UsePaginationHookReturn = {
  currentSprintId: Data.Id.SprintId | undefined;
  items: SprintPathId[] | undefined;
  loadNext: () => string | undefined;
  loadPrev: () => string | undefined;
  loadCurrentSprint: () => string | undefined;
  setSprint: (sprintId: string) => string | undefined;
};
type NewPage = {
  newPivotEndDate?: number;
  newBeforeLimit?: number;
  newAfterLimit?: number;
  newSprintId?: string;
};

const pageIncrement = 1;
const upperLimitLoad = 4;
const lowerLimitLoad = 2;

const getPageSliceKey = (config: {
  pageSize: number;
  beforeLimit: number | null;
  afterLimit: number | null;
  pivotEndDate: number;
}): string => {
  return JSON.stringify({
    ...config,
    pivotEndDate: formatDMMM(toTimestamp({ seconds: config.pivotEndDate })),
  });
};

const increment = (
  pageSize: number,
  selected: string | undefined,
  allSprints: (SprintFragment | null)[],
  offset: number,
): NewPage => {
  const sprints = allSprints.filter(notNull);
  const selectedId = selected ?? sprints[Math.floor(sprints.length / 2)]?.id;
  if (!selectedId) return {};

  const activeIndex = sprints.findIndex((sprint) => sprint?.id === selectedId);
  const newIdx = activeIndex + offset;
  if (newIdx < lowerLimitLoad) {
    return {
      newPivotEndDate: sprints[0]?.endSeconds,
      newBeforeLimit: pageSize,
      newAfterLimit: 1, // to make use don't load too much at once
      newSprintId: sprints[newIdx]?.id,
    };
  }

  if (newIdx > sprints.length - upperLimitLoad) {
    return {
      newPivotEndDate: sprints[sprints.length - 1]?.endSeconds,
      newBeforeLimit: 1, // to make use don't load too much at once
      newAfterLimit: pageSize,
      newSprintId: sprints[newIdx]?.id,
    };
  }

  return { newSprintId: sprints[newIdx]?.id };
};

interface SprintItem {
  sprints?: (SprintFragment | null)[] | undefined;
  items?: SprintPathId[] | undefined;
}
const itemsSelector = (): OutputParametricSelector<
  DefaultRootState,
  number,
  SprintItem,
  (res1: DefaultRootState, res2: number, res3: string[]) => SprintItem
> =>
  createSelector(
    (state: DefaultRootState) => state,
    (___: unknown, afterLimit: number) => afterLimit,
    (___: unknown, ____: unknown, aliases: QueryAlias[]) => aliases,
    (state, afterLimit, aliases): SprintItem => {
      const totalSprintsCount =
        selectTeamDocument(state, selectActiveTeam(state as { team: string }))?.totalSprintsCount ?? 1;
      let loadedLastSprint = false;
      aliases.flat().some((alias) => {
        const { startAfter, limit = 0, ordered: { length = 99 } = {} } = selectFirestoreQuery(state, alias) || {};
        const anyEndQueryHasLessThanRequested = startAfter && limit > 0 && length < limit;
        if (anyEndQueryHasLessThanRequested) {
          loadedLastSprint = true;
          return true;
        }
        return false;
      });

      const allItems = aliases.flatMap((alias) => selectSprintList(state, alias));
      const loadedItems = allItems.filter(notUndefined);

      const minimumLoad = Math.min(totalSprintsCount, afterLimit);
      const allQueriesLoaded = allItems.length === loadedItems.length;
      const canRenderCurrentSprint = loadedItems.length > minimumLoad || allQueriesLoaded;
      if (!canRenderCurrentSprint) {
        return {};
      }

      const allSprints = uniqBy(sort(loadedItems, 'endDate'), 'id');
      const sprints = allSprints.map(({ id, path, endDate }) => ({ id, path, endSeconds: endDate.seconds })) as (
        | SprintFragment
        | PhantomSprint
      )[];
      const items = allSprints.map(({ id, path }) => [path, id] as PathId) as (SprintPathId | PhantomSprintPathId)[];

      // append a phantom sprint when there's not more to sprints to load
      const lastRealSprint = items[items.length - 1];
      if (loadedLastSprint && lastRealSprint) {
        sprints.push(null);
        items.push([lastRealSprint[0], lastRealSprint[1], true]);
      }

      return { sprints, items };
    },
  );

/**
 * This hook enables us to paginate data using page slices
 * @param pageSize
 * @param initialPivotEndDate
 * @param getPageSlice
 */
export const usePagination = (
  pageSize: number,
  initialSelectedSprint: Data.Id.SprintId,
  currentlyRunningSprintId: Data.Id.SprintId,
  initialPivotEndDateSeconds: number,
  getPageSlice: (before: number | null, after: number | null, pivotEndDateSeconds: number) => Query<UI.UISprint>,
): UsePaginationHookReturn => {
  const [beforeLimit, setBeforeLimit] = useState<number | null>(pageSize / 2);
  const [afterLimit, setAfterLimit] = useState<number | null>(pageSize / 2 + 1);
  const [pivotEndDate, setPivotEndDate] = useState(initialPivotEndDateSeconds);
  const [currentSprintId, setCurrentSprintId] = useState<string | undefined>(initialSelectedSprint);

  // store all slices to be able to display all of the loaded pages
  const sliceMap = useRef<Map<string, Query<UI.UISprint>>>(new Map());

  const pageSlice = getPageSlice(beforeLimit, afterLimit, pivotEndDate);
  const pageKey = getPageSliceKey({
    pageSize,
    beforeLimit,
    afterLimit,
    pivotEndDate,
  });
  sliceMap.current.set(pageKey, pageSlice);

  // retrieve all loaded page slices
  const allPageSlices = Array.from(sliceMap.current.values());
  const pageQueries = allPageSlices.flatMap((slice) => slice.query);
  const aliases = pageQueries.map(({ storeAs }) => storeAs);

  useFirestoreConnect(pageQueries);

  const memoSprintSelector = useMemo(itemsSelector, [afterLimit, aliases]);
  const { sprints, items } = useSelector((state) => memoSprintSelector(state, afterLimit ?? 0, aliases), deepEquals);

  const loadPrev = useCallback((): string | undefined => {
    if (!sprints) return;
    const { newSprintId, newPivotEndDate, newBeforeLimit, newAfterLimit } = increment(
      pageSize,
      currentSprintId,
      sprints,
      -pageIncrement,
    );

    if (newPivotEndDate && newBeforeLimit && newAfterLimit) {
      setPivotEndDate(newPivotEndDate);
      setBeforeLimit(newBeforeLimit);
      setAfterLimit(newAfterLimit);
    }

    if (newSprintId) {
      setCurrentSprintId(newSprintId);
    }

    return newSprintId;
  }, [currentSprintId, pageSize, sprints]);

  const loadNext = useCallback((): string | undefined => {
    if (!sprints) return;
    const { newSprintId, newPivotEndDate, newBeforeLimit, newAfterLimit } = increment(
      pageSize,
      currentSprintId,
      sprints,
      pageIncrement,
    );

    if (newPivotEndDate && newBeforeLimit && newAfterLimit) {
      setPivotEndDate(newPivotEndDate);
      setBeforeLimit(newBeforeLimit);
      setAfterLimit(newAfterLimit);
    }

    if (newSprintId) {
      setCurrentSprintId(newSprintId);
    }

    return newSprintId;
  }, [sprints, currentSprintId, pageSize]);

  const setSprint = useCallback(
    (sprintId: string): string | undefined => {
      if (!sprints) return;
      const allSprints = sprints?.filter(notNull);
      const requestedIndex = allSprints?.findIndex((sprint) => sprint?.id === sprintId) ?? 0;
      const selectedIndex = allSprints?.findIndex((sprint) => sprint?.id === currentSprintId) ?? 0;
      const inc = requestedIndex - selectedIndex;

      const { newSprintId, newPivotEndDate, newBeforeLimit, newAfterLimit } = increment(
        pageSize,
        currentSprintId,
        sprints,
        inc,
      );

      if (newPivotEndDate && newBeforeLimit && newAfterLimit) {
        setPivotEndDate(newPivotEndDate);
        setBeforeLimit(newBeforeLimit);
        setAfterLimit(newAfterLimit);
      }

      if (newSprintId) {
        setCurrentSprintId(newSprintId);
      }

      return newSprintId;
    },
    [currentSprintId, pageSize, sprints],
  );

  const loadCurrentSprint = useCallback((): string | undefined => {
    return currentlyRunningSprintId && setSprint(currentlyRunningSprintId);
  }, [currentlyRunningSprintId, setSprint]);

  return {
    items,
    currentSprintId,
    loadPrev,
    loadNext,
    loadCurrentSprint,
    setSprint,
  };
};
