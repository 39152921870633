import { compose, Selector } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export interface TaskCommentsWithThreadsQueryResponse<T> {
  query: ReduxFirestoreQuery[];
  selector: Selector<RootState, T[] | undefined>;
}

// TODO: delete/replace with getTaskCommentsWithThreads after implementing task comments threads on FE
export function getTaskComments(orgId: string, taskId: string): Query<UI.UIComment> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(taskId)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks/${taskId}/comments`,
    orderBy: ['createdAt', 'asc'],
    populates: [['authorId', 'users', 'authorIdDocument']],
    storeAs: `comments/${orgId}/${taskId}`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIComment>(query),
  };
}

const removeChildren = ({ parentId }: UI.UIComment): boolean => parentId === null || parentId === undefined;

const removeDeletes = ({ deletedAt, thread }: UI.UIComment & { thread: UI.UIComment[] }): boolean =>
  deletedAt === null || thread.length > 0;

const includeChildren = ({ id }: UI.UIComment) => ({ deletedAt, parentId }: UI.UIComment) =>
  deletedAt === null && parentId === id;

export function getLastTaskComments(orgId: string, taskId: string, limit = 2): Query<UI.UIComment> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(taskId)) {
    return inertQuery();
  }

  const queryTaskComment: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks/${taskId}/comments`,
    limit,
    orderBy: ['createdAt', 'desc'],
    where: [['deletedAt', '==', null]],
    populates: [['authorId', 'users', 'authorIdDocument']],
    storeAs: `org-${orgId}-task-${taskId}-last-comments`,
  };

  return {
    query: [queryTaskComment],
    selector: createStandardSelector(queryTaskComment),
  };
}

export function getTaskCommentsWithThreads(
  orgId: string,
  taskId: string,
): TaskCommentsWithThreadsQueryResponse<UI.UIComment> {
  const taskComments = getTaskComments(orgId, taskId);

  const withThreads = (comments: UI.UIComment[] | undefined): UI.UIComment[] | undefined =>
    comments
      ?.filter(removeChildren)
      .map((comment) => {
        return {
          ...comment,
          thread: comment.hasThread ? comments.filter(includeChildren(comment)) : [],
        };
      })
      .filter(removeDeletes);

  return {
    ...taskComments,
    selector: compose(withThreads, taskComments.selector),
  };
}
