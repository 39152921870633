import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import CompletedSprints from 'components/app/controllers/CompletedSprints';
import NewSprint from 'components/app/controllers/NewSprint';
import UpcomingSprints from 'components/app/controllers/UpcomingSprints';
import SprintHeader from 'components/app/controllers/views/SprintHeader';
import SprintsBacklog from 'components/app/layouts/SprintsBacklog';
import TabBar from 'components/core/controllers/views/TabBar';
import React, { useCallback, useState } from 'react';
import { DragDropContext, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { moveSprintTask, reduxStore } from 'reduxStore';
import { getDroppableDescriptionId, isBacklog } from 'reduxStore/utils/droppable';
import strings from 'resources/i18n';
import { insertNear, useToast } from 'tools';

export default function SprintsLayout(): JSX.Element {
  const { whenError } = useToast();
  const [backlogOverlay, setBacklogOverlay] = useState(false);

  const onDragEnd = ({ source, destination, draggableId }: DropResult): void => {
    if (!destination) return;

    const { id, type, order, all = null } = getDroppableDescriptionId(destination);

    setBacklogOverlay(false);
    const isSameSprint = source.droppableId === destination?.droppableId;
    const isSameIndex = source.index === destination?.index;
    let destinationSprintId: string | null = null;
    let destinationRequirementId: string | null = null;

    if (isSameSprint && isSameIndex) return;
    if (type === 'requirement') {
      destinationRequirementId = id;
    } else {
      destinationSprintId = id;
    }

    const updated = insertNear(draggableId, destination.index, order, all);

    dragSprintTask(draggableId, destinationSprintId, destinationRequirementId, updated);
  };

  const onDragUpdate = ({ source, destination }: DragUpdate): void => {
    const isDestinationBacklog = destination && isBacklog(destination.droppableId);
    const isSourceBacklog = isBacklog(source.droppableId);

    if (isDestinationBacklog && !isSourceBacklog) {
      setBacklogOverlay(true);
    } else {
      setBacklogOverlay(false);
    }
  };

  const dragSprintTask = useCallback(
    (
      taskId: string,
      destinationSprintId: string | null,
      destinationRequirementId: string | null,
      orderedTaskIds: string[] | null,
    ): void => {
      const action = destinationSprintId
        ? moveSprintTask({ destinationSprintId, taskId, orderedTaskIds })
        : moveSprintTask({
            destinationRequirementId: destinationRequirementId as string,
            taskId,
            orderedTaskIds,
          });
      reduxStore.dispatch(action).then(unwrapResult).catch(whenError(strings.task.failedToUpdateTask));
    },
    [whenError],
  );

  return (
    <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result)} onDragUpdate={onDragUpdate}>
      <Root>
        <BacklogWrapper>
          <StyledSprintsBacklog backlogOverlay={backlogOverlay} />
          <Container>
            <SprintHeader />
            <TabBar
              lineMarginX='1rem'
              tabbedContent={[
                {
                  label: 'Upcoming',
                  content: <UpcomingSprints />,
                  route: 'upcoming-sprints',
                },
                {
                  label: 'Completed',
                  content: <CompletedSprints />,
                  route: 'completed-sprints',
                },
              ]}
              tabsSeparatorBottomMargin='small'
            >
              <NewSprint />
            </TabBar>
          </Container>
        </BacklogWrapper>
      </Root>
    </DragDropContext>
  );
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const BacklogWrapper = styled('div', {
  display: 'flex',
  flexGrow: 1,
  backgroundColor: '$grey1',
  overflow: 'hidden',
});

const StyledSprintsBacklog = styled(SprintsBacklog, {
  minWidth: '25.2rem',
  maxWidth: '25.2rem',
  zIndex: 1,
});

const Container = styled('div', {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  overflowX: 'auto',
});
