import { Data } from '@taraai/types';
import { DefinePanelController } from 'components/app/DefinePanel';
import { DefineSidePanelController } from 'components/app/DefineSidePanel/DefineSidePanel';
import { DNDProvider } from 'components/app/DragAndDrop';
import { SidePanelsLayout } from 'components/app/layouts/SidePanelsLayout/SidePanelsLayout';
import { WorkDrawerController } from 'components/app/WorkDrawer';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';

export function DefinePage(): JSX.Element {
  const { requirementId, taskId } = useParams<{
    requirementId: Data.Id.RequirementId;
    taskId: Data.Id.TaskId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();

  const handleTaskSelect = (selected: Data.Id.TaskId): void =>
    history.push(linkTo('task', { orgId, teamId, taskId: selected }));

  const handleRequirementSelect = (selected: Data.Id.RequirementId): void =>
    history.push(linkTo('requirement', { orgId, teamId, requirementId: selected }));

  return (
    <DNDProvider>
      <SidePanelsLayout
        left={
          <WorkDrawerController
            onRequirementSelect={handleRequirementSelect}
            onTaskSelect={handleTaskSelect}
            selectedRequirementId={requirementId}
            selectedTaskId={taskId}
          />
        }
        right={<DefineSidePanelController requirementId={requirementId} taskId={taskId} />}
      >
        <DefinePanelController />
      </SidePanelsLayout>
    </DNDProvider>
  );
}
