import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { DeleteWithConfirmationModal } from 'components/app/controllers/views/DeleteWithConfirmationModal/DeleteWithConfirmationModal';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { reduxStore, selectTeam } from 'reduxStore';
import { remove } from 'reduxStore/teams/actions/remove';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type Props = {
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  onClose: () => void;
};

export function DeleteTeamModal({ orgId, teamId, onClose }: Props): JSX.Element | null {
  const team = useSelector(selectTeam(orgId, teamId));
  const { whenSuccess, whenError } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async (): Promise<void> => {
    if (!isLoaded(team)) {
      return;
    }

    setIsLoading(true);

    await reduxStore
      .dispatch(remove({ teamId }))
      .then(unwrapResult)
      .then(
        whenSuccess(
          strings.formatString(strings.teamDetails.deleteSuccess, {
            name: team.name,
          }) as string,
        ),
      )
      .then(onClose)
      .catch((error) => {
        const { message }: { message: keyof typeof strings.teamDetails.deleteError } = error;
        whenError(
          strings.formatString(strings.teamDetails.deleteError[message] || strings.teamDetails.deleteError.default, {
            name: team.name,
          }) as string,
        )(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [team, teamId, whenSuccess, whenError, onClose]);

  if (!isLoaded(team)) {
    return null;
  }

  return (
    <DeleteWithConfirmationModal isLoading={isLoading} nameToDelete={team.name} onClose={onClose} onSubmit={onSubmit} />
  );
}
