import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RootStateWithProfile } from 'reduxStore/store';

type State = {
  rendered: boolean;
  scroll?: number;
  selected?: SelectedState;
  settings: Settings;
  unfolded: Record<string, boolean>;
};

export type SettingType =
  | 'requirements:show-archived'
  | 'requirements:show-drafts'
  | 'tasks:show-assigned-to-sprint'
  | 'tasks:show-done';

type Settings = Record<SettingType, boolean>;

type SelectedState = { type: WorkDrawerType; id: string; block: 'nearest' | 'center' };

type WorkDrawerType = 'tasks' | 'requirements' | 'repos' | 'imports';

const defaultId = 'all';

const initialState: State = {
  rendered: false,
  selected: undefined,
  settings: {
    'requirements:show-archived': false,
    'requirements:show-drafts': true,
    'tasks:show-done': false,
    'tasks:show-assigned-to-sprint': false,
  },
  unfolded: {},
};

const workDrawerSlice = createSlice({
  name: 'workDrawer',
  initialState,
  reducers: {
    markRendered: (state) => {
      state.rendered = true;
    },
    toggle: (state, { payload: { name, value } }: PayloadAction<{ name: string; value: boolean }>) => {
      state.unfolded[name] = value;
    },
    select: (state, { payload: { type, id } }: PayloadAction<{ type: WorkDrawerType; id: string }>) => {
      state.selected = { type, id, block: state.rendered ? 'nearest' : 'center' };
    },
    scroll: (state, { payload: { scroll } }: PayloadAction<{ scroll?: number }>) => {
      state.scroll = scroll;
    },
    setSetting: (state, { payload: { setting, value } }: PayloadAction<{ setting: SettingType; value: boolean }>) => {
      state.settings[setting] = value;
    },
    selectReset: (state) => {
      state.selected = undefined;
    },
  },
});

const workDrawerActions = workDrawerSlice.actions;
export const workDrawerReducer = workDrawerSlice.reducer;

export function useWorkDrawerMarkRendered(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(workDrawerActions.markRendered());
  }, [dispatch]);
}

export function useWorkDrawerUnfoldedState(
  type: WorkDrawerType,
  id = defaultId,
): [boolean | undefined, (value: boolean) => void] {
  return [useWorkDrawerUnfoldedValue(type, id), useWorkDrawerUnfoldedSetState(type, id)];
}

export function useWorkDrawerUnfoldedValue(type: WorkDrawerType, id = defaultId): boolean | undefined {
  const name = getNameFromParams(type, id);
  const select = useMemo(() => compose((state) => state.unfolded[name], selectWorkDrawerState), [name]);
  return useSelector(select);
}

export function useWorkDrawerSettingsSetState(setting: SettingType): (value: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (value) => {
      dispatch(workDrawerActions.setSetting({ setting, value }));
    },
    [dispatch, setting],
  );
}

export function useWorkDrawerSettingsToggleState(setting: SettingType): () => void {
  const select = useMemo(() => compose((state) => state.settings[setting], selectWorkDrawerState), [setting]);
  const currentValue = useSelector(select);
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(workDrawerActions.setSetting({ setting, value: !currentValue }));
  }, [currentValue, dispatch, setting]);
}

export function useWorkDrawerSettingsState(setting: SettingType): boolean {
  const select = useMemo(() => compose((state) => state.settings[setting], selectWorkDrawerState), [setting]);
  return useSelector(select);
}

export function useWorkDrawerUnfoldedSetState(type: WorkDrawerType, id = defaultId): (value: boolean) => void {
  const dispatch = useDispatch();
  const name = getNameFromParams(type, id);
  return useCallback(
    (value) => {
      dispatch(workDrawerActions.toggle({ name, value }));
    },
    [dispatch, name],
  );
}

function getNameFromParams(type: WorkDrawerType, id: string): string {
  return `${type}-${id}`;
}

export function useWorkDrawerSelectedValue(type: WorkDrawerType, id: string): [boolean, 'nearest' | 'center'] {
  const select = useMemo(
    () =>
      compose(
        (state): [boolean, 'nearest' | 'center'] => [
          state.selected?.type === type && state.selected?.id === id,
          state.selected?.block ?? 'nearest',
        ],
        selectWorkDrawerState,
      ),
    [id, type],
  );
  return useSelector(select);
}

export function useWorkDrawerSelectedState(): SelectedState | undefined {
  const select = useMemo(() => compose((state) => state.selected, selectWorkDrawerState), []);
  return useSelector(select);
}

export function useWorkDrawerSelectedSetState(): (type: WorkDrawerType, id: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (type, id) => {
      dispatch(workDrawerActions.select({ type, id }));
    },
    [dispatch],
  );
}

export function useWorkDrawerScrollState(): {
  getScroll: () => number | undefined;
  setScroll: (scroll?: number) => void;
} {
  const store = useStore();
  return useMemo(
    () => ({
      /**
       * In order to get the current value from the store we have to avoid useSelector, as it can
       * return an old value since the new one needs to go through the whole React cycle to propagate.
       */
      getScroll: compose((state) => state.scroll, selectWorkDrawerState, store.getState),
      setScroll: (scroll) => {
        store.dispatch(workDrawerActions.scroll({ scroll }));
      },
    }),
    [store],
  );
}

export function useWorkDrawerSelectedResetState(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(workDrawerActions.selectReset());
  }, [dispatch]);
}

function selectWorkDrawerState(state: RootStateWithProfile): State {
  return state.workDrawer;
}
