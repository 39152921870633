import React from 'react';
import { strings } from 'resources';

import { FilterElementLayout } from './FilterElementLayout';
import { ProgressBackground, ProgressBackgroundMode } from './ProgressBackground';

const getBackgroundMode = (isOverloaded: boolean, isAnyFilterActive: boolean): ProgressBackgroundMode => {
  if (isAnyFilterActive) {
    return 'transparent';
  }
  if (isOverloaded) {
    return 'secondary';
  }
  return 'primary';
};

export function PlannedEffortEntry({
  totalPlannedEffort,
  recommendedEffort,
  filterSelected,
}: {
  totalPlannedEffort: number;
  recommendedEffort: number;
  filterSelected: boolean;
}): JSX.Element {
  const isOverloaded = totalPlannedEffort > recommendedEffort;
  return (
    <ProgressBackground
      color={isOverloaded ? '$failure' : '$grey7'}
      mode={getBackgroundMode(isOverloaded, filterSelected)}
      progress={1}
    >
      <FilterElementLayout
        active={!filterSelected}
        color={isOverloaded ? '$failure' : '$grey7'}
        effort={totalPlannedEffort}
        icon={isOverloaded ? 'redAlert' : undefined}
        label={strings.sprintFilters.plannedEffort}
        summary={strings.formatString(strings.sprintFilters.recommendedEffort, { recommendedEffort }) as string}
      />
    </ProgressBackground>
  );
}
