import { createSelector } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import DraggableFeatureCardList from 'components/app/controllers/DraggableFeatureCardList';
import LegacyTaskModalContext from 'components/app/controllers/LegacyTaskModalContext';
import BacklogProductHeader from 'components/app/controllers/views/BacklogProductHeader';
import { css } from 'emotion';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { incompleteRequirementBacklogTasks, orderedDocuments, selectActiveWorkspace } from 'reduxStore';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'backlogTasksCount' | 'archived' | 'orderedTaskIds'>;
export interface RequirementTasksControllerProps extends React.HTMLProps<HTMLDivElement> {
  requirement: RequirementFragment;
  getRequirements: () => void;
  backlogOverlay: boolean;
}

function RequirementTasksController({
  requirement,
  getRequirements,
  backlogOverlay,
}: RequirementTasksControllerProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const { openModal } = useContext(LegacyTaskModalContext);

  const incompleteRequirementBacklogTasksSlice = incompleteRequirementBacklogTasks(orgId, requirement.id);

  useFirestoreConnect(incompleteRequirementBacklogTasksSlice.query);

  const sortedAllTasks = createSelector(incompleteRequirementBacklogTasksSlice.selector, (allTasks) => allTasks);

  const tasks = useSelector(orderedDocuments(sortedAllTasks, () => requirement?.orderedTaskIds ?? [])) ?? [];

  const toggleModal = useCallback(
    (task) => {
      openModal(task, requirement.title);
    },
    [openModal, requirement.title],
  );

  return (
    <>
      <div
        className={css`
          padding: 1rem 0.5rem;
          background-color: #ffffff;
          box-shadow: 0 0.0625rem 0 0 #eaeef5;
        `}
      >
        <BacklogProductHeader getRequirements={getRequirements} orgId={orgId} requirement={requirement} />
      </div>
      <DraggableFeatureCardList
        disableOrdering={backlogOverlay}
        space='$16px'
        tasks={tasks}
        toggleModal={toggleModal}
      />
    </>
  );
}
export default RequirementTasksController;
