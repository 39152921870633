import { noop, notUndefined } from '@taraai/utility/dist/functions';
import { CompositeDecorator, ContentState, EditorState, SelectionState } from 'draft-js';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebounced } from 'tools/utils/hooks/useDebounced';

import { composePlugins, createDefaultPlugin, useHandlers } from './plugins';
import { RichEditorHandlers, RichEditorPlugin } from './types';

/** Amount of time to wait after user stops typing before autosaving */
const autoSaveWait = 1000;

type State = 'initialized' | 'modified' | 'saved';

export const RichEditorContext = createContext<{
  editorState: EditorState;
  forceSelectedEntity: string | undefined;
  handlers: RichEditorHandlers;
  onChange: (editorState: EditorState) => void;
  provided: boolean;
  readOnly: boolean;
  save: (content: ContentState) => void;
  setDisableHandlers: Dispatch<SetStateAction<boolean>>;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
  setForceSelectedEntity: Dispatch<SetStateAction<string | undefined>>;
  setThreadIds: Dispatch<SetStateAction<string[]>>;
  showThread: (threadId: string) => void;
  singleLine: boolean;
  state: State;
  threadIds: string[];
}>({
  editorState: EditorState.createEmpty(),
  forceSelectedEntity: undefined,
  handlers: {},
  onChange: noop,
  provided: false,
  readOnly: false,
  save: noop,
  setDisableHandlers: noop,
  setEditorState: noop,
  setForceSelectedEntity: noop,
  setThreadIds: noop,
  showThread: noop,
  singleLine: false,
  state: 'initialized',
  threadIds: [],
});

interface Props<Value> {
  children: ReactNode;
  initialValue?: Value;
  onSave?: (value: Value, content: ContentState) => void;
  onTextChange?: (text: string) => void;
  plugin?: RichEditorPlugin<((source: Value) => ContentState) | undefined>;
  readOnly?: boolean;
  selectAllContent?: boolean;
  singleLine?: boolean;
  saveStrategy?: 'saveOnReturn' | 'autoSave' | 'both';
}

/**
 * The main goal of `RichEditorProvider` component is to
 * provide a single source of truth for the internal editor state
 * and to share it between `RichEditor` and `Toolbar` components.
 */
export function RichEditorProvider<Value>({
  children,
  initialValue,
  onSave,
  onTextChange,
  plugin,
  readOnly: forceReadOnly,
  selectAllContent = false,
  singleLine = false,
  saveStrategy = 'autoSave',
}: Props<Value>): JSX.Element {
  const [state, setState] = useState<State>('initialized');
  const [disableHandlers, setDisableHandlers] = useState(false);
  const [forceSelectedEntity, setForceSelectedEntity] = useState<string>();
  const saveOnReturn = saveStrategy === 'saveOnReturn' || saveStrategy === 'both';
  const pluginWithDefaults = useMemo(
    () =>
      composePlugins(...[plugin, createDefaultPlugin({ saveOnReturn })].filter(notUndefined)) as Required<
        RichEditorPlugin<(source: Value) => ContentState>
      >,
    [plugin, saveOnReturn],
  );

  const [editorState, setEditorState] = useState(() => {
    const decorator = new CompositeDecorator(pluginWithDefaults.decorator);
    if (!initialValue) return EditorState.createEmpty(decorator);
    const content = pluginWithDefaults.pipeline.read(initialValue);
    const initialEditorState = EditorState.createWithContent(content, decorator);
    return selectAllContent ? selectAllEditorContent(initialEditorState) : initialEditorState;
  });

  const save = useCallback(
    (content: ContentState) => {
      const value = pluginWithDefaults.pipeline.save(content);
      onSave?.(value, content);
      setState('saved');
    },
    [onSave, pluginWithDefaults],
  );

  const [threadIds, setThreadIds] = useState<string[]>([]);

  // @TODO - show thread component when designs will be ready to implement that
  const showThread = (threadId: string): string => {
    return threadId;
  };

  const handlers = useHandlers(pluginWithDefaults, { save, setEditorState, setForceSelectedEntity, setThreadIds });

  const debouncedSave = useDebounced(save, autoSaveWait);
  const prevEditorStateRef = useRef(editorState);
  const autoSave = saveStrategy === 'autoSave' || saveStrategy === 'both';

  useEffect(() => {
    const prevEditorState = prevEditorStateRef.current;
    prevEditorStateRef.current = editorState;
    const prevContent = prevEditorState.getCurrentContent();
    const nextContent = editorState.getCurrentContent();

    if (nextContent.getPlainText() !== prevContent.getPlainText()) onTextChange?.(nextContent.getPlainText());

    if (nextContent !== prevContent && autoSave) debouncedSave(nextContent);
  }, [autoSave, debouncedSave, editorState, onTextChange]);

  const onChange = useCallback((nextState: EditorState) => {
    setState('modified');
    setEditorState(nextState);
  }, []);

  const readOnly =
    forceReadOnly ||
    // Draft.js source code says that if readOnly is on, all handlers are disabled
    disableHandlers;

  return (
    <RichEditorContext.Provider
      value={useMemo(
        () => ({
          editorState,
          forceSelectedEntity,
          handlers,
          onChange,
          provided: true,
          readOnly,
          save,
          setDisableHandlers,
          setEditorState,
          setForceSelectedEntity,
          setThreadIds,
          showThread,
          singleLine,
          state,
          threadIds,
        }),
        [editorState, forceSelectedEntity, handlers, onChange, readOnly, save, singleLine, state, threadIds],
      )}
    >
      {children}
    </RichEditorContext.Provider>
  );
}

export function useRichEditorContextGuard(): void {
  if (!useContext(RichEditorContext).provided) throw new Error('Tried to use RichEditor without RichEditorProvider');
}

function selectAllEditorContent(state: EditorState): EditorState {
  const content = state.getCurrentContent();
  return EditorState.forceSelection(
    state,
    new SelectionState({
      anchorKey: content.getFirstBlock().getKey(),
      anchorOffset: 0,
      focusKey: content.getLastBlock().getKey(),
      focusOffset: content.getLastBlock().getLength(),
    }),
  );
}
