import { createAsyncThunk } from '@reduxjs/toolkit';
import { UISprint, UISprintChangeset } from '@taraai/types/dist/ui';
import { updateTeam } from 'reduxStore/teams';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { funcLog } from 'tools';
import { reportError } from 'tools/libraries/stackdriver';

import { updateSprint } from './update';

export type StartSprintPayload = Pick<UISprint, 'id' | 'startDate' | 'endDate' | 'sprintName'>;

export const startSprint = createAsyncThunk(
  'StartSprint',
  async (payload: StartSprintPayload, { dispatch, extra }): Promise<void> => {
    try {
      const { getOrgId, getFirestore } = extra as ExtraAPI;
      const orgId = getOrgId();
      const firestore = getFirestore();

      const { id: sprintId } = payload;

      const validatedChangeset = decode<UISprintChangeset>(payload, 'UISprintChangeset');

      await firestore.runTransaction(async (transaction) => {
        const orgRef = firestore.doc(`orgs/${orgId}`);

        // Validate that the selected sprint exists
        const currentSprintDoc = await orgRef.collection('sprints').doc(sprintId).get();

        if (!currentSprintDoc.exists) {
          throw new Error(`Sprint document ${sprintId} or it's data does not exist`);
        }

        const { teamId } = currentSprintDoc.data() || {};

        if (!teamId) {
          throw new Error('teamId can not be empty');
        }

        // Validate that team exists
        const teamDoc = await orgRef.collection('teams').doc(teamId).get();

        if (!teamDoc.exists) {
          throw new Error(`Team ${teamId} does not exist`);
        }

        // Set start/end timestamps on the selected sprint
        await dispatch(
          updateSprint({
            ...validatedChangeset,
            meta: { transaction },
          }),
        );

        // Update team settings to specify current sprint
        await dispatch(
          updateTeam({
            id: teamId,
            currentSprintId: sprintId,
            meta: { transaction },
          }),
        );
      });
    } catch (error) {
      funcLog('Failed to start sprint', {
        error,
        ...error,
      });
      reportError(error);
      throw error;
    }
  },
);
