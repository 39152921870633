import { Data } from '@taraai/types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { getOrg, selectActiveWorkspace, selectProfile } from 'reduxStore';
import { getIntegrationSecret } from 'reduxStore/integrations/queries/gitlab/gitlabQueries';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { GitLabProject } from 'resources/assets';
import { InstallGitHubData, InstallSlackData } from 'types/install-integration';
import useImage from 'use-image';

const useStoreForIntegrations = (): {
  organizationId: string;
  integrations: Data.IntegrationStanza[] | undefined;
  userHasPermission: boolean;
  authToken: string;
} => {
  const orgId = useSelector(selectActiveWorkspace);

  // user data
  const profile = useSelector(selectProfile);
  const userHasPermission = profile.token?.claims?.orgs[orgId]?.accessLevel === 'admin';
  const authToken = profile.token?.token ?? '';

  // org data
  const currentOrg = useSelector(selectOrganization(orgId));
  const { integrations } = currentOrg;

  return { organizationId: orgId, integrations, userHasPermission, authToken };
};

const getGithubIntegration = (
  integrations: Data.IntegrationStanza[] | undefined,
): Data.GithubIntegrationPreview | undefined => {
  const stanza = integrations?.find((integration) => integration.service === 'github');

  if (stanza?.service !== 'github') {
    return undefined;
  }

  return stanza;
};

const getManageReposURL = (integration: Data.GithubIntegrationPreview): string => {
  if (integration.ownerType === 'User') {
    return `https://github.com/settings/installations/${integration.installationId}`;
  }
  return `https://github.com/organizations/${integration.ownerName}/settings/installations/${integration.installationId}`;
};

const getGithubActionURL = (): string => {
  const actionURL = process.env.REACT_APP_GITHUB_APP_INSTALLATION_URL;

  if (!actionURL) {
    // eslint-disable-next-line no-console
    console.error('No REACT_APP_GITHUB_APP_INSTALLATION_URL env variable');
    return '';
  }

  return actionURL;
};

export const useInstallGitHub = (): InstallGitHubData => {
  const { integrations, userHasPermission } = useStoreForIntegrations();
  const gitHubIntegration = getGithubIntegration(integrations);
  const actionURL = getGithubActionURL();

  // Integration is yet to be installed
  if (!gitHubIntegration) {
    return {
      service: 'github',
      actionURL,
      isFeatureEnabled: true,
      userHasPermission,
      parameters: [],
      saveOrgForSetupIntegration: true,
    };
  }

  const manageReposURL = getManageReposURL(gitHubIntegration);

  // Integration is installed and a user can manage it
  return {
    service: 'github',
    id: gitHubIntegration?.id,
    manageReposURL,
    isFeatureEnabled: true,
    userHasPermission,
    parameters: [],
    actionURL,
    saveOrgForSetupIntegration: true,
  };
};

export const useInstallSlack = (): InstallSlackData => {
  const { integrations, userHasPermission } = useStoreForIntegrations();

  const slackInstallation = integrations?.find(
    (integration: Data.IntegrationStanza) => integration.service === 'slack',
  );

  const actionURL = getSlackActionURL();

  return {
    service: 'slack',
    id: slackInstallation?.id,
    actionURL,
    parameters: [
      {
        name: 'client_id',
        value: String(process.env.REACT_APP_SLACK_APP_CLIENT_ID),
      },
      {
        name: 'scope',
        value: String(process.env.REACT_APP_SLACK_APP_SCOPE),
      },
      {
        name: 'redirect_uri',
        value: String(process.env.REACT_APP_SLACK_APP_REDIRECT_URL),
      },
    ],
    isFeatureEnabled: true,
    userHasPermission,
    saveOrgForSetupIntegration: true,
  };
};

type InstallGitlabData = {
  isInstalled: boolean;
  closeModal: () => void;
  openModal: () => void;
  isModalOpen: boolean;
  secretToken: string | undefined;
  instructionImgUrl: string | undefined;
};

export const useInstallGitlab = (orgId: Data.Id.OrganizationId): InstallGitlabData => {
  const integrationStanzas = useSelector(compose((org) => org?.integrations, getOrg(orgId).selector));

  const integrationId = integrationStanzas?.find((integration: { service: string }) => integration.service === 'gitlab')
    ?.id;

  const secretSlice = getIntegrationSecret(orgId, integrationId);
  useFirestoreConnect(secretSlice.query);
  const secretToken = useSelector(secretSlice.selector)?.[0]?.secretToken;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [instructionImg] = useImage(GitLabProject);

  return {
    isInstalled: !!integrationId,
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    closeModal: () => setIsModalOpen(false),
    secretToken,
    instructionImgUrl: instructionImg?.src,
  };
};

const getSlackActionURL = (): string => {
  const actionURL = process.env.REACT_APP_SLACK_APP_INSTALLATION_URL;

  if (!actionURL) {
    // eslint-disable-next-line no-console
    console.error('No REACT_APP_SLACK_APP_INSTALLATION_URL env variable');
    return '';
  }

  return actionURL;
};
