import { Avatar, Box, Pill } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { Section } from 'components/app/TaskDetails/common/Section';
import { SectionType } from 'components/core/controllers/Selector';
import React from 'react';
import { reduxStore, updateTask } from 'reduxStore';
import { strings } from 'resources';

type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

type Props = {
  assigneeDocument: UI.UITask['assigneeDocument'];
  taskId: UI.UITask['id'];
  users: UserFragment[];
};

export function Assignee({ assigneeDocument, taskId, users }: Props): JSX.Element {
  const sections: SectionType<UserFragment>[] = [{ id: 'users', options: users }];
  const selection: UserFragment[] = assigneeDocument ? [assigneeDocument] : [];

  const setTaskAssignee = async ({ id }: UserFragment): Promise<void> => {
    await reduxStore.dispatch(updateTask({ id: taskId, assignee: id }));
  };

  const removeTaskAssignee = async (): Promise<void> => {
    await reduxStore.dispatch(updateTask({ id: taskId, assignee: null }));
  };

  return (
    <Section
      right={
        <UserSelector
          closePopupOnSelection
          headerTitle={strings.taskSidebar.modules.assignee.dropdown}
          onDeselectOption={removeTaskAssignee}
          onSelectOption={setTaskAssignee}
          optionSize='small'
          renderSelectButton={({ openPopup }) =>
            !assigneeDocument ? (
              <Box.Button onClick={openPopup}>
                <Avatar />
              </Box.Button>
            ) : (
              <Pill
                maxWidth='$full'
                name={assigneeDocument.name}
                onClick={openPopup}
                url={assigneeDocument.avatarURL}
              />
            )
          }
          searchPlaceholder={strings.taskSidebar.modules.header.searchUsers}
          sections={sections}
          selection={selection}
          selectorPosition='left'
        />
      }
      title={strings.taskSidebar.modules.assignee.title}
    />
  );
}
