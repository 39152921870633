import { createAsyncThunk } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { Write } from 'redux-firestore';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const deleteTask = createAsyncThunk(
  'DeleteTask',
  async (taskIds: Pick<UI.UITask, 'id'> | Pick<UI.UITask, 'id'>[], { extra, getState }) => {
    const state = getState() as RootState;
    const { getOrgId, getFirestore, getUserId } = extra as ExtraAPI;
    const orgId = getOrgId();
    const userId = getUserId(state);
    const firestore = getFirestore();
    const path = `orgs/${orgId}/tasks`;
    const deleteUpdate = { deleted: true, updatedAt: ['::serverTimestamp'], updatedBy: userId };
    const ids = Array.isArray(taskIds) ? taskIds.map((taskId) => taskId.id) : [taskIds.id];
    const writes = ids.map(
      (taskId): Write => {
        const { id, ...data } = decode<UI.UITaskDeleteChangeset>(
          { ...deleteUpdate, id: taskId },
          'UITaskDeleteChangeset',
        );
        return {
          doc: id,
          collection: path,
          data,
        };
      },
    );

    await firestore.mutate(writes);

    return writes.map((write) => write.doc);
  },
);
