import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import LegacyFeatureCard from 'components/app/controllers/LegacyFeatureCard';
import ProgressController from 'components/app/controllers/ProgressController';
import { css } from 'emotion';
import React from 'react';

import { GanttChartFooter } from './GanttChartFooter';
import { getGitStatus } from './helpers';
import { TableHeader } from './TableHeader';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

interface Props {
  assignees: UserFragment[];
  dates: string[];
  handleFiltering: (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => void;
  orgId: Data.Id.OrganizationId;
  requirements: RequirementFragment[];
  sprint: UI.UISprint;
  tasks: UI.UITask[];
  today: string;
  toggleModal: (task: UI.UITask, tasks: UI.UITask[]) => void;
}

export default function GanttChart({
  sprint,
  dates,
  tasks = [],
  today,
  toggleModal,
  orgId,
  ...props
}: Props): JSX.Element {
  const gridTemplateColumns = { gridTemplateColumns: `40% repeat(${dates.length}, 1fr)` };

  return (
    <Container>
      <GanttChartTable>
        <TableHeader dates={dates} today={today} {...props} />
        <VerticalTableLines style={gridTemplateColumns}>
          <span />
          {dates.map((date) => (
            <VerticalLinePerDate key={date} today={date === today} />
          ))}
        </VerticalTableLines>
        {tasks.map((task) => (
          <TaskRow key={task.id} style={gridTemplateColumns}>
            <LegacyFeatureCard
              key={task.id}
              background='transparent'
              onClick={() => toggleModal(task, tasks)}
              task={task}
              title={css`
                outline: 0;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
              withBorder={false}
            />
            <ProgressController
              dates={dates}
              gitStatus={getGitStatus(task)}
              history={task.statusHistory}
              sprint={sprint}
              status={task.status}
            />
          </TaskRow>
        ))}
      </GanttChartTable>
      <GanttChartFooter orgId={orgId} sprint={sprint} tasks={tasks} />
    </Container>
  );
}

const Container = styled('div', {
  minWidth: '46.875rem',
  margin: '0 0 $20px',
  boxShadow: '$ganttChart',
});

const GanttChartTable = styled('div', {
  position: 'relative',
  minHeight: '18.2rem',
});

const focusBorder = 'borderWidths.$1px solid colors.$focus';

const VerticalTableLines = styled('div', {
  display: 'grid',
  position: 'absolute',
  height: 'calc(100% - 3rem)',
  width: '$full',
  backgroundColor: 'transparent',
});

const VerticalLinePerDate = styled(
  'span',
  { borderLeft: 'borderWidths.$1px solid colors.$grey3' },
  {
    today: {
      true: {
        backgroundColor: 'rgba(29, 152, 255, 0.04)',
        borderLeft: focusBorder,
        borderRight: focusBorder,
      },
    },
  },
);

const TaskRow = styled('div', {
  'display': 'grid',
  'cursor': 'pointer',

  ':nth-of-type(odd)': {
    backgroundColor: '$grey1',
  },

  '> span:not(:last-of-type)': {
    display: 'none',
  },
});
