import { unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { ToastContextType } from 'components/app/controllers/Toast/ToastContext';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { reduxStore, updateTask } from 'reduxStore';
import { strings } from 'resources/i18n';
import { debounce, useToast } from 'tools';

interface SetTaskEffortDebouncedProps {
  taskId?: string;
  effort: number;
  toastContext: ToastContextType;
}

type TaskFragment = Pick<UI.UITask, 'id' | 'effortLevel'>;

const setTaskEffortDebounced = debounce(({ taskId, effort, toastContext }: SetTaskEffortDebouncedProps): void => {
  reduxStore
    .dispatch(
      updateTask({
        id: taskId ?? '',
        effortLevel: effort,
      }),
    )
    .then(unwrapResult)
    .catch(() =>
      toastContext.addToast({
        type: 'error',
        message: strings.task.failedToUpdateTask,
      }),
    );
}, 1000);

export interface EffortSelectorProps {
  task?: TaskFragment;
  Ref?: React.RefObject<HTMLInputElement>;
  dataCy?: string;
}

export default function EffortSelector({ task, Ref, dataCy }: EffortSelectorProps): JSX.Element {
  const effortLevel = task?.effortLevel ?? 1;

  const [inputEffortLevel, setInputEffortLevel] = useState(effortLevel);

  const toastContext = useToast();

  useEffect(() => {
    setInputEffortLevel(effortLevel);
  }, [effortLevel]);

  const handleSetEffortLevel = useCallback(
    (event) => {
      const { value } = event.target;
      setInputEffortLevel(value);

      const parsedValue = Number(value);

      if (parsedValue < 1 || parsedValue > 99) {
        // do not save invalid values
        return;
      }
      setTaskEffortDebounced({
        taskId: task?.id,
        effort: parsedValue,
        toastContext,
      });
    },
    [task, toastContext],
  );

  return (
    <div
      className={css`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
        border-radius: 0.1875rem;
        border-style: none;
        border: solid 0.0625rem #dee3ec;
        height: 2rem;
        min-width: 2rem;
        padding: 0rem;
        width: 2rem;
        :hover {
          border-color: #1d98ff;
        }
        :active {
          border-color: #1d98ff;
        }
      `}
      data-cy={dataCy}
    >
      <input
        ref={Ref}
        className={css`
          border-style: none;
          width: 100%;
          height: 100%;
          color: rgb(148, 156, 175);
          text-align: center;
          font-size: 0.875rem;
          font-weight: 500;
          padding: 0px;
        `}
        data-cy={dataCy}
        max={99}
        min={1}
        onChange={handleSetEffortLevel}
        onClick={(event: React.SyntheticEvent): void => event.stopPropagation()}
        type='number'
        value={inputEffortLevel}
      />
    </div>
  );
}
