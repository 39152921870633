import { Tooltip } from '@taraai/design-system';
import Tara, { Data } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectActiveTeam } from 'reduxStore';
import { Asana, Github, Slack, Trello } from 'resources/assets';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface TaskModalHeaderProps {
  taskId: Data.Id.TaskId;
  deleteCurrentTask?: () => void;
  copyTaskId?: () => void;
  requirementTitle?: string;
  currentOrg: Data.Id.OrganizationId;
  requirementId?: string;
  taskService?: Tara.Service;
  taskCopiedText?: boolean;
  closeModal?: () => void;
  setTaskCopiedText?: (value: boolean) => void;
}

/**
 * TaskModalHeader
 * view for header in task modal
 *
 */
export default function TaskModalHeader({
  taskId,
  deleteCurrentTask,
  copyTaskId,
  requirementTitle,
  currentOrg,
  requirementId,
  taskService,
  taskCopiedText,
  setTaskCopiedText,
  closeModal,
}: TaskModalHeaderProps): JSX.Element {
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();

  const getIconSrcForTaskService = (service: Tara.Service): string | null => {
    switch (service) {
      case 'trello':
        return Trello;
      case 'github':
        return Github;
      case 'asana':
        return Asana;
      case 'slack':
        return Slack;
      default:
        return null;
    }
  };

  const taskServiceSrc = taskService && getIconSrcForTaskService(taskService);

  const taskIdAndRequirement = (
    <div
      className={css`
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-top: 1px;
      `}
    >
      <span
        className={css`
          color: #949caf;
          text-transform: uppercase;
          margin-right: 0.25em;
        `}
        data-cy={taskModalTestIds.TASK_ID}
      >
        {strings.formatString(strings.task.taskId, { taskId })}
      </span>
      {requirementTitle && requirementId && (
        <span
          className={css`
            color: #949caf;
            margin-right: 0.25em;
          `}
        >
          {strings.task.in}
        </span>
      )}
      {requirementTitle && requirementId && (
        <a
          className={css`
            color: #1d98ff;
          `}
          data-cy={taskModalTestIds.REQUIREMENT}
          href={['/', currentOrg].join('')}
          onClick={(event: React.SyntheticEvent): void => {
            event.preventDefault();
            closeModal?.();
            history.push(
              linkTo('requirement', {
                orgId: currentOrg,
                teamId,
                requirementId,
              }),
            );
          }}
        >
          {requirementTitle}
        </a>
      )}
    </div>
  );

  return (
    <div
      className={css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        className={css`
          display: flex;
        `}
      >
        {taskServiceSrc && taskService && (
          <Tooltip placement='left' title={`${strings.task.importedFrom}${taskService.toUpperCase()}`}>
            <img
              alt=''
              className={css`
                width: 16px;
                height: 16px;
                margin-right: 8px;
              `}
              src={taskServiceSrc}
            />
          </Tooltip>
        )}
        {taskIdAndRequirement}
      </div>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        {setTaskCopiedText && (
          <div onMouseEnter={(): void => setTaskCopiedText(false)}>
            <Tooltip title={taskCopiedText ? strings.task.copied : strings.task.copyTask}>
              <div
                className={css`
                  display: flex;
                `}
                data-cy={taskModalTestIds.COPY_URL}
              >
                <Icon
                  className={css`
                    background-color: #eaeef5;
                    padding: 6px;
                    height: 12px;
                    width: 12px;
                    border-radius: 2px;
                    :hover {
                      cursor: pointer;
                      opacity: 0.6;
                    }
                  `}
                  data-cy={taskModalTestIds.COPY_URL}
                  name='copy'
                  onClick={copyTaskId}
                />
              </div>
            </Tooltip>
          </div>
        )}
        <div
          className={css`
            padding-top: 0.1875rem;
            position: relative;
            fill: #949caf;
            width: 1.5625rem;
            margin-left: 14px;
          `}
          data-cy={taskModalTestIds.THREE_DOT_MENU}
        >
          {deleteCurrentTask && (
            <Menu
              options={[
                {
                  title: strings.tasks.delete,
                  onSelect: deleteCurrentTask,
                  dataCy: taskModalTestIds.DELETE_TASK,
                },
              ]}
            >
              <Icon
                className={css`
                  fill: #949caf;
                  width: 0.875rem;
                  padding: 0rem;
                  padding-top: 0.125rem;
                  cursor: pointer;
                `}
                data-cy={taskModalTestIds.THREE_DOT_MENU}
                name='meatballs'
              />
            </Menu>
          )}
        </div>
      </div>
    </div>
  );
}
