/* istanbul ignore file */
import firebase from 'firebase/app';
import React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import { reduxStore } from './store';

export function ReduxContext({ children }: React.HTMLProps<HTMLElement>): JSX.Element {
  return (
    <Provider store={reduxStore}>
      <ReactReduxFirebaseProvider
        config={{
          userProfile: 'users',
          enableLogging: false,
          enableClaims: true,
          useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
        }}
        createFirestoreInstance={createFirestoreInstance}
        dispatch={reduxStore.dispatch}
        firebase={firebase}
      >
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}
