import { Avatar, Box, Fluid, HStack, Text, VStack } from '@taraai/design-system';
import { UI } from '@taraai/types';
import moment from 'moment';
import React from 'react';
import { strings } from 'resources/i18n';
import { toDate } from 'tools/libraries/helpers/dates';

export interface RevisionEntryProps extends React.HTMLProps<HTMLDivElement> {
  revision: UI.UITaskRevision;
}

const statusLabels = ['To Do', 'Doing', 'Done'];
const { backlog } = strings.revisions;
function parseDisplayString(revisionValue: string | number | null | undefined, revisionProperty: string): string {
  if (revisionProperty === 'status') {
    return statusLabels[Number(revisionValue) || 0];
  }
  if (revisionProperty === 'sprint' && revisionValue === undefined) {
    return backlog;
  }
  return revisionValue ? revisionValue.toString() : '';
}

export function RevisionEntry({ revision }: RevisionEntryProps): JSX.Element {
  return (
    <HStack>
      <Fluid>
        <Box spaceRight='$12px'>
          <VStack>
            <Text size='$12px'>
              {strings.formatString(strings.revisions.name, {
                name: revision.name ?? strings.revisions.taraBot,
              })}
              {revision.property === 'sprint'
                ? strings.formatString(strings.revisions.sprintTile, {
                    current: parseDisplayString(revision.current, revision.property),
                    previous: parseDisplayString(revision.previous, revision.property),
                  })
                : strings.formatString(strings.revisions.updatedTitle, {
                    property: revision.property === 'effortLevel' ? strings.revisions.effort : revision.property,
                    current: parseDisplayString(revision.current, revision.property),
                    previous: parseDisplayString(revision.previous, revision.property),
                  })}
            </Text>
            <Text size='$10px'>
              {strings.formatString(strings.revisions.updatedAt, {
                updatedAt: moment(toDate(revision.updatedAt)).format('LL'),
                time: moment(toDate(revision.updatedAt)).format('LT'),
              })}
            </Text>
          </VStack>
        </Box>
      </Fluid>
      <Avatar title={revision.user?.name} url={revision.user?.avatarURL ?? undefined} />
    </HStack>
  );
}
