/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tooltip } from '@taraai/design-system';
import { noop } from '@taraai/utility';
import { css, cx } from 'emotion';
import React from 'react';

type NavigationItemProps = {
  title: string;
  active?: boolean;
  onClick?: () => void;
  dataCy?: string;
};

/**
 * Component that contains common styling for `<NavigationLink>` and `<NavigationButton>`.
 */
export const NavigationItem: React.FC<NavigationItemProps> = ({ title, active = false, children, onClick, dataCy }) => {
  const activeClassName = css`
    background-color: #f1f5fd;
    color: #4550ce;
  `;
  return (
    <Tooltip placement='right' title={title}>
      <div
        className={cx(
          css`
            margin-bottom: 1.25rem;
            position: relative;
            display: block;
            height: 3.125rem;
            width: 3.125rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: none;
            font-size: 0.875rem;
            border-radius: 50%;
            background-color: #ffffff;
            color: #949caf;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
              background-color: #f1f5fd;
            }
          `,
          active && activeClassName,
        )}
        data-cy={dataCy}
        onClick={onClick ?? noop}
      >
        {children}
      </div>
    </Tooltip>
  );
};
