import { UI } from '@taraai/types';
import LegacyTaskModalContext from 'components/app/controllers/LegacyTaskModalContext';
import LegacyTaskModalController from 'components/app/controllers/LegacyTaskModalController';
import { browserHistory } from 'components/Router/history';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';

export interface LegacyTaskModalProviderProps extends React.HTMLProps<HTMLElement> {
  children?: JSX.Element | JSX.Element[] | string;
}

/**
 * TaskModalProvider
 * provider for task modal
 *
 */
export default function LegacyTaskModalProvider({ children }: LegacyTaskModalProviderProps): JSX.Element {
  const [taskId, setTaskId] = useState<string | null>(null);
  const [navigationCategory, setNavigationCategory] = useState<string | null>(null);

  const [initialPath, setInitialPath] = useState('');

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const location = useLocation();
  const history = useHistory();

  const openModal = useCallback(
    ({ id }: UI.UITask, category: string) => {
      if (orgId && teamId) {
        setInitialPath(location.pathname);
        setTaskId(id);
        setNavigationCategory(category);

        // Change URL without navigating via react-router-dom
        browserHistory.pushRoute(linkTo('taskDetails', { orgId, teamId, taskId: id }));
      }
    },
    [location.pathname, orgId, teamId],
  );

  const closeModal = useCallback(() => {
    setTaskId(null);
    setNavigationCategory(null);
    history.replace(initialPath);
  }, [history, initialPath]);

  return (
    <LegacyTaskModalContext.Provider value={{ openModal }}>
      {taskId &&
        createPortal(
          <LegacyTaskModalController
            key={taskId}
            closeModal={closeModal}
            navigationCategory={navigationCategory}
            taskId={taskId}
          />,
          document.body,
        )}
      {children}
    </LegacyTaskModalContext.Provider>
  );
}
