import React, { useCallback, useEffect, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { getOrgList, selectActiveOrgIds, selectActiveWorkspace } from 'reduxStore';
import { segment } from 'tools/libraries/analytics';
import { useClickOutside } from 'tools/utils/hooks/useClickOutside';

import { WorkspaceSwitcherNavButton } from './NavButton';
import { WorkspaceSwitcherPopup } from './Popup';

export function WorkspaceSwitcher(): JSX.Element | null {
  const activeOrgIds = useSelector(selectActiveOrgIds, deepEquals);

  const activeOrgsSlice = getOrgList(activeOrgIds);

  useFirestoreConnect(activeOrgsSlice.query);

  const activeUserOrgs = useSelector(activeOrgsSlice.selector);

  useEffect(() => {
    segment.multiGroup(activeUserOrgs);
  }, [activeUserOrgs]);

  const selectedOrgId = useSelector(selectActiveWorkspace);
  const selectedOrg = activeUserOrgs.find((org) => org.id === selectedOrgId);

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  const history = useHistory();

  useEffect(() => closePopup(), [history.location.pathname, closePopup]);

  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, closePopup);

  if (!selectedOrg) {
    return null;
  }

  return (
    <WorkspaceSwitcherNavButton interactive={!showPopup} onClick={openPopup} selectedOrg={selectedOrg}>
      {showPopup && (
        <WorkspaceSwitcherPopup containerRef={popupRef} selectedOrgId={selectedOrgId} userOrgs={activeUserOrgs} />
      )}
    </WorkspaceSwitcherNavButton>
  );
}
