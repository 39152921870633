import { Popup, styled } from '@taraai/design-system';
import { Identifiable } from '@taraai/types';
import { css, cx } from 'emotion';
import React, {
  forwardRef,
  FunctionComponent,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebounced } from 'tools/utils/hooks/useDebounced';

import { isSelected } from './helpers';
import { createKeyFnMap } from './keys';
import {
  FooterRenderProps,
  HeaderRenderProps,
  Index,
  OptionRenderProps,
  PopupHandle,
  SectionRenderProps,
  SectionType,
  SelectButtonRenderProps,
  SelectorPosition,
} from './types';
import { SelectorPopupHeader } from './views/PopupHeader';
import { SelectorPopupList } from './views/PopupList';

type SelectorProps<Section extends SectionType<Option>, Option extends Identifiable> = {
  closePopupOnSelection?: boolean;
  dataCy?: string;
  disablePopup?: boolean;
  filterFn?: (sections: Section[], pattern: string) => Section[];
  onDeselectOption?: (option: Option) => void;
  onSelectOption?: (option: Option) => void;
  renderFooter?: (props: FooterRenderProps) => JSX.Element;
  renderHeader?: (props: HeaderRenderProps) => JSX.Element;
  renderOption: (props: OptionRenderProps<Option>) => JSX.Element;
  renderSectionFooter?: (props: SectionRenderProps<Section>) => JSX.Element | null;
  renderSectionHeader?: (props: SectionRenderProps<Section>) => JSX.Element | null;
  renderSelectButton: (props: SelectButtonRenderProps) => JSX.Element;
  sections: Section[];
  selection: Option | Option[];
  style?: {
    popup?: string;
  };
  selectorPosition?: SelectorPosition;
  Wrapper?: FunctionComponent<{ 'data-cy'?: string }>;
};

/**
 * Selector is a generic component that enables selecting options nested in sections.
 *
 * It supports:
 * - multiple and single selection of options by mouse clicking and keyboard navigation
 * - searching options using filterFn
 * - showing and closing switcher
 *
 * With specific render methods it allows to render:
 * - switch button
 * - popup header
 * - popup footer
 * - section header
 * - section footer
 * - selectable option
 *
 * TODO: refactor this component to use Dropdown from design system
 */
export const Selector = forwardRef(function Selector<Section extends SectionType<Option>, Option extends Identifiable>(
  {
    closePopupOnSelection = false,
    dataCy,
    disablePopup = false,
    filterFn,
    onDeselectOption,
    onSelectOption,
    renderFooter,
    renderHeader,
    renderOption,
    renderSectionFooter,
    renderSectionHeader,
    renderSelectButton,
    sections,
    selection,
    style,
    selectorPosition = 'right',
    Wrapper = DefaultWrapper,
  }: SelectorProps<Section, Option>,
  ref: Ref<PopupHandle>,
): JSX.Element {
  const rootRef = useRef<PopupHandle>(null);
  const [popupOpen, setPopupOpen] = useState(false);

  useImperativeHandle(ref, () => rootRef.current as PopupHandle, []);

  const openPopup = useCallback(() => {
    if (disablePopup) {
      return;
    }
    setPopupOpen(true);
  }, [disablePopup]);

  const closePopup = useCallback(() => {
    setPopupOpen(false);
  }, []);

  // close button

  const handleCloseButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      closePopup();
    },
    [closePopup],
  );

  // search

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchPattern, setSearchPattern] = useState('');

  const updateSearchPattern = useDebounced(
    useCallback((pattern: string) => {
      setSearchPattern(pattern);
      setActiveOptionIndex(null);
    }, []),
    200,
  );

  const onSearch = useCallback(
    (pattern: string) => {
      setSearchInputValue(pattern);
      updateSearchPattern(pattern);
    },
    [updateSearchPattern],
  );

  const resultSections = useMemo(
    () => (searchPattern.length > 0 && filterFn ? filterFn(sections, searchPattern) : sections),
    [filterFn, searchPattern, sections],
  );

  // selection

  const handleOptionSelection = useCallback(
    (option: Option) => (event: React.SyntheticEvent): void => {
      event.stopPropagation();

      if (isSelected(option, selection)) {
        onDeselectOption?.(option);
      } else {
        onSelectOption?.(option);
      }

      if (closePopupOnSelection) {
        closePopup();
      }
    },
    [closePopup, closePopupOnSelection, onDeselectOption, onSelectOption, selection],
  );

  // active option

  const [activeOptionIndex, setActiveOptionIndex] = useState<Index | null>(null);

  const keyFnMap = useMemo(
    () =>
      createKeyFnMap({
        closePopup,
        handleOptionSelection,
        setActiveOptionIndex,
      }),
    [closePopup, handleOptionSelection],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent): void => {
      const keyFn = keyFnMap[event.keyCode];
      keyFn?.({ activeOptionIndex, sections: resultSections, event });
    },
    [activeOptionIndex, keyFnMap, resultSections],
  );

  // render

  return (
    <Wrapper data-cy={dataCy}>
      <Popup
        content={
          <PopupContentWrapper className={cx(style?.popup, popupStyles[selectorPosition])}>
            <SelectorPopupHeader
              onClose={handleCloseButtonClick}
              onSearchInputChange={onSearch}
              onSearchInputKeyDown={onKeyDown}
              render={renderHeader}
              searchInputValue={searchInputValue}
            />
            <SelectorPopupList
              activeOptionIndex={activeOptionIndex}
              onSelect={handleOptionSelection}
              renderOption={renderOption}
              renderSectionFooter={renderSectionFooter}
              renderSectionHeader={renderSectionHeader}
              sections={resultSections}
              selection={selection}
            />
            {renderFooter?.({ onClose: handleCloseButtonClick })}
          </PopupContentWrapper>
        }
        onHide={closePopup}
        placement='bottom-end'
        show={popupOpen}
      >
        <SelectButtonWrapper>
          {
            // FIXME: wrapping this in div in order to prevent adding forwardRef to all usecases of select button
            // this is a temporary solution and will be removed when this whole component is refactored to use
            // Dropdown from design system
            renderSelectButton({
              openPopup,
            })
          }
        </SelectButtonWrapper>
      </Popup>
    </Wrapper>
  );
}) as <Section extends SectionType<Option>, Option extends Identifiable>(
  props: SelectorProps<Section, Option> & { ref?: Ref<PopupHandle> },
) => JSX.Element;

const SelectButtonWrapper = styled('div', {
  height: '$full',
});

const DefaultWrapper = styled('div', {
  display: 'inline-block',
  position: 'relative',
});

const PopupContentWrapper = styled('div', {
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.14)',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '3px',
  width: '21.875rem',
  zIndex: '10',
  backgroundColor: '$white',
  cursor: 'default',
});

const popupStyles: Record<SelectorPosition, string> = {
  right: '',
  left: css`
    right: 0;
    width: 18.75rem;
  `,
};
