import { Box, Fluid, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { DropdownOption as DropdownOptionTypes } from 'components/core/controllers/DropdownController';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { useEffect, useMemo, useRef } from 'react';

type Props = {
  highlighted: boolean;
  isSelected: boolean;
  onClick: (id: string) => void;
  onKeyDown: (event: React.SyntheticEvent) => void;
  option: DropdownOptionTypes;
};

/**
 * DropdownOption
 * option in dropdown component
 *
 */
export default function DropdownOption({ highlighted, isSelected, onClick, onKeyDown, option }: Props): JSX.Element {
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlighted) optionRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
  }, [highlighted]);

  return useMemo(
    () => (
      <div
        key={option.id}
        ref={optionRef}
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 1rem;
          background-color: ${highlighted && '#fbfbfd'};
          :hover {
            background-color: #fbfbfd;
            span:last-child {
              opacity: ${highlighted && 0.6};
            }
          }
        `}
        onClick={() => onClick(option.id)}
        onKeyDown={onKeyDown}
        role='button'
        tabIndex={0}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            width: 100%;
          `}
        >
          {(option as UI.UIUser).name ? (
            <Avatar size='2.5rem' user={option as UI.UIUser} />
          ) : (
            <Avatar requirement={option as UI.UIRequirement} size='2.5rem' />
          )}
          <Box data-mytag='Box' maxWidth='$full' spaceHorz='$12px'>
            <Fluid>
              <Text color='$dark' singleLine size='$14px'>
                {(option as UI.UIUser).name ?? (option as UI.UIRequirement).title}
              </Text>
            </Fluid>
          </Box>
        </div>
        <div>
          {isSelected && (
            <Icon
              className={css`
                color: #949caf;
                width: 0.875rem;
                height: 0.625rem;
                padding: 0rem;
                padding-top: 0.3125rem;
              `}
              name='tick'
            />
          )}
        </div>
      </div>
    ),
    [highlighted, isSelected, onClick, onKeyDown, option],
  );
}
