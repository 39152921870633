/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';

import { ReduxFirestoreQuery } from './types';

export function useFirestoreCache<Collection>(
  queryFnc: (...props: any[]) => { query: ReduxFirestoreQuery[]; selector: (state: RootState) => Collection },
  arg1?: any,
  arg2?: any,
  arg3?: any,
  arg4?: any,
): ReturnType<ReturnType<typeof queryFnc>['selector']> {
  return useSelector(useFirestoreSelector(queryFnc, arg1, arg2, arg3, arg4), deepEquals);
}

export function useFirestoreSelector<Collection>(
  queryFnc: (...props: any) => { query: ReduxFirestoreQuery[]; selector: (state: RootState) => Collection },
  arg1?: any,
  arg2?: any,
  arg3?: any,
  arg4?: any,
): ReturnType<typeof queryFnc>['selector'] {
  const queries = useFirestoreQuery(queryFnc, arg1, arg2, arg3, arg4);

  const [{ storeAs } = { storeAs: undefined }] = queries ?? [];

  useFirestoreConnect(queries);

  return useMemo(() => {
    return (state) => {
      return storeAs && state?.firestore?.cache?.[storeAs]?.docs;
    };
  }, [storeAs]);
}

export function useFirestoreQuery<Collection>(
  queryFnc: (...props: any) => { query: ReduxFirestoreQuery[]; selector: (state: RootState) => Collection },
  arg1?: any,
  arg2?: any,
  arg3?: any,
  arg4?: any,
): ReduxFirestoreQuery[] {
  // console.log('props');
  return useMemo(() => {
    const { query } = queryFnc(...[arg1, arg2, arg3, arg4]);
    return Array.isArray(query) ? query : [query];
  }, [arg1, arg2, arg3, arg4, queryFnc]);
}
