import { UI } from '@taraai/types';
import { Section } from 'components/app/TaskDetails/common/Section';
import { StatusSelector } from 'components/core/controllers/StatusSelector';
import React from 'react';
import { strings } from 'resources';

type Props = {
  status: UI.UITask['status'];
  taskId: UI.UITask['id'];
};

export function Status({ status, taskId }: Props): JSX.Element {
  return (
    <Section
      right={<StatusSelector showLabel status={status} taskId={taskId} />}
      title={strings.taskSidebar.modules.status.title}
    />
  );
}
