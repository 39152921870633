import { Data } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { strings } from 'resources';
import { setPendingIntegrationOrg } from 'tools/utils/pendingIntegrationOrg';
import { InstallGitHubData } from 'types/install-integration';

type ManageRepositoriesBtnProps = {
  installationData: InstallGitHubData;
  orgId: Data.Id.OrganizationId;
};

export function ManageRepositoriesBtn({ installationData, orgId }: ManageRepositoriesBtnProps): JSX.Element | null {
  const onClick = useCallback(() => {
    if (installationData.userHasPermission) {
      setPendingIntegrationOrg(orgId);
      // That's a false positive, eslint mistakes it for Node's fs.open()
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(installationData.manageReposURL);
    }
  }, [installationData, orgId]);

  if (!installationData.manageReposURL) {
    return null;
  }

  return (
    <Button
      className={css`
        padding: 0.25rem 1rem;
        margin-right: 0.5rem;
        outline: none;
      `}
      color='ghost'
      disabled={!installationData.userHasPermission}
      name='manage'
      onClick={onClick}
    >
      <Icon
        className={css`
          width: 1rem;
          height: 1rem;
          padding: 0;
          margin-right: 0.5rem;
          vertical-align: sub;
        `}
        color='black'
        name='github'
      />
      {strings.integrations.manageRepository}
    </Button>
  );
}
