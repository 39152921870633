import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import { tokens } from '@taraai/design-system/dist/core/tokens';
import { UI } from '@taraai/types';
import { keys, markdownLabelIdRegExp, markdownMentionIdRegExp, unique } from '@taraai/utility';
import { getAllEntitiesData } from 'components/editor/entities';
import {
  composePlugins,
  createLabelsPlugin,
  createMentionPlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { labelIdToText } from 'components/editor/plugins/labels/common';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { createSingleLinePlugin } from 'components/editor/plugins/singleLine';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { ContentState } from 'draft-js';
import React, {
  MouseEventHandler,
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  createLabel,
  defaultLabels,
  getCustomLabels,
  reduxStore,
  searchActions,
  selectDefaultLabel,
  selectSearchCount,
  selectSearchQuery,
} from 'reduxStore';
import { strings } from 'resources';
import { useRerender } from 'tools';

export function SearchBar(): JSX.Element {
  const { orgId } = useParams<{ orgId: string }>();
  const searchRef = useRef<RichEditorHandle>(null);
  const [hasText, setHasText] = useState(false);
  const count = useSelector(selectSearchCount);
  const searchQuery = useSelector(selectSearchQuery);
  const active = hasText || typeof searchQuery !== 'undefined';
  const getUserTagForId = useUserTagForId(orgId);
  const customLabels = useSelector(getCustomLabels(orgId).selector);
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels || [])];

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
          readOnly: true,
        }),
        createMentionPlugin(getUserTagForId),
        createSingleLinePlugin(),
      ),
    [getUserTagForId],
  );

  const handleTextChange = useCallback((text: string) => {
    setHasText(text.length > 0);
    if (!text.length) reduxStore.dispatch(searchActions.search());
  }, []);

  const handleWrapperMouseDown = useCallback<MouseEventHandler>((event) => {
    // Prevent focus & blur on mouse down because editor focus will handle it
    // The editor itself should still handle selection, so it needs to be wrapped in stopPropagation
    event.preventDefault();
    searchRef.current?.focus();
  }, []);

  const handleClearclick = useCallback<MouseEventHandler>((event) => {
    // Prevent focus & blur on mouse down because clear with hasFocus will handle it
    event.preventDefault();
    event.stopPropagation();
    searchRef.current?.clear(true);
  }, []);

  const conditionalLabels = useRef<string | undefined>(undefined);
  const [editorKey, rerenderEditor] = useRerender();
  useEffect(() => {
    if (searchQuery?.source === 'label') {
      conditionalLabels.current = searchQuery?.labels?.map(labelIdToText).join(' ');
      rerenderEditor();
    } else {
      conditionalLabels.current = undefined;
    }
  }, [rerenderEditor, searchQuery?.labels, searchQuery?.source]);

  return (
    <RichEditorProvider
      key={editorKey}
      initialValue={conditionalLabels.current}
      onSave={handleSave}
      onTextChange={handleTextChange}
      plugin={plugin}
      singleLine
    >
      <WrapperBox
        active={active}
        border='$white'
        borderRadius='$2px'
        height='$24px'
        onMouseDown={handleWrapperMouseDown}
      >
        <HStack alignY='center' full space='$4px'>
          <Box center width='$24px'>
            {searchIcon}
          </Box>
          <Fluid enabled='horizontal' onMouseDown={stopPropagation}>
            <SearchEditor ref={searchRef} placeholder={strings.searchBar.placeholder} />
          </Fluid>
          {active && (
            <>
              {count !== null && (
                <Text color='$grey6' size='$10px'>
                  {count} found
                </Text>
              )}
              <Box.Button onClick={handleClearclick}>{clearIcon}</Box.Button>
            </>
          )}
        </HStack>
      </WrapperBox>
    </RichEditorProvider>
  );
}

function handleSave(text: string, content: ContentState): void {
  if (!text) return;
  reduxStore.dispatch(
    searchActions.search({
      text: text.replaceAll(markdownLabelIdRegExp, '').replaceAll(markdownMentionIdRegExp, ''),
      labels: unique(getAllEntitiesData('label', content).map(({ id }) => id)),
      mentions: unique(getAllEntitiesData('mention', content).map(({ id }) => id)),
    }),
  );
}

const focusStyle = {
  '--loupeColor': 'colors.$focus',
  'boxShadow': '$navbar',
  'backgroundColor': '$white',
};

const WrapperBox = styled(
  Box,
  {
    '--loupeColor': 'colors.$grey6',

    '&:focus-within': focusStyle,
  },
  {
    active: { true: focusStyle },
  },
);

const searchIcon = (
  <svg fill='var(--loupeColor)' height='12' width='12' xmlns='http://www.w3.org/2000/svg'>
    <path d='M11.8409 11.069L8.74593 7.97297C9.52929 6.99255 9.90752 5.74938 9.80292 4.49879C9.69831 3.24821 9.11883 2.08515 8.18348 1.24848C7.24812 0.411807 6.02791 -0.034955 4.77344 -5.18314e-05C3.51898 0.0348514 2.32549 0.54877 1.43811 1.43615C0.550723 2.32354 0.0368045 3.51702 0.00190129 4.77149C-0.0330019 6.02596 0.41376 7.24617 1.25043 8.18152C2.0871 9.11688 3.25016 9.69636 4.50075 9.80096C5.75133 9.90557 6.9945 9.52734 7.97493 8.74398L11.0699 11.84C11.1205 11.8907 11.1805 11.931 11.2467 11.9585C11.3129 11.986 11.3838 12.0001 11.4554 12.0001C11.5271 12.0001 11.598 11.986 11.6642 11.9585C11.7303 11.931 11.7904 11.8907 11.8409 11.84C11.8916 11.7894 11.9318 11.7293 11.9592 11.6631C11.9866 11.597 12.0007 11.5261 12.0007 11.4545C12.0007 11.3829 11.9866 11.312 11.9592 11.2458C11.9318 11.1797 11.8916 11.1196 11.8409 11.069ZM4.91093 8.72698C3.89833 8.72711 2.92716 8.32498 2.21105 7.60906C1.49494 6.89314 1.09256 5.92207 1.09243 4.90947C1.0923 3.89688 1.49442 2.9257 2.21034 2.20959C2.92626 1.49349 3.89733 1.09111 4.90993 1.09097C5.92252 1.09097 6.89365 1.49323 7.60966 2.20924C8.32568 2.92526 8.72793 3.89638 8.72793 4.90897C8.72793 5.92157 8.32568 6.89269 7.60966 7.60871C6.89365 8.32472 5.92252 8.72698 4.90993 8.72698H4.91093Z' />
  </svg>
);

const SearchEditor = getStyledRichEditor({
  caretColor: 'colors.$focus',
  fontSize: '$12px',
  lineHeight: '$12px',
});

const clearIcon = (
  <svg fill='none' height='18' style={{ stroke: tokens.colors.$grey6 }} width='18' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.80273 5.81799L12.1667 12.182' strokeLinecap='round' />
    <path d='M5.80273 12.182L12.1667 5.81805' strokeLinecap='round' />
  </svg>
);

function stopPropagation(event: SyntheticEvent): void {
  event.stopPropagation();
}
