import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { createEntity } from 'components/editor/entities';
import { isWithinSelection } from 'components/editor/plugins/utils';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React, { useCallback, useContext } from 'react';

import { labelTitleToDisplayText } from './common';
import { LabelSelectorDropdown } from './LabelSelectorDropdown';

interface Props extends DraftDecoratorComponentProps {
  createLabel: (title: string) => void;
  getLabels: () => UI.UILabel[];
  readOnly?: boolean;
}

export function LabelSelector({
  blockKey,
  children,
  createLabel,
  decoratedText,
  end,
  getLabels,
  start,
  readOnly,
}: Props): JSX.Element {
  const { editorState, setEditorState } = useContext(RichEditorContext);
  const entityHasSelection = isWithinSelection(editorState.getSelection(), getSelectionState(blockKey, start, end));
  const handleCreate = useCallback(
    (text: string) => {
      setEditorState(replaceWithLabel({ id: text, title: text }, blockKey, start, end));
      createLabel(text);
    },
    [blockKey, createLabel, end, setEditorState, start],
  );
  const handleSelect = useCallback(
    (label: UI.UILabel) => setEditorState(replaceWithLabel(label, blockKey, start, end)),
    [blockKey, end, setEditorState, start],
  );
  return (
    <LabelSelectorDropdown
      getOptions={getLabels}
      onCreate={handleCreate}
      onSelect={handleSelect}
      readOnly={readOnly}
      searchQuery={decoratedText.slice(1)}
      show={entityHasSelection}
    >
      <Wrapper>{children}</Wrapper>
    </LabelSelectorDropdown>
  );
}

const Wrapper = styled('span', { position: 'relative' });

export function getLabelSelector({
  createLabel,
  getLabels,
  readOnly,
}: {
  createLabel: (title: string) => void;
  getLabels: () => UI.UILabel[];
  readOnly?: boolean;
}) {
  return function LabelSelectorWrapper(props: DraftDecoratorComponentProps) {
    return <LabelSelector {...props} createLabel={createLabel} getLabels={getLabels} readOnly={readOnly} />;
  };
}

const replaceWithLabel = (label: UI.UILabel, blockKey: string, start: number, end: number) => (
  state: EditorState,
): EditorState => {
  const content = state.getCurrentContent();
  const { contentWithEntity, key } = createEntity('label', label, content);
  const selection = getSelectionState(blockKey, start, end);
  const displayText = labelTitleToDisplayText(label.title);
  return EditorState.push(
    state,
    Modifier.replaceText(contentWithEntity, selection, displayText, undefined, key).set(
      'selectionAfter',
      getSelectionState(blockKey, start + displayText.length),
    ) as ContentState,
    'apply-entity',
  );
};

function getSelectionState(blockKey: string, anchorOffset: number, focusOffset = anchorOffset): SelectionState {
  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset,
    hasFocus: true,
  }) as SelectionState;
}
