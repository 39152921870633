import { Box, Fluid, styled, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { WorkDrawerImportToastController } from 'components/app/controllers/WorkDrawerImportToastController';
import { GithubImportToastController } from 'components/app/controllers/WorkDrawerToastController';
import { DroppableDescription } from 'components/app/DragAndDrop';
import { TaskCreation } from 'components/app/TaskCreation';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import React, { RefObject, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { useWorkDrawerScrollState } from 'reduxStore/workDrawer';
import { strings } from 'resources';

import { SelectTaskFragment, WorkDrawerImportTasksId } from './WorkDrawerController';
import {
  WorkDrawerHeader,
  WorkDrawerImports,
  WorkDrawerRepos,
  WorkDrawerRequirements,
  WorkDrawerTaskCardProps,
  WorkDrawerTasks,
} from './WorkDrawerSections';

type Props = {
  imports: WorkDrawerImportTasksId[];
  onRequirementCreate: (title: string) => void;
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => Promise<boolean>;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  repos: WorkDrawerImportTasksId[];
  requirements: UI.UIRequirement[];
  tasks: WorkDrawerTaskCardProps[];
  tasksDroppableAreaDescription: DroppableDescription;
  filterTask: (task: SelectTaskFragment) => boolean;
};

export const WorkDrawer = React.memo(function WorkDrawer({
  imports,
  onRequirementCreate,
  onRequirementSelect,
  onTaskCreate,
  onTaskSelect,
  repos,
  requirements,
  tasks,
  tasksDroppableAreaDescription,
  filterTask,
}: Props): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  return (
    <WorkDrawerContainer borderRight='$grey4'>
      <VStack full>
        <WorkDrawerHeader />
        <Fluid>
          <WorkDrawerBodyWrapper>
            <CreateTaskWrapper onTaskCreate={onTaskCreate} />
            <WorkDrawerTasks
              droppableAreaDescription={tasksDroppableAreaDescription}
              onTaskCreate={onTaskCreate}
              onTaskSelect={onTaskSelect}
              tasks={tasks}
            />
            <WorkDrawerRequirements
              filterTask={filterTask}
              onRequirementCreate={onRequirementCreate}
              onRequirementSelect={onRequirementSelect}
              onTaskCreate={onTaskCreate}
              onTaskSelect={onTaskSelect}
              orgId={orgId}
              requirements={requirements}
            />
            <WorkDrawerRepos onTaskSelect={onTaskSelect} orgId={orgId} repos={repos} teamId={teamId} />
            <WorkDrawerImports imports={imports} onTaskSelect={onTaskSelect} orgId={orgId} teamId={teamId} />
          </WorkDrawerBodyWrapper>
        </Fluid>
      </VStack>
    </WorkDrawerContainer>
  );
});

function CreateTaskWrapper({
  onTaskCreate,
}: {
  onTaskCreate: (
    title: string,
    requirementId?: Data.Id.RequirementId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => Promise<boolean>;
}): JSX.Element {
  const editorRef = useRef<RichEditorHandle>(null);
  const [inputIsActive, setInputIsActive] = useState(false);
  return (
    <StickyHeader background='$grey2'>
      <Box spaceBottom='$4px' spaceLeft='$2px' spaceTop='$4px'>
        <CreateTaskContainer
          background='$white'
          borderRadius='$2px'
          selected={inputIsActive}
          shadow='$taskCreation'
          space='$8px'
          spaceRight='$4px'
        >
          <TaskCreation
            onEnter={async (title: string) => {
              if (await onTaskCreate(title)) editorRef.current?.clear();
            }}
          >
            <CreateTaskEditor
              ref={editorRef}
              onBlur={() => setInputIsActive(false)}
              onFocus={() => setInputIsActive(true)}
              placeholder={strings.workDrawer.createTask}
            />
          </TaskCreation>
        </CreateTaskContainer>
      </Box>
    </StickyHeader>
  );
}

function WorkDrawerBodyWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useScrollState(ref);

  return (
    <ScrollContainer ref={ref} background='$grey2' spaceBottom='$12px'>
      <VStack>{children}</VStack>
      <BottomPosition space='$8px'>
        <GithubImportToastController />
        <WorkDrawerImportToastController
          importInProgressCopy={strings.importNotificationBar.asana.importInProgressCopy}
          service='asana'
        />
        <WorkDrawerImportToastController
          importInProgressCopy={strings.importNotificationBar.trello.importInProgressCopy}
          service='trello'
        />
      </BottomPosition>
    </ScrollContainer>
  );
}

/**
 * Use the previously saved scroll position on mount, save the scroll position on unmount.
 *
 * Note that the result of `useWorkDrawerScrollState` never changes.
 */
function useScrollState(ref: RefObject<HTMLDivElement>): void {
  const scrollState = useWorkDrawerScrollState();

  useLayoutEffect(() => {
    const scrollElement = ref.current as HTMLDivElement;
    const { getScroll, setScroll } = scrollState;
    const scroll = getScroll();
    if (typeof scroll !== 'undefined') scrollElement.scrollTop = scroll;
    return () => setScroll(scrollElement.scrollTop);
  }, [ref, scrollState]);
}

const WorkDrawerContainer = styled(Box, {
  zIndex: 5,
  height: '100%',
});

const ScrollContainer = styled(Box, {
  overflowY: 'scroll',
});

const StickyHeader = styled(Box, {
  position: 'sticky',
  zIndex: 5,
  top: 0,
});

const BottomPosition = styled(Box, {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
});

const CreateTaskContainer = styled(
  Box,
  {},
  {
    selected: { true: { boxShadow: 'inset 0 0 0 1px colors.$focus' } },
  },
);

const CreateTaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$14px',
  minHeight: '46px',
  caretColor: 'colors.$focus',
});
