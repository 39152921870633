import styled from '@emotion/styled';
import { FormProps } from 'components/app/Onboarding/FlowWrapper';
import { Description, Header, NextButton, SubHeader, Wrapper } from 'components/app/Onboarding/Form';
import { animations } from 'components/app/Onboarding/Preview';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import React from 'react';
import { atomic, strings } from 'resources';
import { workspaceSetupTestIds } from 'resources/cypress/testAttributesValues';

import { NEXT_BUTTON, SPINNER } from './test-ids';

export const Form = ({ isOnboardingFlowFinished, onNext }: FormProps): JSX.Element => {
  return (
    <Wrapper>
      <Emoji>{strings.onboarding.confirmationForm.partypopper.emoji}</Emoji>
      <Description>
        <Header>{strings.onboarding.confirmationForm.header}</Header>
        <SubHeader>{strings.onboarding.confirmationForm.text}</SubHeader>
      </Description>
      {isOnboardingFlowFinished ? (
        <CustomNextButton
          data-cy={workspaceSetupTestIds.FOURTH_STEP_NEXT_BUTTON}
          data-testid={NEXT_BUTTON}
          onClick={onNext}
        >
          {strings.onboarding.confirmationForm.next}
        </CustomNextButton>
      ) : (
        <SpinnerContainer data-testid={SPINNER}>
          <FastSmallSpinner />
        </SpinnerContainer>
      )}
    </Wrapper>
  );
};

const CustomNextButton = styled((props) => <NextButton {...props} />)`
  animation: ${animations.fadeIn} 0.5s ease;
`;

const Emoji = styled.div`
  display: none;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    display: block;
    font-size: 2.3rem;
  }
`;

const SpinnerContainer = styled.div`
  animation: ${animations.fadeIn} 0.5s ease 1s both;
  text-align: center;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    text-align: left;
  }
`;
