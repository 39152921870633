/* eslint-disable react/no-array-index-key */

import { styled } from '@taraai/design-system';
import { markdownLabelIdRegExp } from '@taraai/utility';
import { ReduxLabelChip } from 'components/app/controllers/ReduxLabelChip';
import React, { ReactNode, useMemo } from 'react';

interface Props {
  text: string;
  filterLabelsOnClick?: boolean;
}

/**
 * SmartText
 * A global text component that can parse draft-like input and render it in a
 * draft-like manner, specifically scoped to Labels for now
 *
 */
export function SmartText({ text, filterLabelsOnClick }: Props): JSX.Element {
  const textToRender: ReactNode[] = useMemo(
    () =>
      text
        .split(markdownLabelIdRegExp)
        .map((token, index) =>
          index % 2 === 0 ? (
            token
          ) : (
            <ReduxLabelChipWrapper key={index}>
              <ReduxLabelChip filterOnClick={filterLabelsOnClick} id={token}>
                #{token}
              </ReduxLabelChip>
            </ReduxLabelChipWrapper>
          ),
        )
        .filter((split) => split !== ''),
    [filterLabelsOnClick, text],
  );
  return <DraftEditorBorder>{textToRender}</DraftEditorBorder>;
}

const ReduxLabelChipWrapper = styled('div', {
  display: 'inline-block',
  wordBreak: 'break-all',
});

/**
 * Draft Editor has built-in 0.1px border left style
 * This border is implemented to omit issue with translating text when changing between SmartText and RichEditor
 */
const DraftEditorBorder = styled('div', {
  borderLeft: '.1px solid transparent',
});
