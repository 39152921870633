import { unwrapResult } from '@reduxjs/toolkit';
import { isTaskStatus, TaskStatus, UI } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Select from 'components/core/controllers/views/Select';
import { OptionDefinition, SelectProps } from 'components/core/controllers/views/Select/Select';
import { css } from 'emotion';
import React, { useCallback, useMemo } from 'react';
import { components } from 'react-select';
import { styleFn } from 'react-select/src/styles';
import { reduxStore } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type TaskFragment = Pick<UI.UITask, 'id' | 'status'>;
interface TaskStatusProps {
  width?: string;
  task: TaskFragment;
  statusRef?: SelectProps['innerRef'];
  dataCy?: string;
}

interface TaskOption extends OptionDefinition {
  value: number;
  label: string;
  backgroundColor: string;
  fontColor: string;
}

/**
 * Font colors (background / font):
 * To do: grey2 / grey7
 * Doing: lightSecondaryBlue / secondary
 * Done: success1 / success
 */
export const TaskOptions: TaskOption[] = [
  {
    value: TaskStatus.Todo,
    label: strings.taskStatus.DEPRECATED_statusLabels[TaskStatus.Todo],
    backgroundColor: '#eef1f7',
    fontColor: '#67728b',
  },
  {
    value: TaskStatus.Doing,
    label: strings.taskStatus.DEPRECATED_statusLabels[TaskStatus.Doing],
    backgroundColor: '#ddf0ff',
    fontColor: '#1d98ff',
  },
  {
    value: TaskStatus.Done,
    label: strings.taskStatus.DEPRECATED_statusLabels[TaskStatus.Done],
    backgroundColor: '#ddf4d9',
    fontColor: '#389e0d',
  },
];

/**
 * Tara 2.0 Task status selector with mutations
 * @deprecated
 */
export default function TaskStatusDropdown({ width = '5rem', task, dataCy, statusRef }: TaskStatusProps): JSX.Element {
  function generateStatus(stat: number): OptionDefinition {
    return {
      value: stat,
      label: strings.taskStatus.DEPRECATED_statusLabels[isTaskStatus(stat) ? stat : TaskStatus.Todo],
    };
  }

  const status = generateStatus(task?.status);
  const taskOption = TaskOptions[parseInt(status?.value?.toString() ?? '0', 10) ?? 0];

  const DropdownIndicator = useCallback(
    (props) =>
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props} data-cy={dataCy}>
          <Icon
            className={css`
              padding-left: 0rem;
              width: 0.4375rem;
              padding-top: 0.5;
              padding-right: 0.375rem;
            `}
            color={taskOption?.fontColor}
            dataCy={dataCy}
            name='dropdownindicator'
          />
        </components.DropdownIndicator>
      ),
    [dataCy, taskOption],
  );

  const { addToast } = useToast();

  const handleChange = useCallback(
    (option: OptionDefinition) => {
      if (option.value !== status.value && typeof option.value === 'number') {
        reduxStore
          .dispatch(
            updateTask({
              id: task?.id,
              status: option.value as TaskStatus,
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToUpdateTask,
            }),
          );
      }
    },
    [addToast, status.value, task?.id],
  );

  interface StyleOptions {
    isFocused?: boolean;
    data: {
      backgroundColor?: string;
      fontColor?: string;
    };
  }
  /**
   * Custom styles to pass to Select component (which uses React-Select)
   *
   * @see https://react-select.com/styles
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customStyles: Record<string, styleFn> = {
    container(styles) {
      return {
        ...styles,
        fontSize: '0.75rem',
        padding: '0rem',
        width,
        minWidth: width,
        height: 'auto',
      };
    },
    control(styles) {
      return {
        ...styles,
        'cursor': 'pointer',
        'height': '1.625rem',
        'maxHeight': '1.625rem',
        'minHeight': '1.625rem',
        'fontSize': '0.75rem',
        'fontWeight': 600,
        'fontStyle': 'normal',
        'fontStretch': 'normal',
        'lineHeight': 'normal',
        'letterSpacing': 'normal',
        'backgroundColor': taskOption?.backgroundColor,
        'border': 'none',
        'boxShadow': 'none',
        '&:hover': {
          border: 'none',
          boxShadow: 'none',
        },
      };
    },
    singleValue(styles) {
      return {
        ...styles,
        color: taskOption?.fontColor,
      };
    },
    valueContainer(styles) {
      return {
        ...styles,
        padding: 0,
        paddingLeft: '0.25rem',
      };
    },
    indicatorsContainer(styles) {
      return {
        ...styles,
        height: '1.625rem',
      };
    },
    dropdownIndicator(styles) {
      return {
        ...styles,
        padding: 0,
        color: taskOption?.fontColor,
      };
    },
    input(styles, { data }: StyleOptions) {
      return {
        ...styles,
        backgroundColor: data.backgroundColor,
      };
    },
    option(styles, { data, isFocused }: StyleOptions) {
      return {
        ...styles,
        padding: 10,
        fontSize: '0.75rem',
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: data?.fontColor,
        backgroundColor: isFocused ? data?.backgroundColor : undefined,
      };
    },
    menuList(styles) {
      return {
        ...styles,
        padding: '0rem',
      };
    },
    menu(styles) {
      return {
        ...styles,
        marginTop: '0.1563rem',
      };
    },
  };
  return useMemo(
    () => (
      <Select
        components={{ DropdownIndicator }}
        dataCy={dataCy}
        innerRef={statusRef}
        isSearchable={false}
        onChange={handleChange}
        options={TaskOptions}
        styles={customStyles}
        value={status}
      />
    ),
    [DropdownIndicator, customStyles, dataCy, handleChange, status, statusRef],
  );
}
