import { UI } from '@taraai/types';
import { Section } from 'components/app/TaskDetails/common/Section';
import React, { useRef } from 'react';
import { strings } from 'resources';

import { RequirementSelector } from './RequirementSelector';

type Props = {
  task: UI.UITask;
};

export function Requirement({ task }: Props): JSX.Element {
  const requirementsRef = useRef<HTMLInputElement>(null);
  return (
    <Section
      right={<RequirementSelector requirementsRef={requirementsRef} task={task} />}
      title={strings.taskSidebar.modules.requirement.title}
    />
  );
}
