import { createSelector, Selector } from '@reduxjs/toolkit';
import { TaskStatus, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { TaskListSelector } from 'reduxStore/tasks/queries';
import { isBefore } from 'tools';
import { LIMIT_NUMBER_OF_SPRINTS } from 'tools/libraries/helpers/constants';
import { sort } from 'tools/libraries/helpers/sort';

import { SprintFragment, SprintListSelector, SprintSelector } from './queries';
import { SprintCompletingNotification } from './reducers';

// Typed Functions

export const computeEffortFromTasks = (tasks: Pick<UI.UITask, 'effortLevel'>[]): number => {
  return tasks.reduce((effortTotal, task) => effortTotal + (Number(task.effortLevel) || 1), 0);
};

export const computeTotalEffort = (
  sprint: Pick<UI.UISprint, 'isComplete' | 'computedOnCompletion'> | undefined,
  allSprintTasks: Pick<UI.UITask, 'effortLevel'>[] | undefined,
): number => {
  if (!sprint || !allSprintTasks) {
    return 0;
  }

  // if sprint is completed, use precomputed value
  if (sprint.isComplete) {
    const { totalEffortEstimated } = sprint.computedOnCompletion;

    // if for some reason sprint wasn't properly completed
    if (typeof totalEffortEstimated !== 'number') {
      return 0;
    }

    return totalEffortEstimated;
  }

  // if sprint is NOT completed, we have to compute effort ourselves
  return computeEffortFromTasks(allSprintTasks);
};

export const computeCompletedEffort = (
  sprint: Pick<UI.UISprint, 'isComplete' | 'computedOnCompletion'> | undefined,
  allSprintTasks: Pick<UI.UITask, 'status' | 'effortLevel'>[] | undefined,
): number => {
  if (!sprint || !allSprintTasks) {
    return 0;
  }

  // if sprint is completed, use precomputed value
  if (sprint.isComplete) {
    const { totalEffortCompleted } = sprint.computedOnCompletion;

    // if for some reason sprint wasn't properly completed
    if (typeof totalEffortCompleted !== 'number') {
      return 0;
    }

    return totalEffortCompleted;
  }

  // if sprint is NOT completed, we have to compute effort ourselves
  const completedTasks = allSprintTasks.filter(({ status }) => status === TaskStatus.Done);
  return computeEffortFromTasks(completedTasks);
};

export const lastNCompletedSprints = (
  sprints?: SprintFragment[],
  currentSprint?: SprintFragment,
  count: number = LIMIT_NUMBER_OF_SPRINTS,
): SprintFragment[] | undefined => {
  if (!sprints) return undefined;

  const sprintsWithEffortEstimation = sprints.filter((sprint) => sprint.completedAt && sprint.computedOnCompletion);

  if (!currentSprint) return undefined;

  // if current sprint is completed, filter out sprints that are completed after it
  const filteredSprints = Array.from(sprintsWithEffortEstimation).filter(
    (sprint: Pick<UI.UISprint, 'completedAt' | 'computedOnCompletion'>) =>
      !currentSprint.completedAt || isBefore(sprint.completedAt, currentSprint.completedAt),
  );

  return sort(filteredSprints, 'completedAt').slice(-count);
};

export const sprintsEstimatedEffort = (sprints?: Pick<UI.UISprint, 'computedOnCompletion'>[]): number | undefined => {
  if (!sprints?.length) {
    return undefined;
  }

  return Math.round(
    sprints.reduce((total: number, sprint) => total + (sprint.computedOnCompletion.totalEffortCompleted || 0), 0) /
      sprints.length,
  );
};

export const lastNSprintsEstimatedEffort = (
  sprints?: SprintFragment[],
  currentSprint?: SprintFragment,
  count: number = LIMIT_NUMBER_OF_SPRINTS,
): number | undefined => sprintsEstimatedEffort(lastNCompletedSprints(sprints, currentSprint, count));

// Custom Selectors

export const getSprintCompletingNotification = (state: RootState): SprintCompletingNotification =>
  state.sprints.sprintCompletingNotification;

export const estimatedEffortSelector = (
  completed: SprintListSelector,
  sprint: SprintSelector,
): Selector<RootState, number | undefined> => createSelector([completed, sprint], lastNSprintsEstimatedEffort);

export const totalEffortSelector = (sprint: SprintSelector, tasks: TaskListSelector): Selector<RootState, number> =>
  createSelector([sprint, tasks], computeTotalEffort);
