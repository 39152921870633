import { mapTokens, styled, TokenProp } from '@taraai/design-system';
import React from 'react';

export type IconProps = {
  color: TokenProp<'colors'>;
};

export function UnknownIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M7.317 7.65h1.464v3.652H6.634V8.157h.683V7.65zM8.917 5.894c.575-.263.859-.643.859-1.147 0-.813-.755-1.372-1.767-1.372-1.098 0-1.768.593-1.768 1.525H4.045c0-1.068.378-1.932 1.116-2.56.737-.643 1.664-.965 2.813-.965 1.115 0 2.059.322 2.831.966.772.627 1.15 1.457 1.15 2.457 0 .397-.062.762-.185 1.096H8.917z'
        fillRule='evenodd'
      />
      <path d='M8.776 9.355c-.064-1.188 2.296-2.043 2.977-3.469.681-1.425-1.473-.839-2.819 0-1.346.84-2.297 1.711-2.297 2.297 0 .586 2.202 2.36 2.139 1.172zM8.781 12.662H6.637v1.964h2.144z' />
    </ColorSvg>
  );
}

export function BoldIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12.1 5.26c0-2-1.47-3.26-3.77-3.26H3.2v12h5.53a4.1 4.1 0 002.95-1.04 3.39 3.39 0 001.12-2.57c0-.57-.2-1.14-.65-1.7a3.05 3.05 0 00-1.78-1.1 2.37 2.37 0 001.73-2.33zM5.63 3.99H8.1c.93 0 1.48.54 1.48 1.39 0 .83-.55 1.44-1.4 1.44H5.62V4zm2.91 4.7c1.07 0 1.71.82 1.71 1.67 0 .89-.64 1.65-1.65 1.65H5.63V8.7h2.91z' />
    </ColorSvg>
  );
}

export function ItalicIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M5.6 2h7.2v1.8h-2.7l-2.33 8.4h2.63V14H3.2v-1.8h2.7l2.33-8.4H5.6V2z'
        fillRule='evenodd'
      />
    </ColorSvg>
  );
}

export function UnderlineIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M5.73 2.04v4.9a2.3 2.3 0 004.6 0v-4.9h1.73v4.9a4.03 4.03 0 11-8.06 0v-4.9h1.73zM12.67 14H3.33v-2h9.34v2z'
        fillRule='evenodd'
      />
    </ColorSvg>
  );
}

export function StrikeIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M4.82 12.97a3.27 3.27 0 01-1.19-2.56h2.2c.05.9.82 1.64 2.04 1.64 1.15 0 1.9-.6 1.96-1.4.05-.77-.48-1.28-1.63-1.6l-.6-.15h4.01c.34.54.46 1.15.41 1.8a3.05 3.05 0 01-1.27 2.42c-.79.6-1.75.88-2.88.88a4.44 4.44 0 01-3.05-1.03zM6.06 5.9a1.1 1.1 0 01-.13-.53C5.93 4.56 6.7 4 7.7 4c1.1 0 1.77.6 1.77 1.53h2.2a3.2 3.2 0 00-1.12-2.56A4.1 4.1 0 007.73 2c-1.11 0-2.06.32-2.83.97a3.02 3.02 0 00-1.15 2.45c0 .17.01.33.03.48h2.28z'
        fillRule='evenodd'
      />
      <path clipRule='evenodd' d='M13.33 9H2.67V7h10.66v2z' fillRule='evenodd' />
    </ColorSvg>
  );
}

export function InlineCodeIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fill='none' strokeColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 4L1 8l4 4M11 4l4 4-4 4' strokeLinejoin='bevel' strokeWidth='2' />
    </ColorSvg>
  );
}

export function LinkIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fill='none' strokeColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.2 9v0c1-1 1-2.3 0-3.2l-.5-.5a3 3 0 00-4.2 0l-2.2 2a3 3 0 000 4.3l.6.6c.9.8 2.3.8 3.2 0v0M6.8 7.2v0c-1 .9-1 2.3 0 3.2l.5.5a3 3 0 004.2 0l2.2-2.1a3 3 0 000-4.2L13 4c-.9-.9-2.3-.9-3.2 0v0'
        strokeWidth='2'
      />
    </ColorSvg>
  );
}

export function HeadingOneIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.06 7.2H3.91V3H2v10h1.91V8.86h4.15V13H10V3H8.06v4.2zM11 7.25v1.43l1.55-.62V13H14V6h-.13L11 7.25z' />
    </ColorSvg>
  );
}

export function HeadingTwoIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.06 7.2H2.91V3H1v10h1.91V8.86h4.15V13H9V3H7.06v4.2zM14.89 11.77h-2.3l1.68-1.94c.49-.56.73-1.14.73-1.76 0-.6-.22-1.1-.65-1.48A2.22 2.22 0 0012.8 6c-.6 0-1.11.18-1.53.55-.42.36-.68.86-.77 1.48l1.19.22c.08-.59.5-1 1.06-1 .57 0 .98.36.98.82 0 .27-.14.58-.42.9l-2.78 3.37V13h4.36v-1.23z' />
    </ColorSvg>
  );
}

export function BulletListIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 2h8v2H6zM6 7h8v2H6zM6 12h8v2H6zM2 2h2v2H2zM2 7h2v2H2zM2 12h2v2H2z' />
    </ColorSvg>
  );
}

export function NumListIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 2h8v2H6zM6 7h8v2H6zM6 12h8v2H6zM1 1.72v.81l.94-.35V5h.89V1h-.08L1 1.72zM3.3 8.2c.24-.28.41-.6.41-1 0-.72-.5-1.2-1.32-1.2-.83 0-1.28.52-1.39 1.26l.81.12c.05-.33.22-.56.53-.56.28 0 .46.15.46.37 0 .12-.08.28-.21.43L1.02 9.5v.5h2.73v-.82H2.42l.87-.98zM3.24 13.01c.35-.18.57-.48.57-.83.02-.73-.6-1.18-1.34-1.18-.58 0-1 .25-1.33.58l.5.62c.16-.2.43-.33.7-.33.27 0 .47.14.49.35.03.27-.23.52-.7.52h-.17v.7h.4c.4 0 .66.23.66.54 0 .3-.25.54-.67.54-.29 0-.5-.14-.72-.36l-.63.53c.32.46.8.69 1.4.69.93 0 1.6-.55 1.6-1.3 0-.5-.3-.9-.76-1.07z' />
    </ColorSvg>
  );
}

export function BlockquoteIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 2h6v2H6zM6 7h8v2H6zM6 12h4v2H6zM2 2h2v12H2z' />
    </ColorSvg>
  );
}

export function BlockCodeIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M12 4h-1V2h3v12H3V8h2v4h7V4zM3.12 4.2l1.8-1.79L3.5 1 1 3.5v1.41l2.5 2.5L4.91 6 3.12 4.2z'
        fillRule='evenodd'
      />
      <path clipRule='evenodd' d='M7.88 4.2l-1.8-1.79L7.5 1 10 3.5v1.41l-2.5 2.5L6.09 6l1.79-1.8z' fillRule='evenodd' />
    </ColorSvg>
  );
}

export function ImageIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path clipRule='evenodd' d='M14 2H2v12h12V2zM4 8V4h8v6L8.5 8.5 4 12V8z' fillRule='evenodd' />
      <circle cx='6.25' cy='6.25' r='1.25' />
    </ColorSvg>
  );
}

export function AttachIcon({ color }: IconProps): JSX.Element {
  return (
    <ColorSvg fillColor={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path clipRule='evenodd' d='M4 9H2V14H14V9H12V12H4V9Z' fillRule='evenodd' />
      <ColorPath d='M8 3L8 10' strokeColor={color} strokeWidth='2' />
      <ColorPath d='M5 6L8 3L11 6' strokeColor={color} strokeWidth='2' />
    </ColorSvg>
  );
}

const ColorSvg = styled(
  'svg',
  {
    width: '$full',
    height: '$full',
  },
  {
    fillColor: mapTokens('colors', (color) => ({
      fill: color,
    })),
    strokeColor: mapTokens('colors', (color) => ({
      stroke: color,
    })),
  },
);

const ColorPath = styled(
  'path',
  {},
  {
    strokeColor: mapTokens('colors', (color) => ({
      stroke: color,
    })),
  },
);
