import Button from 'components/core/controllers/views/Button';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { atomic } from 'resources';
import { requirementsListTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

/**
 * Header component displaying data for Requirements Page
 */
export default function RequirementsHeader({ children }: React.HTMLProps<HTMLElement>): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();

  return (
    <header
      className={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          className={css`
            padding-bottom: 1rem;
          `}
        >
          <Text
            className={css`
              font-size: 1rem;
              font-weight: 500;
              letter-spacing: normal;
            `}
            color={atomic.get(atomic.colors.grey7)}
          >
            {strings.requirements.title}
          </Text>
        </div>
        <div>
          <Text
            className={css`
              color: #67728b;
              font-size: 0.875rem;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            `}
            data-cy={requirementsListTestIds.REQUIREMENTS_DESCRIPTION}
          >
            {strings.requirements.description}
          </Text>
        </div>
      </div>
      <div
        className={css`
          padding-left: 0.9375rem;
          align-self: flex-end;
        `}
      >
        <Button
          className={css`
            padding: 0.5rem 1rem;
            min-height: 1.6875rem;
          `}
          data-cy={requirementsListTestIds.CREATE_REQUIREMENT_BUTTON}
          onClick={(event: React.SyntheticEvent): void => {
            event.preventDefault();
            history.push(linkTo('newRequirement', { orgId, teamId }));
          }}
        >
          {strings.requirements.createAndDefine}
        </Button>
      </div>
      {children}
    </header>
  );
}
