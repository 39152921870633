import { TaskStatus } from '@taraai/types';
import { IconName, strings } from 'resources';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

type TaskStatusMap = {
  key: TaskStatus;
  color: typeof mapTaskStatusToColor[TaskStatus];
  label: string;
  name: IconName;
};

export const taskStatusMap: TaskStatusMap[] = [
  {
    key: 0,
    color: mapTaskStatusToColor[TaskStatus.Todo],
    label: strings.taskStatus.labels[TaskStatus.Todo],
    name: 'todo',
  },
  {
    key: 1,
    color: mapTaskStatusToColor[TaskStatus.Doing],
    label: strings.taskStatus.labels[TaskStatus.Doing],
    name: 'doing',
  },
  {
    key: 2,
    color: mapTaskStatusToColor[TaskStatus.Done],
    label: strings.taskStatus.labels[TaskStatus.Done],
    name: 'done',
  },
];
