import { compose } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data, Functions, UI } from '@taraai/types';
import BacklogTasks from 'components/app/controllers/BacklogTasks';
import { ImportedTasksController } from 'components/app/controllers/ImportedTasksController';
import LegacyTaskModalContext from 'components/app/controllers/LegacyTaskModalContext';
import RequirementsListController from 'components/app/controllers/RequirementsListController';
import RequirementTasksController from 'components/app/controllers/RequirementTasksController';
import TeamBacklogPopupController from 'components/app/controllers/TeamBacklogPopupController';
import { AreaOverlay } from 'components/app/controllers/views/AreaDropZone/AreaOverlay';
import CreateTaskInput from 'components/app/controllers/views/CreateTaskInput';
import EmptyBacklogView from 'components/app/controllers/views/EmptyBacklogView';
import SprintsBacklogHeader from 'components/app/controllers/views/SprintsBacklogHeader';
import { usePathParams } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React, { useCallback, useContext, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import {
  getRequirement,
  getRequirements,
  incompleteBacklogTasks,
  incompleteRequirementBacklogTasks,
  orderedDocuments,
  requirementTasks,
} from 'reduxStore';
import { getSerializedDroppableId } from 'reduxStore/utils/droppable';
import { useFirestoreCache, useFirestoreSelector } from 'reduxStore/utils/useFirestoreCache';
import { strings } from 'resources';
import { sprintsTabTestIds } from 'resources/cypress/testAttributesValues';
import { sort } from 'tools/libraries/helpers/sort';

import { sprintsBacklogWrapperStyles } from './styles';

interface SprintsBacklogProps extends React.HTMLProps<HTMLDivElement> {
  backlogOverlay: boolean;
}

const orderUpdatedAt = { orderBy: 'updatedAt' };
/**
 * Sprints backlog component. Will be replaced with work drawer in Tara 3.0.
 * @deprecated
 */

export default function SprintsBacklog({ backlogOverlay, className, ...props }: SprintsBacklogProps): JSX.Element {
  const { orgId, teamId } = usePathParams('sprints');
  const team = teamId;
  const [selectedRequirementId, setSelectedRequirementId] = useState<string>();
  const [displayBacklogTasks, setDisplayBacklogTasks] = useState(false);
  const [displayTasksImportedFromService, setDisplayImportTasks] = useState(
    null as Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'] | null,
  );

  const { openModal } = useContext(LegacyTaskModalContext);

  const cachedRequirement = useFirestoreCache(getRequirement, orgId, selectedRequirementId ?? '') as
    | UI.UIRequirement[]
    | undefined;
  const requirements = useFirestoreCache(getRequirements, orgId, team, orderUpdatedAt) ?? [];

  const selectedRequirement = cachedRequirement && cachedRequirement[0];

  const backlogSelector = useFirestoreSelector(incompleteBacklogTasks, orgId);
  const orderSelector = useFirestoreSelector(incompleteRequirementBacklogTasks, orgId, selectedRequirementId);
  const allSelector = useFirestoreSelector(requirementTasks, orgId, selectedRequirementId);

  const tasks = useSelector(backlogSelector) ?? [];
  const taraTasksSelector = compose((allTasks) => {
    const filteredTasks = (allTasks ?? [])
      .filter((task) => task?.externalIssue === undefined || task?.externalIssue.service === 'slack')
      .filter((task) => task?._relationships.requirement === null);
    return sort(filteredTasks, 'createdAt', true);
  }, backlogSelector);

  const taraTasks = useSelector(taraTasksSelector, deepEquals);
  const all = useSelector(
    compose(
      (allTasks: UI.UITask[] | undefined) => allTasks?.map(({ id }) => id),
      orderedDocuments(allSelector, () => selectedRequirement?.orderedTaskIds ?? []),
    ),
  );
  const order = useSelector(
    compose(
      (allTasks: UI.UITask[] | undefined) => allTasks?.map(({ id }) => id),
      orderedDocuments(orderSelector, () => selectedRequirement?.orderedTaskIds ?? []),
    ),
  );

  const getRequirementTasks = useCallback((requirement) => {
    setSelectedRequirementId(requirement.id);
  }, []);

  const getBacklogTasks = useCallback(() => {
    setDisplayBacklogTasks(true);
  }, []);

  const selectNoRequirement = useCallback(() => {
    setSelectedRequirementId(undefined);
  }, []);

  const getGroups = useCallback(() => {
    setDisplayBacklogTasks(false);
  }, []);

  const hideImportedTasks = useCallback(() => {
    setDisplayImportTasks(null);
  }, []);

  const toggleModal = useCallback(
    (task) => {
      openModal(task, strings.tasks.tasks);
    },
    [openModal],
  );

  const shouldDisplayProductList =
    !selectedRequirementId && !displayBacklogTasks && displayTasksImportedFromService === null;
  const displayEmptyBacklog = requirements?.length === 0 && tasks?.length === 0;

  const droppableId = getSerializedDroppableId(selectedRequirementId, order, all);

  return (
    <>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={cx(
              css`
                position: relative;
                ${sprintsBacklogWrapperStyles};
              `,
              className,
            )}
            {...props}
          >
            {backlogOverlay && <AreaOverlay>Move to backlog</AreaOverlay>}
            <SprintBacklogHeaderContainer>
              <SprintsBacklogHeader />
            </SprintBacklogHeaderContainer>
            {!displayTasksImportedFromService && (
              <CreateTaskInput
                dataCy={sprintsTabTestIds.NEW_TASK_INPUT_SPRINT_BACKLOG}
                requirement={selectedRequirement}
              />
            )}
            {selectedRequirement && (
              <RequirementTasksController
                backlogOverlay={backlogOverlay}
                getRequirements={selectNoRequirement}
                requirement={selectedRequirement}
              />
            )}
            {displayBacklogTasks && <BacklogTasks getGroups={getGroups} tasks={taraTasks} toggleModal={toggleModal} />}
            {displayTasksImportedFromService === null ? null : (
              <ImportedTasksController onBackClick={hideImportedTasks} service={displayTasksImportedFromService} />
            )}
            {shouldDisplayProductList && (
              <RequirementsListController
                backlogTaskCount={taraTasks.length}
                getBacklogTasks={getBacklogTasks}
                getRequirementTasks={getRequirementTasks}
                listItem={taraTasks}
                requirements={requirements ?? []}
                showImportedTasks={setDisplayImportTasks}
              />
            )}
            <TeamBacklogPopupController />
            {displayEmptyBacklog && <EmptyBacklogView />}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
const SprintBacklogHeaderContainer = styled('div', {
  padding: '1.125rem 1rem',
});
