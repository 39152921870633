import { Data, UI } from '@taraai/types';
import Select from 'components/core/controllers/views/Select';
import { OptionDefinition } from 'components/core/controllers/views/Select/Select';
import { css } from 'emotion';
import React, { useCallback, useEffect } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { strings } from 'resources';
import { COMPLETE_AND_CREATE_NEW_SPRINT } from 'tools/libraries/helpers/constants';

export interface SprintDropdownProps {
  orgId: Data.Id.OrganizationId;
  currentSprint: UI.UISprint | undefined;
  selectedSprint: OptionDefinition | null;
  upcomingSprints: UI.UISprint[] | undefined;
  setSelectedSprint: (option: OptionDefinition) => void;
}

export default function SprintDropdown({
  currentSprint,
  selectedSprint,
  upcomingSprints,
  setSelectedSprint,
}: SprintDropdownProps): JSX.Element | null {
  const upcomingSprintsOptions =
    upcomingSprints
      ?.slice(0)
      .reverse()
      .filter((sprint: UI.UISprint) => sprint?.id !== currentSprint?.id)
      .map(
        (sprint: UI.UISprint): OptionDefinition => ({
          label: sprint.sprintName,
          value: sprint.id,
        }),
      ) ?? [];

  // Used to highlight the next sprint
  const nextSprint = upcomingSprints
    ?.filter((sprint: UI.UISprint) => sprint?.sprintNumber > (currentSprint?.sprintNumber ?? -1))
    .pop();

  const backlogOption = {
    label: strings.sprintDropdown.backlog,
    value: 'Backlog',
  };

  const newSprintOption = {
    label: strings.sprintDropdown.createNew,
    value: COMPLETE_AND_CREATE_NEW_SPRINT,
  };

  const sprintDropdownOptions = [...upcomingSprintsOptions, backlogOption, newSprintOption];

  useEffect(() => {
    if (nextSprint) {
      setSelectedSprint({
        label: nextSprint.sprintName,
        value: nextSprint?.id,
      });
    } else {
      setSelectedSprint({
        label: strings.sprintDropdown.createNew,
        value: COMPLETE_AND_CREATE_NEW_SPRINT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSprintChange = useCallback(
    (option) => {
      setSelectedSprint(option);
    },
    [setSelectedSprint],
  );

  if (
    !isLoaded(upcomingSprints) ||
    !isLoaded(currentSprint) ||
    !isLoaded(selectedSprint) ||
    upcomingSprints === undefined
  ) {
    return null;
  }

  return (
    <Select
      className={css`
        min-width: 9.375rem;
      `}
      onChange={handleSprintChange}
      options={sprintDropdownOptions}
      placeholder={strings.sprintDropdown.select}
      value={selectedSprint ?? undefined}
    />
  );
}
