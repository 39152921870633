import { Box, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
// As agreed with Bobby, this module has low priority and should not block the delivery of Tara v2 Alpha
// So I'm commenting it now until I finish the refactor required to make it work as for the requirements.
// That will happen here: https://github.com/TARAAI/tara-js/pull/4266
// import SprintActivityController from 'components/app/controllers/SprintActivityController';
import SprintDateModuleController from 'components/app/controllers/SprintDateModuleController';
import { SprintDetailsSidebarHeader } from 'components/app/controllers/SprintDetailsSidebarHeader';
import { SprintFiltersModuleController } from 'components/app/controllers/SprintFiltersModuleController';
import React from 'react';

export interface SprintDetailsLayoutProps {
  selectedSprintId: Data.Id.SprintId;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
}
/**
 * Layout for the Sprint Details Sidebar and takes in a selected sprint to pass to child component controllers and views
 */

export default React.memo(function SprintDetailsSidebarLayout({
  selectedSprintId,
  orgId,
  teamId,
}: SprintDetailsLayoutProps): JSX.Element {
  return (
    <Box background='$white' borderLeft='$grey4' height='$full'>
      <VStack space='$1px'>
        <SprintDetailsSidebarHeader sprintId={selectedSprintId} />
        <SprintDateModuleController orgId={orgId} sprintId={selectedSprintId} />
        <SprintFiltersModuleController orgId={orgId} sprintId={selectedSprintId} teamId={teamId} />
        {/* <SprintActivityController orgId={orgId} sprintId={selectedSprintId} /> */}
      </VStack>
    </Box>
  );
});
