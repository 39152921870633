import { UI } from '@taraai/types';
import { SectionWithLoader } from 'components/app/TaskDetails/common/Section';
import Activity from 'components/core/controllers/views/Activity';
import React from 'react';
import { strings } from 'resources';

type Props = {
  revisions: UI.UITaskRevision[] | undefined;
};

export function TaskDetailsActivity({ revisions }: Props): JSX.Element {
  return (
    <SectionWithLoader
      content={(revisionsLoaded) => <Activity revisions={revisionsLoaded} />}
      data={revisions}
      noBorder
      title={strings.taskSidebar.modules.activity}
    />
  );
}
