/* eslint-disable @typescript-eslint/no-shadow */

import { unwrapResult } from '@reduxjs/toolkit';
import { Box, getCustomSize, HStack, styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { OrganizationId, TaskId } from '@taraai/types/src/data/id';
import { Section } from 'components/app/TaskDetails/common/Section';
import { UserFragment } from 'components/app/TaskDetails/TaskDetails';
import DropdownController from 'components/core/controllers/DropdownController';
import React, { useCallback, useState } from 'react';
import { reduxStore, updateTask } from 'reduxStore';
import { decodeChain } from 'reduxStore/utils/decoders';
import { strings } from 'resources';
import { useToast } from 'tools';

import { AddCollaboratorButton } from './AddCollaboratorButton';
import { CollaboratorsList } from './CollaboratorsList';

type Props = {
  collaborators: UserFragment[];
  orgId: OrganizationId;
  taskId: TaskId;
  users: UserFragment[];
};

export const Collaborators = ({ collaborators, orgId, taskId, users }: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const { whenError } = useToast();

  /** Add/remove a collaborator by id */
  const updateCollaborators = useCallback(
    ({ collaborator, remove }: { collaborator: string; remove?: boolean }) => {
      const action = remove ? '::arrayRemove' : '::arrayUnion';
      const change = { id: taskId, collaborators: [action, collaborator] };

      decodeChain<UI.UITaskChangeset>(change, 'UITaskChangeset')
        .then((changeset) => reduxStore.dispatch(updateTask({ orgId, ...changeset })))
        .then(unwrapResult)
        .catch(whenError(strings.task.failedToUpdateTask));
    },
    [orgId, taskId, whenError],
  );
  return (
    <Section
      right={
        <DropdownController
          header
          headerPlaceholder={strings.dropdown.placeholder.searchUsers}
          headerTitle={strings.dropdown.header.addTeamMembers}
          onAdd={(collaborator) => updateCollaborators({ collaborator })}
          onClose={useCallback(() => setShow(false), [])}
          onRemove={(collaborator) => updateCollaborators({ collaborator, remove: true })}
          options={users}
          selectedOptions={collaborators}
          show={show}
        >
          <CustomBox width='$full'>
            <Wrapper align='right' space='$4px'>
              <CollaboratorsList
                collaborators={collaborators}
                onClick={useCallback(() => setShow((show) => !show), [])}
              />
              <AddCollaboratorButton
                isListEmpty={collaborators.length === 0}
                onClick={useCallback(() => setShow((show) => !show), [])}
              />
            </Wrapper>
          </CustomBox>
        </DropdownController>
      }
      spaceVert='$none'
      title={strings.taskSidebar.modules.collaborators.title}
    />
  );
};

const Wrapper = styled(HStack, { flexWrap: 'wrap' });

const CustomBox = styled(Box, {
  // use custom size to use appropriate paddings together with spacings between wrapped avatars
  padding: `${getCustomSize(6)} 0`,
});
