import { Data, UI } from '@taraai/types';
import SprintsRowView from 'components/app/controllers/views/SprintsRowView';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { markPage, Marks, useWindowWidth } from 'tools/utils';

type SprintFragment = Pick<
  UI.UISprint,
  | 'id'
  | 'sprintName'
  | 'startDate'
  | 'endDate'
  | 'completedAt'
  | 'computedOnCompletion'
  | 'isComplete'
  | 'orderedTaskIds'
>;

export interface SprintsRowProps {
  /**
   * Array of sprints objects to display
   */
  sprints: SprintFragment[] | undefined;
  /**
   * The sprint id of the currently active sprint if any
   */
  currentSprintId?: Data.Id.SprintId;
  /**
   * Indicates if sprints are completed
   */
  isComplete?: boolean;
}

const SPRINT_COLUMN_WIDTH = 366;

export default function SprintsRow({ sprints, isComplete, currentSprintId }: SprintsRowProps): JSX.Element {
  const [renderedSprintsCount, setRenderedSprintsCount] = useState(0);
  const [batchSize, setBatchSize] = useState(0);
  const sprintsToRender = sprints?.slice(0, renderedSprintsCount) ?? [];
  const additionalSprintsToRender = sprintsToRender.length < (sprints || []).length;
  const dynamicScreenWidth = useWindowWidth();
  const containerRef = useRef(null);
  const containerDivElement = containerRef.current as never;

  const renderAdditionalSprints = useCallback(() => {
    if (sprints) {
      const newRenderedSprintCount = renderedSprintsCount + batchSize;
      setRenderedSprintsCount(newRenderedSprintCount);
    }
  }, [batchSize, renderedSprintsCount, sprints]);

  const handleOnScroll = useCallback((): void => {
    if (containerDivElement) {
      const { scrollLeft, clientWidth, scrollWidth } = containerDivElement;
      if (scrollLeft + clientWidth + SPRINT_COLUMN_WIDTH > scrollWidth && additionalSprintsToRender) {
        renderAdditionalSprints();
      }
    }
  }, [additionalSprintsToRender, containerDivElement, renderAdditionalSprints]);

  useEffect(() => {
    // // render initial sprints
    if (renderedSprintsCount === 0 && sprints?.[0]) renderAdditionalSprints();
    if (renderedSprintsCount === 1) {
      markPage(Marks.PageSprint, true);
    }
    // render additional sprints on screen resize
    if (renderedSprintsCount < batchSize && renderedSprintsCount > 0) {
      renderAdditionalSprints();
    }
    if (sprints && sprints.length > sprintsToRender.length && renderedSprintsCount > sprintsToRender.length) {
      renderAdditionalSprints();
    }
    // set number of sprints to render at a time
    if (containerDivElement) {
      const { clientWidth } = containerDivElement;
      const numberOfSprintsPerBatch = Math.ceil(clientWidth / SPRINT_COLUMN_WIDTH);
      setBatchSize(numberOfSprintsPerBatch);
    }
  }, [
    batchSize,
    renderAdditionalSprints,
    renderedSprintsCount,
    dynamicScreenWidth,
    sprints,
    containerDivElement,
    sprintsToRender.length,
  ]);
  return (
    <SprintsRowView
      additionalSprintsToRender={additionalSprintsToRender}
      currentSprintId={currentSprintId}
      forwardedRef={containerRef}
      handleOnScroll={handleOnScroll}
      isComplete={isComplete}
      sprints={sprintsToRender}
    />
  );
}
