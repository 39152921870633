"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.defaultTextColor = void 0;
var core_1 = require("./core");
exports.defaultTextColor = '$grey7';
exports.Text = core_1.styled('span', {
    fontFamily: '$basic',
    fontWeight: '$regular',
}, {
    color: __assign(__assign({}, core_1.mapTokens('colors', function (value) { return ({ color: value }); })), { current: { color: 'currentColor' } }),
    italic: { true: { fontStyle: 'italic' } },
    underline: { true: { textDecoration: 'underline' } },
    size: core_1.mapTokens('fontSizes', function (value, name) { return ({ fontSize: value, lineHeight: name }); }),
    uppercase: { true: { textTransform: 'uppercase' } },
    weight: { bold: { fontWeight: '$bold' }, medium: { fontWeight: '$medium' }, regular: { fontWeight: '$regular' } },
    singleLine: {
        true: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    textAlign: {
        left: { textAlign: 'left' },
        center: { textAlign: 'center' },
        right: { textAlign: 'right' },
    },
}, { required: ['size'] });
exports.Text.defaultProps = {
    color: exports.defaultTextColor,
};
