import { Tooltip } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { strings } from 'resources/i18n';

export interface GitLabSnippetProps extends React.HTMLProps<HTMLDivElement> {
  token?: boolean;
  dataCy?: string;
  secretToken?: string;
}

const GITLAB_WEBHOOK_URL = `${process.env.REACT_APP_API_SERVER}webhooks/gitlab`;

export default function GitLabSnippet({ className, token, dataCy, secretToken }: GitLabSnippetProps): JSX.Element {
  const [urlCopiedText, setUrlCopiedText] = useState(false);

  const copyGit = (): void => {
    if (token) {
      navigator.clipboard.writeText(secretToken?.toString() as string);
    } else {
      navigator.clipboard.writeText(GITLAB_WEBHOOK_URL);
    }
    setUrlCopiedText(true);
  };
  return (
    <div
      className={cx(
        css`
          background-color: #fbfbfd;
          border: solid 0.0625rem #dee3ec;
          border-radius: 0.1875rem;
          padding: 0.5rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `,
        className,
      )}
    >
      <span
        className={css`
          color: #67728b;
          font-size: 0.75rem;
          font-weight: 500;
          font-family: SourceCodePro;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        {token ? secretToken : GITLAB_WEBHOOK_URL}
      </span>
      <div data-cy={dataCy} onMouseEnter={(): void => setUrlCopiedText(false)}>
        <Tooltip
          data-cy={dataCy}
          title={urlCopiedText ? strings.integrations.gitlab.modal.copied : strings.integrations.gitlab.modal.copy}
        >
          <div data-cy={dataCy}>
            <Icon
              className={css`
                padding: 0rem;
                display: flex;
                cursor: pointer;
              `}
              dataCy={dataCy}
              name='copyGit'
              onClick={copyGit}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
