/* eslint-disable rulesdir/imports */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { createFirestoreId, RootStateWithProfile } from 'reduxStore';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

import { getNewSprintName } from './create';

type createSprintPayload = {
  taskId: Data.Id.TaskId;
};
// @TODO: Consider adding the "populate" functionality to the createSprint method
export const createAndPopulateSprint = createAsyncThunk(
  'CreateAndPopulateSprint',
  async (payload: createSprintPayload, { extra, getState }) => {
    const { getOrgId, getFirestore, getTeamId, getUserId } = extra as ExtraAPI;

    const state = getState() as RootStateWithProfile;
    const userId = getUserId(state);
    const teamId = getTeamId(state);
    const orgId = getOrgId();
    const firestore = getFirestore();
    const taskPath = `orgs/${orgId}/tasks`;
    const sprintPath = `orgs/${orgId}/sprints`;
    const taskId = payload?.taskId;

    // @TODO: This is an implicit dependency that must be handled by the mutate function. See: https://app.tara.ai/tara-ai/team-tara/tasks/3748
    const previousSprint = Object.values<UI.UISprint>(state.firestore.cache.database[sprintPath]).sort(
      (first: UI.UISprint, next: UI.UISprint) => next.endDate.toMillis() - first.endDate.toMillis(),
    )[0];

    const sprintId = createFirestoreId(firestore);

    const newSprintNumber = previousSprint.sprintNumber + 1;
    const now = firestore.Timestamp.now();

    const startDate = previousSprint.endDate;

    // Setting the new sprint duration to the same duration as the previous sprint
    // @TODO: Can I get this from somewhere else?
    const endDate = firestore.Timestamp.fromDate(
      new Date(startDate.toMillis() + previousSprint.endDate.toMillis() - previousSprint.startDate.toMillis()),
    );

    const sprint = decode<Data.Sprint>(
      {
        teamId,
        startDate,
        endDate,
        createdAt: now,
        updatedAt: now,
        sprintName: getNewSprintName(previousSprint, newSprintNumber),
        sprintNumber: newSprintNumber,
        orderedTaskIds: [taskId],
        totalTasksCount: 1,
      },
      'Sprint',
      CREATION_DEFAULTS,
    );

    firestore.mutate({
      reads: {
        taskData: {
          collection: taskPath,
          doc: taskId,
        },
      },
      writes: [
        { doc: sprintId, collection: sprintPath, data: sprint },
        {
          doc: taskId,
          collection: taskPath,
          data: { sprint: sprintId, updatedBy: userId },
        },
        ({ taskData }) => {
          return {
            doc: taskData.sprint,
            collection: sprintPath,
            data: { orderedTaskIds: ['::arrayRemove', taskId] },
          };
        },
      ],
    });
  },
);
