import { createSelector, OutputParametricSelector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { addDuration } from '@taraai/utility';
import { SprintColumnView } from 'components/app/controllers/views/SprintColumnView/SprintColumnView';
import pick from 'lodash.pick';
import React, { useCallback, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, useSelector } from 'react-redux';
import { reduxStore, searchActions, selectSprintDocument, selectTeamDocument } from 'reduxStore';
import { getNewSprintName } from 'reduxStore/sprints/actions/create';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { toTimestamp } from 'tools';
import { firestore } from 'tools/libraries/firebaseValues';

type SprintFragment = Pick<
  UI.UISprint,
  | 'id'
  | 'path'
  | 'sprintName'
  | 'startDate'
  | 'endDate'
  | 'isComplete'
  | 'computedOnCompletion'
  | 'completedAt'
  | 'orderedTaskIds'
> & { isPhantom?: boolean };

type SprintColumnControllerProps = {
  acceptDrops: boolean;
  currentSprintId: string;
  isPhantom: boolean;
  onSelectSprint: (sprintId: Data.Id.SprintId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selected: boolean;
  selectedTaskId: Data.Id.TaskId | undefined;
  sprintId: string;
};

const createPhantomSprint = (previousSprint: UI.UISprint, team: UI.UITeam): UI.UISprint => {
  const newSprintNumber = previousSprint.sprintNumber + 1;
  const now = firestore.Timestamp.now();
  // We need the toTimestamp because during the creation of a sprint, can be the optimistic update dates
  // are not a firestore object just yet
  const startDate = toTimestamp(previousSprint.endDate);
  const endDate = firestore.Timestamp.fromDate(addDuration(startDate.toDate(), team.sprintSettings.duration));

  return decode<UI.UISprint>(
    {
      teamId: team.id,
      startDate,
      endDate,
      createdAt: now,
      updatedAt: now,
      sprintName: getNewSprintName(previousSprint, newSprintNumber),
      sprintNumber: newSprintNumber,
    },
    'Sprint',
    CREATION_DEFAULTS,
  );
};

const fields: (keyof UI.UISprint)[] = [
  'id',
  'path',
  'sprintName',
  'sprintNumber',
  'startDate',
  'teamId',
  'endDate',
  'isComplete',
  'computedOnCompletion',
  'completedAt',
  'orderedTaskIds',
];

const selectOrCreatePhantomSprint = (): OutputParametricSelector<
  DefaultRootState,
  string,
  SprintFragment | undefined,
  (res1: DefaultRootState, res2: string, res3: boolean) => SprintFragment | undefined
> =>
  createSelector(
    (state: DefaultRootState) => state,
    (_: unknown, sprintId: string) => sprintId,
    (_: unknown, __: unknown, isPhantom: boolean) => isPhantom,
    (state, sprintId, isPhantom): SprintFragment | undefined => {
      const sprint = selectSprintDocument(state, sprintId, fields);
      if (sprint) {
        if (!isPhantom) {
          return sprint;
        }
        const team = selectTeamDocument(state, sprint.teamId);
        if (!team) return undefined;
        const phantomSprint = createPhantomSprint(sprint, team);
        const phantom = pick(phantomSprint, fields) as SprintFragment;

        phantom.isPhantom = true;
        return phantom;
      }
      return undefined;
    },
  );

export default function SprintColumnController({
  acceptDrops,
  currentSprintId,
  isPhantom,
  onSelectSprint,
  onTaskSelect,
  selected,
  selectedTaskId,
  sprintId,
}: SprintColumnControllerProps): JSX.Element | null {
  // NOTE: Loading all tasks in list controller to avoid firestore listeners sets + unsets.

  const bulkLoadedInListController = '';

  const memoSprintCreator = useMemo(selectOrCreatePhantomSprint, [sprintId, isPhantom]);
  const sprint = useSelector((state) => memoSprintCreator(state, sprintId, isPhantom), deepEquals);

  const handleFilterReset = useCallback(() => reduxStore.dispatch(searchActions.search(undefined)), []);

  return !sprint ? null : (
    <SprintColumnView
      acceptDrops={acceptDrops}
      currentSprintId={currentSprintId}
      onFilterReset={handleFilterReset}
      onSelectSprint={onSelectSprint}
      onTaskSelect={onTaskSelect}
      selected={selected}
      selectedTaskId={selectedTaskId}
      sprintEndDateSeconds={sprint.endDate.seconds}
      sprintId={sprint.id}
      sprintIsComplete={sprint.isComplete}
      sprintIsPhantom={isPhantom}
      sprintName={sprint.sprintName}
      sprintStartDateSeconds={sprint.startDate.seconds}
      taskAlias={bulkLoadedInListController}
    />
  );
}
