import { Box, VStack } from '@taraai/design-system';
import { TaskPartial, UI } from '@taraai/types';
import { keys, noop } from '@taraai/utility';
import {
  composePlugins,
  createLabelsPlugin,
  createMaxLengthPlugin,
  createMentionPlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createLabel,
  defaultLabels,
  getCustomLabels,
  reduxStore,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';

import { Options } from './Options';

const LIMIT = 140;

type Props = {
  children: ReactNode;
  initialValue?: string;
  onEnter: (text: string) => void;

  optionsAnimation?: {
    onAnimationEnd: () => void;
    show: boolean;
  };
};

export function TaskCreation({ children, initialValue = '', onEnter, optionsAnimation }: Props): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const [text, setText] = useState(initialValue);
  const [atLimit, setAtLimit] = useState(false);
  const [tooShort, setTooShort] = useState(true);

  const customLabels = useSelector(getCustomLabels(orgId).selector);
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels || [])];

  const getUserTagForId = useUserTagForId(orgId);

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
        }),
        createMaxLengthPlugin({ maxLength: LIMIT }),
        createMentionPlugin(getUserTagForId),
      ),
    [getUserTagForId],
  );

  useEffect((): void => {
    try {
      decode<TaskPartial>({ title: text }, 'TaskPartial');
      if (text.length >= LIMIT) return setAtLimit(true);
      setAtLimit(false);
      setTooShort(false);
    } catch {
      setTooShort(true);
    }
  }, [text]);

  return (
    <RichEditorProvider
      initialValue={initialValue}
      onSave={!tooShort ? onEnter : noop}
      onTextChange={setText}
      plugin={plugin}
      saveStrategy='saveOnReturn'
      selectAllContent={!!initialValue}
    >
      <VStack>
        <Box spaceRight='$4px'>{children}</Box>
        <Options atLimit={atLimit} optionsAnimation={optionsAnimation} text={text} tooShort={tooShort} />
      </VStack>
    </RichEditorProvider>
  );
}
