import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import DeleteCommentModal from 'components/app/controllers/views/DeleteCommentModal';
import React, { useCallback } from 'react';
import { deleteTaskComment, reduxStore } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

interface Props {
  commentId: Data.Id.CommentId;
  onClose: () => void;
  taskId: Data.Id.TaskId;
}

export default function DeleteTaskCommentController({ commentId, onClose, taskId }: Props): JSX.Element {
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (): Promise<void> => {
    onClose();
    await reduxStore
      .dispatch(deleteTaskComment({ commentId, taskId }))
      .then(unwrapResult)
      .then(() =>
        addToast({
          type: 'success',
          message: strings.comments.deleteModal.commentDeleted,
          timeoutMs: 3000,
        }),
      )
      .catch(() =>
        addToast({
          type: 'error',
          message: strings.comments.deleteModal.couldntDelete,
          timeoutMs: 3000,
        }),
      );
  }, [addToast, commentId, onClose, taskId]);

  return <DeleteCommentModal onClose={onClose} onSubmit={handleSubmit} />;
}
