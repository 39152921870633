/* eslint-disable rulesdir/imports */
import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, TaskStatus, Timestamp, UI } from '@taraai/types';
import { isLoaded } from 'react-redux-firebase';
import { getSprintCommits, getSprintPullRequests, getSprintTasks as sprintTasksQuery, RootState } from 'reduxStore';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

import { getSprint } from './getSprint';
import { getEffortPercent, getSprintCalculations } from './getSprintCalculations';

export type CompleteSprintData = {
  sprintTasks: UI.UITask[] | undefined;
  incompleteSprintTasks: UI.UITask[] | undefined;
  completeSprintTasks: UI.UITask[] | undefined;

  closedPullRequestsCount: number;
  allPullRequestsCount: number;

  totalEffortCompleted: number;
  totalEffortEstimated: number;
  effortCompletedPercent: number;

  totalTasks: number;
  totalTasksCompleted: number;

  totalCommits: number;
};

type GetCompleteSprintDataRes = {
  query: ReduxFirestoreQuery[];
  selector: Selector<RootState, CompleteSprintData | undefined>;
};

export const getCompleteSprintData = (
  orgId: Data.Id.OrganizationId,
  sprintId: Data.Id.SprintId,
  startDate: Timestamp | null,
  endDate: Timestamp | null,
): GetCompleteSprintDataRes => {
  const data = {
    sprint: getSprint(orgId, sprintId),
    sprintTasks: sprintTasksQuery(orgId, sprintId, { orderBy: 'status' }),
    sprintPullRequests: getSprintPullRequests(orgId, sprintId, startDate, endDate),
    sprintCommits: getSprintCommits(orgId, sprintId, startDate, endDate),
  };

  const query = [
    ...data.sprint.query,
    ...data.sprintTasks.query,
    ...data.sprintPullRequests.query,
    ...data.sprintCommits.query,
  ];

  const sprintDataSelector = createSelector(
    [data.sprint.selector, data.sprintTasks.selector, data.sprintPullRequests.selector, data.sprintCommits.selector],
    (sprint, sprintTasks, sprintPullRequests, sprintCommits): CompleteSprintData | undefined => {
      if (!isLoaded(sprint) || !isLoaded(sprintTasks) || !isLoaded(sprintPullRequests) || !isLoaded(sprintCommits)) {
        return undefined;
      }

      const incompleteSprintTasks = sprintTasks?.filter(
        (task: UI.UITask): boolean => task.status === TaskStatus.Todo || task.status === TaskStatus.Doing,
      );

      const completeSprintTasks = sprintTasks?.filter((task: UI.UITask): boolean => task.status === TaskStatus.Done);

      const closedPullRequestsCount =
        sprintPullRequests?.filter((pullRequest) => pullRequest.state === 'closed').length || 0;

      const { totalEffortCompleted, totalEffortEstimated, totalTasksCompleted, totalTasks } = getSprintCalculations(
        sprint,
        sprintTasks,
        completeSprintTasks,
      );

      const effortCompletedPercent = getEffortPercent(totalEffortCompleted, totalEffortEstimated);

      return {
        sprintTasks,
        incompleteSprintTasks,
        completeSprintTasks,
        closedPullRequestsCount,
        totalEffortCompleted,
        totalEffortEstimated,
        effortCompletedPercent,
        totalTasksCompleted,
        totalTasks,
        allPullRequestsCount: sprintPullRequests?.length || 0,
        totalCommits: sprintCommits?.length || 0,
      };
    },
  );

  return {
    query,
    selector: sprintDataSelector,
  };
};
