import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Fluid, getCustomSize, HStack, styled } from '@taraai/design-system';
import { Data, TaskPartial, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { keys } from '@taraai/utility/dist/objects';
import Icon from 'components/core/controllers/views/Icon';
import {
  createLabelsPlugin,
  createSingleLinePlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { composePlugins } from 'components/editor/plugins/utils';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createLabel,
  createTask,
  defaultLabels,
  getCustomLabels,
  reduxStore,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

type SprintFragment = Pick<UI.UISprint, 'id' | 'sprintName'>;
type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;

export interface CreateTaskInputProps extends React.HTMLProps<HTMLDivElement> {
  callback?: (taskId: Data.Id.TaskId) => void;
  dataCy?: string;
  noTopBorder?: boolean;
  requirement?: RequirementFragment;
  sprint?: SprintFragment;
}

type TaskCreationLocation = 'RequirementBacklog' | 'SprintsColumn' | 'Backlog';
function getTaskCreationLocation(requirement: boolean, sprint: boolean): TaskCreationLocation {
  if (requirement) return 'RequirementBacklog';
  if (sprint) return 'SprintsColumn';
  return 'Backlog';
}

function getPlaceholder(requirement: RequirementFragment | undefined, sprint: SprintFragment | undefined): string {
  // formatString method returns an array of strings if formatted string still contains an object (eg React Component)
  // so cast the type, as in this case we are sure we return string
  if (requirement) return strings.formatString(strings.sprints.createTaskName, { name: requirement.title }) as string;
  if (sprint) return strings.formatString(strings.sprints.createSprintName, { name: sprint.sprintName }) as string;
  return strings.sprints.createTask;
}

export default function CreateTaskInput({ callback, requirement, sprint, dataCy }: CreateTaskInputProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const { addToast } = useToast();

  const customLabels = useSelector(getCustomLabels(orgId).selector);

  const editorRef = useRef<RichEditorHandle>(null);
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels || [])];

  const [hasText, setHasText] = useState(false);

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
        }),
        createSingleLinePlugin({ returnHandled: true }),
      ),
    [],
  );

  const handleEnter = async (title: string): Promise<void> => {
    try {
      decode<TaskPartial>({ title }, 'TaskPartial');
    } catch {
      addToast({ type: 'error', message: strings.task.titleTooShort });
      return;
    }

    const labels = unique(parseLabelsFromPlainText(title));

    const taskId = await reduxStore
      .dispatch(
        createTask({
          title,
          labels,
          _relationships: { requirement: requirement?.id ?? null },
          sprint: sprint?.id ?? null,
        }),
      )
      .then(unwrapResult)
      .then((id) => {
        segment.track('TaskCreated', {
          orgID: orgId,
          taskID: id,
          location: getTaskCreationLocation(!!requirement, !!sprint),
        });
        editorRef.current?.clear();

        return id;
      })
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToCreateTask });
      });

    taskId && callback && callback(taskId);
  };

  const placeholder = getPlaceholder(requirement, sprint);
  return (
    <RichEditorProvider
      initialValue=''
      onSave={handleEnter}
      onTextChange={(text) => setHasText(text.length > 0)}
      plugin={plugin}
      saveStrategy='saveOnReturn'
      singleLine
    >
      <WrapperBox borderVert='$grey3' height={taskInputHeight} spaceHorz='$16px'>
        <HStack alignY='center' full space='$16px'>
          <StyledIcon name='plus' noPadding />
          <Fluid enabled='horizontal'>
            <CreateTaskEditor ref={editorRef} data-cy={dataCy} placeholder={placeholder} />
          </Fluid>
          {!hasText && <StyledIcon name='enter' />}
        </HStack>
      </WrapperBox>
    </RichEditorProvider>
  );
}

const taskInputHeight = getCustomSize(40);

const WrapperBox = styled(Box, {
  ':hover': { backgroundColor: '$grey1' },
});

const StyledIcon = styled(Icon, { color: '$dark' });

const CreateTaskEditor = getStyledRichEditor({
  fontSize: '$14px',
  lineHeight: '$14px',
});
