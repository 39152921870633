import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { RequirementPartial, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const createRequirement = createAsyncThunk(
  'CreateRequirement',
  async ({ assignedTeamIds, title }: RequirementPartial, { extra, getState }) => {
    const { getOrgId, getUserId, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userId = getUserId(state);
    const orgId = getOrgId();

    const firestore = getFirestore();
    const requirement = decode<Tara.Requirement>(
      {
        author: userId,
        updatedAt: firestore.Timestamp.now(),
        createdAt: firestore.Timestamp.now(),
        title,
        description: '',
        assignedTeamIds,
      },
      'Requirement',
      CREATION_DEFAULTS,
    );

    return firestore.add<Tara.Requirement>(`orgs/${orgId}/requirements/`, requirement).then((docRef) => {
      const { id } = docRef;
      return { ...requirement, id } as UI.UIRequirement;
    });
  },
);
