import { Data } from '@taraai/types';
import { getPath, linkTo } from 'components/Router/paths';
import React from 'react';
import { homePageTestIds } from 'resources/cypress/testAttributesValues';

import { NavigationIcon } from './NavigationIcon';
import { NavigationItem } from './NavigationItem';
import { NavigationLink } from './NavigationLink';

type NavigationListElementsProps = {
  orgId?: Data.Id.OrganizationId;
  teamId?: Data.Id.TeamId;
};

export const NavigationListElements: React.FC<NavigationListElementsProps> = ({
  orgId,
  teamId,
}: NavigationListElementsProps) => {
  if (orgId && teamId) {
    return (
      <>
        <NavigationLink
          dataCy={homePageTestIds.HOME_ICON}
          icon='home'
          title='Home'
          to={linkTo('home', { orgId, teamId })}
        />
        <NavigationLink icon='builder' title='Requirements' to={linkTo('requirements', { orgId, teamId })} />
        <NavigationLink icon='sprints' title='Sprint Plans' to={linkTo('sprints', { orgId, teamId })} />
        <NavigationLink
          icon='progress'
          matchOn={getPath('sprintDetails')}
          title='Sprint Reports'
          to={linkTo('sprintDetails', { orgId, teamId, sprintId: 'current' })}
        />
      </>
    );
  }

  return (
    <>
      <NavigationItem dataCy={homePageTestIds.HOME_ICON} title='Home'>
        <NavigationIcon dataCy={homePageTestIds.HOME_ICON} name='home' />
      </NavigationItem>

      <NavigationItem title='Requirements'>
        <NavigationIcon name='builder' />
      </NavigationItem>

      <NavigationItem active title='Sprint Plans'>
        <NavigationIcon name='sprints' />
      </NavigationItem>

      <NavigationItem title='Sprint Reports'>
        <NavigationIcon name='progress' />
      </NavigationItem>
    </>
  );
};
