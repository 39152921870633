import { Data } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { getOrgId as getOrgIdFromPath, getTeamId as getTeamIdFromPath } from 'route-utils';

export function getUserId(state: RootState): Data.Id.UserId {
  const userId = state.firebase.auth.uid;

  if (!isNonEmptyString(userId)) {
    throw new Error('getUserId called for non authenticated user');
  }

  return userId;
}

export function getOrgId(): Data.Id.OrganizationId {
  const orgId = getOrgIdFromPath();

  if (!isNonEmptyString(orgId)) {
    throw new Error('getOrgId called from non organizational page');
  }

  return orgId;
}

export function getTeamId(): Data.Id.OrganizationId {
  const teamId = getTeamIdFromPath();

  if (!isNonEmptyString(teamId)) {
    throw new Error('getTeamId called from non team page');
  }

  return teamId;
}
