import { ImportNotificationBarController } from 'components/app/controllers/ImportNotificationBarController';
import React from 'react';
import { strings } from 'resources/i18n';

/**
 * Tara 2.0 will show import notifications inside Work Drawer
 * @deprecated
 */
export function TrelloNotificationBarController(): JSX.Element {
  return (
    <ImportNotificationBarController
      bgColor='#0075b8'
      iconName='trelloWhite'
      importInProgressCopy={strings.importNotificationBar.trello.importInProgressCopy}
      service='trello'
    />
  );
}
