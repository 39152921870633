import { Box, Fluid, Hidden, HStack, Spacer, styled, Text, tokens, Tooltip, VStack } from '@taraai/design-system';
import { Data, TaskStatus, Timestamp, UI } from '@taraai/types';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { TaskCreation } from 'components/app/TaskCreation';
import Icon from 'components/core/controllers/views/Icon/Icon';
import { getStyledRichEditor } from 'components/editor/RichEditor';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { strings } from 'resources';
import { taskDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { formatFromNow, useClickOutside } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

import { Indicator } from './Indicator';

type Props = {
  authorName: string;
  id: UI.UITask['id'];
  status: TaskStatus;
  title: string;
  updateTask: (title: string) => void;
  onClickDeleteTask?: () => void;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  taskId: Data.Id.TaskId;
  updatedAt: Timestamp;
};

export const Title = ({
  authorName,
  id,
  status,
  title,
  updateTask,
  onClickDeleteTask,
  orgId,
  teamId,
  taskId,
  updatedAt,
}: Props): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/${teamId}/define/tasks/${taskId}`);
    setTaskCopiedText(true);
  };
  const popupRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const deletePopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);
  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  useEffect(() => closePopup(), [closePopup]);

  useClickOutside(popupRef, closePopup);

  const handleTextClick = (): void => {
    setIsEditing(true);
    setShowOptions(true);
  };
  const onAnimationEnd = (): void => {
    if (!showOptions) setIsEditing(false);
  };

  const color = mapTaskStatusToColor[status];

  const handleTaskTitleChange = (newTitle: string): void => {
    updateTask(newTitle);
    setShowOptions(false);
  };

  const handleDeleteTask = (): void => {
    onClickDeleteTask && onClickDeleteTask();
    closePopup();
  };

  return (
    <Box background='$grey1' borderBottom='$grey2' spaceVert='$12px'>
      <VStack space='$12px'>
        <HStack>
          <Indicator color={color}>
            <Text color={color} size='$12px' weight='medium'>
              TASK-{id}
            </Text>
          </Indicator>
          <Fluid />
          <Tooltip placement='top' title={taskCopiedText ? strings.task.copied : strings.task.copyTask}>
            <Box.Button
              onClick={copyTaskId}
              onMouseEnter={(): void => setTaskCopiedText(false)}
              spaceLeft='$8px'
              spaceRight='$2px'
              spaceTop='$1px'
            >
              <CopyURLIcon name='copy' />
            </Box.Button>
          </Tooltip>
          <ShowMoreIcon interactive={!showPopup} name='showMore' noPadding onClick={deletePopup} />
          {showPopup && onClickDeleteTask && (
            <DeleteTask containerRef={popupRef} label={strings.taskSidebar.deleteTask} onClick={handleDeleteTask} />
          )}
          <Spacer space='$12px' />
        </HStack>
        <Box spaceHorz='$12px'>
          <Hidden hidden={!isEditing} strategy='remove'>
            <TaskCreation
              initialValue={title}
              onEnter={handleTaskTitleChange}
              optionsAnimation={{ onAnimationEnd, show: showOptions }}
            >
              <CreateTaskEditor placeholder={strings.workDrawer.createTask} saveOnBlur />
            </TaskCreation>
          </Hidden>
          <Hidden hidden={isEditing}>
            <Text color='$dark' data-cy={taskDetailsTestIds.TASK_TITLE} onDoubleClick={handleTextClick} size='$12px'>
              <SmartText text={title} />
            </Text>
          </Hidden>
        </Box>
        <Box spaceHorz='$12px'>
          <Text size='$10px'>
            {formatI18n(strings.taskSidebar.modules.header.updateStatus)({
              authorName,
              updatedAt: formatFromNow(updatedAt),
            })}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

const CreateTaskEditor = getStyledRichEditor({
  color: '$dark',
  fontSize: '$12px',
  lineHeight: '$12px',
  caretColor: 'colors.$focus',
});

const ShowMoreIcon = styled(
  Icon,
  { marginLeft: '8px' },
  {
    interactive: {
      true: {
        pointerEvents: 'auto',
      },
      false: {
        pointerEvents: 'none',
      },
    },
  },
);

type DeleteTaskProps = {
  onClick: () => void;
  label: string;
  containerRef: React.RefObject<HTMLDivElement>;
};

const DeleteTask = ({ onClick, label, containerRef }: DeleteTaskProps): JSX.Element => (
  <StyledDelete
    ref={containerRef}
    background='$white'
    border='$grey5'
    borderRadius='$4px'
    onClick={onClick}
    shadow='$popup'
  >
    <StyledText color='$failure' size='$10px' weight='medium'>
      {label}
    </StyledText>
  </StyledDelete>
);

const StyledDelete = styled(Box.Button, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'absolute',
  width: '100px',
  height: '1.75rem',
  top: '2rem',
  right: '1.0625rem',
  zIndex: '1',
});

const StyledText = styled(Text, {
  position: 'absolute',
  left: '24%',
  top: 'calc(50% - 14px/2)',
});

const CopyURLIcon = styled(Icon, {
  'padding': '0px',
  'height': tokens.space.$12px,
  'width': tokens.space.$12px,
  ':hover': { cursor: 'pointer', opacity: '0.6' },
});
