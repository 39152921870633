/* eslint-disable sonarjs/no-duplicate-string */

import { styled } from '@taraai/design-system';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  description?: string;
}

/**
 * A label chip that is used for categorizing items on the Tara app.
 */
export function LabelChip({ children, description }: Props): JSX.Element {
  return <Wrapper title={description}>{children}</Wrapper>;
}

const Wrapper = styled('span', {
  'fontStyle': 'normal',
  'fontWeight': '$regular',
  'textDecoration': 'none',
  'color': '$focus',
  '& *': {
    color: 'inherit !important',
    fontSize: 'inherit !important',
    fontStyle: 'inherit !important',
    fontWeight: 'inherit !important',
    textDecoration: 'inherit !important',
  },
});
