/* eslint-disable @typescript-eslint/no-explicit-any */
import { UI } from '@taraai/types';
import LegacyFeatureCard from 'components/app/controllers/LegacyFeatureCard';
import React, { ComponentProps } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTaskHasMatch } from 'reduxStore';

interface Props extends ComponentProps<typeof LegacyFeatureCard> {
  index: number;
  task: UI.UITask;
  disableOrdering?: boolean;
}

export default function DraggableFeatureCard({ task, index, disableOrdering, ...props }: Props): JSX.Element {
  const getStyle = (style: any, snapshot: any): any => {
    if (disableOrdering) {
      if (!snapshot.isDragging) return {};
      if (!snapshot.isDropAnimating) {
        return style;
      }

      return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: '0.001s',
      };
    }
    return { ...style };
  };

  const inSearchResults = useTaskHasMatch(task);

  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          hidden={!inSearchResults}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          <div>
            <LegacyFeatureCard filterLabelsOnClick task={task} {...props} />
          </div>
        </div>
      )}
    </Draggable>
  );
}
