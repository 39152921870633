"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectInput = void 0;
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var BaseInput_1 = require("./common/BaseInput");
var Dropdown_1 = require("./Dropdown");
var Fluid_1 = require("./Fluid");
var HStack_1 = require("./HStack");
var typography_1 = require("./typography");
function SelectInput(_a) {
    var _b;
    var onChange = _a.onChange, options = _a.options, placeholder = _a.placeholder, _c = _a.size, size = _c === void 0 ? '$14px' : _c, value = _a.value;
    var _d = react_1.useState(false), open = _d[0], setOpen = _d[1];
    var currentOption = options.find(function (_a) {
        var option = _a.option;
        return option === value;
    });
    return (react_1.default.createElement(Dropdown_1.Dropdown, { items: options.map(function (_a) {
            var option = _a.option, label = _a.label;
            return (react_1.default.createElement(Dropdown_1.Dropdown.DefaultItem, { key: label, onClick: function () { return onChange(option); } },
                react_1.default.createElement(typography_1.Text, { size: size }, label)));
        }), onHide: function () { return setOpen(false); }, placement: 'bottom-start', show: open },
        react_1.default.createElement(BaseInput_1.BaseInputWrapper, { onMouseDown: function () { return setOpen(true); } },
            react_1.default.createElement(HStack_1.HStack, { alignY: 'center', space: '$8px' },
                react_1.default.createElement(Fluid_1.Fluid, null,
                    react_1.default.createElement(typography_1.Text, { size: size }, (_b = currentOption === null || currentOption === void 0 ? void 0 : currentOption.label) !== null && _b !== void 0 ? _b : placeholder)),
                react_1.default.createElement(Box_1.Box, { space: '$8px' }, open ? selectArrows.expanded : selectArrows.folded)))));
}
exports.SelectInput = SelectInput;
var selectArrows = {
    folded: (react_1.default.createElement("svg", { fill: 'none', height: '4', viewBox: '0 0 6 4', width: '6', xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("path", { d: 'M3 3.5L5.57322 0.926777C5.73071 0.769286 5.61917 0.5 5.39645 0.5H0.603553C0.380827 0.5 0.269285 0.769285 0.426777 0.926777L3 3.5Z', fill: '#949CAF' }))),
    expanded: (react_1.default.createElement("svg", { fill: 'none', height: '4', viewBox: '0 0 6 4', width: '6', xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("path", { d: 'M3 0.5L0.426777 3.07322C0.269286 3.23071 0.380827 3.5 0.603554 3.5L5.39645 3.5C5.61917 3.5 5.73071 3.23071 5.57322 3.07322L3 0.5Z', fill: '#949CAF' }))),
};
