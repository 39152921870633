import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Popup, styled } from '@taraai/design-system';
import { SprintSettingsView } from 'components/app/controllers/views/SprintSettingsView';
import Icon from 'components/core/controllers/views/Icon';
import { usePathParams } from 'components/Router/paths';
import React, { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { reduxStore, selectOrganization, selectTeam, updateSprintSettings } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

export function SprintSettings(): JSX.Element | null {
  const { orgId, teamId } = usePathParams('sprints');
  const org = useSelector(selectOrganization(orgId));
  const team = useSelector(selectTeam(orgId, teamId));
  const [sprintSettings, setSprintSettings] = useState(team?.sprintSettings);
  const [show, setShow] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    // save on popup close
    if (!show && sprintSettings && !deepEquals(sprintSettings, team?.sprintSettings)) {
      // since saving sprint settings makes expensive update to all sprints
      // save settings only if local settings differ form the ones in the Firebase
      reduxStore
        .dispatch(
          updateSprintSettings({
            teamId,
            organizationId: orgId,
            sprintSettings,
          }),
        )
        .then(unwrapResult)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          addToast({
            message: strings.sprintsSettings.updateError,
            type: 'error',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!sprintSettings) {
    return null;
  }

  return (
    <Popup
      content={
        <Box spaceTop='$4px'>
          <SprintSettingsView
            onChange={setSprintSettings}
            sprintSettings={sprintSettings}
            testMode={org?.featureFlags.testMode}
          />
        </Box>
      }
      onHide={() => setShow(false)}
      placement='bottom-end'
      show={show}
    >
      <Box.Button onClick={() => setShow((value) => !value)} spaceVert='$2px'>
        <CogIcon name='cog' />
      </Box.Button>
    </Popup>
  );
}

const CogIcon = styled(Icon, {
  width: '12px',
  height: '12px',
  padding: 0,
});
