/* eslint-disable  sonarjs/cognitive-complexity */
import { Selector } from '@reduxjs/toolkit';
import { Data, TaskStatus, UI } from '@taraai/types';
import { UITask } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { OrderByOptions, WhereOptions } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import {
  createIndividualSelector,
  createStandardSelector,
  IndividualQuery,
  inertIndividualQuery,
  inertQuery,
  Query,
} from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export type TaskSelector = Selector<RootState, UI.UITask | undefined>;
export type TaskListSelector = Selector<RootState, UI.UITask[] | undefined>;

const taskRelationshipsPath = '_relationships';
const taskParentFieldPath = `${taskRelationshipsPath}.parent`;
const taskRequirementFieldPath = `${taskRelationshipsPath}.requirement`;
const taskProductFieldPath = `${taskRelationshipsPath}.product`;

type OrderBy = keyof Pick<UITask, 'title' | 'createdAt' | 'updatedAt' | 'status'>;
interface QueryOptions {
  orderBy?: OrderBy;
  reverseOrder?: boolean;
  limit?: number;
  skipPopulates?: true;
}

/**
 * Private Query Builder
 */
function queryBuilder(
  orgId: Data.Id.OrganizationId | undefined,
  where: WhereOptions[],
  options?: QueryOptions,
): Query<UITask> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const isSingle = where.some((clause) => clause[0] === '__name__');

  let orderBy: OrderByOptions[] | undefined;
  switch (options?.orderBy) {
    case 'title':
      orderBy = [['title', options.reverseOrder ? 'desc' : 'asc']];
      break;
    case 'updatedAt':
      orderBy = [['updatedAt', options.reverseOrder ? 'asc' : 'desc']];
      break;
    case 'createdAt':
      orderBy = [['createdAt', options.reverseOrder ? 'asc' : 'desc']];
      break;
    case 'status':
      orderBy = [['status', options.reverseOrder ? 'desc' : 'asc']];
      break;
    default:
      break;
  }
  if (isSingle) {
    orderBy = undefined;
  }

  const queryTask: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks`,
    where,
    orderBy,
    storeAs: `tasks/${orgId}/${where.toString()}-${options?.orderBy ?? ''}`,
  };

  if (options?.limit) {
    queryTask.limit = options?.limit;
  }
  if (orderBy === undefined) {
    delete queryTask.orderBy;
  }

  const query = [queryTask];
  if (options?.skipPopulates !== true) {
    queryTask.populates = [
      ['author', 'users', 'authorDocument'],
      ['assignee', 'users', 'assigneeDocument'],
      ['_relationships.requirement', `orgs/${orgId}/requirements`, 'requirementDocument'],
    ];

    const queryRequirements: ReduxFirestoreQuery = {
      collection: `orgs/${orgId}/requirements`,
      storeAs: `requirements/${orgId}`,
    };
    query.push(queryRequirements);
  }

  return {
    query,
    selector: isSingle ? createIndividualSelector<UITask>(queryTask) : createStandardSelector<UITask>(queryTask),
  } as Query<UITask>;
}

/**
 * Public functions
 */

export function getTask(orgId: string, taskId: string): IndividualQuery<UITask> {
  if (!isNonEmptyString(taskId)) {
    return inertIndividualQuery();
  }

  return queryBuilder(orgId, [['__name__', '==', taskId]]) as IndividualQuery<UITask>;
}

export function getTasks(orgId: Data.Id.OrganizationId | undefined, opts?: QueryOptions): Query<UITask> {
  const where: WhereOptions[] = [
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  const options = opts || {};
  if (!options.limit) {
    options.limit = 1;
  }

  return queryBuilder(orgId, where, options);
}

export function incompleteAssignedTasks(orgId: string, userId: string): Query<UITask> {
  if (![orgId, userId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['status', '<', TaskStatus.Done],
    ['assignee', '==', userId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  // Due to a limitation of firebase any filter with inequality on a filed
  // must also order by that field. The filed being 'status' here.
  return queryBuilder(orgId, where, { orderBy: 'status' });
}

export function requirementTasks(
  orgId: string | undefined,
  requirementId: string | undefined,
  options: QueryOptions = { orderBy: 'createdAt' },
): Query<UITask> {
  if (![orgId, requirementId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', requirementId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function getTasksWithoutRequirementAndNotImported(orgId: string, options?: QueryOptions): Query<UITask> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
    [taskProductFieldPath, '==', null],
  ];

  return queryBuilder(orgId, where, options);
}

export function incompleteRequirementBacklogTasks(
  orgId: string | undefined,
  requirementId: string | undefined,
): Query<UITask> {
  if (![orgId, requirementId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', requirementId],
    ['status', '<', TaskStatus.Done],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, { orderBy: 'status' });
}

export function requirementBacklogTasks(orgId: string, requirementId: string, options?: QueryOptions): Query<UITask> {
  if (![orgId, requirementId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', requirementId],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function getSprintTasks(orgId: string, sprintId: string[] | undefined, options?: QueryOptions): Query<UITask>[];
export function getSprintTasks(orgId: string, sprintId: string | undefined, options?: QueryOptions): Query<UITask>;
export function getSprintTasks(
  orgId: string,
  sprintId: string[] | string | undefined = '',
  options?: QueryOptions,
): Query<UITask> | Query<UITask>[] {
  const isArray = Array.isArray(sprintId);
  const sprintIds = Array.isArray(sprintId) ? sprintId : [sprintId];
  if (![orgId, ...sprintIds].every(isNonEmptyString) || sprintIds.length === 0) {
    return isArray ? [inertQuery()] : inertQuery();
  }

  if (sprintIds.length === 1) {
    const where: WhereOptions[] = [
      ['sprint', '==', sprintId],
      ['archived', '==', false],
      ['deleted', '==', false],
    ];

    return queryBuilder(orgId, where, options);
  }

  return new Array(Math.ceil(sprintIds.length / 10)).fill(null).map((query, idx) => {
    const start = idx * 10;
    const ids = sprintIds.slice(start, start + 10);
    return queryBuilder(
      orgId,
      [
        ['sprint', 'in', ids],
        ['archived', '==', false],
        ['deleted', '==', false],
      ],
      { ...options, skipPopulates: true },
    );
  });
}

export function sprintTasksUnassigned(orgId: string, sprintId: string, options?: QueryOptions): Query<UITask> {
  if (![orgId, sprintId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintId],
    ['assignee', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function currentSprintAssignedTasks(
  orgId: string,
  userId: string,
  sprintId: string | null,
  options?: QueryOptions,
): Query<UITask> {
  if (![orgId, userId, sprintId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintId],
    ['assignee', '==', userId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function currentSprintCollaboratingTasks(
  orgId: string,
  userId: string,
  sprintId: string | null,
  options?: QueryOptions,
): Query<UITask> {
  if (![orgId, userId, sprintId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintId],
    ['collaborators', 'array-contains', userId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  return queryBuilder(orgId, where, options);
}

export function productTasks(orgId: string, productId: string, options?: QueryOptions): Query<UITask> {
  if (![orgId, productId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['_relationships.product', '==', productId],
    [taskParentFieldPath, '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function productBacklogTasks(orgId: string, productId: string, options?: QueryOptions): Query<UITask> {
  if (![orgId, productId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['_relationships.product', '==', productId],
    [taskParentFieldPath, '==', null],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function incompleteBacklogTasks(orgId: string): Query<UITask> {
  if (![orgId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    ['status', '<', TaskStatus.Done],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  return queryBuilder(orgId, where, { orderBy: 'status' });
}

export function loneTasks(orgId: string, options?: QueryOptions): Query<UITask> {
  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', null],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export function taskSubTasks(orgId?: string, parentId?: string, options?: QueryOptions): Query<UITask> {
  if (![orgId, parentId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', parentId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}

export const tasksImportedFromService = (
  orgId: Data.Id.OrganizationId,
  service: Data.ExternalIssue.Any['service'],
): Query<UITask> => {
  if (![orgId, service].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    ['externalIssue.service', '==', service],
    ['status', '<', TaskStatus.Done],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  // Due to a limitation of firebase any filter with inequality on a filed
  // must also order by that field. The filed being 'status' here.
  return queryBuilder(orgId, where, { orderBy: 'status' });
};

export function getAssignedTasksFromUserSprints(
  orgId: string,
  userId: string,
  sprintIds: string[],
  options?: QueryOptions,
): Query<UITask> {
  if (![orgId, userId].every(isNonEmptyString)) {
    return inertQuery();
  }

  if (sprintIds.length === 0) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', 'in', sprintIds],
    ['assignee', '==', userId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  return queryBuilder(orgId, where, options);
}

export function getCollaborativeTasksFromUserSprints(
  orgId: string,
  userId: string,
  sprintIds: string[],
  options?: QueryOptions,
): Query<UITask> {
  if (![orgId, userId].every(isNonEmptyString)) {
    return inertQuery();
  }

  if (sprintIds.length === 0) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', 'in', sprintIds],
    ['collaborators', 'array-contains', userId],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options);
}
