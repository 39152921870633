import { Data } from '@taraai/types';
import { DraggableLocation } from 'react-beautiful-dnd';

/**
 *
 * All the content of this file is considered deprecated in favor of the
 * ( similar ) content in the dnd.tsx component.
 *
 * As soon the Tara.ai v2 version is released, and all the legacy code removed,
 * this file should go away
 *
 * @deprecated since version 2.0
 *
 */

/**
 * All the DnD functionalities are now in the dnd.tsx file
 * @deprecated
 */
export type DroppableDescription = {
  id: string;
  type: 'requirement' | 'sprint' | null;
  order: string[];
  all: string[] | null;
};
/**
 * All the DnD functionalities are now in the dnd.tsx file
 * @deprecated
 */
export function getSerializedDroppableId(
  selectedRequirementId: Data.Id.RequirementId | undefined,
  order: string[] | undefined,
  all: string[] | undefined,
): string {
  return JSON.stringify({
    id: selectedRequirementId ?? null,
    type: selectedRequirementId ? 'requirement' : 'sprint',
    order: order && order.length > 0 ? order : null,
    all: all && all.length > 0 ? all : null,
  });
}
/**
 * All the DnD functionalities are now in the dnd.tsx file
 * @deprecated
 */
export function getDroppableDescriptionId(serializedDroppable: DraggableLocation): DroppableDescription {
  const locationString = serializedDroppable.droppableId ?? '{}';
  return JSON.parse(locationString);
}
/**
 * All the DnD functionalities are now in the dnd.tsx file
 * @deprecated
 */
export function isBacklog(droppableId: string): boolean {
  return droppableId.includes('"id":null');
}
