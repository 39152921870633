const onKeyDown = (keyCode: React.KeyboardEvent['keyCode']) => (callback?: () => void) => (
  event: React.KeyboardEvent,
): React.KeyboardEvent => {
  if (event.keyCode === keyCode) callback?.();
  return event;
};

export const onEnterDown = onKeyDown(13);
export const onBackspaceDown = onKeyDown(8);
export const onEscDown = onKeyDown(27);
