import { Box } from '@taraai/design-system';
import { Attachment, UI } from '@taraai/types';
import { RichEditor } from 'components/editor/RichEditor';
import { EditorAttachments } from 'components/EditorAttachments';
import React from 'react';
import { strings } from 'resources';

interface Props {
  onAttachmentRemove: (attachment: Attachment) => void;
  requirement: UI.UIRequirement;
}

export default function RequirementDescription({ onAttachmentRemove, requirement }: Props): JSX.Element {
  const requirementAttachments: Attachment[] = requirement?.attachments ?? [];
  return (
    <>
      <Box spaceVert='$20px'>
        <RichEditor placeholder={strings.editor.requirementDescriptionPlaceholder} />
      </Box>
      <EditorAttachments attachments={requirementAttachments} onRemove={onAttachmentRemove} />
    </>
  );
}
