import { linkTo, usePathParams } from 'components/Router/paths';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import {
  getOrgFromInvitation,
  pending,
  reduxStore,
  RootState,
  selectAuth,
  selectOrganizationInvitation,
  selectPreferredTeamId,
  useConnectToOrganization,
} from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { setInvitationTokenCookie } from 'tools/utils/invitationToken';

import JoinWorkspaceLayout from './JoinWorkspaceLayout';

/**
 * Join Workspace Flow:
 * - for logged in users
 *     - call to `joinOrganization` cloud function after `Join Tara` button click, then redirect to home
 * - for not logged in users
 *     - saves invitation token to cookies and redirects to login page after `Join Tara` button click
 */
export default function JoinWorkspaceController(): JSX.Element {
  const history = useHistory();
  const { addToast } = useToast();
  const { token: tokenFromParam } = usePathParams('invite');

  const organizationInvitation =
    useSelector((state: RootState) => selectOrganizationInvitation(state, tokenFromParam || '')) || pending;

  const auth = useSelector(selectAuth);
  const userIsLogged = !auth.isEmpty;

  const [
    { pending: joinOrganizationPending, error: joinOrganizationError, data: joinOrganizationData },
    connectToOrganization,
  ] = useConnectToOrganization();

  const preferredTeamId = useSelector(selectPreferredTeamId(joinOrganizationData?.organizationId));

  useEffect(() => {
    if (joinOrganizationError) {
      addToast({
        message: strings.joinWorkspace.errors.general,
        type: 'error',
      });
    } else {
      reduxStore.dispatch(getOrgFromInvitation({ token: tokenFromParam || '' }));
    }
  }, [addToast, joinOrganizationError, tokenFromParam, userIsLogged]);

  const onJoinClick = useCallback(async (): Promise<void> => {
    if (userIsLogged && tokenFromParam) {
      await connectToOrganization(tokenFromParam);
    } else if (tokenFromParam) {
      setInvitationTokenCookie(tokenFromParam);
      history.replace(linkTo('login'));
    }
  }, [userIsLogged, tokenFromParam, connectToOrganization, history]);

  if (joinOrganizationData && preferredTeamId) {
    const link = {
      pathname: linkTo('sprints', {
        orgId: joinOrganizationData.organizationId,
        teamId: preferredTeamId,
      }),
    };
    return <Redirect to={link} />;
  }

  return (
    <JoinWorkspaceLayout
      isLoading={joinOrganizationPending}
      onJoinClick={onJoinClick}
      organizationInvitation={organizationInvitation}
    />
  );
}
