import { css } from '@emotion/core';
import { atomic } from 'resources';

export const sprintsBacklogWrapperStyles = css`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0.0625rem 0 0 0 #eaeef5;
  flex-grow: 1;
  overflow-y: auto;
`;

export const overlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  box-sizing: border-box;
  padding: 74px;
  justify-content: center;
  align-items: center;
  background-color: rgba(221, 240, 255, 0.9);
  border: 1px dashed ${atomic.get(atomic.colors.focus)};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  color: ${atomic.get(atomic.colors.focus)};
`;
