"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (typeof value !== 'string') {
        return ts_data_json_1.err(errorMessage(value));
    }
    switch (value.toLowerCase()) {
        case 'true':
            return ts_data_json_1.ok(true);
        case 'false':
            return ts_data_json_1.ok(false);
        default:
            return ts_data_json_1.err(errorMessage(value));
    }
});
function errorMessage(value) {
    return "Failed to decode " + typeof value + " " + value + " as a boolean";
}
