import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

export type AddCommentInputStatus =
  | 'disabled' // inital state, empty comment
  | 'ready' // ready to send
  | 'loading'; // sending in progress

type AddCommentInputViewProps = {
  onSendButtonClick: () => void;
  status: AddCommentInputStatus;

  /** Component to render in the input place */
  inputSlot: (props: { inputRootStyle: string; disabled: boolean }) => JSX.Element;
};

/**
 * This component wraps any _Input component_ and renders
 * it with applied styling as well as send button.
 */
export default function AddCommentInputView({
  onSendButtonClick,
  status,
  inputSlot,
}: AddCommentInputViewProps): JSX.Element {
  const inputRootStyle = css`
    height: 100%;
    overflow-y: auto;
    font-size: 0.9rem;
    padding: 1rem;
    padding-right: 2.2rem;

    .public-DraftEditorPlaceholder-root {
      color: ${atomic.get(atomic.colors.grey6)};
      user-select: none;
    }

    &:focus-within .public-DraftEditorPlaceholder-root {
      display: none;
    }
  `;

  return (
    <div
      className={css`
        background-color: ${atomic.get(atomic.colors.grey1)};
        height: 4.625rem;
        width: 100%;
        position: relative;
      `}
    >
      {inputSlot({ inputRootStyle, disabled: status === 'disabled' })}
      {status === 'loading' ? (
        <FastSmallSpinner
          className={css`
            bottom: 1rem;
            padding: 0;
            position: absolute;
            right: 1rem;
          `}
        />
      ) : (
        <Icon
          className={css`
            bottom: 1rem;
            cursor: ${status === 'ready' ? 'pointer' : 'default'} !important;
            fill: ${status === 'ready' ? atomic.get(atomic.colors.primary) : atomic.get(atomic.colors.grey5)};
            height: 1rem;
            padding: 0;
            position: absolute;
            right: 1rem;
            width: 1rem;
            z-index: 2; /* z-index from Draft.css + 1 */
          `}
          name='send'
          onClick={onSendButtonClick}
        />
      )}
    </div>
  );
}
