import { unwrapResult } from '@reduxjs/toolkit';
import { Box } from '@taraai/design-system';
import { Attachment, Data, Markdown } from '@taraai/types';
import { UIRequirement } from '@taraai/types/dist/ui';
import { getAllEntitiesData } from 'components/editor/entities';
import {
  composePlugins,
  createAttachmentPlugin,
  createMentionPlugin,
  createSingleLinePlugin,
  getWhitespacePlugin,
  linkPlugin,
  markdownPlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { ContentState } from 'draft-js';
import identity from 'lodash.identity';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { addAttachment, getPathForRequirement, reduxStore, removeAttachment, updateRequirement } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { reportError } from 'tools/libraries/stackdriver';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { DefinePanel } from './DefinePanel';

type Props = { orgId: Data.Id.OrganizationId; requirement: UIRequirement };

export function DefineRequirement({ requirement, orgId }: Props): JSX.Element | null {
  const { addToast, removeToast } = useToast();
  const getUserTagForId = useUserTagForId(orgId);
  const path = getPathForRequirement(requirement.id, orgId);

  const description = requirement.description ?? null;
  const title = requirement.title ?? null;
  const attachments = requirement.attachments ?? [];

  const handleAttachmentUpload = useCallback(
    async (file: File): Promise<string> => {
      const toastId = file.name;
      addToast({ id: toastId, message: strings.attachments.uploading, type: 'loading' });
      return reduxStore
        .dispatch(addAttachment({ type: 'requirement', path, file }))
        .then(unwrapResult)
        .then((attachmentURL) => {
          removeToast(toastId);
          return attachmentURL;
        })
        .catch((error) => {
          reportError(error);
          removeToast(toastId);
          const message = formatI18n(strings.attachments.uploadError)({ errorMessage: error.message });
          addToast({ message, type: 'error' });

          throw error;
        });
    },
    [addToast, path, removeToast],
  );

  const handleAttachmentRemove = useCallback(
    (attachment: Attachment): void => {
      reduxStore
        .dispatch(removeAttachment({ type: 'task', attachment, path }))
        .then(unwrapResult)
        .catch((error: Error) => {
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
        });
    },
    [addToast, path],
  );

  const descriptionPlugin = useMemo(
    () =>
      composePlugins(
        markdownPlugin,
        createMentionPlugin(getUserTagForId),
        linkPlugin,
        createAttachmentPlugin(addToast, handleAttachmentUpload),
      ),
    [addToast, getUserTagForId, handleAttachmentUpload],
  );

  const titlePlugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createSingleLinePlugin({ returnHandled: false }),
      ),
    [],
  );

  const uploadDescriptionMarkdown = useCallback(
    async (newDescription: Markdown, content: ContentState) => {
      await reduxStore
        .dispatch(
          updateRequirement({
            description: newDescription,
            id: requirement.id,
            mentionedUserIds: getAllEntitiesData('mention', content).map(({ id }) => id),
          }),
        )
        .then(unwrapResult)
        .catch((error: Error) => addToast({ message: error.message, type: 'error' }));
    },
    [addToast, requirement.id],
  );

  const uploadTitle = useCallback(
    async (newTitle: string) => {
      await reduxStore
        .dispatch(updateRequirement({ title: newTitle, id: requirement.id }))
        .then(unwrapResult)
        .catch((error: Error) => addToast({ message: error.message, type: 'error' }));
    },
    [addToast, requirement.id],
  );
  const { trySave: trySaveDescription } = useForceSave(description, uploadDescriptionMarkdown, identity);
  const { trySave: trySaveTitle } = useForceSave(title, uploadTitle, identity);

  if (!requirement) return <Container />;

  return (
    <Container>
      <DefinePanel
        attachments={attachments}
        description={requirement.description}
        descriptionPlugin={descriptionPlugin}
        handleAttachmentRemove={handleAttachmentRemove}
        handleAttachmentUpload={handleAttachmentUpload}
        id={requirement.id}
        orgId={orgId}
        title={requirement.title}
        titlePlugin={titlePlugin}
        trySaveDescription={trySaveDescription}
        trySaveTitle={trySaveTitle}
        type='requirement'
      />
    </Container>
  );
}

function Container({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <Box background='$grey4' center='horz' full spaceTop='$24px'>
      {children}
    </Box>
  );
}
