import { markdownMentionIdRegExp, notUndefined } from '@taraai/utility';
import { createEntity, getEntityData, getEntityRanges } from 'components/editor/entities';
import { ContentBlock, ContentState, Modifier, SelectionState } from 'draft-js';

/**
 * Upgrades editor state with mentions in `@tara-user-<id>` format
 * into `@<USER>` entity with id stored in entity data object.
 *
 * It does so by searching through all ContentBlocks using
 * `@tara-user-<id>` regex and then replacing matches with
 * text fetched based on parsed `<id>` using `idToText()` function.
 * Each mention replacement is additionally annotated with `mention`
 * entity data containing parsed user id.
 *
 * If the parsed `<id>` does not correspond to any valid user,
 * `idToText` function is expected to return `undefined`.
 */
export const upgradeToEntity = (content: ContentState, idToText: (id: string) => string | undefined): ContentState => {
  const mentionSpans = content
    .getBlocksAsArray()
    .map(getMentionsFromBlock)
    // reverse span order and flatten
    // it's important to reverse the order, so that mentions
    // are replaced from back to front
    // (in order to prevent replacement from screwing up next span in line)
    .reduce((acc, mentions) => [...acc, ...Array.from(mentions).reverse()], []);

  // go through all mentions and replace them one by one
  return mentionSpans.reduce((previousContent, metadata) => {
    const replacedText = idToText(metadata.userId);
    // if there is no user found, do not upgrade this mention to entity
    // TODO: It might be a good idea to remove such mentions
    if (!replacedText) {
      return previousContent;
    }

    const selection = getSelectionFromMentionSpan(metadata.span);

    // annotate the mention with user id
    const { contentWithEntity, key } = createEntity('mention', { id: metadata.userId }, previousContent);
    return Modifier.replaceText(contentWithEntity, selection, replacedText, undefined, key);
  }, content);
};

export const upgradeMentionsTransform = (idToText: (id: string) => string | undefined) => (
  content: ContentState,
): ContentState => upgradeToEntity(content, idToText);

type MentionMetadata = {
  userId: string;
  span: {
    start: { key: string; offset: number };
    end: { key: string; offset: number };
  };
};

export const getSelectionFromMentionSpan = ({ start, end }: MentionMetadata['span']): SelectionState =>
  SelectionState.createEmpty(start.key).merge({
    anchorKey: start.key,
    anchorOffset: start.offset,
    focusKey: start.key,
    focusOffset: end.offset,
    isBackward: false,
  }) as SelectionState;

/**
 * This function takes a `ContentBlock` finds all mentions based on
 * provided regex and returns a list of all mention metadata
 * (its span and parsed user id).
 */
export const getMentionsFromBlock = (block: ContentBlock): MentionMetadata[] => {
  const text = block.getText();
  const key = block.getKey();
  return Array.from(text.matchAll(markdownMentionIdRegExp), ({ index, 0: wholeMatch, 1: userId }) => ({
    span: {
      start: { key, offset: index as number },
      end: { key, offset: (index as number) + wholeMatch.length },
    },
    userId,
  }));
};

const getMentionMetadataFromEntities = (block: ContentBlock, content: ContentState): MentionMetadata[] => {
  const blockKey = block.getKey();
  return getEntityRanges('mention', content, block)
    .map(([start, end]) => {
      const entityKey = block.getEntityAt(start);
      const { id } = getEntityData('mention', content, entityKey);
      return {
        span: {
          start: { key: blockKey, offset: start },
          end: { key: blockKey, offset: end },
        },
        userId: id,
      };
    })
    .filter(notUndefined);
};

/**
 * Downgrades editor state with mentions as `mention` entities
 * with `@<USER>` text, into `@tara-user-<id>` plain text tags.
 *
 * It does so by searching through all ContentBlocks entities with type
 * 'mention' and then replacing matches with plain text tags.
 */
export const downgradeToPlainText = (content: ContentState): ContentState => {
  const mentionMetadata = content
    .getBlocksAsArray()
    .map((block) => getMentionMetadataFromEntities(block, content))
    .reduce((acc, mentions) => [...acc, ...Array.from(mentions).reverse()], []);

  return mentionMetadata.reduce((previousContent, metadata) => {
    const selection = getSelectionFromMentionSpan(metadata.span);

    const plainTextMention = `@tara-user-${metadata.userId}`;
    // replace text with plain text mention tag and remove
    // annotated entity (entityKey === undefined).
    return Modifier.replaceText(previousContent, selection, plainTextMention);
  }, content);
};

export const downgradeMentionsTransform = (content: ContentState): ContentState => downgradeToPlainText(content);
