import { styled } from '@taraai/design-system';
import { isTaskStatus, TaskStatus, UI } from '@taraai/types';
import { UserAvatar } from 'components/core/controllers/views/Avatar';
import moment from 'moment';
import React from 'react';
import { atomic } from 'resources';
import { strings } from 'resources/i18n';
import { toDate } from 'tools/libraries/helpers/dates';

export interface RevisionEntryProps extends React.HTMLProps<HTMLDivElement> {
  revision: UI.UITaskRevision;
}

/**
 * RevisionEntry displays
 * Any changes made to the effort level, status and sprint
 *
 */
const statusLabels = {
  [TaskStatus.Todo]: '"To Do"',
  [TaskStatus.Doing]: '"Doing"',
  [TaskStatus.Done]: '"Done"',
};

const { backlog } = strings.revisions;
function parseDisplayString(revisionValue: string | number | null | undefined, revisionProperty: string): string {
  if (revisionProperty === 'status') {
    const status = Number(revisionValue);
    return statusLabels[isTaskStatus(status) ? status : TaskStatus.Todo];
  }
  if (revisionProperty === 'sprint' && revisionValue === undefined) {
    return backlog as string;
  }
  return revisionValue as string;
}

/**
 * @deprecated - this should be removed with TaskModal component in V3
 */
export default function RevisionEntry({ revision }: RevisionEntryProps): JSX.Element {
  return (
    <TaskRevisionContainer>
      <TaskRevision>
        <UserAvatar showBorder={revision.wasSprintActive} size='medium' user={revision.user} />
        <div>
          <TaskActivityTitle>
            <Name>
              {strings.formatString(strings.revisions.name, {
                name: revision.name ?? strings.revisions.taraBot,
              })}
            </Name>
            {revision.property === 'sprint'
              ? strings.formatString(strings.revisions.sprintTile, {
                  current: parseDisplayString(revision.current, revision.property),
                  previous: parseDisplayString(revision.previous, revision.property),
                })
              : strings.formatString(strings.revisions.updatedTitle, {
                  property: revision.property === 'effortLevel' ? strings.revisions.effort : revision.property,
                  current: parseDisplayString(revision.current, revision.property),
                  previous: parseDisplayString(revision.previous, revision.property),
                })}
          </TaskActivityTitle>
          <TaskActivityUpdatedTime>
            {strings.formatString(strings.revisions.updatedAt, {
              updatedAt: moment(toDate(revision.updatedAt)).format('LL'),
              time: moment(toDate(revision.updatedAt)).format('LT'),
            })}
          </TaskActivityUpdatedTime>
        </div>
      </TaskRevision>
      {revision.wasSprintActive && (
        <ActiveSprintBackground>
          <ActiveSprintName>{revision.sprint?.sprintName || strings.revisions.backlog}</ActiveSprintName>
        </ActiveSprintBackground>
      )}
    </TaskRevisionContainer>
  );
}

const TaskRevisionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '1.25rem',
});

const TaskRevision = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  float: 'left',
});
const TaskActivityTitle = styled('div', {
  fontSize: '0.875rem',
  color: '#575f65',
  paddingLeft: '1rem',
});

const Name = styled('span', {
  fontWeight: '600',
  fontSize: '1rem',
});

const ActiveSprintBackground = styled('div', {
  height: '100%',
  borderRadius: '0.1875rem',
  backgroundColor: atomic.get(atomic.colors.success1),
  marginLeft: 'auto',
});
const ActiveSprintName = styled('div', {
  fontSize: '0.75rem',
  fontWeight: '600',
  textAlign: 'center',
  color: atomic.get(atomic.colors.success),
  padding: '0.3125rem',
});
const TaskActivityUpdatedTime = styled('div', {
  fontSize: '0.75rem',
  color: '#949caf',
  paddingLeft: '1rem',
  paddingTop: '0.5rem',
});
