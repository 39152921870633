/* eslint-disable sonarjs/cognitive-complexity */
import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, RequirementPartial, TaskStatus, UI } from '@taraai/types';
import { isNonEmptyString, parseLabelsFromPlainText, unique } from '@taraai/utility';
import { DroppableType } from 'components/app/DragAndDrop';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import {
  createRequirement,
  createTask,
  getArchivedRequirements,
  getRequirements,
  getTask,
  getTasksWithoutRequirementAndNotImported,
  inertQuery,
  reduxStore,
  RootState,
  selectActiveTeam,
  selectActiveWorkspace,
  selectTaskList,
} from 'reduxStore';
import { getImportedTasks } from 'reduxStore/imports/queries/imported-tasks';
import { decode } from 'reduxStore/utils/decoders';
import {
  useWorkDrawerMarkRendered,
  useWorkDrawerSelectedResetState,
  useWorkDrawerSelectedSetState,
  useWorkDrawerSettingsState,
  useWorkDrawerUnfoldedSetState,
  useWorkDrawerUnfoldedValue,
} from 'reduxStore/workDrawer';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

import { groupTasksByRepository } from './helpers';
import { WorkDrawer } from './WorkDrawer';
import { WorkDrawerTaskCardProps } from './WorkDrawerSections';

type Props = {
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedRequirementId: Data.Id.RequirementId | undefined;
  selectedTaskId: Data.Id.TaskId | undefined;
};

export type SelectTaskFragment = Pick<UI.UITask, 'id' | 'status' | 'sprint' | 'externalIssue'>;

export type WorkDrawerImportTasksFragment = { tasks: SelectTaskFragment[]; title: string };

export type WorkDrawerImportTasksId = { tasks: Pick<UI.UITask, 'id'>[]; title: string };

// @TODO: This memo would not be required if this
// component would not be refreshed when we change the selected sprint
export const WorkDrawerController = React.memo(function WorkDrawerController({
  onRequirementSelect,
  onTaskSelect,
  selectedRequirementId,
  selectedTaskId,
}: Props): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const { addToast } = useToast();
  const history = useHistory();
  const submitting = useRef(false);
  const showArchivedRequirements = useWorkDrawerSettingsState('requirements:show-archived');
  const showDraftRequirements = useWorkDrawerSettingsState('requirements:show-drafts');
  const showDoneTasks = useWorkDrawerSettingsState('tasks:show-done');
  const showAssignedToSprintTasks = useWorkDrawerSettingsState('tasks:show-assigned-to-sprint');
  const initialTasksUnfolded = typeof useWorkDrawerUnfoldedValue('tasks') !== 'undefined';
  const initialReposUnfolded = typeof useWorkDrawerUnfoldedValue('repos') !== 'undefined';
  const initialImportsUnfolded = typeof useWorkDrawerUnfoldedValue('imports') !== 'undefined';

  const selectedTaskSlice = getTask(orgId, selectedTaskId ?? '');
  const selectedTaskRequirement = useSelector(
    compose((task) => task?._relationships.requirement, selectedTaskSlice.selector),
    deepEquals,
  );
  const tasksWithoutRequirementSlice = initialTasksUnfolded
    ? getTasksWithoutRequirementAndNotImported(orgId, { orderBy: 'createdAt' })
    : inertQuery<UI.UITask>();

  const tasksWithoutRequirement = useSelector(
    (state) =>
      selectTaskFragment(state, tasksWithoutRequirementSlice.query[0]?.storeAs)
        ?.filter((task) => task.id && (task?.externalIssue === undefined || task?.externalIssue.service === 'slack'))
        ?.filter(removeExtraTaskFields) || [],
    deepEquals,
  );

  const requirementsSlice = getRequirements(orgId);
  const archivedRequirementsSlice = showArchivedRequirements
    ? getArchivedRequirements(orgId)
    : inertQuery<UI.UIRequirement>();
  const allRequirements = useSelector(
    createSelector(
      [requirementsSlice.selector, archivedRequirementsSlice.selector],
      (requirements?: UI.UIRequirement[], archivedRequirements?: UI.UIRequirement[]): UI.UIRequirement[] => [
        ...(requirements ?? []),
        ...(archivedRequirements ?? []),
      ],
    ),
    deepEquals,
  );
  // TODO: Gitlab repos
  const githubSlice = initialReposUnfolded ? getImportedTasks(orgId, 'github') : inertQuery<UI.UITask>();
  const slackSlice = initialReposUnfolded ? getImportedTasks(orgId, 'slack') : inertQuery<UI.UITask>();
  const asanaSlice = initialImportsUnfolded ? getImportedTasks(orgId, 'asana') : inertQuery<UI.UITask>();
  const trelloSlice = initialImportsUnfolded ? getImportedTasks(orgId, 'trello') : inertQuery<UI.UITask>();
  const imports = useSelector(
    (state) => [
      {
        title: 'asana',
        tasks: selectTaskFragment(state, asanaSlice.query[0]?.storeAs) ?? [],
      },
      {
        title: 'trello',
        tasks: selectTaskFragment(state, trelloSlice.query[0]?.storeAs) ?? [],
      },
    ],
    deepEquals,
  );

  useFirestoreConnect([
    ...selectedTaskSlice.query,
    ...tasksWithoutRequirementSlice.query,
    ...requirementsSlice.query,
    ...archivedRequirementsSlice.query,
    ...githubSlice.query,
    ...slackSlice.query,
    ...asanaSlice.query,
    ...trelloSlice.query,
  ]);

  const repos: WorkDrawerImportTasksFragment[] = useSelector(
    compose(
      (tasks: UI.UITask[]) => groupTasksByRepository(tasks),
      (state: RootState) => selectTaskFragment(state, githubSlice.query[0]?.storeAs) || [],
    ),
    deepEquals,
  );

  const filteredRequirements = useMemo(
    () =>
      allRequirements.filter((requirement) => (showDraftRequirements ? true : requirement.assignedTeamIds.length > 0)),
    [allRequirements, showDraftRequirements],
  );

  const filterTaskByDrawerFilters = useCallback(
    (task: SelectTaskFragment): boolean =>
      (showDoneTasks ? true : task.status !== TaskStatus.Done) &&
      (showAssignedToSprintTasks ? true : task.sprint === null),
    [showAssignedToSprintTasks, showDoneTasks],
  );

  const filteredTasks = useMemo(() => tasksWithoutRequirement.filter(filterTaskByDrawerFilters), [
    filterTaskByDrawerFilters,
    tasksWithoutRequirement,
  ]);

  const filteredImports = useMemo(
    () =>
      imports.map((importService) => ({
        ...importService,
        tasks: importService.tasks.filter(filterTaskByDrawerFilters)?.filter(removeExtraTaskFields) as Pick<
          UI.UITask,
          'id'
        >[],
      })),
    [filterTaskByDrawerFilters, imports],
  );

  const filteredRepos = useMemo(
    () =>
      repos.map((repo) => ({
        ...repo,
        tasks: repo.tasks.filter(filterTaskByDrawerFilters)?.filter(removeExtraTaskFields),
      })),
    [filterTaskByDrawerFilters, repos],
  );

  const tasksDroppableAreaDescription = useMemo(() => {
    return {
      id: 'workDrawer-tasks',
      type: DroppableType.workdrawerTasks,
      visibleList: filteredTasks.map((task) => task.id),
      list: tasksWithoutRequirement.map((task) => task.id),
    };
  }, [filteredTasks, tasksWithoutRequirement]);

  const handleTaskCreation = useCallback(
    async (
      title: string,
      requirementId?: Data.Id.RequirementId,
      insertAtIndex?: number,
      taskIds?: Data.Id.TaskId[],
    ): Promise<boolean> => {
      if (submitting.current) return false;
      submitting.current = true;

      const partialTask = extractMentions(extractEffortLevel({ title }));
      const labels = unique(parseLabelsFromPlainText(partialTask.title));
      const taskToCreate = requirementId
        ? { ...partialTask, labels, _relationships: { requirement: requirementId } }
        : { ...partialTask, labels };

      let success = false;
      await reduxStore
        .dispatch(createTask({ ...taskToCreate, insertAtIndex, taskIds }))
        .then(unwrapResult)
        .then((newTaskId) => {
          segment.track('TaskCreated', { orgID: orgId, taskID: newTaskId, location: 'WorkDrawer' });
          success = true;
        })
        .catch(() => addToast({ type: 'error', message: strings.task.failedToCreateTask }));

      submitting.current = false;

      return success;
    },
    [addToast, orgId],
  );

  const handleRequirementCreation = useCallback(
    async (title: string): Promise<void> => {
      if (submitting.current) return;
      if (!isNonEmptyString(title)) return;

      submitting.current = true;
      try {
        decode<RequirementPartial>({ title }, 'RequirementPartial');
      } catch (error) {
        submitting.current = false;
        return addToast({ type: 'error', message: strings.requirements.createTooShort });
      }

      await reduxStore
        .dispatch(createRequirement({ title }))
        .then(unwrapResult)
        .then(({ id }) => {
          segment.track('RequirementCreated', { orgID: orgId, requirementID: id, location: 'WorkDrawer' });
          history.replace(linkTo('requirement', { orgId, teamId, requirementId: id }));
        })
        .catch(() => addToast({ type: 'error', message: strings.requirements.failedToCreateRequirement }));

      submitting.current = false;
    },
    [addToast, history, orgId, teamId],
  );

  useWorkDrawerInitialState({ selectedRequirementId, selectedTaskId, selectedTaskRequirement });

  return (
    <WorkDrawer
      filterTask={filterTaskByDrawerFilters}
      imports={filteredImports}
      onRequirementCreate={handleRequirementCreation}
      onRequirementSelect={onRequirementSelect}
      onTaskCreate={handleTaskCreation}
      onTaskSelect={onTaskSelect}
      repos={filteredRepos}
      requirements={filteredRequirements}
      tasks={filteredTasks}
      tasksDroppableAreaDescription={tasksDroppableAreaDescription}
    />
  );
});

function useWorkDrawerInitialState({
  selectedRequirementId,
  selectedTaskId,
  selectedTaskRequirement,
}: {
  selectedRequirementId: string | undefined;
  selectedTaskId: string | undefined;
  selectedTaskRequirement: string | null | undefined;
}): void {
  const markRendered = useWorkDrawerMarkRendered();
  const setUnfoldedTasks = useWorkDrawerUnfoldedSetState('tasks');
  const setUnfoldedRequirements = useWorkDrawerUnfoldedSetState('requirements');
  const setUnfoldedTaskRequirement = useWorkDrawerUnfoldedSetState('requirements', selectedTaskRequirement ?? '');
  const setSelected = useWorkDrawerSelectedSetState();
  const resetSelected = useWorkDrawerSelectedResetState();

  useEffect(() => {
    if (selectedRequirementId) {
      setUnfoldedRequirements(true);
      setSelected('requirements', selectedRequirementId);
      markRendered();
    } else if (selectedTaskId) {
      if (!isLoaded(selectedTaskRequirement)) return;
      if (selectedTaskRequirement) {
        setUnfoldedRequirements(true);
        setUnfoldedTaskRequirement(true);
      } else {
        setUnfoldedTasks(true);
      }
      setSelected('tasks', selectedTaskId);
      // Don't put it at the end since there's an early return in this branch
      markRendered();
    } else {
      resetSelected();
      markRendered();
    }
  }, [
    markRendered,
    resetSelected,
    selectedRequirementId,
    selectedTaskId,
    selectedTaskRequirement,
    setSelected,
    setUnfoldedRequirements,
    setUnfoldedTaskRequirement,
    setUnfoldedTasks,
  ]);
}

export const removeExtraTaskFields = ({ id }: SelectTaskFragment | UI.UITask): WorkDrawerTaskCardProps => ({ id });

export const selectTaskFragment = (state: DefaultRootState, queryAlias: string): SelectTaskFragment[] | undefined =>
  selectTaskList(state, queryAlias, ['id', 'status', 'sprint', 'externalIssue']);
