import styled from '@emotion/styled';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { userProfileTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface AuthButtonsProps {
  onLogoutClick: () => void;
  onPasswordReset: () => void;
  emailResetSent: boolean;
  userEmail: string | null;
  isBasicAuth: boolean;
}

export function AuthButtons({
  onLogoutClick,
  emailResetSent,
  onPasswordReset,
  userEmail,
  isBasicAuth,
}: AuthButtonsProps): JSX.Element {
  return (
    <>
      {isBasicAuth && userEmail && (
        <ResetPasswordContainer>
          <ResetPasswordButton onClick={onPasswordReset}>{strings.profile.resetPassword}</ResetPasswordButton>
          <InfoLabel visible={emailResetSent}>
            {strings.formatString(strings.profile.resetEmailSent, {
              userEmail,
            })}
          </InfoLabel>
        </ResetPasswordContainer>
      )}
      <LogoutButton data-cy={userProfileTestIds.LOGOUT_BUTTON} name='logout' onClick={onLogoutClick}>
        <Icon
          className={css`
            padding: 0rem 0.25rem 0rem 0rem;
            vertical-align: sub;
          `}
          name='logout'
        />
        {strings.profile.logout}
      </LogoutButton>
    </>
  );
}

type InfoLabelProps = {
  visible: boolean;
};

const ResetPasswordContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  color: #949caf;
  font-size: 0.875rem;
`;

const ResetPasswordButton = styled.button`
  border: none;
  color: #949caf;
  background: transparent;
  outline: none;
  text-decoration: underline;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const InfoLabel = styled.div`
  padding-left: 1rem;
  color: #389e0d;
  display: ${({ visible }: InfoLabelProps): string => (visible ? 'block' : 'none')};
`;

const LogoutButton = styled.button`
  color: #949caf;
  text-align: left;
  border: none;
  outline: none;
  background-color: transparent;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
