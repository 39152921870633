import { GlobalStyles } from '@taraai/design-system';
import React, { StrictMode } from 'react';
import ReactDOM, { render } from 'react-dom';

import ToastProvider from './components/app/controllers/Toast/ToastProvider';
import Auth from './components/Auth';
import AuthIsLoaded from './components/AuthIsLoaded';
import ErrorBoundary from './components/core/stacks/ErrorBoundary';
import Template from './components/Template';
import ThemeProvider from './components/ThemeProvider';
import { init as initFullstory } from './fullstory';
import { ReduxContext } from './reduxStore';
import * as serviceWorker from './serviceWorker';
import { AXE_DEBUG, DEBUG, RENDER_DEBUG, STRICT_DEBUG, TESTING } from './tools/libraries/loggers';
import { reportError } from './tools/libraries/stackdriver'; // Initializes Stackdriver

initFullstory();

const App = (
  <>
    <ErrorBoundary>
      <ReduxContext>
        <ThemeProvider>
          <AuthIsLoaded>
            <Auth>
              <ToastProvider>
                <Template />
              </ToastProvider>
            </Auth>
          </AuthIsLoaded>
        </ThemeProvider>
      </ReduxContext>
    </ErrorBoundary>
    <GlobalStyles />
  </>
);

const APP = STRICT_DEBUG ? <StrictMode>{App}</StrictMode> : App;
const ROOT = document.querySelector('#root');

if (DEBUG)
  import('@welldone-software/why-did-you-render')
    .then(({ default: whyDidYouRender }) => {
      if (RENDER_DEBUG) whyDidYouRender(React, { include: [/.*/] });
      return import('react-axe');
    })
    .then(({ default: axe }) => {
      if (AXE_DEBUG) axe(React, ReactDOM, 1000);
      ReactDOM.render(APP, ROOT);
      return undefined;
    })
    .catch((err) => {
      reportError(err);
      throw err;
    });
else render(APP, ROOT);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (!TESTING)
  // eslint-disable-next-line no-console
  console.info(`Version: ${process.env.REACT_APP_VERSION}
GIT: ${process.env.REACT_APP_COMMIT_SHA}
Build: ${process.env.REACT_APP_BUILD_ID || 'local-dev'}
Project: ${process.env.REACT_APP_GCP_PROJECT_ID}`);
