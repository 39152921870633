"use strict";
/**
 * Tokens here in general should reflect what's in the following designs:
 * https://www.figma.com/file/qrTv4irY2u3KMO0mQbWPBs/Design-Assets
 * https://www.figma.com/file/TuF1X0AlGCHB2MpGISrrHH/Tara-App-UI?node-id=45%3A155 (Guidelines)
 *
 * Tokens marked as FROZEN shouldn't be touched without consulting the designers AND code owners of design-system.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokens = void 0;
exports.tokens = {
    shadows: {
        $dividerLeft: '-0.0625rem 0 0 0 #eaeef5',
        $dividerRight: '0.0625rem 0 0 0 #eaeef5',
        $ganttChart: '0 0 1.25rem 0 rgba(0, 0, 0, 0.08)',
        $navbar: '0 0.0625rem 0 0 #eaeef5, 0 0.125rem 0.5rem 0 rgba(87, 95, 101, 0.06)',
        $popup: '0 0 0.625rem 0 #00000020',
        $taskCreation: '0 0.0625rem 0.25rem -0.0625rem rgba(48, 63, 75, 0.35)',
        $soft: '0 0 1.875rem 0 #67728b30',
    },
    // FROZEN below this line
    backgrounds: {
        $primaryGradient: 'linear-gradient(135.19deg, #58a6e7, #775dfa)',
        $upgradeBGGradient: 'linear-gradient(90deg, #614ADF 0%, #2BB8C5 100%)',
        $checkoutSuccessBGGradient: 'radial-gradient(64.17% 58.19% at 50% 50%, #1A2A8B 0%, #121B50 100%)',
    },
    borderWidths: {
        $1px: '0.0625rem',
        $2px: '0.125rem',
        $3px: '0.1875rem',
    },
    colors: {
        $background: '#F4F4FA',
        $beige: '#FEF8E6',
        $dark: '#303F4B',
        $failure: '#E86262',
        $focus: '#1D98FF',
        $grey1: '#FBFBFD',
        $grey2: '#EEF1F7',
        $grey3: '#EAEEF5',
        $grey4: '#DEE3EC',
        $grey5: '#C8D0DF',
        $grey6: '#949CAF',
        $grey7: '#67728B',
        $indigo: '#4D5BFF',
        $primaryBlue: '#4550CE',
        $severity1: '#E4197A',
        $severity2: '#C0729C',
        $success: '#389E0D',
        $successLight: '#DDF4D9',
        $todo: '#F19135',
        $white: '#FFFFFF',
        // Colors used only in stories, not for use in the app
        $debug: '#FF69B4',
        $debugLight: '#FFD0E7',
    },
    fonts: {
        $basic: 'Sofia',
        $code: 'SourceCodePro',
    },
    fontSizes: {
        $10px: '0.625rem',
        $12px: '0.75rem',
        $14px: '0.875rem',
        $16px: '1rem',
        $18px: '1.125rem',
        $24px: '1.5rem',
        $28px: '1.75rem',
        $38px: '2.375rem',
    },
    fontWeights: {
        // Basic
        $regular: '400',
        $medium: '600',
        $bold: '700',
        // Code
        $codeMedium: '500',
    },
    // lineHeights have values related to specific *font sizes* (see Typography guidelines in Figma ^)
    lineHeights: {
        $10px: '135%',
        $12px: '135%',
        $14px: '1.375rem',
        $16px: '1.5rem',
        $18px: '1.75rem',
        $24px: '2rem',
        $28px: '2.25rem',
        $38px: '2.783rem',
    },
    opacities: {
        '$5%': 0.05,
        '$10%': 0.1,
        '$15%': 0.15,
        '$20%': 0.2,
    },
    radii: {
        $none: '0rem',
        $2px: '0.125rem',
        $4px: '0.25rem',
        $8px: '0.5rem',
        $12px: '0.75rem',
        // A big border radius is just what we want, 50% would result in an ellipse
        $circle: '10000px',
    },
    sizes: {
        // If a custom size is needed, DON'T ADD IT HERE - please use getCustomSize
        $fitContent: 'fit-content',
        $full: '100%',
        $fullScreenHeight: '100vh',
        $fullScreenWidth: '100wh',
    },
    space: {
        $none: '0rem',
        $1px: '0.0625rem',
        $2px: '0.125rem',
        $4px: '0.25rem',
        $8px: '0.5rem',
        $12px: '0.75rem',
        $16px: '1rem',
        $20px: '1.25rem',
        $24px: '1.5rem',
    },
};
