"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClickOutside = void 0;
var react_1 = require("react");
function useClickOutside(enabled, onClickOutside) {
    var refs = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        refs[_i - 2] = arguments[_i];
    }
    var refsRef = react_1.useRef(refs);
    refsRef.current = refs;
    react_1.useEffect(function () {
        if (!enabled)
            return;
        document.addEventListener('mousedown', handleClick);
        return function () { return document.removeEventListener('mousedown', handleClick); };
        function handleClick(event) {
            if (refsRef.current.every(function (ref) { var _a; return !((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)); }))
                onClickOutside === null || onClickOutside === void 0 ? void 0 : onClickOutside();
        }
    }, [enabled, onClickOutside]);
}
exports.useClickOutside = useClickOutside;
