import { SearchBar } from 'components/app/controllers/views/SearchBar';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reduxStore, searchActions, SearchQuery, selectSearchCount, selectSearchQuery } from 'reduxStore';

export function FilterSearchBar(): JSX.Element {
  const count = useSelector(selectSearchCount);
  const searchQuery = useSelector(selectSearchQuery);
  const handleSearch = useCallback((query?: SearchQuery) => {
    reduxStore.dispatch(searchActions.search(query));
  }, []);
  return <SearchBar count={count} onSearch={handleSearch} searchQuery={searchQuery} />;
}
