import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { actions, Step } from 'components/app/Onboarding/FlowWrapper';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { checkOrganizationExists, connectToOrganization, reduxStore } from 'reduxStore';
import { organizationIdValidator, organizationNameValidator, useFormInput } from 'tools';

import { Form } from './Form';

export const WorkspaceStep: Step = ({ children, dispatch }) => {
  const [slug, setSlug] = React.useState('');
  const history = useHistory();

  const checkOrgExists = async (orgId: Data.Id.OrganizationId): Promise<boolean> => {
    return reduxStore.dispatch(checkOrganizationExists({ orgId })).then(unwrapResult);
  };

  const [organizationNameInputValue, organizationNameInputProps, isOrganizationNameValid] = useFormInput('', {
    debounceValidation: true,
    initialValid: false,
    validator: organizationNameValidator,
  });

  const [organizationUrlInputValue, organizationUrlInputProps, isOrganizationUrlValid, setUrl] = useFormInput('', {
    debounceValidation: true,
    initialValid: false,
    validator: organizationIdValidator(checkOrgExists),
  });

  useEffect(() => {
    dispatch(actions.setOrgName(organizationNameInputValue));
  }, [dispatch, organizationNameInputValue]);

  useEffect(() => {
    setSlug(organizationUrlInputValue);
  }, [organizationUrlInputValue]);

  const isFormValid = isOrganizationNameValid && isOrganizationUrlValid;

  const onNext = useCallback(async () => {
    await reduxStore
      .dispatch(
        connectToOrganization({
          type: 'CreateNewOrganization',
          id: slug,
          name: organizationNameInputValue,
        }),
      )
      .then(unwrapResult);

    history.push(linkTo('onboarding', { orgId: slug }));
  }, [organizationNameInputValue, slug, history]);

  return children({
    childProps: {
      isFormValid,
      orgName: organizationNameInputValue,
      organizationNameInputProps,
      organizationUrlInputProps,
      slug,
      setUrl,
    },
    Form,
    onNext,
  });
};
