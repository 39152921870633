import { Data, UI } from '@taraai/types';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { css, cx } from 'emotion';
import moment from 'moment';
import React, { useCallback } from 'react';
import { strings } from 'resources';
import { atomic } from 'resources/theme';
import { formatFromNow, toDate } from 'tools';

import CommentDraftContent from './CommentDraftContent';

interface CommentProps {
  comment: UI.UIComment;
  onDeleteComment: (commentId: Data.Id.CommentId) => void;
  showMenu: boolean;
}

export default function Comment({
  comment: { authorIdDocument: author, createdAt, description, id },
  onDeleteComment,
  showMenu,
}: CommentProps): JSX.Element {
  const onDeleteCurrentComment = useCallback(() => onDeleteComment(id), [id, onDeleteComment]);

  return (
    <div
      className={css`
        padding: 0.75rem;
        width: 100%;
        transition: all 0.2s ease;
        &:hover {
          background-color: #f4f4fa;
        }
        ${showMenu &&
        css`
          &:hover .comment-menu {
            opacity: 1;
          }
        `}
      `}
    >
      <div
        className={css`
          display: flex;
          width: 100%;
        `}
      >
        {author && (
          <Avatar
            className={css`
              margin-right: 0.5rem;
            `}
            size='medium'
            user={author}
          />
        )}
        <div
          className={css`
            padding-top: 0.1rem;
            width: 100%;
          `}
        >
          <div>
            {author && (
              <span
                className={css`
                  font-size: 0.9rem;
                  font-weight: 600;
                  margin-right: 0.4rem;
                `}
              >
                {author.name}
              </span>
            )}
            <span
              className={css`
                color: ${atomic.get(atomic.colors.grey6)};
                font-size: 0.75rem;
              `}
              title={moment(toDate(createdAt)).format('LL LT')}
            >
              {formatFromNow(createdAt)}
            </span>
            <div
              className={cx(
                css`
                  float: right;
                  position: relative;
                  opacity: 0;
                  transition: all 0.2s ease;
                `,
                'comment-menu',
              )}
            >
              <Menu
                options={[
                  {
                    title: strings.comments.menu.delete,
                    onSelect: onDeleteCurrentComment,
                  },
                ]}
                styledType='commentDeleteMenu'
              >
                <Icon
                  className={css`
                    fill: #949caf;
                    width: 0.875rem;
                    padding: 0rem;
                    padding-top: 0.125rem;
                    cursor: pointer;
                  `}
                  name='meatballs'
                />
              </Menu>
            </div>
          </div>
          <div
            className={css`
              font-size: 0.9rem;
              line-height: 1.71;
              padding-top: 0.2rem;
            `}
          >
            <CommentDraftContent content={description} />
          </div>
        </div>
      </div>
    </div>
  );
}
