import { compose, unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { UIRequirementChangeset } from '@taraai/types/dist/ui';
import RequirementBuilderLayout from 'components/app/layouts/RequirementBuilderLayout';
import React, { useCallback } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import {
  archiveRequirement,
  getRequirement,
  reduxStore,
  restoreArchivedRequirement,
  selectActiveWorkspace,
  selectRequirementAssignedTeams,
  updateRequirement,
  UpdateRequirementTeamAction,
} from 'reduxStore';
import { strings } from 'resources';
import { useFirebaseInput, useToast } from 'tools';

type TeamFragment = Pick<UI.UITeam, 'id'>;

/**
 * RequirementBuilderController does…
 * Controller that accesses the current page requirement data
 * This component contains a performance issue as it is using useParams
 * and it is not at the very top of the rendering tree.
 * We are skipping it because it is
 * @deprecated
 */

export default function RequirementBuilderController(): JSX.Element | null {
  const { requirementId } = useParams<{
    requirementId: string;
  }>();
  const orgId = useSelector(selectActiveWorkspace);

  const requirementSlice = getRequirement(orgId, requirementId);

  useFirestoreConnect(requirementSlice.query);

  const requirement = useSelector(requirementSlice.selector);

  const assignedTeams = useSelector(
    compose(
      (teams: UI.UITeam[]) => teams.map(({ id, name }) => ({ id, name })),
      selectRequirementAssignedTeams(orgId, requirementId),
    ),
    deepEquals,
  );
  const title = requirement?.title ?? '';

  const { addToast, whenError, removeToast, maybeToast } = useToast();

  const [titleProps] = useFirebaseInput(title, (val: string) => {
    if (!requirement)
      return addToast({
        message: strings.requirements.missingCredentials,
        type: 'error',
        id: 'requirementTitleError',
      });

    removeToast('requirementTitleError');
    const onValid = (data: unknown): void => {
      /**
       * `RequirementPartial` validation will remove `id` property
       * because it is not a part of `RequirementPartial` type. That's why
       * we need to manually add it to `updateRequirement` action payload.
       */
      const payload = {
        ...(data as UIRequirementChangeset),
        id: requirement.id,
      };
      reduxStore.dispatch(updateRequirement(payload));
    };
    maybeToast({ title: val }, 'RequirementPartial', onValid);
  });

  const manageRequirementTeam = useCallback(
    (action: UpdateRequirementTeamAction) => ({ id: teamId }: TeamFragment) => {
      reduxStore
        .dispatch(updateRequirement({ id: requirementId, [action]: [teamId] }))
        .then(unwrapResult)
        .catch(
          whenError(
            action === 'appendTeamIds'
              ? strings.requirements.failedToAssignTeam
              : strings.requirements.failedToRemoveTeam,
          ),
        );
    },
    [whenError, requirementId],
  );

  if (!requirement) return null;

  const handleRestoreArchivedRequirement = (): void => {
    reduxStore
      .dispatch(restoreArchivedRequirement({ id: requirement.id }))
      .then(unwrapResult)
      .then(() => {
        const message = strings
          .formatString(strings.requirements.restoreArchivedSuccess, {
            name: title,
          })
          .toString();
        return addToast({
          message,
          timeoutMs: 3000,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        const message = strings
          .formatString(strings.requirements.restoreArchivedFailure, {
            errorMessage: error.message,
          })
          .toString();
        addToast({
          message,
          type: 'error',
        });
      });
  };

  const handleArchiveRequirement = (): void => {
    reduxStore
      .dispatch(archiveRequirement({ id: requirement.id }))
      .then(unwrapResult)
      .then(() => {
        const message = strings
          .formatString(strings.requirements.archivedSuccess, {
            name: title,
          })
          .toString();
        return addToast({
          message,
          timeoutMs: 3000,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        const message = strings
          .formatString(strings.requirements.archivedFailure, {
            errorMessage: error.message,
          })
          .toString();
        addToast({
          message,
          type: 'error',
        });
      });
  };

  return (
    <RequirementBuilderLayout
      archiveRequirement={handleArchiveRequirement}
      assignedTeams={assignedTeams}
      manageRequirementTeam={manageRequirementTeam}
      orgId={orgId}
      requirement={requirement}
      restoreArchivedRequirement={handleRestoreArchivedRequirement}
      titleProps={titleProps}
    />
  );
}
