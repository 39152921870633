import { Dropdown, HStack, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { isNonEmptyString, notUndefined } from '@taraai/utility';
import { LabelChip } from 'components/core/controllers/views/LabelChip';
import Fuse from 'fuse.js';
import React, { MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { isDefaultLabel } from 'reduxStore';

const fuseOptions = {
  keys: ['title'],
  ignoreLocation: true,
};

export function LabelSelectorDropdown({
  children,
  getOptions,
  onCreate,
  onSelect,
  readOnly,
  searchQuery,
  show,
}: {
  children: ReactElement;
  getOptions: () => UI.UILabel[];
  onCreate: (text: string) => void;
  onSelect: (label: UI.UILabel) => void;
  /** If true, creating labels is turned off */
  readOnly?: boolean;
  searchQuery: string;
  show: boolean;
}): JSX.Element {
  const optionsOnShow = useOptionsOnShow(getOptions, show);

  const dropdownItems = useMemo(() => {
    const fuse = new Fuse(optionsOnShow, fuseOptions);
    const filteredOptions: Fuse.FuseResult<UI.UILabel>[] = isNonEmptyString(searchQuery)
      ? fuse.search(searchQuery)
      : optionsOnShow.map((label, index) => ({ item: label, refIndex: index }));
    const hasCreateLabelOption =
      !readOnly &&
      searchQuery &&
      !filteredOptions.some(({ item }) => item.title.toLocaleLowerCase() === searchQuery.toLocaleLowerCase());
    return [
      ...filteredOptions.map(({ item }) => (
        <Dropdown.DefaultItem key={item.id} onClick={() => onSelect(item)}>
          <HStack space='$8px'>
            <Text size='$12px'>
              <LabelChip description={isDefaultLabel(item) ? item.description : undefined}>#{item.title}</LabelChip>
            </Text>
            {isDefaultLabel(item) && item.description && (
              <Text color='$grey6' size='$12px'>
                ({item.description})
              </Text>
            )}
          </HStack>
        </Dropdown.DefaultItem>
      )),
      hasCreateLabelOption ? (
        <Dropdown.DefaultItem onClick={() => onCreate(searchQuery)}>
          <Text color='$focus' size='$12px'>
            Create a new label &quot;#{searchQuery}&quot;
          </Text>
        </Dropdown.DefaultItem>
      ) : undefined,
    ].filter(notUndefined);
  }, [onCreate, onSelect, optionsOnShow, readOnly, searchQuery]);

  return (
    <Dropdown
      items={dropdownItems}
      placement='bottom-start'
      preventBlurOnSelection
      selectOnTab
      show={show && dropdownItems.length > 0}
    >
      {children}
    </Dropdown>
  );
}

function useOptionsOnShow(getOptions: () => UI.UILabel[], show: boolean): UI.UILabel[] {
  // Prevent having getOptions as a hook dependency
  const getOptionsRef = useRef() as MutableRefObject<() => UI.UILabel[]>;
  getOptionsRef.current = getOptions;
  const [options, setOptions] = useState<UI.UILabel[]>([]);

  useEffect(() => {
    if (!show) return;
    setOptions(getOptionsRef.current);
    return () => setOptions([]);
  }, [show]);

  return options;
}
