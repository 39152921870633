import { unwrapResult } from '@reduxjs/toolkit';
import { DeleteWithConfirmationModal } from 'components/app/controllers/views/DeleteWithConfirmationModal/DeleteWithConfirmationModal';
import { linkTo, usePathParams } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteOrganization, reduxStore, selectActiveOrgIds } from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

export default function DeleteWorkspaceController(): JSX.Element {
  const history = useHistory();
  const { orgId, teamId } = usePathParams('workspace');
  const org = useSelector(selectOrganization(orgId));
  const organizationIds = useSelector(selectActiveOrgIds);
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const redirect = useCallback(() => {
    // filter out deleted org
    const availableOrgs = organizationIds.filter((id) => id !== orgId);

    if (availableOrgs.length > 0) {
      // user have another org to redirect to
      return history.push(linkTo('home', { orgId: availableOrgs[0], teamId }));
    }
    return history.push(linkTo('logout'));
  }, [history, orgId, organizationIds, teamId]);

  const onSubmit = useCallback(() => {
    setIsLoading(true);

    reduxStore
      .dispatch(deleteOrganization())
      .then(unwrapResult)
      .catch((error: Error) => {
        const message = error.message || strings.workspace.deleteWorkspace.error;
        addToast({
          message,
          timeoutMs: 3500,
          type: 'error',
        });
      });

    // Redirect to home page before organization is removed to prevent loading data errors [TASK-3921]
    redirect();
  }, [addToast, redirect]);

  const onClose = useCallback(() => {
    history.go(-1);
  }, [history]);

  return (
    <DeleteWithConfirmationModal isLoading={isLoading} nameToDelete={org.name} onClose={onClose} onSubmit={onSubmit} />
  );
}
