import { Avatar, Box } from '@taraai/design-system';
import { UserFragment } from 'components/app/TaskDetails/TaskDetails';
import React from 'react';

export interface Props {
  collaborators: UserFragment[];
  onClick: () => void;
}

export const CollaboratorsList = ({ collaborators, onClick }: Props): JSX.Element => (
  <>
    {collaborators?.map((collaborator) => (
      <Box key={collaborator.id} spaceVert='$2px'>
        <Box.Button onClick={onClick} width='$fitContent'>
          <Avatar title={collaborator.name} url={collaborator.avatarURL} />
        </Box.Button>
      </Box>
    ))}
  </>
);
