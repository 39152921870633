/* eslint-disable sonarjs/cognitive-complexity */
import { Data } from '@taraai/types';
import SprintColumnsController from 'components/app/controllers/SprintColumnsController';
import { DNDProvider } from 'components/app/DragAndDrop';
import { SidePanelsLayout } from 'components/app/layouts/SidePanelsLayout/SidePanelsLayout';
import SprintDetailsSidebarLayout from 'components/app/layouts/SprintDetailsSidebarLayout';
import { RequirementDetailsController } from 'components/app/RequirementDetails/RequirementDetails';
import { TaskDetails } from 'components/app/TaskDetails/TaskDetails';
import { WorkDrawerController } from 'components/app/WorkDrawer/WorkDrawerController';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { compose } from 'redux';
import {
  getSprint,
  getTask,
  selectActiveTeam,
  selectActiveWorkspace,
  selectSprintValue,
  selectTaskValue,
  selectTeam,
} from 'reduxStore';
import { useQuerySetState, useQueryValue } from 'tools';

/**
 * Sprint Plan page. This page is a part of Tara 3.0 redesign.
 */
export const SprintPage = React.memo(function SprintPage(): JSX.Element {
  const { sprintId } = useParams<{
    sprintId: Data.Id.SprintId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const typeFromQuery = useQueryValue('type');
  const idFromQuery = useQueryValue('active');
  const setQuery = useQuerySetState();

  const selectedTaskId = (typeFromQuery === 'task' && idFromQuery) || undefined;

  // gets sprint id from selected task
  const selectedTaskSprintId = useSelector((state) => selectTaskValue(state, selectedTaskId, 'sprint'));

  const setSelected = useCallback((name: string, id: string): void => setQuery({ type: name, active: id }), [setQuery]);

  const currentlyRunningSprintId =
    useSelector(compose((data) => data?.currentSprintId ?? null, selectTeam(orgId, teamId))) || '';

  const selectedSprintId =
    selectedTaskSprintId || (typeFromQuery === 'sprint' && idFromQuery) || sprintId || currentlyRunningSprintId;

  const selectedSlice = getSprint(orgId, selectedSprintId);
  // get task so that selectedTaskSprintId wont get stuck
  const selectedTaskSlice = getTask(orgId, selectedTaskId || '');

  useFirestoreConnect(selectedSlice.query);
  useFirestoreConnect(selectedTaskSlice.query);

  const isSprintLoaded = useSelector((state) => selectSprintValue(state, selectedSprintId, 'endDate') !== undefined);

  // if a task is selected wait until the corresponding sprint id is pulled from the store. If no task is selected return true to unblock rendering.
  const isTaskMaybeLoaded = selectedTaskId ? !!selectedTaskSprintId || selectedTaskSprintId === null : true;

  const handleTaskSelect = useCallback(
    (taskId: Data.Id.TaskId): void => {
      setSelected('task', taskId);
    },
    [setSelected],
  );

  const handleRequirementSelect = useCallback(
    (requirementId: Data.Id.RequirementId): void => {
      setSelected('requirement', requirementId);
    },
    [setSelected],
  );

  const handleSprintSelect = useCallback(
    (newSprintId?: Data.Id.SprintId): void => {
      setSelected('sprint', newSprintId || currentlyRunningSprintId);
    },
    [currentlyRunningSprintId, setSelected],
  );

  return (
    <DNDProvider>
      <SidePanelsLayout
        left={
          <WorkDrawerController
            onRequirementSelect={handleRequirementSelect}
            onTaskSelect={handleTaskSelect}
            selectedRequirementId={(typeFromQuery === 'requirement' && idFromQuery) || undefined}
            selectedTaskId={selectedTaskId}
          />
        }
        right={
          <>
            {[null, 'sprint'].includes(typeFromQuery) && selectedSprintId !== null && (
              <SprintDetailsSidebarLayout orgId={orgId} selectedSprintId={selectedSprintId} teamId={teamId} />
            )}
            {typeFromQuery === 'task' && (
              <TaskDetails orgId={orgId} showSummary taskId={idFromQuery ?? ''} teamId={teamId} />
            )}
            {typeFromQuery === 'requirement' && (
              <RequirementDetailsController orgId={orgId} requirementId={idFromQuery ?? ''} teamId={teamId} />
            )}
          </>
        }
      >
        {isSprintLoaded && isTaskMaybeLoaded && (
          <SprintColumnsController
            currentSprintId={currentlyRunningSprintId}
            onTaskSelect={handleTaskSelect}
            selectedSprintId={selectedSprintId}
            setSelectedSprintId={handleSprintSelect}
          />
        )}
      </SidePanelsLayout>
    </DNDProvider>
  );
});
