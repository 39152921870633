/**
 * Insert as close as possible to the desired index.
 * Also ensures that no duplicates are in the array
 * @param item - The item to insert in the array
 * @param index - The index in the visible array you'd like to insert
 * @param visibleList - The array displayed in React
 * @param entireList - The entire array from the Firestore Document
 * @returns
 */
export const insertNear = (
  item: string,
  index: number,
  visibleList?: string[],
  entireList?: string[] | null,
): string[] | null => {
  if (!visibleList || index === -1) return null;

  // if visibleList != entireList then insert into entireList
  if (entireList && entireList.length !== visibleList.length) {
    return insertAfterPreviousVisibleItem(item, index, visibleList, entireList);
  }

  // is item in displayed array
  const itemIndex = visibleList.findIndex((id) => id === item);
  // if item in the list then remove
  if (itemIndex > -1) {
    visibleList.splice(itemIndex, 1);
  }
  // add item into new index
  visibleList.splice(index, 0, item);

  return visibleList;
};

const insertAfterPreviousVisibleItem = (
  item: string,
  index: number,
  visibleList: string[],
  entireList: string[],
): string[] | null => {
  const idx = visibleList.findIndex((_item) => _item === item);
  if (idx > -1) {
    visibleList.splice(idx, 1);
  }
  visibleList.splice(index, 0, item);

  // find previous item
  const priorIdx = visibleList.findIndex((_item) => _item === item) - 1;
  const priorId = visibleList[priorIdx];

  // if item in entireList then remove
  const indexNow = entireList.findIndex((id) => id === item);
  if (indexNow > -1) {
    entireList.splice(indexNow, 1);
  }

  // find previous item in entireList array
  const insertAfter = entireList.findIndex((id) => id === priorId);
  entireList.splice(insertAfter + 1, 0, item);

  return entireList;
};
