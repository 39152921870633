import { Box, styled } from '@taraai/design-system';
import Text from 'components/core/controllers/views/Text';
import React from 'react';
import { strings } from 'resources/i18n';
import { formatDMMM } from 'tools/libraries/helpers/dates';

type SprintType = 'active' | 'complete' | 'upcoming';

type SprintColumnHeaderViewProps = {
  type: SprintType;
  sprintName: string;
  onSelectSprint: () => void;
  endDateSeconds: number;
  startDateSeconds: number;
  effortCompleted: number;
  effortTotal: number;
};

export function SprintColumnHeaderView({
  effortCompleted,
  effortTotal,
  onSelectSprint,
  type,
  sprintName,
  endDateSeconds,
  startDateSeconds,
}: SprintColumnHeaderViewProps): JSX.Element {
  return (
    <Box.Button onClick={onSelectSprint}>
      <SprintHeader type={type === 'active'}>
        <HeaderItemWrapper>
          <Title type={type}>{sprintName}</Title>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <DateRange type={type}>
            {formatDMMM(startDateSeconds)}
            {strings.sprints.sprintColumn.sprintDateSpace}
            {formatDMMM(endDateSeconds)}
          </DateRange>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <TaskCompletionRate type={type}>
            {strings.formatString(strings.sprints.sprintColumn.totalEffortCompleted, {
              effortCompleted,
              effortTotal,
            })}
          </TaskCompletionRate>
        </HeaderItemWrapper>
      </SprintHeader>
    </Box.Button>
  );
}

const SprintHeader = styled(
  Box,
  {
    display: 'flex',
    order: '0',
    flexGrow: '0',
    borderRadius: '$4px $4px $none $none',
    backgroundColor: '$white',
    padding: '$4px $8px',
  },
  {
    type: {
      true: {
        backgroundColor: '$indigo',
      },
    },
  },
);

const HeaderItemWrapper = styled(Box, {
  lineHeight: '$12px',
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Title = styled(
  Text,
  {
    fontWeight: '$medium',
    fontSize: '$12px',
    marginRight: 'auto',
  },
  {
    type: {
      active: { color: '$white' },
      complete: { color: '$success' },
      upcoming: { color: '$grey7' },
    },
  },
);

const DateRange = styled(
  Text,
  {
    alignSelf: 'flex-end',
    fontWeight: '$regular',
    fontSize: '$12px',
    whiteSpace: 'nowrap',
  },
  {
    type: {
      active: { color: '$white' },
      complete: { color: '$success' },
      upcoming: { color: '$grey6' },
    },
  },
);

const TaskCompletionRate = styled(
  Text,
  {
    fontWeight: '$medium',
    fontSize: '$12px',
    marginLeft: 'auto',
  },
  {
    type: {
      active: { color: '$white' },
      complete: { color: '$success' },
      upcoming: { color: '$grey6' },
    },
  },
);
