"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
var utility_1 = require("@taraai/utility");
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var core_1 = require("./core");
var Hidden_1 = require("./Hidden");
var AccordionContext = react_1.createContext({ toggle: utility_1.noop, unfolded: false });
function Accordion(_a) {
    var children = _a.children, _b = _a.initialUnfolded, initialUnfolded = _b === void 0 ? false : _b, onChange = _a.onChange, forceUnfolded = _a.unfolded;
    var _c = react_1.useState(initialUnfolded), unfolded = _c[0], setUnfolded = _c[1];
    var toggle = react_1.useCallback(function () {
        if (typeof forceUnfolded === 'boolean') {
            /* istanbul ignore next */
            return onChange === null || onChange === void 0 ? void 0 : onChange(!forceUnfolded);
        }
        setUnfolded(function (prevUnfolded) {
            onChange === null || onChange === void 0 ? void 0 : onChange(!prevUnfolded);
            return !prevUnfolded;
        });
    }, [forceUnfolded, onChange]);
    return (react_1.default.createElement(AccordionContext.Provider, { value: { toggle: toggle, unfolded: forceUnfolded !== null && forceUnfolded !== void 0 ? forceUnfolded : unfolded } }, children));
}
exports.Accordion = Accordion;
Accordion.Widget = AccordionContext.Consumer;
/** Accepts the same props as Box.Button */
Accordion.Header = function AccordionHeader(props) {
    var toggle = react_1.useContext(AccordionContext).toggle;
    return react_1.default.createElement(Box_1.Box.Button, __assign({}, props, { onClick: toggle }));
};
Accordion.Arrow = function AccordionArrow(_a) {
    var clickArea = _a.clickArea;
    var _b = react_1.useContext(AccordionContext), toggle = _b.toggle, unfolded = _b.unfolded;
    var arrow = unfolded ? arrows.unfolded : arrows.folded;
    return (react_1.default.createElement(Box_1.Box.Button, { onClick: function (event) {
            event.stopPropagation();
            toggle();
        } },
        react_1.default.createElement("div", { style: __assign({ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0 }, clickArea) }),
        arrow));
};
var arrows = {
    folded: (react_1.default.createElement("svg", { fill: core_1.tokens.colors.$grey6, height: '16', width: '16', xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("path", { d: 'M10.5 8L7.9 5.4a.3.3 0 00-.4.2v4.8c0 .2.3.3.4.2L10.5 8z' }))),
    unfolded: (react_1.default.createElement("svg", { fill: core_1.tokens.colors.$grey7, height: '16', width: '16', xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("path", { d: 'M8 10.5l2.6-2.6c.1-.1 0-.4-.2-.4H5.6c-.2 0-.3.3-.2.4L8 10.5z' }))),
};
Accordion.Content = function AccordionContent(props) {
    return react_1.default.createElement(Hidden_1.Hidden, __assign({ hidden: !react_1.useContext(AccordionContext).unfolded }, props));
};
Accordion.FoldedContent = function AccordionFoldedContent(props) {
    return react_1.default.createElement(Hidden_1.Hidden, __assign({ hidden: react_1.useContext(AccordionContext).unfolded }, props));
};
