import { UpgradeController } from 'components/app/monetization/Upgrade';
import React from 'react';

/**
 * Upgrade
 * route for Upgrade
 *
 */
export default function Upgrade(): JSX.Element {
  return <UpgradeController />;
}
