import { Data } from '@taraai/types';
import { intercomUpdateCompany } from 'intercom';
import React, { useEffect } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Redirect, useLocation, useParams } from 'react-router';
import { compose } from 'redux';
import {
  getCustomLabels,
  getOrg,
  getOrgTeams,
  getUsers,
  getWorkspaceCustomClaimsQuery,
  listOrganizationUsers,
  reduxStore,
  selectActiveOrgIds,
  selectPreferredTeamId,
  selectUserTeams,
  setTeam,
  setWorkspace,
} from 'reduxStore';
import { getTeamId } from 'route-utils';
import { segment } from 'tools/libraries/analytics';

import { getRouteName, linkTo } from './paths';

/**
 * Shows spinner until organization document is loaded from firestore
 */
export const OrgAndTeamIsLoaded: React.FC = ({ children }): JSX.Element | null => {
  const location = useLocation();
  const { orgId, ...params } = useParams<{ orgId: Data.Id.OrganizationId }>();
  const orgSlice = getOrg(orgId);
  const userSlice = getUsers(orgId);
  const teamSlice = getOrgTeams(orgId);
  const labelsSlice = getCustomLabels(orgId);
  const workspaceCustomClaimsSlice = getWorkspaceCustomClaimsQuery(orgId);

  const orgName = useSelector(compose((data) => data?.name, orgSlice.selector));
  const uids = useSelector(
    compose((data) => data?.map(({ id }) => id), userSlice.selector),
    deepEquals,
  );
  const teamNames = useSelector(
    compose((data) => data?.map(({ name }) => name), teamSlice.selector),
    deepEquals,
  );
  const profileHasAccessToOrg = useSelector(
    compose((organizationIds) => organizationIds.includes(orgId), selectActiveOrgIds),
  );
  const workspaceCustomClaims = useSelector(workspaceCustomClaimsSlice.selector);

  useFirestoreConnect([
    ...orgSlice.query,
    ...userSlice.query,
    ...teamSlice.query,
    ...labelsSlice.query,
    ...workspaceCustomClaimsSlice.query,
  ]);

  const userTeams = useSelector(selectUserTeams(orgId), deepEquals);

  useEffect(() => {
    segment.multiGroup(userTeams.map((team) => ({ ...team, orgId })));
  }, [orgId, userTeams]);

  // When the org, user custom-claims or users in org changes get the new access levels
  useEffect(() => {
    // theoretically user without custom claims should never get here
    // to be 100% sure that user has rights to request organization data
    // there should be another layer of abstraction checking that
    // this is not part of a hotfix so ticket for that: https://app.tara.ai/tara-ai/tasks/2273
    if (profileHasAccessToOrg) {
      reduxStore.dispatch(listOrganizationUsers());
    }
  }, [profileHasAccessToOrg]);

  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const routeTeamId = getTeamId();
  const orgTeams = useSelector(getOrgTeams(orgId).selector);
  const orgTeamsCount = (orgTeams ?? []).length;
  const teamId = routeTeamId && orgTeams?.some(({ id }) => id === routeTeamId) ? routeTeamId : undefined;

  // Each time user switches workspace, re-initialize Intercom.
  // TODO: Consider re-initializing Fullstory and Firebase Analytics.
  useEffect(() => {
    if (orgId && orgId !== '404') {
      intercomUpdateCompany(orgId, orgTeamsCount);
      reduxStore.dispatch(setWorkspace(orgId));
    }
    if (teamId) {
      reduxStore.dispatch(setTeam(teamId));
    }
  }, [orgId, teamId, orgTeamsCount]);

  if (!isLoaded(orgName) || !isLoaded(uids) || !isLoaded(teamNames) || !isLoaded(workspaceCustomClaims)) {
    return null;
  }

  /**
   * We need support for legacy links without team id in the URL
   * for tasks and requirements.
   */
  const isLegacyRoute = getRouteName().startsWith('LEGACY_');
  if (isLegacyRoute && !teamId) {
    const routeWithPreferredTeam = location.pathname.replace(orgId, `${orgId}/${preferredTeamId}`);

    return <Redirect to={routeWithPreferredTeam} />;
  }

  if (!teamId) {
    return (
      <Redirect
        to={linkTo(getRouteName(), {
          ...params,
          orgId,
          teamId: preferredTeamId,
          // TODO: linkTo requires static params but in order to have global
          //       navigation the route must be a variable
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)}
      />
    );
  }

  return <>{children}</>;
};
