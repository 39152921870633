import styled from '@emotion/styled';
import { Data, UI } from '@taraai/types';
import TeamSelector from 'components/app/controllers/Selectors/TeamSelector';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace, UpdateRequirementTeamAction } from 'reduxStore';
import { atomic, strings } from 'resources';
import { requirementDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { FirebaseInput } from 'tools';

import HeaderMenu from './HeaderMenu';

type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;
interface Props {
  archiveRequirement: () => void;
  assignedTeams: TeamFragment[];
  manageRequirementTeam: (action: UpdateRequirementTeamAction) => (team: TeamFragment) => void;
  requirement: UI.UIRequirement;
  titleProps: FirebaseInput<string>;
  restoreArchivedRequirement: () => void;
}

/**
 * Header for the builder page, currently displaying title of requirement
 * This component contains a performance issue as it is using useParams
 * and it is not at the very top of the rendering tree.
 * We are skipping it because it is
 * @deprecated
 */
export function RequirementBuilderHeader({
  archiveRequirement,
  assignedTeams,
  manageRequirementTeam,
  requirement,
  titleProps,
  restoreArchivedRequirement,
  ...props
}: Props): JSX.Element {
  const { requirementId } = useParams<{
    requirementId: Data.Id.RequirementId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  return (
    <>
      <BackToRequirementsWrapper>
        <Link className={BackToRequirementsLink} to={linkTo('requirements', { orgId, teamId })}>
          <Icon color={atomic.get(atomic.colors.primary)} name='sprintleft' />
          <Text a className={BackToRequirementsText}>
            {strings.requirements.backToRequirements}
          </Text>
        </Link>
      </BackToRequirementsWrapper>
      <Wrapper>
        <Avatar key={requirement.id} className={avatarClassName} requirement={requirement} size='3rem' />
        <Input
          data-cy={requirementDetailsTestIds.HEADER_NAME_INPUT}
          naked
          {...titleProps}
          style={{
            main: inputMainClassName,
          }}
        />
        <TeamSelectorWrapper>
          <TeamSelector
            assignedTeams={assignedTeams}
            linkToCreateTeam={linkTo('requirementCreateTeam', {
              orgId,
              teamId,
              requirementId,
            })}
            onDeselectOption={manageRequirementTeam('removeTeamIds')}
            onSelectOption={manageRequirementTeam('appendTeamIds')}
          />
        </TeamSelectorWrapper>
        <HeaderMenu
          archiveRequirement={archiveRequirement}
          requirement={requirement}
          titleProps={titleProps}
          {...props}
          restoreArchivedRequirement={restoreArchivedRequirement}
        />
      </Wrapper>
    </>
  );
}

const BackToRequirementsWrapper = styled.div`
  max-width: 64rem;
  width: 100%;
  display: flex;
`;

const BackToRequirementsLink = css`
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 1rem;
`;

const BackToRequirementsText = css`
  line-height: 1rem;
  padding-top: 0.15rem;
  color: ${atomic.get(atomic.colors.primary)};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem 0.5rem 1.5rem;
  width: 100%;
  max-width: 65.5rem;
`;

const TeamSelectorWrapper = styled.div`
  margin-left: 1rem;
`;

const avatarClassName = css`
  margin-right: 1rem;
`;

const inputMainClassName = css`
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal;
  color: #191919;
  padding-left: 0;
  &:focus-within {
    border-radius: 0.1875rem;
    border: solid 0.0625rem #dee3ec;
  }
`;
