import { keyframes } from '@emotion/core';
import { compose, unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import TaskModal from 'components/app/controllers/TaskModal';
import Modal from 'components/core/controllers/views/Modal';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import {
  deleteTask,
  getTask,
  getTaskComments,
  reduxStore,
  selectActiveTeam,
  selectActiveWorkspace,
  selectPreferredTeamId,
  taskSubTasks,
} from 'reduxStore';
import strings from 'resources/i18n';
import { useToast } from 'tools';

export interface TaskModalControllerProps extends React.HTMLProps<HTMLElement> {
  initialTaskId: string;
  closeModal: () => void;
}

/**
 * TaskModalController
 * controller specific logic for task modal
 *
 */
export default function TaskModalController({
  initialTaskId,
  closeModal,
}: TaskModalControllerProps): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const { whenError } = useToast();
  const history = useHistory();

  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSlice = getTask(orgId, initialTaskId);
  const taskCommentsSlice = getTaskComments(orgId, initialTaskId);
  const subTasksSlice = taskSubTasks(orgId, initialTaskId);

  useFirestoreConnect([...taskSlice.query, ...taskCommentsSlice.query, ...subTasksSlice.query]);

  const task = useSelector(taskSlice.selector);
  const subtaskIds = (useSelector(compose(getSubtaskIds, subTasksSlice.selector), deepEquals) ?? []) as Pick<
    UI.UITask,
    'id'
  >[];
  const taskComments = useSelector(taskCommentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));

  useHotkeys('esc', () => closeModal(), {}, []);

  const isNotLoaded = !isLoaded(task) || !isLoaded(taskComments);

  if (isNotLoaded) {
    return (
      <Modal
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
      >
        <StandardSpinner fillSpace size='medium' />
      </Modal>
    );
  }

  const deleteCurrentTask = (): void => {
    if (!task) return;
    reduxStore
      .dispatch(deleteTask([...subtaskIds, { id: initialTaskId }]))
      .then(unwrapResult)
      .catch(() => {
        whenError(strings.task.failedToDeleteTask);
      });
    closeModal();
    history.push(linkTo('sprints', { orgId, teamId: teamId ?? preferredTeamId }));
  };

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/*/tasks/${initialTaskId}`);
    setTaskCopiedText(true);
  };

  if (!task) return null;

  return (
    task && (
      <TaskModal
        key={task.id}
        author={task.authorDocument}
        closeModal={closeModal}
        copyTaskId={copyTaskId}
        currentOrg={orgId}
        deleteCurrentTask={deleteCurrentTask}
        setTaskCopiedText={setTaskCopiedText}
        task={task}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
      />
    )
  );
}

const getSubtaskIds = (data: UI.UITask[] | undefined): Pick<UI.UITask, 'id'>[] | undefined =>
  data?.map(({ id }) => ({
    id,
  }));

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
