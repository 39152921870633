import { Data } from '@taraai/types';
import SprintColumnsLayout from 'components/app/layouts/SprintColumnsLayout';
import { browserHistory } from 'components/Router/history';
import { linkTo } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { getSprintTasks, RootState, selectActiveTeam, selectActiveWorkspace, selectSprintDocument } from 'reduxStore';
import getSprintsWindow from 'reduxStore/sprints/queries/getSprintsWindow';
import { useQueryValue } from 'tools';
import { fromEpoch, toEpoch } from 'tools/libraries/helpers/dates';
import { useShallowMemo } from 'tools/utils/hooks/general';
import { usePagination } from 'tools/utils/hooks/usePagination';

const pageSize = 6; // this should be even

type Props = {
  currentSprintId: Data.Id.SprintId;
  selectedSprintId: Data.Id.SprintId;
  setSelectedSprintId: (id: Data.Id.SprintId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
};

/**
 * SprintColumnsController is responsible for the sprints moving window columns page data management
 * Accesses higher level data that will be passed to multiple child components in SprintColumnsLayout
 */

export default function SprintColumnsController({
  currentSprintId,
  selectedSprintId,
  setSelectedSprintId,
  onTaskSelect,
}: Props): JSX.Element | null {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const typeFromQuery = useQueryValue('type');
  const idFromQuery = useQueryValue('active');
  const selectedTaskId = (typeFromQuery === 'task' && idFromQuery) || undefined;

  const selectedSprintEndDateSeconds = useSelector((state: RootState) => {
    const sprint = selectSprintDocument(state, selectedSprintId);
    return sprint ? toEpoch(sprint.endDate) : undefined;
  });

  const loadSprintWindow = useCallback(
    (limitBefore, limitAfter, pivotEndDate) => {
      return getSprintsWindow(orgId, teamId, fromEpoch(pivotEndDate), {
        limitBefore,
        limitAfter,
      });
    },
    [orgId, teamId],
  );

  const { items: sprints, loadNext, loadPrev, loadCurrentSprint, setSprint } = usePagination(
    pageSize,
    selectedSprintId,
    currentSprintId,
    selectedSprintEndDateSeconds || toEpoch(new Date()),
    loadSprintWindow,
  );

  // bulk load all tasks up top
  const taskSlices = getSprintTasks(orgId, sprints?.map(([, id]) => id) ?? []);
  useFirestoreConnect(taskSlices.flatMap(({ query }) => query));

  const showSprintDetailsSidebar = useCallback((): void => {
    history.push(linkTo('sprints', { orgId, teamId }));
  }, [history, orgId, teamId]);

  const selectNextSprint = useCallback((): void => {
    const nextSprintId = loadNext();
    if (!nextSprintId) return;

    browserHistory.pushRoute(
      linkTo('sprintSelected', {
        orgId,
        teamId,
        sprintId: nextSprintId,
      }),
    );
    if (nextSprintId) {
      showSprintDetailsSidebar();
      setSelectedSprintId(nextSprintId);
    }
  }, [loadNext, orgId, setSelectedSprintId, showSprintDetailsSidebar, teamId]);

  const selectPrevSprint = useCallback((): void => {
    const prevSprintId = loadPrev();
    if (!prevSprintId) return;

    browserHistory.pushRoute(
      linkTo('sprintSelected', {
        orgId,
        teamId,
        sprintId: prevSprintId,
      }),
    );
    if (prevSprintId) {
      showSprintDetailsSidebar();
      setSelectedSprintId(prevSprintId);
    }
  }, [loadPrev, orgId, setSelectedSprintId, showSprintDetailsSidebar, teamId]);

  const selectCurrentSprint = useCallback((): void => {
    const initialSprintId = loadCurrentSprint();
    if (!sprints || !initialSprintId) return;

    browserHistory.pushRoute(
      linkTo('sprintSelected', {
        orgId,
        teamId,
        sprintId: initialSprintId,
      }),
    );
    setSelectedSprintId(initialSprintId);
  }, [loadCurrentSprint, orgId, setSelectedSprintId, sprints, teamId]);

  const selectSprint = useCallback(
    (sprintId: Data.Id.SprintId): void => {
      const activeSprint = setSprint(sprintId);
      if (activeSprint) {
        showSprintDetailsSidebar();
        setSelectedSprintId(activeSprint);
      }
    },
    [setSelectedSprintId, setSprint, showSprintDetailsSidebar],
  );

  return useShallowMemo(
    () => (
      <SprintColumnsLayout
        currentSprintId={currentSprintId}
        onSelectCurrentSprint={selectCurrentSprint}
        onSelectNextSprint={selectNextSprint}
        onSelectPrevSprint={selectPrevSprint}
        onSelectSprint={selectSprint}
        onTaskSelect={onTaskSelect}
        selectedSprintId={selectedSprintId}
        selectedTaskId={selectedTaskId}
        sprints={sprints}
      />
    ),
    [
      currentSprintId,
      onTaskSelect,
      selectCurrentSprint,
      selectNextSprint,
      selectPrevSprint,
      selectSprint,
      selectedSprintId,
      selectedTaskId,
      sprints,
    ],
  );
}
