import { compose, createSelector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import SprintDetailsLayout from 'components/app/layouts/SprintDetailsLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace, selectTeam } from 'reduxStore';
import { findInIterator, getAdjacentItemsIterator } from 'tools/helpers/iterators';
import { sort } from 'tools/libraries/helpers/sort';
import { markPage, Marks } from 'tools/utils/perfmarks';

import { useDataLoader } from './useDataLoader';

/** Pass through only active sprint and sprints that are before it. */
const isSprintBeforeOrActive = (activeSprint: UI.UISprint | undefined) => (sprint: UI.UISprint): boolean => {
  if (!activeSprint) {
    // when there is no active sprint, show only completed ones
    return sprint.isComplete;
  }
  const isCurrentlyActiveSprint = activeSprint.id === sprint.id;
  const sprintStartsBeforeActive = activeSprint?.sprintNumber > sprint.sprintNumber;
  return sprintStartsBeforeActive || isCurrentlyActiveSprint;
};

/**
 * SprintDetailsController does…
 * Accesses higher level data that will be passed to multiple child components in SprintDetailsLayout
 */
export default function SprintDetailsController(): JSX.Element | null {
  const { sprintId: sprintIdParam } = useParams<{
    sprintId: Data.Id.SprintId;
  }>();

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const {
    activeSprint,
    completedSprints,
    requirements,
    selectedSprint,
    selectedSprintData,
    sprintTasks,
    sprintTasksSelector,
    upcomingSprint,
  } = useDataLoader();

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgId, teamId)));
  const sprintId = sprintIdParam === 'current' ? currentSprintId : sprintIdParam;

  const sortedTasksSelector = createSelector(sprintTasksSelector, (allTasks) =>
    sort(allTasks || [], 'taskAssigneeNameStatus'),
  );

  const sprintsToSort = activeSprint ? [...completedSprints, ...upcomingSprint] : completedSprints;

  const allSprintIdsSorted = sort(Array.from(sprintsToSort), 'sprintNumber')
    .filter(isSprintBeforeOrActive(activeSprint))
    .map((sprint) => sprint.id);

  const adjacentSprints = findInIterator(
    getAdjacentItemsIterator(allSprintIdsSorted),
    ({ current }) => current === selectedSprint?.id,
  );

  const lastCompletedFromInactiveSprint =
    !selectedSprint?.id && allSprintIdsSorted.slice(allSprintIdsSorted.length - 1)[0];

  // Consider page loaded when there is no sprint id
  const loaded = !sprintId || (isLoaded(selectedSprint) && isLoaded(sprintTasks));

  if (!isLoaded(currentSprintId)) {
    return null;
  }

  if (loaded) {
    markPage(Marks.PageSprintDetails, true);
  }

  return (
    <SprintDetailsLayout
      currentSprintId={currentSprintId}
      isLoaded={loaded}
      nextSprintId={adjacentSprints?.next || null}
      organizationId={orgId}
      previousSprintId={adjacentSprints?.prev || lastCompletedFromInactiveSprint || null}
      requirements={requirements}
      selectedSprint={selectedSprint}
      selectedSprintData={selectedSprintData}
      tasksSelector={sortedTasksSelector}
    />
  );
}
