import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { FirebaseThunkAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';

export const deleteRequirement = createAsyncThunk<
  Pick<UI.UIRequirement, 'id'>,
  Pick<UI.UIRequirement, 'id'>,
  FirebaseThunkAPI
>('DeleteRequirement', async ({ id: requirementId }, { extra: { getFirestore, getOrgId } }) => {
  const orgId = getOrgId();

  if (!isNonEmptyString(requirementId)) throw strings.requirements.missingCredentials;

  await getFirestore().update<Tara.Requirement>(`orgs/${orgId}/requirements/${requirementId}`, {
    deleted: true,
  });
  return { id: requirementId };
});
