/* eslint-disable @typescript-eslint/no-explicit-any */
import { compose, createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { noop, parseLabelsFromPlainText, unique } from '@taraai/utility';
import LegacyTaskModalContext from 'components/app/controllers/LegacyTaskModalContext';
import RequirementTasksListEditorView from 'components/app/controllers/views/RequirementTasksListEditorView';
import { RichEditorHandle } from 'components/editor/RichEditor';
import flow from 'lodash.flow';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { createTask, getCustomLabels, getUsers, reduxStore, requirementTasks, updateTask } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { sort } from 'tools/libraries/helpers/sort';
import {
  filterTaraTasks,
  getResults,
  getTaskStatusOptions,
  searchTaraTasks,
} from 'tools/libraries/helpers/tableFilter';

export interface RequirementTasksEditorControllerProps {
  orgId: Data.Id.OrganizationId;
  requirement: UI.UIRequirement;
}

export default function RequirementTasksEditorController({
  orgId,
  requirement,
}: RequirementTasksEditorControllerProps): JSX.Element {
  const teamUsers = useSelector(getUsers(orgId).selector) ?? [];

  const requirementTasksSlice = requirementTasks(orgId, requirement.id);
  useFirestoreConnect(requirementTasksSlice.query);
  const sortedAllTasks = createSelector(requirementTasksSlice.selector, (allTasksData) =>
    sort(allTasksData ?? [], 'createdAt', true),
  );

  const tasksData = useSelector(requirementTasksSlice.selector);
  const customLabels = useSelector(getCustomLabels(orgId).selector);
  const statusFilterOptions = getTaskStatusOptions();

  // Track the state of whether or not a person is searching and also the search results
  const [searchResults, setSearchResults] = useState<UI.UITask[]>([]);
  const [filterResults, setFilterResults] = useState<UI.UITask[]>([]);
  const [intersectedTasks, setIntersectedTasks] = useState<UI.UITask[]>([]);
  useEffect(() => {
    const combinedResults = getResults(searchResults, filterResults);
    setIntersectedTasks(combinedResults);
  }, [filterResults, searchResults]);

  const finalTasks = useSelector(
    compose((sortedAllTasksData) => (!intersectedTasks.length ? sortedAllTasksData : intersectedTasks), sortedAllTasks),
  );

  const { openModal } = useContext(LegacyTaskModalContext);
  const toggleModal = useCallback((task: UI.UITask) => openModal(task, requirement.title), [
    openModal,
    requirement.title,
  ]);

  const { addToast, maybeToast } = useToast();
  const submitting = useRef(false);

  const taskEditorRef = useRef<RichEditorHandle>(null);
  const onTaskSave = (title: string): void => {
    if (submitting.current) return;
    submitting.current = true;

    const labels = flow(parseLabelsFromPlainText, unique)(title);

    const onValid = async (data: UI.UITask): Promise<void> => {
      /**
       * `TaskPartial` validation will remove `requirement` property
       * because it is not a part of `TaskPartial` type. That's why
       * we need to manually add it to `createTask` action payload.
       */
      await reduxStore
        .dispatch(createTask({ ...data, _relationships: { requirement: requirement.id } }))
        .then(unwrapResult)
        .then((taskId) => {
          taskEditorRef.current?.clear();
          segment.track('TaskCreated', { orgID: orgId, taskID: taskId, location: 'Requirement' });
        })
        .catch(noop);
      submitting.current = false;
    };
    maybeToast({ title, labels }, 'TaskPartial', onValid as <T extends unknown>(data: T) => void);
  };

  const addAssignee = useCallback(
    (taskId: string) => (assigneeId: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskId, assignee: assigneeId }))
        .then(unwrapResult)
        .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const removeAssignee = useCallback(
    (taskId: string) => (): void => {
      reduxStore
        .dispatch(updateTask({ id: taskId, assignee: null }))
        .then(unwrapResult)
        .catch((error: Error) => addToast({ type: 'error', message: error.message }));
    },
    [addToast],
  );

  /**
   * handleSearch accepts a string that then uses fusejs to search the collection
   */
  const handleSearch = useCallback(
    (query: string) => {
      if (query.length === 0) {
        setSearchResults([]);
      } else {
        const results = searchTaraTasks(tasksData as any[], query);
        setSearchResults(results);
      }
    },
    [tasksData],
  );

  const handleFilter = useCallback(
    (collectionToFilter: keyof UI.UITask, itemsToFilter: (string | number)[]) => {
      if (itemsToFilter.length === 0) {
        setFilterResults([]);
      } else {
        const filtered = filterTaraTasks(tasksData as any[], collectionToFilter, itemsToFilter);
        setFilterResults(filtered);
      }
    },
    [tasksData],
  );

  return (
    <RequirementTasksListEditorView
      addAssignee={addAssignee}
      addFeatureRef={taskEditorRef}
      customLabels={customLabels}
      handleFilter={handleFilter}
      handleSearch={handleSearch}
      onTaskSave={onTaskSave}
      orgId={orgId}
      removeAssignee={removeAssignee}
      statusFilterOptions={statusFilterOptions}
      tasksData={finalTasks.length > 0 ? finalTasks : (tasksData as any[])}
      team={teamUsers ?? []}
      toggleModal={toggleModal}
    />
  );
}
