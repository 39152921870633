import { Data, UI } from '@taraai/types';
import LastCheckIn from 'components/app/controllers/views/LastCheckIn';
import PullRequests from 'components/app/controllers/views/PullRequests';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getOpenPullRequests, getSprintCommits } from 'reduxStore';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { toDate, useInstallGitHub } from 'tools';

interface SprintDetailsSummaryProps {
  organizationId: Data.Id.OrganizationId;
  currentSprintId: string | undefined;
  sprint: UI.UISprint;
}

/**
 * SprintDetailsSummary
 * This component will render the last check in and open PR cards
 * If these components are not going to be rendered in this component, will delete
 */

export default function SprintDetailsSummary({
  organizationId,
  sprint,
  currentSprintId,
}: SprintDetailsSummaryProps): JSX.Element | null {
  const gitHubInstallation = useInstallGitHub();

  const sprintStartDate = toDate(sprint.startDate);
  const sprintEndDate = toDate(sprint.endDate);

  const prs = getOpenPullRequests(organizationId);
  const commits = getSprintCommits(organizationId, currentSprintId ?? '', sprintStartDate, sprintEndDate);
  const allSprintCommits = useSelector(commits.selector);
  const pullRequests = useSelector(prs.selector);

  const loaded = isLoaded(pullRequests) && isLoaded(allSprintCommits);

  useFirestoreConnect([...commits.query, ...prs.query]);

  const activeSprintSummary = (
    <div
      className={css`
        display: flex;
        align-items: stretch;
      `}
    >
      <LastCheckIn
        className={css`
          width: 50%;
        `}
        commits={!loaded ? undefined : allSprintCommits}
        installGitHubData={gitHubInstallation}
        organizationId={organizationId}
      />
      <PullRequests
        className={css`
          width: 50%;
        `}
        icon='pullRequest'
        installGitHubData={gitHubInstallation}
        orgId={organizationId}
        pullRequests={!loaded ? undefined : pullRequests}
        title={strings.pullRequests.empty.title}
      />
    </div>
  );

  return (
    <div>
      <Text
        className={css`
          font-size: 1rem;
          font-weight: 600;
          color: ${atomic.get(atomic.colors.dark)};
        `}
      >
        {strings.sprints.sprintDetails.summary}
      </Text>
      {/* TODO: add completedSprintSummary */}
      {activeSprintSummary}
    </div>
  );
}
