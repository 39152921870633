import { Data } from '@taraai/types';
import LegacyTaskController from 'components/app/controllers/LegacyTaskController';
import React from 'react';
import { useParams } from 'react-router-dom';

/**
 * TaskPage
 * page for deep link task
 * @deprecated
 */
export default function LegacyTaskPage(): JSX.Element {
  const { taskId } = useParams<{ taskId: Data.Id.TaskId }>();

  return <LegacyTaskController key={taskId} taskId={taskId} />;
}
