import { keyframes } from '@emotion/core';
import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import TaskModal from 'components/app/controllers/TaskModal';
import RemovedTaskView from 'components/app/controllers/views/RemovedTaskView';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { deleteTask, getTask, reduxStore, selectActiveWorkspace, taskSubTasks } from 'reduxStore';
import { getTaskComments } from 'reduxStore/comments/queries';
import strings from 'resources/i18n';
import { useToast } from 'tools/utils/hooks/useToast';

export interface TaskControllerProps {
  taskId: Data.Id.TaskId;
}

/**
 * TaskController
 * top level controller for high level task data
 */
export default function TaskController({ taskId }: TaskControllerProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const { whenError } = useToast();
  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSlice = getTask(orgId, taskId);
  const subTasksSlice = taskSubTasks(orgId, taskId);
  const commentsSlice = getTaskComments(orgId, taskId);

  const task = useSelector(taskSlice.selector);

  useFirestoreConnect([...taskSlice.query, ...commentsSlice.query, ...subTasksSlice.query]);

  const subtaskIds = useSelector(compose(getSubtaskIds, subTasksSlice.selector), deepEquals) ?? [];

  const taskComments = useSelector(commentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);

  const deleteCurrentTask = (): void => {
    task &&
      reduxStore
        .dispatch(deleteTask([...subtaskIds, { id: task.id }]))
        .then(unwrapResult)
        .catch(() => {
          whenError(strings.task.failedToDeleteTask);
        });
  };

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/*/tasks/${taskId}`);
    setTaskCopiedText(true);
  };

  if (!isLoaded(task) || !isLoaded(taskComments)) {
    return (
      <StandardSpinner
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
        fillSpace
        size='medium'
      />
    );
  }

  if (task.deleted) {
    return <RemovedTaskView currentOrg={orgId} />;
  }

  return (
    <TaskModal
      author={task.authorDocument}
      copyTaskId={copyTaskId}
      currentOrg={orgId}
      deleteCurrentTask={deleteCurrentTask}
      noModal
      setTaskCopiedText={setTaskCopiedText}
      task={task}
      taskComments={taskComments}
      taskCopiedText={taskCopiedText}
    />
  );
}

const getSubtaskIds = (data: UI.UITask[] | undefined): Pick<UI.UITask, 'id'>[] | undefined =>
  data?.map(({ id }) => ({
    id,
  }));

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
