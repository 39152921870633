import { AddToastFn } from 'components/app/controllers/Toast/ToastContext';
import { PluginFunctions, RichEditorPlugin } from 'components/editor/types';

import { imageDecorator } from './imageDecorator';
import { createInsertFiles } from './insertFiles';

export const createAttachmentPlugin = (
  addToast: AddToastFn,
  uploadAttachment: (file: File) => Promise<string>,
): RichEditorPlugin => {
  const insertFiles = createInsertFiles(addToast, uploadAttachment);
  return {
    decorator: [imageDecorator],
    handleDroppedFiles: insertFiles,
    handlePastedFiles: (files: Array<File>, pluginFunctions: PluginFunctions) =>
      insertFiles(null, files, pluginFunctions),
  };
};
