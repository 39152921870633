import { compose } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import SprintInsightsModal from 'components/app/controllers/views/SprintInsightsModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import {
  estimatedEffortSelector,
  getCompletedSprints,
  getSprint,
  getSprintTasks,
  selectActiveTeam,
  selectActiveWorkspace,
  totalEffortSelector,
} from 'reduxStore';

/**
 * SprintInsightsController handles logic for sprint and effort information in modal
 */
export default function SprintInsightsController(): JSX.Element {
  const { sprintId } = useParams<{
    sprintId: Data.Id.SprintId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const tasksSlice = getSprintTasks(orgId, sprintId ?? '');
  const sprintSlice = getSprint(orgId, sprintId ?? '');
  const completedSlice = getCompletedSprints(orgId, teamId);

  useFirestoreConnect([...tasksSlice.query, ...sprintSlice.query, ...completedSlice.query]);

  const currentSprint = useSelector(sprintSlice.selector);
  const lastNCompletedSprints = useSelector(
    compose(
      (arr) =>
        arr
          ?.map((data) => ({
            id: data.id,
            sprintName: data.sprintName,
            computedOnCompletion: data.computedOnCompletion,
          }))
          .slice(-3),
      completedSlice.selector,
    ),
  );
  const estimatedEffort = useSelector(estimatedEffortSelector(completedSlice.selector, sprintSlice.selector)) ?? 0;

  const totalEffort = useSelector(totalEffortSelector(sprintSlice.selector, tasksSlice.selector));

  return (
    <SprintInsightsModal
      currentSprint={currentSprint}
      estimatedEffort={estimatedEffort}
      lastNCompletedSprints={lastNCompletedSprints}
      overload={totalEffort > estimatedEffort && estimatedEffort > 0}
      totalEffort={totalEffort}
    />
  );
}
