import { Data, UI } from '@taraai/types';
import DashboardStatistics from 'components/app/controllers/DashboardStatistics';
import DashboardTasks from 'components/app/controllers/DashboardTasks';
import { css } from 'emotion';
import React from 'react';

export interface DashboardSprintProps {
  orgId: Data.Id.OrganizationId;
  allTeamsWithActiveSprintsOrdered: UI.UITeam[];
  activeSprintIds: Data.Id.SprintId[];
}

/**
 * DashboardSprint contains the views and controllers for
 * all user sprint related information
 *
 */
export default function DashboardSprint({
  orgId,
  allTeamsWithActiveSprintsOrdered,
  activeSprintIds,
}: DashboardSprintProps): JSX.Element {
  return (
    <div>
      <div
        className={css`
          margin-top: 1rem;
          display: flex;
          flex-direction: column;
          border: 0.0625rem solid #dee3ec;
          border-radius: 0.125rem 0.125rem 0rem 0rem;
        `}
      >
        {activeSprintIds.length > 0 && (
          <div
            className={css`
              height: 0.375rem;
              border-radius: 0.125rem 0.125rem 0rem 0rem;
              background-color: #389e0d;
            `}
          />
        )}
        <div
          className={css`
            display: flex;
            flex-direction: row;
          `}
        >
          {activeSprintIds.length > 0 && (
            <DashboardStatistics
              activeSprintIds={activeSprintIds}
              allTeamsWithActiveSprintsOrdered={allTeamsWithActiveSprintsOrdered}
              orgId={orgId}
            />
          )}
          <DashboardTasks activeSprintIds={activeSprintIds} orgId={orgId} />
        </div>
      </div>
    </div>
  );
}
