import { Box, fade, styled } from '@taraai/design-system';
import { TaskStatus } from '@taraai/types';
import React, { CSSProperties } from 'react';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

import { FilterColor } from './types';

export type ProgressBackgroundMode = 'primary' | 'secondary' | 'transparent' | 'disabled';
type Props = {
  /** Number between `0` and `1` */
  progress: number;
  color: FilterColor;
  children: React.ReactNode;
  /**
   * Available modes:
   * - `primary` - bar is 15% opaque, background is 5% opaque
   * - `secondary` - bar is 5% opaque, background is transparent
   * - `transparent` - both bar and background are transparent
   * - `disabled` - - both bar and background are transparent and opacity is 0.5
   */
  mode: ProgressBackgroundMode;
};

export function ProgressBackground({ progress, color, children, mode }: Props): JSX.Element {
  return (
    <StyledProgressBackground
      borderRadius='$2px'
      color={color}
      mode={mode}
      space='$4px'
      style={
        {
          '--percentage': `${progress * 100}%`,
        } as CSSProperties
      }
    >
      {children}
    </StyledProgressBackground>
  );
}

const StyledProgressBackground = styled(
  Box,
  {
    transition: 'background-position 0.2s ease',
    background:
      'linear-gradient(90deg, var(--fgColor) 0%, var(--fgColor) 50%, transparent 50%, transparent 100%), var(--bgColor)',
    backgroundSize: '200% 100%',
    backgroundPosition: 'calc(100% - var(--percentage))',
  },
  {
    mode: {
      primary: { '--fgColor': 'var(--primaryColor)', '--bgColor': 'var(--secondaryColor)' },
      secondary: { '--fgColor': 'var(--secondaryColor)', '--bgColor': 'transparent' },
      transparent: { '--fgColor': 'transparent', '--bgColor': 'transparent' },
      disabled: { '--fgColor': 'transparent', '--bgColor': 'transparent', 'opacity': '0.5' },
    },
    color: {
      [mapTaskStatusToColor[TaskStatus.Todo]]: {
        '--primaryColor': fade(mapTaskStatusToColor[TaskStatus.Todo], '$10%'),
        '--secondaryColor': fade(mapTaskStatusToColor[TaskStatus.Todo], '$5%'),
      },
      [mapTaskStatusToColor[TaskStatus.Doing]]: {
        '--primaryColor': fade(mapTaskStatusToColor[TaskStatus.Doing], '$10%'),
        '--secondaryColor': fade(mapTaskStatusToColor[TaskStatus.Doing], '$5%'),
      },
      [mapTaskStatusToColor[TaskStatus.Done]]: {
        '--primaryColor': fade(mapTaskStatusToColor[TaskStatus.Done], '$10%'),
        '--secondaryColor': fade(mapTaskStatusToColor[TaskStatus.Done], '$5%'),
      },
      $failure: { '--primaryColor': fade('$failure', '$10%'), '--secondaryColor': fade('$failure', '$5%') },
      $grey7: { '--primaryColor': fade('$grey7', '$10%'), '--secondaryColor': fade('$grey7', '$5%') },
    },
  },
);
