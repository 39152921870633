/* eslint-disable no-secrets/no-secrets */
// 100mb max attachment size
export const ONE_HUNDRED_MEGABYTES = 1e8;

export const TEN_MINUTES = 600000;

export const LIMIT_NUMBER_OF_SPRINTS = 3;

export const COMPLETE_AND_CREATE_NEW_SPRINT = 'COMPLETE_AND_CREATE_NEW_SPRINT';

export const TRELLO_EXPORT_INSTRUCTION_URL = 'https://help.trello.com/article/747-exporting-data-from-trello-1';

export const ASANA_EXPORT_INSTRUCTION_URL = 'https://blog.asana.com/2014/09/export-to-csv/';

export const PRODUCT_GUIDE = 'https://help.tara.ai/';

export const JOIN_COMMUNITY_URL =
  'https://join.slack.com/t/taracommunity/shared_invite/zt-ew0w5uyf-m0hXYHnWC_rZNIbfFWzQzQ';

export const TWO_MEGABYTES = 2e6;

export const THREE_MINUTES = 180000;
