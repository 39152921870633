"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTaskStatus = exports.TaskStatus = exports.Schema = exports.UI = exports.Functions = exports.TypescriptGenerator = exports.TypescriptDecoder = exports.Decoders = exports.DataDecoder = exports.Data = exports.Changesets = void 0;
var DataDecoder = __importStar(require("ts.data.json"));
exports.DataDecoder = DataDecoder;
var Changesets = __importStar(require("./changesets"));
exports.Changesets = Changesets;
var Data = __importStar(require("./data"));
exports.Data = Data;
var TypescriptDecoder = __importStar(require("./decoder"));
exports.TypescriptDecoder = TypescriptDecoder;
var Decoders = __importStar(require("./decoders"));
exports.Decoders = Decoders;
var Functions = __importStar(require("./functions"));
exports.Functions = Functions;
var TypescriptGenerator = __importStar(require("./generator"));
exports.TypescriptGenerator = TypescriptGenerator;
var Schema = __importStar(require("./schema"));
exports.Schema = Schema;
var UI = __importStar(require("./ui"));
exports.UI = UI;
/**
 * A task status.
 */
var TaskStatus;
(function (TaskStatus) {
    TaskStatus[TaskStatus["Todo"] = 0] = "Todo";
    TaskStatus[TaskStatus["Doing"] = 1] = "Doing";
    TaskStatus[TaskStatus["Done"] = 2] = "Done";
})(TaskStatus = exports.TaskStatus || (exports.TaskStatus = {}));
function isTaskStatus(value) {
    return value === TaskStatus.Todo || value === TaskStatus.Doing || value === TaskStatus.Done;
}
exports.isTaskStatus = isTaskStatus;
