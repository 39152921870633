import { Data } from '@taraai/types';
import { UIRequirement } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { Selector } from 'react-redux';
import { OrderByOptions, WhereOptions } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import {
  createIndividualSelector,
  createStandardSelector,
  IndividualQuery,
  inertIndividualQuery,
  inertQuery,
  Query,
} from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

/**
 * Private Query Builder
 */

type OrderBy = keyof Pick<UIRequirement, 'title' | 'updatedAt' | 'createdAt'>;

function queryBuilder(orgId: string, where: WhereOptions[], ordered?: OrderBy): Query<UIRequirement> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  let orderBy: OrderByOptions | undefined;
  switch (ordered) {
    case 'createdAt':
      orderBy = ['createdAt', 'desc'];
      break;
    case 'title':
      orderBy = ['title', 'asc'];
      break;
    case 'updatedAt':
      orderBy = ['updatedAt', 'desc'];
      break;
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/requirements`,
    where,
    populates: [['author', 'users', 'authorDocument']],
    storeAs: `requirements/${orgId}/${where}-${ordered}`,
  };

  if (orderBy) query.orderBy = orderBy;

  return {
    query: [query],
    selector: createStandardSelector<UIRequirement>(query),
  };
}

export type RequirementListSelector = Selector<RootState, UIRequirement[] | undefined>;

export type RequirementSelector = Selector<RootState, UIRequirement | undefined>;

/**
 * Public functions
 */
/**
 * Gets requirements from global/team scope
 * @param orgId
 * @param teamId - if not provided it will get all requirements
 * @param options
 */
export function getRequirements(
  orgId?: string,
  teamId?: Data.Id.TeamId | null,
  options?: { orderBy?: OrderBy },
): Query<UIRequirement> {
  if (!orgId) {
    return inertQuery();
  }
  const where: WhereOptions[] = [
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  if (teamId) {
    where.push(['assignedTeamIds', 'array-contains', teamId]);
  }

  return queryBuilder(orgId, where, options?.orderBy ?? 'createdAt');
}

export function getRequirement(orgId: string, requirementId: string): IndividualQuery<UIRequirement> {
  if (!isNonEmptyString(requirementId)) {
    return inertIndividualQuery();
  }
  const queryRequirement: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/requirements`,
    where: [['__name__', '==', requirementId]],
    populates: [['author', 'users', 'authorDocument']],
    storeAs: `requirements/${orgId}/${requirementId}`,
  };

  return {
    query: [queryRequirement],
    selector: createIndividualSelector(queryRequirement),
  };
}

export function getArchivedRequirements(orgId: string, options?: { orderBy: OrderBy }): Query<UIRequirement> {
  const where: WhereOptions[] = [
    ['archived', '==', true],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgId, where, options?.orderBy ?? 'createdAt');
}
